import React, { useState } from "react";
import UploadPhotoHeader from "./UploadPhotoHeader";
import FileUpload from "../SummarizerNew/FileUpload";
import UploadPhotoForm from "./UploadPhotoForm";
import VerifyPhotoForm from "./VerifyPhotoForm";

const REQUIREMENTS = [
  {
    id: 1,
    label:
      "Streamlines and accelerates facial recognition with an intuitive interface.",
    img: "/images/planIcon.png",
  },
  {
    id: 2,
    label: "Upload photo.",
    img: "/images/BulbIcon.png",
  },
  {
    id: 3,
    label:
      "Analyze faces and compare original images side-by-side for precise verification.",
    img: "/images/InfoIcon.png",
  },
];

const TAB_DATA = [
  { id: 1, name: "Upload face", value: "UPLOAD_FACE" },
  { id: 2, name: "Verify face", value: "VERIFY_FACE" },
];

const UploadPhoto = () => {
  const [isGetStarted, setIsGetStarted] = useState(false);
  const [selectedTab, setSelectedTab] = useState("UPLOAD_FACE");

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] lg:h-screen bg-[#FCFCFC] dark:bg-gray-900 overflow-auto">
      <UploadPhotoHeader title={"Face Recognition"} />

      {isGetStarted ? (
        <>
          <div className="flex items-center mx-auto mt-6 gap-9 border-b border-gray-300 w-fit">
            {TAB_DATA?.map(({ id, name, value }) => (
              <button
                key={id}
                onClick={() => setSelectedTab(value)}
                className={`${
                  selectedTab === value
                    ? "border-[#005CFF] !text-[#005CFF]"
                    : "border-transparent"
                } font-poppins text-base font-medium text-[#989898] pb-2 border-b-2  m-[-1px]`}
              >
                {name}
              </button>
            ))}
          </div>
          {selectedTab === "UPLOAD_FACE" && <UploadPhotoForm />}

          {selectedTab === "VERIFY_FACE" && <VerifyPhotoForm />}
        </>
      ) : (
        <div className="h-[calc(100vh-60px)] py-3">
          <FileUpload
            title={"Face Recognition"}
            setIsGetStarted={setIsGetStarted}
            data={REQUIREMENTS}
          />
        </div>
      )}
    </div>
  );
};

export default UploadPhoto;
