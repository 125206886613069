import React from "react";
import useChatStore from "../../stores/chatStore";
import { motion } from "framer-motion";

const ChatHistory = ({ toggleHistory }) => {
  const { histories, selectHistory, addNewHistory } = useChatStore();

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      exit={{ x: "-100%" }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white h-full flex flex-col overflow-hidden shadow-lg"
    >
      <div className="flex justify-between items-center p-4 bg-gray-100 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
        <h2 className="text-xl font-poppins font-semibold">Chat History</h2>
        <button
          onClick={toggleHistory}
          className="text-gray-500 font-poppins hover:text-gray-700 dark:text-gray-300 dark:hover:text-white transition-colors duration-200"
          aria-label="Close chat history"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="p-4 space-y-3">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={addNewHistory}
            className="w-full font-poppins text-left p-3 bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 rounded-md transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 text-white font-medium"
          >
            <span className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              New Chat
            </span>
          </motion.button>
          {histories.map((history, index) => (
            <motion.button
              key={history.id}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => selectHistory(index)}
              className="w-full text-left p-3 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 rounded-md transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <div className="flex items-center">
                <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center mr-3 flex-shrink-0">
                  <span className="text-white font-poppins font-bold text-xs">
                    {history.id}
                  </span>
                </div>
                <div className="flex-grow min-w-0">
                  <h3 className="font-medium font-poppins text-sm truncate">
                    {history.messages[1]?.text || "New Chat"}
                  </h3>
                  <p className="text-xs font-poppins text-gray-500 dark:text-gray-400 mt-1 truncate">
                    {history.messages[2]?.text || "No messages yet"}
                  </p>
                </div>
              </div>
            </motion.button>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ChatHistory;
