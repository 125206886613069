import React, { useState } from "react";

const ImagesList = () => {
  const [images, setImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleImageUpload = (e) => {
    const uploadedImages = Array.from(e.target.files).map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      size: (file.size / 1024).toFixed(2) + " KB",
      uploadDate: new Date().toLocaleDateString(),
    }));
    setImages([...images, ...uploadedImages]);
  };

  const handleDeleteImage = (imageName) => {
    setImages(images.filter((img) => img.name !== imageName));
  };

  const filteredImages = images.filter((img) =>
    img.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Manage Your Images
          </h2>
          <p className="text-gray-600 mb-4">
            Upload, search, and manage your images efficiently.
          </p>

          {/* Image Upload Section */}
          <div className="mb-6">
            <label
              htmlFor="imageUpload"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Upload Images:
            </label>
            <input
              type="file"
              id="imageUpload"
              multiple
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search images..."
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredImages.length > 0 ? (
              filteredImages.map((img, index) => (
                <div
                  key={index}
                  className="relative group rounded-lg overflow-hidden bg-gray-100 shadow"
                >
                  <img
                    src={img.url}
                    alt={img.name}
                    className="w-full h-48 object-cover"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <div className="text-center text-white">
                      <p className="text-lg font-semibold">{img.name}</p>
                      <div className="mt-2 flex justify-center space-x-4">
                        <button
                          onClick={() => handleDeleteImage(img.name)}
                          className="bg-red-500 hover:bg-red-600 text-white py-1 px-4 rounded-lg"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-span-full text-center text-gray-500">
                <p>No images found. Upload new images to get started.</p>
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

export default ImagesList;
