import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

const SettingsModel = ({ isOpen, setIsOpen, settings, setSettings }) => {
  const handleToggle = (id) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id
          ? { ...setting, isSelect: !setting.isSelect }
          : setting
      )
    );
  };

  useEffect(() => {}, [settings]);

  return (
    <>
      {isOpen && (
        <div className="bg-[#FCFCFC] dark:bg-gray-700 flex flex-col gap-6 w-full p-6 shadow-shadow-box rounded-xl">
          <div className="flex w-full items-center justify-between">
            <h2 className="text-xl tracking-[-0.4px] font-medium">Settings</h2>

            <button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <MdClose size={23} />
            </button>
          </div>

          <div className="flex flex-col items-start gap-3 w-full">
            <div className="flex items-center justify-between w-full gap-1.5">
              <div className="flex flex-col max-w-[175px] gap-0.5 w-full">
                <h3 className="text-sm font-medium tracking-[-0.28px] dark:text-white text-blackDark-10">
                  Masking Strength
                </h3>
                <p className="text-[10px] font-normal tracking-[-0.2px]">
                  Choose to what degree you want to mask your sensitive
                  information.
                </p>
              </div>
              <span className="p-2 rounded-lg text-blue-20 font-medium text-sm tracking-[-0.28px] bg-blue-10 border border-blue-30">
                100%
              </span>
            </div>

            {settings?.map(({ id, label, description, isSelect }) => (
              <div
                key={id}
                className="flex items-center justify-between w-full gap-1.5"
              >
                <div className="flex flex-col max-w-[175px] gap-0.5 w-full">
                  <h3 className="text-sm font-medium tracking-[-0.28px] dark:text-white text-blackDark-10">
                    {label}
                  </h3>
                  <p className="text-[10px] font-normal tracking-[-0.2px]">
                    {description}
                  </p>
                </div>

                <button
                  onClick={() => handleToggle(id)}
                  className={`w-9 p-[2px] h-5 rounded-full outline-none transition-colors duration-200 ${
                    isSelect ? 'bg-blue-45' : 'bg-gray-300'
                  }`}
                >
                  <div
                    className={`bg-white w-4 h-4 rounded-full transition-transform duration-200 ${
                      isSelect ? 'translate-x-4' : 'translate-x-0'
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsModel;
