import React from 'react';
import { motion } from 'framer-motion';

const CodingAI = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col p-6">
      <section
        id="hero"
        className="flex items-center justify-center p-6 rounded-lg bg-gray-50 shadow-shadow-div"
      >
        <div className="text-center">
          <motion.h1
            className="text-4xl font-extrabold mb-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Coding AI
          </motion.h1>
          <p className="text-xl mb-8">
            Advanced coding solutions for modern healthcare.
          </p>
          <motion.button
            className="bg-blue-600 text-white px-6 py-3 rounded-lg mr-4"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Try Now
          </motion.button>
          <motion.button
            className="bg-gray-300 text-gray-800 px-6 py-3 rounded-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Learn More
          </motion.button>
        </div>
      </section>

      <section
        id="features"
        className="p-6 bg-gray-50 mt-6 rounded-lg  shadow-shadow-div"
      >
        <div className="text-center p-4">
          <h2 className="text-3xl font-bold mb-8">Features</h2>
          <div className="flex flex-wrap justify-center">
            <motion.div
              className="bg-white shadow-lg rounded-lg p-6 m-4 w-full md:w-1/3"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h3 className="text-xl font-semibold mb-2">Feature 1</h3>
              <p>Description of the feature.</p>
            </motion.div>
          </div>
        </div>
      </section>

      <section className="flex gap-5">
        <div className="p-6 bg-gray-50 mt-6 rounded-lg w-full shadow-shadow-div">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-8">See It in Action</h2>
            <div className="mb-8">
              <video className="mx-auto" controls>
                <source src="demo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <button className="bg-blue-600 text-white px-6 py-3 rounded-lg">
              Start Demo
            </button>
          </div>
        </div>

        <div className="p-6 bg-gray-50 mt-6 rounded-lg w-full shadow-shadow-div">
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>
            <p>If you have any questions, feel free to contact us!</p>
            <button className="bg-blue-600 text-white px-6 py-3 rounded-lg mt-4">
              Contact Us
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CodingAI;
