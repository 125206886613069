import React, { useEffect, useState, useRef } from 'react';

import { ChevronDown, Send } from 'react-feather';

import { useTransition, animated, config } from '@react-spring/web';
import { toast } from 'react-toastify';
import axios from 'axios';

const formatText = (text) => {
  const lines = text.split('\n');
  return lines.map((line, index) => {
    const trimmedLine = line?.trim();
    if (trimmedLine.startsWith('Answer:') || trimmedLine.endsWith(':')) {
      return (
        <p key={index} className="font-poppins mb-2">
          {trimmedLine}
        </p>
      );
    } else if (/^\d+\./.test(trimmedLine)) {
      return (
        <p key={index} className="font-poppins ml-4 mb-1">
          {trimmedLine}
        </p>
      );
    } else {
      return (
        <p key={index} className="font-poppins mb-1">
          {trimmedLine}
        </p>
      );
    }
  });
};

const ChatBody = ({ messages, loading }) => {
  const messagesEndRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const transitions = useTransition(messages, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: (item) => async (next) => {
      await next({ opacity: 1, transform: 'translateY(0)' });
    },
    leave: { opacity: 0 },
    trail: 100,
    config: config.gentle,
    keys: (message, index) => index,
  });

  useEffect(() => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, loading]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        messagesEndRef.current.parentElement;
      setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
    };

    const scrollContainer = messagesEndRef.current.parentElement;
    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const renderMessageContent = (msg) => {
    return <div className="message-content">{formatText(msg.text)}</div>;
  };

  return (
    <div className="relative flex-1 p-4 overflow-y-auto helpdesk-custom-scrollbar bg-gray-50 dark:bg-gray-900 scroll-smooth">
      {transitions((style, msg, _, index) => (
        <animated.div
          style={index === messages.length - 1 ? style : {}}
          className={`flex mb-6 ${
            msg.type === 'ai' ? 'justify-start' : 'justify-end'
          }`}
          key={index}
        >
          {msg.type === 'ai' && (
            <div className="flex-shrink-0 mr-4">
              <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center shadow-md">
                <img
                  alt="AI Agent"
                  src="https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                  className="w-6 h-6"
                />
              </div>
            </div>
          )}
          <div
            className={`p-4 rounded-lg max-w-xl shadow-lg ${
              msg.type === 'ai'
                ? 'bg-white dark:bg-gray-800 text-gray-800 dark:text-white'
                : 'bg-[#377FFF] text-white'
            }`}
          >
            {renderMessageContent(msg)}
          </div>
        </animated.div>
      ))}
      {loading && (
        <div className="flex mb-6 justify-start">
          <div className="flex-shrink-0 mr-4">
            <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center shadow-md">
              <img
                alt="AI Agent"
                src="https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                className="w-6 h-6"
              />
            </div>
          </div>
          <div className="p-4 rounded-lg shadow-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-white">
            <div className="flex justify-center items-center space-x-2">
              <div className="w-2 h-2 bg-[#377FFF] rounded-full animate-pulse"></div>
              <div className="w-2 h-2 bg-[#377FFF] rounded-full animate-pulse delay-75"></div>
              <div className="w-2 h-2 bg-[#377FFF] rounded-full animate-pulse delay-150"></div>
            </div>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
      {showScrollButton && (
        <button
          className="fixed bottom-10 right-4 bg-[#377FFF] text-white rounded-full p-3 shadow-xl hover:bg-blue-600 transition-all duration-300 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
          onClick={scrollToBottom}
        >
          <ChevronDown size={24} />
        </button>
      )}
    </div>
  );
};

const Nl2SQLTable = ({ data, fileName }) => {
  if (!data || data.length === 0) return null;

  const headers = Object.keys(data.columns);

  return (
    <div className="overflow-x-auto helpdesk-custom-scrollbar">
      <h3 className="text-lg font-semibold mb-2">{fileName}</h3>
      <table className="w-full">
        <thead className="bg-gray-50 dark:bg-gray-700">
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                className="p-2 text-left text-sm font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
              >
                {data.columns[header]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
          {data?.rows?.map((row, index) => (
            <tr
              key={index}
              className="hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-200 ease-in-out"
            >
              {headers.map((header) => (
                <td
                  key={`${index}-${header}`}
                  className="p-2 text-sm text-gray-700 dark:text-gray-300"
                >
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="animate-pulse w-full h-full">
    {/* Table Header */}
    <div className="mb-4 h-10 bg-gray-200 dark:bg-gray-700 rounded"></div>

    {/* Table Rows */}
    {[...Array(10)].map((_, rowIndex) => (
      <div key={rowIndex} className="flex mb-4">
        {[...Array(5)].map((_, colIndex) => (
          <div
            key={colIndex}
            className="h-8 bg-gray-200 dark:bg-gray-700 rounded mr-2 flex-grow"
            style={{ width: `${Math.floor(Math.random() * 15 + 10)}%` }}
          ></div>
        ))}
      </div>
    ))}

    {/* Query Input Skeleton */}
    <div className="mt-8 h-12 bg-gray-200 dark:bg-gray-700 rounded"></div>
  </div>
);

//TODO: Cleanup all states whenever we move out of this page!

const NL2SQL = () => {
  const [localQuery, setLocalQuery] = useState('');
  const [isChatDisabled] = useState(false);
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [nl2SqlData, setNl2SqlData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getInitialData = async () => {
    try {
      setIsPreviewLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_NL2SQL}/top-5-rows`
      );
      console.log('>>>', response);

      if (response.status === 200) {
        setIsPreviewLoading(false);
        console.log('response', response.data);
        setNl2SqlData(response.data);
      } else {
        setIsPreviewLoading(false);
        toast.error('Fetch data failed.');
        throw new Error('Fetch data failed');
      }
    } catch (error) {
      setIsPreviewLoading(false);
      console.error('Error Fetch data:', error);
      //  set({ error: "Failed to upload PDF.", isUploadLoading: false });
      toast.error('Error Fetch data.');
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {}, [nl2SqlData]);

  const handleLocalQuery = async () => {
    setMessages((prev) => [...prev, { text: localQuery, type: 'user' }]);
    setLocalQuery('');

    try {
      // Make API call
      setIsQueryLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_NL2SQL}/ask_nlsql`,
        {
          question: localQuery,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setIsQueryLoading(false);
        setMessages((prev) => [
          ...prev,
          {
            text: response?.data?.answer,
            type: 'ai',
          },
        ]);
      } else {
        setIsQueryLoading(false);
        toast.error('Message sending failed.');
        throw new Error('Message failed');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && localQuery) {
      handleLocalQuery();
    }
  };

  return (
    <div className="p-4 md:p-6 bg-gray-100 dark:bg-gray-900 min-h-screen font-sans">
      <div className="w-full mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">
          Natural Language to SQL
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* Chat Interface */}
          <div className="bg-white dark:bg-gray-800 rounded-lg rounded-tr-none rounded-br-none shadow-md overflow-y-auto  flex flex-col h-[calc(100vh-110px)]">
            <ChatBody messages={messages} loading={isQueryLoading} />
            <div className="p-4 bg-white dark:bg-gray-800">
              <div
                className={`flex items-center bg-gray-100 dark:bg-gray-700 rounded-lg ${
                  isChatDisabled ? 'opacity-50' : ''
                }`}
              >
                <input
                  type="text"
                  value={localQuery}
                  onChange={(e) => setLocalQuery(e.target.value)}
                  placeholder={
                    isChatDisabled
                      ? 'Select CSV files to start querying'
                      : 'Ask about the CSV data...'
                  }
                  className="flex-grow p-3 font-poppins bg-transparent border-none focus:outline-none focus:ring-0 text-gray-800 dark:text-gray-200"
                  disabled={isChatDisabled}
                  onKeyDown={handleKeyDown}
                />
                <button
                  onClick={handleLocalQuery}
                  className={`p-3 ${
                    isChatDisabled
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-[#377FFF] hover:text-blue-600'
                  } focus:outline-none transition-all duration-300 ease-in-out`}
                  disabled={isPreviewLoading || isChatDisabled}
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          </div>

          {/* Nl2SQLTable Tables */}
          <div className="bg-white dark:bg-gray-800 rounded-lg rounded-tl-none rounded-bl-none   shadow-md overflow-y-auto  h-[calc(100vh-110px)]">
            <div className="h-full overflow-y-auto px-2 py-2 helpdesk-custom-scrollbar">
              {isPreviewLoading ? (
                <SkeletonLoader />
              ) : (
                Object.keys(nl2SqlData).length > 0 &&
                Object.entries(nl2SqlData).map(([fileName, data], index) => (
                  <>
                    <div
                      key={fileName}
                      className={`mb-6 ${
                        index > 0
                          ? 'pt-6 border-t border-gray-200 dark:border-gray-700'
                          : ''
                      }`}
                    >
                      <Nl2SQLTable data={data} fileName={fileName} />
                    </div>
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NL2SQL;
