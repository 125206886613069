import React, { useState } from 'react';
import StartIcon from '../assets/icons/StartIcon';

const StarFillButton = ({ isFillBtn }) => {
  const [isFill, setIsFill] = useState(isFillBtn);
  return (
    <div
      onClick={(e) => {
        e?.stopPropagation();
        setIsFill(!isFill);
      }}
      className="cursor-pointer"
    >
      <StartIcon isFill={isFill} />
    </div>
  );
};

export default StarFillButton;
