import React from "react";
import jsonData from "../../../components/MedicalScribingNew/jsonData.json";

const SELECT_DATA = [
  {
    id: 1,
    name: "Section Header",
    color: {
      bg: "#FFD7D7",
      text: "#F5095C",
    },
  },
  {
    id: 2,
    name: "Age",
    color: {
      bg: "#D5F7D8",
      text: "#238B5B",
    },
  },
  {
    id: 3,
    name: "Race/Ethnicity",
    color: {
      bg: "#FFDFD3",
      text: "#FF9E7A",
    },
  },
  {
    id: 4,
    name: "Gender",
    color: {
      bg: "#EBF2FF",
      text: "#377FFF",
    },
  },
  {
    id: 5,
    name: "Oncological",
    color: {
      bg: "#E6C7AF",
      text: "#71390E",
    },
  },
  {
    id: 6,
    name: "Psychological Condition",
    color: {
      bg: "#FFFDDB",
      text: "#DBCD00",
    },
  },
  {
    id: 7,
    name: "Modifier",
    color: {
      bg: "#EDDBFF",
      text: "#7432BB",
    },
  },
  {
    id: 8,
    name: "Disease Syndrome Disorder",
    color: {
      bg: "#DAFEFF",
      text: "#007D80",
    },
  },
  {
    id: 9,
    name: "Date",
    color: {
      bg: "#F2F2F2",
      text: "#949494",
    },
  },
  {
    id: 10,
    name: "Admission Discharge",
    color: {
      bg: "#FFD0DF",
      text: "#8B485D",
    },
  },
  {
    id: 11,
    name: "Symptom",
    color: {
      bg: "#E1DCFF",
      text: "#240FAB",
    },
  },
  {
    id: 12,
    name: "Clinical Department",
    color: {
      bg: "#E4F6D2",
      text: "#639F23",
    },
  },
];

const NOTES_DATA = [
  {
    id: 1,
    name: "Back Pain Check-Up",
    authorData: ["John Jones", "Aug 08 2024, 3:22 AM", "English (Global)"],
  },
  {
    id: 2,
    name: "Headaches 9/23",
    authorData: ["Pat Bellamy", "Aug 08 2024, 3:22 AM", "English (Global)"],
  },
  {
    id: 3,
    name: "Diarrhea Complaints",
    authorData: ["Alexis Gupta", "Aug 08 2024, 3:22 AM", "English (Global)"],
  },
];

const NotesICDCodeBreakdownTab = ({ activeIndex }) => {
  return (
    <div className="p-6 mb-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4 items-start">
        <h4 className="text-2xl font-medium text-blackDark-10 dark:text-white">
          {NOTES_DATA[activeIndex]?.name}
        </h4>

        <div className="flex items-center gap-3">
          <div className="bg-[#D9D9D9] size-5 rounded-full" />
          {NOTES_DATA[activeIndex]?.authorData?.map((label, index) => (
            <p
              key={index}
              className="text-sm font-normal dark:text-white text-blackDark-10"
            >
              {label}
            </p>
          ))}
        </div>
      </div>

      <div className="w-full flex items-center gap-3 flex-wrap m-2.5">
        {SELECT_DATA?.map(({ id, name, color }) => (
          <button
            key={id}
            className={`border duration-300 px-3 py-1.5 font-medium rounded-full`}
            style={{
              color: color?.text,
              backgroundColor: color?.bg,
              borderColor: color?.text,
            }}
          >
            {name}
          </button>
        ))}
      </div>

      <div className="relative dark:border-gray-600 border overflow-y-hidden flex items-center border-gray-10 py-12 rounded-xl">
        <div className="text-[#737373] dark:text-white flex flex-wrap gap-2.5 px-12  w-full text-base font-normal font-poppins tracking-[-0.08px]">
          {jsonData
            ? jsonData?.map(({ Chunk, name, text, color }, key) => (
                <div key={key} className="h-fit">
                  {name ? (
                    <div
                      key={name}
                      className="border gap-2 rounded-3xl flex items-center flex-col justify-center border-blue-600 w-fit py-2 px-4 bg-blue-100"
                      style={{
                        borderColor: color?.text,
                        backgroundColor: color?.bg,
                      }}
                    >
                      <p className="bg-white w-fit text-[#737373]  text-center rounded-full text-base px-3 font-poppins font-normal">
                        {Chunk}
                      </p>
                      <span
                        className="font-poppins text-base font-medium"
                        style={{
                          color: color?.text,
                        }}
                      >
                        {name}
                      </span>
                    </div>
                  ) : (
                    <div
                      key={name}
                      className="font-poppins py-2 dark:text-white text-black text-base leading-6"
                    >
                      {text}
                    </div>
                  )}
                </div>
              ))
            : "Your summary will be generated here."}
        </div>
      </div>
    </div>
  );
};

export default NotesICDCodeBreakdownTab;
