export const UpArrow = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 6L6 2.5M6 2.5L9.5 6M6 2.5V9.5"
        stroke="#238B5B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
