/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';

import useAuthStore from '../stores/authStore';
import { DateIcon } from '../assets/icons/DateIcon';
import { DownArrowIcon } from '../assets/icons/DownArrowIcon';
import { ExportIcon } from '../assets/icons/exportIcon.js';

import Frame from './Frame';
import ProjectsSection from './Dashboard/ProjectsSection.js';
import UpcomingMeetings from './Dashboard/UpcomingMeetings.js';
import MapSection from './Dashboard/MapSection.js';
import RecentConversationsTable from './Dashboard/RecentConversationsTable.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const Dashboard = () => {
  const { user } = useAuthStore();

  return (
    <Frame name={'Dashboard'}>
      <div className="flex justify-between items-center md:flex-row flex-col gap-3">
        <div>
          <h1 className="text-2xl leading-9 text-black dark:text-white font-medium -tracking-[2%]">
            👋 Good morning, {user?.username || 'User'}
          </h1>
          <p className="text-sm -tracking-[0.5%] leading-6 text-[#A1A1A1] dark:text-[#A1A1A1] font-medium">
            Here's an overview of the major KPI's for your company and the
            metrics most related to you.
          </p>
        </div>
        <div>
          <div className="flex gap-2">
            <button className="flex items-center gap-1.5 p-2 border rounded-lg border-blue-10 duration-3000 font-medium bg-blue-50 text-[#377FFF] hover:bg-blue-100">
              <DateIcon />
              <span>Monthly</span>
              <DownArrowIcon />
            </button>

            <button className="flex gap-1.5 items-center px-4 py-2 bg-blue-20 text-white font-medium rounded-lg hover:bg-blue-45 duration-300">
              <ExportIcon />
              <span>Export</span>
            </button>
          </div>
        </div>
      </div>

      <MapSection />

      <div className="mt-6 flex flex-col gap-9 w-full pb-10">
        <ProjectsSection />

        <div className="flex xl:flex-row flex-col gap-[46px]">
          <UpcomingMeetings />

          <div className="flex flex-col gap-6 w-full">
            <div className="w-full flex items-center justify-between">
              <h3 className="text-black dark:text-white font-medium text-xl tracking-[-2%] font-poppins">
                Recent Conversations
              </h3>
              <button
                type="button"
                className="text-[#9747FF] font-semibold text-base font-poppins tracking-[-2%]"
              >
                See all
              </button>
            </div>
            <div className="w-full overflow-x-auto">
              <RecentConversationsTable />
            </div>
          </div>
        </div>
      </div>
    </Frame>
  );
};

export default Dashboard;
