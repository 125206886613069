/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';

const UpcomingMeetingsData = [
  {
    id: 1,
    dateNumber: '22',
    dateString: 'Feb',
    title: 'UX Interview x Laura Hill',
    time: '2:00 PM - 2:30 PM',
    link: 'meet.google.com/mbv-adfa-plg',
  },
  {
    id: 2,
    dateNumber: '22',
    dateString: 'Feb',
    title: 'UX Interview x Laura Hill',
    time: '2:00 PM - 2:30 PM',
    link: 'meet.google.com/mbv-adfa-plg',
  },
  {
    id: 3,
    dateNumber: '22',
    dateString: 'Feb',
    title: 'UX Interview x Laura Hill',
    time: '2:00 PM - 2:30 PM',
    link: 'meet.google.com/mbv-adfa-plg',
  },
];

const UpcomingMeetings = () => {
  return (
    <div className="xl:max-w-[385px] w-full flex flex-col gap-6">
      <h2 className="text-black font-medium text-xl tracking-[-2%]  dark:text-white font-poppins">
        Upcoming Meetings
      </h2>

      <div className="flex flex-col gap-3">
        {UpcomingMeetingsData?.map(
          ({ id, dateNumber, dateString, link, time, title }) => (
            <div
              key={id}
              className="border border-[#E2E0E5] dark:border-gray-600 rounded-2xl p-6 flex flex-col gap-3"
            >
              <div className="flex items-start gap-6">
                <div className="border w-fit border-[#E2E0E5] rounded-md p-2 flex flex-col items-center">
                  <p className="text-black dark:text-white text-base font-poppins">
                    {dateNumber}
                  </p>
                  <p className="text-black dark:text-white text-base font-poppins">
                    {dateString}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <h4 className="text-base font-medium text-black dark:text-white tracking-[-2%] font-poppins">
                    {title}
                  </h4>
                  <p className="text-sm text-[#A1A1A1] font-medium font-poppins">
                    {time}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <img
                  src="/images/linkIcon.png"
                  alt="image"
                  height={'14px'}
                  width={'14px'}
                  className="min-h-[14px] min-w-[14px]"
                />
                <p className="text-sm text-[#A1A1A1] font-medium font-poppins">
                  {link}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UpcomingMeetings;
