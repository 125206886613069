import React from 'react';
import LocationIcon from '../../assets/icons/LocationIcon';
import PlusIcon from '../../assets/icons/PlusIcon';
import CalendarIcon from '../../assets/icons/CalendarIcon';

const ChartDetailsFillter = () => {
  return (
    <div className="flex items-center gap-4 flex-wrap">
      <button
        type="button"
        className="flex items-center gap-2 text-blackDark-10 font-poppins font-medium text-base py-1 px-2 bg-lightGray-20 rounded-md"
      >
        <span>
          <CalendarIcon />
        </span>
        Mar 13, 2023 - Mar 13, 2024
      </button>

      <button
        type="button"
        className="flex items-center gap-2 text-blackDark-10 font-poppins font-medium text-base py-1 px-2 bg-lightGray-20 rounded-md"
      >
        <span>
          <LocationIcon />
        </span>
        USA
      </button>

      <button
        type="button"
        className="flex items-center text-blue-20 text-base font-medium"
      >
        <span>
          <PlusIcon />
        </span>
        Add filter
      </button>

      <button
        type="button"
        className="flex items-center text-blue-20 text-base font-medium"
      >
        Clear filters
      </button>
    </div>
  );
};

export default ChartDetailsFillter;
