const BellIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.2 13.6C25.2 15.144 23.944 16.4 22.4 16.4C20.856 16.4 19.6 15.144 19.6 13.6C19.6 12.056 20.856 10.8 22.4 10.8C23.944 10.8 25.2 12.056 25.2 13.6ZM23.6 17.832C23.2 17.936 22.8 18 22.4 18C21.2337 17.9979 20.1158 17.5336 19.2911 16.7089C18.4664 15.8842 18.0022 14.7663 18 13.6C18 12.424 18.464 11.36 19.2 10.568C19.0549 10.39 18.8718 10.2467 18.6642 10.1484C18.4566 10.0502 18.2297 9.99947 18 10C17.12 10 16.4 10.72 16.4 11.6V11.832C14.024 12.536 12.4 14.72 12.4 17.2V22L10.8 23.6V24.4H25.2V23.6L23.6 22V17.832ZM18 26.8C18.888 26.8 19.6 26.088 19.6 25.2H16.4C16.4 25.6244 16.5686 26.0313 16.8687 26.3314C17.1687 26.6314 17.5757 26.8 18 26.8Z"
        fill="#377FFF"
      />
    </svg>
  );
};

export default BellIcon;
