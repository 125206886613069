import React from 'react';
import {
  ComposedChart,
  LabelList,
  Cell,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import StartBarIcon from '../../assets/icons/StartBarIcon';

const DetailsCategoryBarChart = ({ insightIcon, insight, chartData }) => {
  return (
    <div className="bg-white dark:bg-gray-700 dark:border-gray-600 flex flex-col gap-9 p-9 rounded-3xl border border-gray-10">
      <div className="flex items-start gap-2">
        {insightIcon && (
          <div>
            <StartBarIcon />
          </div>
        )}

        <div className="text-sm font-light">{insight}</div>
      </div>

      <div className="h-48">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            layout="vertical"
            data={chartData}
            margin={{
              left: 20,
            }}
          >
            {/* <CartesianGrid stroke="#EBF2FF" fill="#EBF2FF" /> */}
            <XAxis
              type="number"
              tickLine={false}
              tick={false}
              axisLine={false}
            />
            <YAxis
              dataKey="name"
              stroke="#000"
              strokeWidth={2}
              tickLine={false}
              type="category"
            />

            <Bar
              dataKey="price"
              barSize={26}
              fill="#5693FF"
              radius={[3, 3, 0, 0]}
            >
              <LabelList
                dataKey="percentage"
                position="right"
                fill="#FF5D22"
                fontWeight={500}
              />

              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry?.name === 'All else' ? '#E2E0E5' : '#5693FF'}
                />
              ))}
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DetailsCategoryBarChart;
