import React from 'react';
import SearchIcon from '../../assets/icons/SearchIcon';
import { SlashIcon } from '../../assets/icons/SlashIcon';
import { Plus } from 'react-feather';

const AllNotesSearch = () => {
  return (
    <div className="flex items-center gap-3">
      <div className="relative w-full max-w-[310px] sm:block hidden">
        <input
          type="text"
          placeholder="Search for a note..."
          className="w-full px-4 py-2.5 pl-9 text-xs rounded-lg border outline-none dark:border-gray-600 border-gray-10 bg-transparent text-black dark:text-white placeholder-[#787878] "
        />
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
          <SearchIcon />
        </div>
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">
          <SlashIcon />
        </div>
      </div>

      <button className="text-blue-20 hover:text-blue-45 duration-300 flex items-center gap-1 text-base font-medium">
        <Plus size={16} />
        Add filter
      </button>
    </div>
  );
};

export default AllNotesSearch;
