import React, { useState } from 'react';
import { Heart } from 'react-feather';
import Tick from '../../assets/icons/Tick';
import ChartAlertBox from './ChartAlertBox';
import StartBarIcon from '../../assets/icons/StartBarIcon';
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { Chart as Legend } from 'chart.js';

const AllReportsBarChart = ({
  title,
  subtitle,
  value,
  change,
  isUnusual,
  chartData,
  color,
  insight,
  insightIcon,
  tickIcon,
  hasHeartIcon,
}) => {
  const alertBoxColor = () => {
    if (color === 'green') {
      return '#D5F7D8';
    } else if (color === 'red') {
      return '#FFD7D7';
    } else if (color === 'blue') {
      return '#EBF2FF';
    }
  };

  const alertBoxTextColor = () => {
    if (color === 'green') {
      return '#159722';
    } else if (color === 'red') {
      return '#F5095C';
    } else if (color === 'blue') {
      return '#2E7CFE';
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-2 border">
          <p className="label">{`${label}`}</p>
          <p className="text-xs">{`Human Support: ${payload[0]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  const [isFillHeart, setIsFillHeart] = useState(false);

  return (
    <div className="bg-white p-6 dark:bg-gray-800 dark:border-gray-600 rounded-3xl border-[#F1EBF8] border-[1.5px] shadow">
      <div className="flex justify-between items-center mb-2">
        <div>
          <h2 className="font-medium text-xl">{title}</h2>
          <p className="text-base font-medium text-[#918B9C] text-start dark:text-gray-300">
            {subtitle}
          </p>
        </div>
        <div className="flex items-center gap-1">
          {hasHeartIcon && (
            <button
              onClick={(e) => {
                e?.stopPropagation();
                setIsFillHeart(!isFillHeart);
              }}
            >
              <Heart
                fill={`${isFillHeart ? '#2e7cfe' : 'transparent'}`}
                className="text-blue-40 mr-2"
              />
            </button>
          )}

          {tickIcon && <Tick className="mr-2" />}

          <ChartAlertBox
            text={isUnusual ? 'Unusual' : 'Normal'}
            backgroundColor={alertBoxColor()}
            textColor={alertBoxTextColor()}
            borderColor={alertBoxTextColor()}
            borderWidth={color === 'blue' ? 0 : 1.5}
          />
        </div>
      </div>
      <div className="mt-6 text-start">
        <h3
          className={`font-bold mb-2 text-[2rem] ${
            color === 'green'
              ? 'text-[#238B5B]'
              : color === 'red'
              ? 'text-[#F5095C]'
              : 'text-[#5D97FF]'
          }`}
        >
          {value}
        </h3>
        <p
          className={`text-sm font-semibold ${
            color === 'green'
              ? 'text-[#238B5B]'
              : color === 'red'
              ? 'text-[#F5095C]'
              : 'text-[#5D97FF]'
          }`}
        >
          {change}
        </p>
      </div>
      <div className="mt-4 h-48">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={180}
            data={chartData}
            margin={{ top: 20 }}
            className={'cursor-pointer'}
          >
            <CartesianGrid vertical={false} stroke="#E2E0E5" />
            <XAxis
              dataKey="month"
              tick={{ fill: '#ccc' }}
              axisLine={false}
              tickLine={false}
              tickMargin={10}
            />
            <YAxis
              type="number"
              tick={{ fill: '#ccc' }}
              tickFormatter={(value) => `${value / 1000}`}
              tickMargin={10}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={<CustomTooltip />} />

            <Legend />

            <Bar
              dataKey="value"
              name="Human Support"
              fill="#377FFF"
              radius={[3, 3, 0, 0]}
              barSize={36}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 pb-5 flex items-start gap-2">
        {insightIcon && <StartBarIcon />}

        <div className="text-sm font-light">{insight}</div>
      </div>
    </div>
  );
};

export default AllReportsBarChart;
