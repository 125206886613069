// type: "icd10"

import React from 'react';
import { motion } from 'framer-motion';
import Accordion from '../Accordian';
import ICD10CMConceptsTable from './IcdTabTables/ICD10CMConceptsTable';
import IcdTraitsTable from './IcdTabTables/IcdTraitsTable';
import IcdAttributesTable from './IcdTabTables/IcdAttributesTable';

const IcdTab = ({ IcdData }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className={`p-4 rounded-lg min-h-[200px] max-h-[500px] overflow-y-auto helpdesk-custom-scrollbar dark:bg-gray-700 bg-white`}
    >
      {IcdData.map((item) => (
        <Accordion
          key={item.Id}
          title={item.Text}
          content={
            <div className="mt-5 flex flex-col gap-6">
              {item?.ICD10CMConcepts?.length > 0 && (
                <div>
                  <h3 className="text-xl mb-3 font-semibold">
                    ICD10CMConcepts:
                  </h3>

                  <ICD10CMConceptsTable data={item?.ICD10CMConcepts} />
                </div>
              )}

              {item?.Attributes?.length > 0 && (
                <div>
                  <h3 className="text-xl mb-3 font-semibold">Attributes:</h3>

                  <IcdAttributesTable data={item?.Attributes} />
                </div>
              )}

              {item?.Traits?.length > 0 && (
                <div>
                  <h3 className="text-xl mb-3 font-semibold">Traits:</h3>

                  <IcdTraitsTable data={item?.Traits} />
                </div>
              )}
            </div>
          }
        />
      ))}
    </motion.div>
  );
};

export default IcdTab;
