import React, { useEffect, useState } from "react";
import UploadPhoto from "./UploadPhoto";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { FaCamera } from "react-icons/fa";
import Takecamera from "./Takecamera";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const VerifyPhotoForm = () => {
  const [files, setFiles] = useState([]);
  const [isShowVerify, setIsShowVerify] = useState(false);
  const [error, setError] = useState("");
  const [isOpenCameraModal, setIsOpenCameraModal] = useState(false);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      // name: "",
      // age: "",
      // state: "",
      // emailAddress: "",
      name: "",
      similarity: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required").optional(),
      similarity: Yup.string().required("Required").optional(),
      // name: Yup.string().required("Required").optional(),
      // age: Yup.string().required("Required").optional(),
      // state: Yup.string().required("Required").optional(),
      // emailAddress: Yup.string()
      //   .trim()
      //   .matches(emailRegex, "Invalid email address")
      //   .required("Required")
      //   .optional(),
    }),
    onSubmit: async (value) => {
      if (files?.length > 0) {
        setError("");
        setSubmitting(true);
        try {
          const formData = new FormData();
          formData.append("file", files[0]);

          const response = await axios.post(
            `${process.env.REACT_APP_API_FACE_RECOGNISATION}/api/verify_face`,
            formData,
            {
              maxBodyLength: Infinity,
            }
          );

          if (response?.status === 200 && response?.data?.isSuccess) {
            setIsShowVerify(true);
            toast.success(response?.data?.message);
            setFieldValue("name", response?.data?.person);
            setFieldValue("similarity", response?.data?.similarity);
          } else {
            setError(response?.data?.response);
            console.error("Error", response?.data?.response);
          }
        } catch (error) {
          console.error("Error", error);
        }
      } else {
        toast.error("Required verify face");
      }
    },
  });

  return (
    <div className="max-w-3xl mx-auto w-full flex flex-col p-6">
      <form
        onSubmit={handleSubmit}
        className="flex w-full shadow-shadow-button p-6 bg-white dark:bg-gray-800 rounded-2xl justify-between flex-col gap-4"
      >
        <UploadPhoto
          title={"Verify face"}
          files={files}
          setFiles={setFiles}
          setIsShowVerify={setIsShowVerify}
          setErr={setError}
        />
        <div className="border-t border-dashed border-gray-25 relative my-2">
          <span className="absolute left-1/2 -top-3 dark:bg-gray-800 bg-white px-2 -translate-x-1/2">
            OR
          </span>
        </div>

        <div className="flex flex-row items-center justify-center gap-2">
          <p className="text-sm">Take picture from camera</p>

          <button
            type="button"
            onClick={() => setIsOpenCameraModal(!isOpenCameraModal)}
          >
            <FaCamera size={24} fill="#3B82F6" />
          </button>
        </div>
        {isShowVerify && files && files.length > 0 ? (
          <>
            <div className="flex flex-col gap-1">
              <label htmlFor="name" className="text-sm">
                Person
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={values?.name}
                disabled
                readOnly
                className="text-base outline-none bg-transparent border dark:border-gray-500 border-gray-300 p-2 rounded-lg"
              />
              {/* {errors?.name && touched?.name && (
                <span className="text-red-600 text-xs font-medium">
                  {errors?.name}
                </span>
              )} */}
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="similarity" className="text-sm">
                Similarity
              </label>
              <input
                type="text"
                id="similarity"
                name="similarity"
                value={values?.similarity}
                className="text-base outline-none bg-transparent border dark:border-gray-500 border-gray-300 p-2 rounded-lg"
              />
              {/* {errors?.age && touched?.age && (
                <span className="text-red-600 text-xs font-medium">
                  {errors?.age}
                </span>
              )} */}
            </div>

            {/* <div className="flex flex-col gap-1">
              <label htmlFor="state" className="text-sm">
                State
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={values?.state}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter state"
                className="text-base outline-none bg-transparent border dark:border-gray-500 border-gray-300 p-2 rounded-lg"
              />
              {errors?.state && touched?.state && (
                <span className="text-red-600 text-xs font-medium">
                  {errors?.state}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <label htmlFor="emailAddress" className="text-sm">
                Email address
              </label>
              <input
                type="text"
                id="emailAddress"
                name="emailAddress"
                value={values?.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter email address"
                className="text-base outline-none bg-transparent border dark:border-gray-500 border-gray-300 p-2 rounded-lg"
              />
              {errors?.emailAddress && touched?.emailAddress && (
                <span className="text-red-600 text-xs font-medium">
                  {errors?.emailAddress}
                </span>
              )}
            </div> */}
          </>
        ) : (
          <>
            <div className="text-red-700 font-medium text-sm">{error}</div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-fit font-medium px-4 ml-auto text-white bg-blue-20 hover:bg-blue-45 transition-colors duration-300 rounded-lg py-2"
            >
              Verify
            </button>
          </>
        )}
      </form>

      <Takecamera
        isOpen={isOpenCameraModal}
        setIsOpen={setIsOpenCameraModal}
        onSaveFile={(file) => {
          setFiles([file]);
        }}
      />
    </div>
  );
};

export default VerifyPhotoForm;
