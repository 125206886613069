import React from 'react';

const SendBigIcon = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1868_2441)">
        <path
          d="M75.1183 9.06526C76.9583 3.9753 72.0259 -0.957067 66.936 0.887247L4.72767 23.3853C-0.379328 25.2339 -0.99694 32.2022 3.70116 34.924L23.5584 46.4201L41.2902 28.6883C42.0936 27.9124 43.1695 27.4831 44.2863 27.4928C45.4031 27.5025 46.4714 27.9504 47.2611 28.7401C48.0508 29.5299 48.4988 30.5982 48.5085 31.715C48.5182 32.8318 48.0889 33.9077 47.313 34.711L29.5812 52.4428L41.0815 72.3001C43.799 76.9982 50.7674 76.3763 52.6159 71.2736L75.1183 9.06526Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1868_2441">
          <rect width="76" height="76" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendBigIcon;
