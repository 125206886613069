import React, { useRef, useEffect, useState } from "react";
import { useTransition, animated, config } from "@react-spring/web";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCopy } from "@fortawesome/free-solid-svg-icons";
import DynamicChatMessage from "./DynamicChatMessage";
import remarkGfm from "remark-gfm";

const ChatBody = ({ messages, loading, lastMessageId }) => {
  const messagesEndRef = useRef(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const transitions = useTransition(messages, {
    from: { opacity: 0, transform: "translateY(20px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0 },
    trail: 100,
    config: config.gentle,
    keys: (message) => message.id,
  });

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, loading]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        messagesEndRef.current.parentElement;
      setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
    };

    const scrollContainer = messagesEndRef.current.parentElement;
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => scrollContainer.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const renderMessageContent = (msg) => {
    if (msg.file) {
      if (msg.file.type.startsWith("image/")) {
        return (
          <img
            src={URL.createObjectURL(msg.file)}
            alt={msg.file.name}
            className="max-w-xs sm:max-w-md rounded-lg shadow-md"
          />
        );
      } else {
        return (
          <div className="flex items-center space-x-2 bg-gray-200 dark:bg-gray-700 rounded-lg p-2">
            <svg
              className="w-6 h-6 text-gray-600 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              />
            </svg>
            <span className="text-sm font-medium text-gray-800 dark:text-white">
              {msg.file.name}
            </span>
          </div>
        );
      }
    } else {
      if (msg.text.includes("###")) {
        return <DynamicChatMessage content={msg.text} />;
      }
      return (
        <ReactMarkdown
          className="markdown-content"
          children={msg.text}
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <div className="relative mt-4 mb-4">
                  <div className="flex justify-between items-center bg-gray-800 dark:bg-gray-900 text-gray-200 dark:text-gray-300 rounded-t-lg px-4 py-2">
                    <span className="text-xs font-semibold font-poppins uppercase">
                      {match[1]}
                    </span>
                    <button
                      onClick={() => copyToClipboard(String(children))}
                      className="text-gray-400 font-poppins hover:text-white transition-colors duration-200"
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </div>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, "")}
                    style={atomDark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                    customStyle={{
                      margin: 0,
                      borderRadius: "0 0 0.5rem 0.5rem",
                    }}
                  />
                </div>
              ) : (
                <code
                  className="bg-gray-200 font-poppins dark:bg-gray-700 rounded px-1 py-0.5"
                  {...props}
                >
                  {children}
                </code>
              );
            },

            h3: ({ children }) => (
              <h3 className="text-2xl font-poppins font-bold mt-6 mb-3 text-gray-800 dark:text-gray-200">
                {children}
              </h3>
            ),
            ul: ({ children }) => (
              <ul className="list-disc font-poppins list-inside my-3 space-y-1 text-gray-700 dark:text-gray-300">
                {children}
              </ul>
            ),
            ol: ({ children }) => (
              <ol className="list-decimal font-poppins list-inside my-3 space-y-1 text-gray-700 dark:text-gray-300">
                {children}
              </ol>
            ),
            li: ({ children }) => <li className="mb-1">{children}</li>,
          }}
        />
      );
    }
  };

  const renderStructuredMessage = (msg) => {
    return renderMessageContent(msg);
  };

  return (
    <div className="relative flex-1 p-4 overflow-y-auto bg-gray-100 dark:bg-gray-900 scroll-smooth">
      {transitions((style, msg) => (
        <animated.div
          style={style}
          className={`flex mb-6 flex-col sm:flex-row ${
            msg.isBot ? "justify-start" : "justify-end"
          } items-start sm:items-start`}
          key={msg.id}
        >
          {msg.isBot && (
            <div className="flex-shrink-0 mr-4 sm:mr-2">
              <div className="w-10 h-10 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center shadow-md mb-1 sm:mb-0 sm:mr-2">
                <img
                  alt="AI Agent"
                  src="https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                  className="w-6 h-6"
                />
              </div>
            </div>
          )}
          <div className="flex flex-col sm:flex-row items-start sm:items-start">
            {!msg.isBot && (
              <span className="sm:hidden flex text-xs text-gray-600 dark:text-gray-400 font-poppins mb-1 sm:mb-0 sm:mr-4 font-semibold">
                You
              </span>
            )}
            <div
              className={`p-3 rounded-lg max-w-sm sm:max-w-lg shadow-md ${
                msg.isBot
                  ? "bg-white dark:bg-gray-800 font-poppins text-gray-800 dark:text-white"
                  : "bg-blue-500 dark:bg-blue-600 font-poppins text-white"
              }`}
            >
              {renderStructuredMessage(msg)}
            </div>
          </div>
        </animated.div>
      ))}
      {loading && (
        <div className="flex mb-6 flex-row items-start sm:items-start">
          <div className="flex-shrink-0 mr-4 sm:mr-4">
            <div className="w-10 h-10 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center shadow-md">
              <img
                alt="AI Agent"
                src="https://cdn-icons-png.flaticon.com/512/6134/6134346.png"
                className="w-6 h-6"
              />
            </div>
          </div>
          <div className="flex flex-col items-start sm:flex-row sm:items-start">
            <div className="p-3 rounded-lg mt-3 rounded-tl-none max-w-xs sm:max-w-md shadow-md bg-white dark:bg-gray-800 text-gray-800 dark:text-white">
              <div className="flex justify-center items-center space-x-2">
                <div className="w-2 h-2 bg-gray-600 dark:bg-gray-400 rounded-full animate-pulse"></div>
                <div className="w-2 h-2 bg-gray-600 dark:bg-gray-400 rounded-full animate-pulse delay-75"></div>
                <div className="w-2 h-2 bg-gray-600 dark:bg-gray-400 rounded-full animate-pulse delay-150"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
      {showScrollButton && (
        <button
          className="fixed flex items-center justify-center bottom-20 right-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-full p-2 shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 border-0"
          onClick={scrollToBottom}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className="text-xl font-poppins"
          />
        </button>
      )}
    </div>
  );
};

export default ChatBody;
