import React, { useState } from 'react';

import AllNotesHeader from '../AllNotes/AllNotesHeader';
import AllNotesSearch from '../AllNotes/AllNotesSearch';
import NotesDetails from '../AllNotes/NotesDetails';
import UserIcon from '../../assets/icons/UserIcon';
import PatientsProfile from '../AllNotePatients/PatientsProfile';
import StarFillButton from '../StarFillButton';

const FavoriteNotes = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const FavoriteNotesData = [
    {
      id: 1,
      title: 'Back Pain Check-Up',
      username: 'John Jones',
      count: '8/08',
      description: `Mr. Jones visits for his recurring back pain, which originated from a workplace injury three years ago. While Mr. Jones previously underwent physical therapy and took tramadol, he discontinued these treatments due to cost concerns, insurance limitations, and scheduling difficulties. Dr. Smith suggests more affordable alternatives, including using a heating pad for immediate relief and exploring yoga or tai chi classes as long-term solutions instead of medication. The doctor acknowledges the patient's financial constraints and schedules a follow-up phone call for the following Tuesday to discuss researched treatment options, while also recommending that Mr. Jones review his insurance options during open season.`,
    },
    {
      id: 2,
      title: 'Headaches 9/23',
      username: 'Pat Bellamy',
      count: '9/23',
      description: `Ms. Bellamy visits with complaints of a severe headache that started three days ago. The headache is described as a constant 10/10 pain affecting her entire head, made worse by movement and light, with associated neck stiffness and nausea. Pat is particularly concerned because of a neighbor who had a brain tumor, and she's also worried about insurance coverage since she recently changed insurance companies. Her medical history includes controlled high blood pressure, and she smokes half a pack of cigarettes daily. She recently attended a family reunion in North Carolina where a sick child was present. The doctor takes a thorough history, including family history noting her mother's history of migraines, and plans to conduct a physical exam and arrange insurance verification while addressing her concerns.`,
    },
  ];

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] ovey lg:h-screen w-full bg-[#FCFCFC] dark:bg-gray-900 overflow-y-hidden">
      <AllNotesHeader
        title={'Favorite Notes'}
        SoundbiteName={isShowProfile ? 'Profile Info' : 'Soundbite Name'}
        isShowDetails={isShowDetails || isShowProfile}
        setIsShowDetails={isShowProfile ? setIsShowProfile : setIsShowDetails}
      />

      {!isShowDetails && !isShowProfile && (
        <>
          <div className="max-h-[calc(100%-60px)] w-full overflow-y-auto h-full py-6 px-9">
            <AllNotesSearch />

            <div className="relative grid md:grid-cols-3 sm:grid-cols-2 py-6 gap-2 w-full">
              {FavoriteNotesData?.map(
                ({ count, description, id, title, username }, index) => (
                  <button
                    key={id}
                    onClick={() => {
                      setIsShowDetails(true);
                      setActiveIndex(index);
                    }}
                    className="p-6 w-full flex items-start flex-col gap-2.5 border dark:border-gray-600 border-gray-10 rounded-2xl"
                  >
                    <div className="flex items-center justify-between w-full">
                      <h2 className="text-black dark:text-white text-base font-medium tracking-[-0.32px]">
                        {title}
                      </h2>

                      <StarFillButton isFillBtn={true} />
                    </div>

                    <div className="flex items-center justify-between w-full">
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsShowProfile(true);
                          setActiveIndex(index);
                        }}
                        className="flex text-blackDark-10 dark:text-white text-xs font-medium gap-1 items-center tracking-[-0.24px]"
                      >
                        <UserIcon />
                        {username}
                      </span>

                      <span className="text-xs font-medium text-gray-500 tracking-[-0.24px]">
                        {count}
                      </span>
                    </div>

                    <p className="text-sm line-clamp-4 text-start font-normal tracking-[-0.28px] text-blackDark-10 dark:text-white">
                      {description}
                    </p>
                  </button>
                )
              )}
            </div>
          </div>
        </>
      )}

      {isShowProfile && (
        <div className="max-h-[calc(100%-60px)] w-full overflow-y-auto h-full">
          <PatientsProfile activeIndex={activeIndex} />
        </div>
      )}

      {!isShowProfile && isShowDetails && (
        <NotesDetails activeIndex={activeIndex} />
      )}
    </div>
  );
};

export default FavoriteNotes;
