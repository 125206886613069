import React from 'react';
import { motion } from 'framer-motion';

const TaxProcessing = () => {
    return (
        <motion.div
            className="min-h-screen bg-gray-100 flex flex-col justify-between"
            initial="hidden"
            animate="visible"
            variants={{
                hidden: { opacity: 0 },
                visible: {
                    opacity: 1,
                    transition: {
                        staggerChildren: 0.2,
                    },
                },
            }}
        >
            <main>
                <motion.section
                    className="bg-gray-50 p-6 rounded-lg shadow-lg max-w-4xl mx-auto my-8"
                    variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                    }}
                >
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Start New Tax Processing</h2>
                    <form className="space-y-4">
                        <div>
                            <label className="block text-gray-600 mb-1" htmlFor="tax-type">Tax Type</label>
                            <select id="tax-type" className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:ring-blue-500">
                                <option>Income Tax</option>
                                <option>Business Tax</option>
                                <option>VAT</option>
                            </select>
                        </div>

                        <div>
                            <label className="block text-gray-600 mb-1" htmlFor="year">Year</label>
                            <input type="number" id="year" placeholder="2023" className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:ring-blue-500" />
                        </div>

                        <div>
                            <label className="block text-gray-600 mb-1">Upload Documents</label>
                            <div className="border-dashed border-2 border-gray-400 rounded-lg p-4 flex justify-center items-center">
                                <input type="file" className="hidden" id="file-upload" />
                                <label htmlFor="file-upload" className="cursor-pointer text-blue-500">Click to Upload</label>
                            </div>
                        </div>

                        <motion.button
                            type='button'
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Start Processing
                        </motion.button>
                    </form>
                </motion.section>

                <motion.section
                    className="bg-white p-6 rounded-lg shadow-lg max-w-4xl mx-auto my-8"
                    variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                    }}
                >
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4">Processing Status</h2>
                    <div className="space-y-4">
                        <div className="flex justify-between items-center">
                            <p className="text-gray-600">Income Tax - 2023</p>
                            <motion.span
                                className="bg-yellow-100 text-yellow-600 px-3 py-1 rounded-full"
                                whileHover={{ scale: 1.1 }}
                            >
                                Processing
                            </motion.span>
                        </div>
                        <div className="flex justify-between items-center">
                            <p className="text-gray-600">Business Tax - 2022</p>
                            <motion.span
                                className="bg-green-100 text-green-600 px-3 py-1 rounded-full"
                                whileHover={{ scale: 1.1 }}
                            >
                                Completed
                            </motion.span>
                        </div>
                        <div className="flex justify-between items-center">
                            <p className="text-gray-600">VAT - 2023</p>
                            <motion.span
                                className="bg-red-100 text-red-600 px-3 py-1 rounded-full"
                                whileHover={{ scale: 1.1 }}
                            >
                                Error
                            </motion.span>
                        </div>
                    </div>
                </motion.section>
            </main>
        </motion.div>
    );
};

export default TaxProcessing;
