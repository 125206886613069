import React from "react";

const GoogleCloudPlatform = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7812 16.5117L32.6133 7.51172H19.0547C17.8477 7.51172 16.7227 8.15625 16.1367 9.21094L5.16797 28.3242C4.57031 29.3672 4.57031 30.6328 5.16797 31.6875L9.33984 38.9648L14.4961 30.0117L22.2539 16.5117H37.7812Z"
        fill="#F8BB16"
      />
      <path
        d="M54.8672 28.3242L43.8984 9.21094C43.2891 8.16797 42.1758 7.51172 40.9805 7.51172H32.6133L37.7813 16.5117L45.5391 30.0117L37.7813 43.5117H48.0938L54.8672 31.6875C55.4531 30.6445 55.4531 29.3672 54.8672 28.3242Z"
        fill="#EA4334"
      />
      <path
        d="M48.082 43.5H22.2305L14.4727 30L9.31641 38.9531L16.1133 50.7891C16.7227 51.832 17.8359 52.4883 19.0312 52.4883H40.957C42.1641 52.4883 43.2891 51.8438 43.875 50.7891L48.082 43.5Z"
        fill="#0074BC"
      />
      <path
        d="M37.7578 43.5H22.2188L14.4727 30L22.2305 16.5H37.7578L45.5391 30L37.7578 43.5ZM30 23.2617C26.2617 23.2617 23.25 26.2734 23.25 30.0117C23.25 33.75 26.2617 36.7617 30 36.7617C33.7383 36.7617 36.75 33.75 36.75 30.0117C36.75 26.2734 33.7266 23.2617 30 23.2617Z"
        fill="#E2E3E4"
      />
    </svg>
  );
};

export default GoogleCloudPlatform;
