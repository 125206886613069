import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ContractManagement = () => {
    const [activeTab, setActiveTab] = useState('list');
    const [search, setSearch] = useState('');
    const [selectedContract, setSelectedContract] = useState(null);

    const contracts = [
        { id: 1, title: 'Contract A', date: '2024-01-15', description: 'Details about Contract A' },
        { id: 2, title: 'Contract B', date: '2024-02-20', description: 'Details about Contract B' },
    ];

    const filteredContracts = contracts.filter(contract =>
        contract.title.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex flex-col p-6">
                <div className="flex space-x-4 mb-4">
                    <button
                        onClick={() => {
                            setActiveTab('list');
                            setSelectedContract(null);
                        }}
                        className={`py-2 px-4 font-semibold ${activeTab === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded`}
                    >
                        Contract List
                    </button>
                    <button
                        onClick={() => setActiveTab('details')}
                        disabled={!selectedContract}
                        className={`py-2 px-4 font-semibold ${activeTab === 'details' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded`}
                    >
                        Contract Details
                    </button>
                </div>

                <AnimatePresence>
                    {activeTab === 'list' && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.3 }}
                        >
                            <input
                                type="text"
                                placeholder="Search contracts..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="border border-gray-300 rounded px-4 py-2 mb-4"
                            />
                            <div className="bg-white shadow-md rounded-lg p-4">
                                <table className="w-full table-auto">
                                    <thead>
                                        <tr className="bg-gray-100 text-gray-700">
                                            <th className="px-4 py-2 text-start">Title</th>
                                            <th className="px-4 py-2 text-start">Date</th>
                                            <th className="px-4 py-2 text-start">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredContracts.map(contract => (
                                            <tr key={contract.id} className="border-b">
                                                <td className="px-4 py-2">{contract.title}</td>
                                                <td className="px-4 py-2">{contract.date}</td>
                                                <td className="px-4 py-2">
                                                    <button
                                                        onClick={() => {
                                                            setSelectedContract(contract);
                                                            setActiveTab('details');
                                                        }}
                                                        className="text-blue-500 hover:underline"
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </motion.div>
                    )}

                    {activeTab === 'details' && selectedContract && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.3 }}
                            className="bg-white shadow-md rounded-lg p-4"
                        >
                            <h2 className="text-xl font-semibold mb-4">Contract Details</h2>
                            <p><strong>Title:</strong> {selectedContract.title}</p>
                            <p><strong>Date:</strong> {selectedContract.date}</p>
                            <p><strong>Description:</strong> {selectedContract.description}</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default ContractManagement;
