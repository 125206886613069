/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useState } from 'react';
import Select from 'react-select';
import FileUpload from './SummarizerNew/FileUpload';
import { RiAiGenerate } from 'react-icons/ri';
import axios from 'axios';
import { MdOutlineFileDownload } from 'react-icons/md';
import { ThemeContext } from './ThemeProvider';

export const MODEL_OPTION = [
  { id: 1, value: 'amazon.titan.v1', label: 'Amazon titan V1' },
  { id: 2, value: 'sdxl', label: 'SDXL' },
  { id: 3, value: 'sd3-large', label: 'SD3 large' },
  { id: 4, value: 'stable-image-ultra', label: 'Stable image ultra' },
  { id: 5, value: 'stable-image-core', label: 'Stable image core' },
];

const REQUIREMENTS = [
  {
    id: 1,
    label: 'Create stunning visuals effortlessly in an intuitive layout.',
    img: '/images/planIcon.png',
  },
  {
    id: 2,
    label:
      'Generate and view variations alongside the original image for instant comparison.',
    img: '/images/InfoIcon.png',
  },
];

const ImageGeneration = () => {
  const [isGetStarted, setIsGetStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputText, setInputText] = useState('');
  const [selectedModel, setSelectedModel] = useState(MODEL_OPTION[0]?.value);
  const [imagesrc, setImagesrc] = useState(null);
  const { isDarkMode } = useContext(ThemeContext);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#111827' : '#fff',
      color: isDarkMode ? '#fff' : '#000',
      borderColor: isDarkMode ? '#374151' : '#ccc',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? '#1f2937' : '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDarkMode ? '#fff' : '#000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDarkMode ? '#aaa' : '#666',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? isDarkMode
          ? '#374151'
          : '#eee'
        : isDarkMode
        ? '#1f2937'
        : '#fff',
      color: isDarkMode ? '#fff' : '#000',
      cursor: 'pointer',
    }),
  };

  const handleGenerate = async () => {
    if (inputText?.trim()) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API__IMAGE_GENERATION}/api/image_generation`,
          JSON.stringify({
            input_text: inputText,
            model_name: selectedModel,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response?.data?.statusCode === 200) {
          const bodyParse = JSON.parse(response?.data?.body);
          setImagesrc(`data:image/png;base64,${bodyParse?.bas64_img_str}`);
        }
      } catch (error) {
        console.error('Error', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex relative w-full font-poppins flex-col h-[calc(100vh-72px)] lg:h-screen bg-[#FCFCFC] dark:bg-gray-900 overflow-y-hidden">
      <div className="border-b py-2.5 px-6 bg-lightGray-100 dark:bg-gray-900 dark:border-gray-700 border-lightGray-10 h-[60px] flex items-center justify-center gap-2">
        <h2 className="font-poppins font-medium text-sm">Image generation</h2>
      </div>

      <div className="h-full overflow-y-auto">
        {!isGetStarted && (
          <FileUpload
            title={'Image generation'}
            setIsGetStarted={setIsGetStarted}
            data={REQUIREMENTS}
          />
        )}

        {isGetStarted && (
          <div className="p-6 flex lg:flex-row flex-col h-[calc(100vh-72px)] gap-4">
            <div className="w-full max-w-screen-lg mx-auto space-y-3">
              <div className="w-full flex gap-2 items-center">
                <label className="text-base font-medium text-gray-800 dark:text-white">
                  Select model
                </label>
                <Select
                  defaultValue={MODEL_OPTION[0]}
                  options={MODEL_OPTION}
                  className="max-w-[400px] w-full bg-transparent"
                  onChange={(e) => setSelectedModel(e?.value)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStyles}
                />
              </div>

              <textarea
                type="text"
                placeholder="Your image will be generated here."
                onChange={(e) => {
                  setInputText(e?.target?.value);
                }}
                className={`${
                  imagesrc ? 'h-[calc(100%-100px)]' : 'h-[300px]'
                } resize-none w-full  min-h-[300px] overflow-y-auto helpdesk-custom-scrollbar text-base dark:border-gray-700 border-lightGray-10 placeholder:text-[#787878] p-4 rounded-lg bg-lightGray-100 dark:bg-gray-900 border-2 text-black dark:text-white outline-none`}
              />

              <div className="flex items-center justify-end">
                <button
                  className="z-30 flex items-center justify-center gap-1.5 rounded-lg border  border-gray-10 bg-blue-20 dark:border-gray-700  px-3 py-1.5 font-poppins text-base font-medium leading-6 text-white outline-none transition-all hover:bg-blue-45"
                  type="button"
                  onClick={() => handleGenerate()}
                >
                  <RiAiGenerate size={20} />
                  <span>Generate</span>
                </button>
              </div>
            </div>

            {imagesrc && (
              <div className="w-full lg:mt-12 mt-6 flex flex-col items-center ">
                <div className="h-full">
                  <img
                    src={imagesrc || ''}
                    width={1000}
                    height={1000}
                    alt="Generated image"
                    className="!h-[calc(100%-80px)] min-h-[300px] w-fit  rounded-lg object-contain"
                  />
                  <a
                    href={imagesrc}
                    download={'generatedimage'}
                    className="flex items-center gap-1.5 p-2.5 text-gray-75 dark:text-white"
                  >
                    <MdOutlineFileDownload />
                    <span className="text-xs font-normal">Download</span>
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {isLoading && (
        <div className="fixed top-0 left-0 z-30 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}
    </div>
  );
};

export default ImageGeneration;
