import React from 'react';

const AutomationPayerBehaviour = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-2xl font-semibold text-gray-800">
        Automation Payer Behaviour
      </h1>

      <main className=" mx-auto py-6">
        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-blue-600">
                Total Payers
              </h3>
              <p className="text-gray-700 text-2xl">1,234</p>
            </div>
            <div className="bg-green-50 p-4 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-green-600">
                Active Payers
              </h3>
              <p className="text-gray-700 text-2xl">1,045</p>
            </div>
            <div className="bg-yellow-50 p-4 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold text-yellow-600">
                Pending Actions
              </h3>
              <p className="text-gray-700 text-2xl">189</p>
            </div>
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Details</h2>
          <div className="space-y-4">
            <div className="flex items-center justify-between border-b pb-4">
              <span className="text-gray-600">Behavior Analysis</span>
              <button className="text-blue-600 hover:underline">
                View More
              </button>
            </div>
            <div className="flex items-center justify-between border-b pb-4">
              <span className="text-gray-600">Recent Trends</span>
              <button className="text-blue-600 hover:underline">
                View More
              </button>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-600">Top Payers</span>
              <button className="text-blue-600 hover:underline">
                View More
              </button>
            </div>
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Actions</h2>
          <div className="flex space-x-4">
            <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
              Automate Payer Actions
            </button>
            <button className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700">
              Review Payer Behavior
            </button>
            <button className="bg-yellow-600 text-white px-4 py-2 rounded-lg hover:bg-yellow-700">
              Export Data
            </button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AutomationPayerBehaviour;
