import React, { useState } from "react";
import UploadPhoto from "./UploadPhoto";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { FaCamera } from "react-icons/fa";
import Takecamera from "./Takecamera";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const UploadPhotoForm = () => {
  const [files, setFiles] = useState([]);
  const [isOpenCameraModal, setIsOpenCameraModal] = useState(false);

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      // name: "",
      // age: "",
      // state: "",
      // emailAddress: "",
      full_name: "",
    },
    validationSchema: Yup.object().shape({
      full_name: Yup.string().required("Required"),
      // age: Yup.string().required("Required"),
      // state: Yup.string().required("Required"),
      // emailAddress: Yup.string()
      //   .trim()
      //   .matches(emailRegex, "Invalid email address")
      //   .required("Required"),
    }),
    onSubmit: async (value) => {
      if (files?.length > 0) {
        setSubmitting(true);
        try {
          const formData = new FormData();
          formData.append("file", files[0]);

          formData.append("full_name", value?.full_name.replace(" ", "-"));
          //formData.append("extra_id", value?.full_name.replace(" ", "-"));

          // formData.append(
          //   "metadata",
          //   JSON.stringify({
          //     name: value?.name,
          //     age: value?.age,
          //     state: value?.state,
          //     email_address: value?.emailAddress,
          //   })
          // );
          const response = await axios.post(
            `${process.env.REACT_APP_API_FACE_RECOGNISATION}/api/upload_face_photo`,
            formData,
            {
              maxBodyLength: Infinity,
            }
          );

          if (response?.status === 200) {
            toast.success("Face uploaded successfully");
            setFiles([]);
            resetForm();
          }
          console.log("response", response);
        } catch (error) {
          console.error("Error", error);
        } finally {
          setSubmitting(false);
        }
      } else {
        toast.error("Required upload face");
      }
    },
  });

  return (
    <div className="max-w-3xl mx-auto w-full flex flex-col p-6">
      <form
        onSubmit={handleSubmit}
        className="flex w-full shadow-shadow-button p-6 bg-white dark:bg-gray-800 rounded-2xl justify-between flex-col gap-4"
      >
        <UploadPhoto title={"Upload Face"} files={files} setFiles={setFiles} />
        <div className="border-t border-dashed border-gray-25 relative my-2">
          <span className="absolute left-1/2 -top-3   bg-white dark:bg-gray-800 px-2 -translate-x-1/2">
            OR
          </span>
        </div>

        <div className="flex flex-row items-center justify-center gap-2">
          <p className="text-sm">Take picture from camera</p>

          <button
            type="button"
            onClick={() => setIsOpenCameraModal(!isOpenCameraModal)}
          >
            <FaCamera size={24} fill="#3B82F6" />
          </button>
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="name" className="text-sm">
            Full Name
          </label>
          <input
            type="text"
            id="full_name"
            name="full_name"
            value={values?.full_name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter Full Name"
            className="text-base outline-none border bg-transparent dark:border-gray-500 border-gray-300 p-2 rounded-lg"
          />
          {errors?.full_name && touched?.full_name && (
            <span className="text-red-600 text-xs font-medium">
              {errors?.full_name}
            </span>
          )}
        </div>
        {/* <div className="flex flex-col gap-1">
          <label htmlFor="age" className="text-sm">
            Age
          </label>
          <input
            type="text"
            id="age"
            name="age"
            value={values?.age}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter age"
            className="text-base outline-none border bg-transparent dark:border-gray-500 border-gray-300 p-2 rounded-lg"
          />
          {errors?.age && touched?.age && (
            <span className="text-red-600 text-xs font-medium">
              {errors?.age}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="state" className="text-sm">
            State
          </label>
          <input
            type="text"
            id="state"
            name="state"
            value={values?.state}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter state"
            className="text-base outline-none bg-transparent border dark:border-gray-500 border-gray-300 p-2 rounded-lg"
          />
          {errors?.state && touched?.state && (
            <span className="text-red-600 text-xs font-medium">
              {errors?.state}
            </span>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="emailAddress" className="text-sm">
            Email address
          </label>
          <input
            type="text"
            id="emailAddress"
            name="emailAddress"
            value={values?.emailAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter email address"
            className="text-base outline-none bg-transparent border dark:border-gray-500 border-gray-300 p-2 rounded-lg"
          />
          {errors?.emailAddress && touched?.emailAddress && (
            <span className="text-red-600 text-xs font-medium">
              {errors?.emailAddress}
            </span>
          )}
        </div> */}
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-fit font-medium px-4 ml-auto text-white bg-blue-20 hover:bg-blue-45 transition-colors duration-300 rounded-lg py-2"
        >
          {isSubmitting ? "Saving..." : "Save"}
        </button>
      </form>
      <Takecamera
        isOpen={isOpenCameraModal}
        setIsOpen={setIsOpenCameraModal}
        onSaveFile={(file) => {
          setFiles([file]);
        }}
      />
    </div>
  );
};

export default UploadPhotoForm;
