/* eslint-disable jsx-a11y/anchor-is-valid */
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import {
  ChevronDown,
  Moon,
  Sun,
  MoreHorizontal,
  Send,
  Paperclip,
  Menu,
  MessageSquare,
  Bell,
  Users,
  BarChart2,
  Info,
} from 'react-feather';

const Inbox = () => {
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const dummyMessages = [
    {
      id: 1,
      sender: 'Hailey Garza',
      content: 'Can I change the date of my reservation?',
      time: '3min',
      avatar: 'H',
    },
    {
      id: 2,
      sender: 'Ivan Deen',
      content: 'Hi, I have a quest...',
      time: '8min',
      avatar: 'I',
    },
    {
      id: 3,
      sender: 'Jason Shaw',
      content: 'Is the app down?',
      time: '12min',
      avatar: 'J',
    },
    {
      id: 4,
      sender: 'Robin Benson',
      content: 'Could you point m...',
      time: '2min',
      avatar: 'R',
    },
    {
      id: 5,
      sender: 'Carla Fité',
      content: 'I created a new page...',
      time: '1min',
      avatar: 'C',
    },
  ];

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
    setMessages([
      {
        id: 1,
        sender: message.sender,
        content: message.content,
        time: 'Now',
        isUser: true,
      },
      {
        id: 2,
        sender: 'Bot',
        content: 'Thank you for your message. How can I assist you today?',
        time: 'Now',
        isUser: false,
      },
    ]);
  };

  const handleSendMessage = (content) => {
    setMessages([
      ...messages,
      {
        id: messages.length + 1,
        sender: 'You',
        content,
        time: 'Now',
        isUser: true,
      },
    ]);
  };

  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const toggleSidebar = () => setIsSidebarCollapsed(!isSidebarCollapsed);

  const handleCloseChat = () => {
    setSelectedMessage(null);
    setMessages([]);
  };

  return (
    <div className={`flex h-screen ${isDarkMode ? 'dark' : ''}`}>
      <motion.div
        className={`bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 flex flex-col`}
        animate={{
          width: isSidebarCollapsed ? 64 : 256,
          transition: {
            duration: 0.3,
            ease: [0.25, 0.1, 0.25, 1],
          },
        }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
          <AnimatePresence>
            {!isSidebarCollapsed && (
              <motion.h2
                className="text-xl font-semibold text-gray-800 dark:text-white flex items-center"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
              >
                Inbox <ChevronDown className="ml-2" size={20} />
              </motion.h2>
            )}
          </AnimatePresence>
          <motion.button
            onClick={toggleSidebar}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Menu size={24} />
          </motion.button>
        </div>
        <nav className="flex-1 overflow-y-auto">
          <NavItem
            icon={<MessageSquare size={20} />}
            text="Your Inbox"
            active
            collapsed={isSidebarCollapsed}
          />
          <NavItem
            icon={<Bell size={20} />}
            text="Mentions"
            collapsed={isSidebarCollapsed}
          />
          <NavItem
            icon={<Users size={20} />}
            text="All"
            collapsed={isSidebarCollapsed}
          />
          <NavItem
            icon={<BarChart2 size={20} />}
            text="Dashboard"
            collapsed={isSidebarCollapsed}
          />
        </nav>
        <div className="p-4 border-t border-gray-200 dark:border-gray-700">
          <AnimatePresence>
            {!isSidebarCollapsed && (
              <motion.h3
                className="text-xs font-semibold text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                Teams
              </motion.h3>
            )}
          </AnimatePresence>
          <NavItem
            icon={<Info size={20} />}
            text="VIP Inbox"
            collapsed={isSidebarCollapsed}
          />
          <NavItem
            icon={<Info size={20} />}
            text="EMEA Waiting"
            collapsed={isSidebarCollapsed}
          />
          <NavItem
            icon={<Info size={20} />}
            text="UK Waiting"
            collapsed={isSidebarCollapsed}
          />
        </div>
      </motion.div>

      {/* Message List */}
      <motion.div
        className="w-1/3 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700 flex flex-col"
        initial={{ x: -300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
          <motion.h2
            className="text-lg font-semibold text-gray-800 dark:text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            VIP Inbox
          </motion.h2>
          <motion.div
            className="flex items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <span className="mr-2 text-sm text-gray-500 dark:text-gray-400">
              8 Open
            </span>
            <ChevronDown
              size={20}
              className="text-gray-400 dark:text-gray-500"
            />
          </motion.div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <AnimatePresence>
            {dummyMessages.map((message, index) => (
              <MessagePreview
                key={message.id}
                message={message}
                isSelected={selectedMessage?.id === message.id}
                onClick={() => handleMessageClick(message)}
                index={index}
              />
            ))}
          </AnimatePresence>
        </div>
      </motion.div>

      {/* Chat Area */}
      <motion.div
        className="flex-1 flex flex-col bg-gray-50 dark:bg-gray-900"
        initial={{ x: 300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <AnimatePresence mode="wait">
          {selectedMessage ? (
            <motion.div
              key="chat"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="flex flex-col h-full"
            >
              <ChatHeader
                sender={selectedMessage.sender}
                isDarkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
                onClose={handleCloseChat}
              />
              <ChatMessages messages={messages} />
              <ChatInput onSendMessage={handleSendMessage} />
            </motion.div>
          ) : (
            <motion.div
              key="placeholder"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="flex-1 flex items-center justify-center text-gray-500 dark:text-gray-400"
            >
              Select a message to view the conversation
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const NavItem = ({ icon, text, active, collapsed }) => (
  <a
    href="#"
    className={`
    flex items-center px-4 py-2 text-gray-600 dark:text-gray-300
    transition-colors duration-150 ease-in-out
    hover:bg-gray-100 dark:hover:bg-gray-700
    ${
      active
        ? 'bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-300'
        : ''
    }
    ${collapsed ? 'justify-center' : ''}
  `}
  >
    <div
      className={`transition-transform duration-300 ease-in-out ${
        collapsed ? 'rotate-360' : ''
      }`}
    >
      {icon}
    </div>
    {!collapsed && (
      <span className="ml-3 transition-opacity duration-200 ease-in-out">
        {text}
      </span>
    )}
  </a>
);

const MessagePreview = ({ message, isSelected, onClick, index }) => (
  <motion.div
    layout
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3, delay: index * 0.05 }}
    whileHover={{ scale: 1.02, transition: { duration: 0.1 } }}
    whileTap={{ scale: 0.98 }}
    className={`m-2 p-3 rounded-lg shadow-sm cursor-pointer ${
      isSelected ? 'bg-blue-50 dark:bg-blue-900' : 'bg-white dark:bg-gray-800'
    }`}
    onClick={onClick}
  >
    <div className="flex items-center">
      <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white mr-3">
        {message.avatar}
      </div>
      <div className="flex-1">
        <div className="flex justify-between items-center">
          <span className="font-semibold text-gray-800 dark:text-white">
            {message.sender}
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {message.time}
          </span>
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300 truncate">
          {message.content}
        </p>
      </div>
    </div>
  </motion.div>
);

const ChatHeader = ({ sender, isDarkMode, toggleDarkMode, onClose }) => (
  <motion.div
    initial={{ y: -20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="p-4 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center"
  >
    <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
      {sender}
    </h2>
    <div className="flex items-center space-x-4">
      <button
        onClick={toggleDarkMode}
        className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
      >
        {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
      </button>
      <button
        onClick={onClose}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors duration-150 flex items-center"
      >
        Close
      </button>
      <button className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
        <MoreHorizontal size={20} />
      </button>
    </div>
  </motion.div>
);

const ChatMessages = ({ messages }) => (
  <motion.div className="flex-1 overflow-y-auto p-4">
    <AnimatePresence>
      {messages.map((msg) => (
        <motion.div
          key={msg.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className={`mb-4 flex ${
            msg.isUser ? 'justify-end' : 'justify-start'
          }`}
        >
          {!msg.isUser && (
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center text-gray-600 mr-2">
              B
            </div>
          )}
          <div
            className={`max-w-xs lg:max-w-md xl:max-w-lg ${
              msg.isUser
                ? 'bg-blue-500 text-white'
                : 'bg-white dark:bg-gray-700 text-gray-800 dark:text-white'
            } rounded-lg p-3 shadow-sm`}
          >
            <p>{msg.content}</p>
            <p className="text-xs text-right mt-1 opacity-75">{msg.time}</p>
          </div>
          {msg.isUser && (
            <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white ml-2">
              U
            </div>
          )}
        </motion.div>
      ))}
    </AnimatePresence>
  </motion.div>
);

const ChatInput = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
      className="p-4 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700"
    >
      <div className="flex items-center bg-gray-100 dark:bg-gray-700 rounded-md p-2">
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="flex-1 bg-transparent border-none focus:outline-none text-gray-700 dark:text-white"
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <button className="ml-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200">
          <Paperclip size={20} />
        </button>
        <button
          className="ml-2 text-blue-500 hover:text-blue-600"
          onClick={handleSend}
        >
          <Send size={20} />
        </button>
      </div>
    </motion.div>
  );
};

export default Inbox;
