import React from 'react';
import { IoShareSocialSharp } from 'react-icons/io5';

const AllNotesHeader = ({
  title,
  isShowDetails,
  setIsShowDetails,
  SoundbiteName,
  isHideBtn,
}) => {
  return (
    <div
      className={`border-b ${
        !isHideBtn ? 'justify-between' : 'justify-center'
      } py-2.5 px-6 bg-lightGray-100 dark:bg-gray-900 dark:border-gray-600 border-lightGray-10 h-[60px] flex items-center  gap-2`}
    >
      {!isHideBtn && <div />}

      {!isShowDetails && (
        <h2 className="font-poppins font-medium text-sm">{title}</h2>
      )}

      {isShowDetails && (
        <div>
          <button
            onClick={() => setIsShowDetails(false)}
            className="font-poppins font-medium text-sm text-gray-500"
          >
            {title}
          </button>{' '}
          <button className="font-poppins dark:text-white text-black font-medium text-sm">
            / {SoundbiteName}
          </button>
        </div>
      )}

      {!isHideBtn && (
        <div>
          <button className="px-3 py-2 bg-blue-20 hover:bg-blue-45 duration-300 text-white flex items-center rounded-lg gap-2">
            <IoShareSocialSharp size={20} />
            Share
          </button>
        </div>
      )}
    </div>
  );
};

export default AllNotesHeader;
