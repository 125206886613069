import React from 'react';

const VerticalAlineIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6665 2.5H12.4998C12.0396 2.5 11.6665 2.8731 11.6665 3.33333V7.5C11.6665 7.96024 12.0396 8.33333 12.4998 8.33333H16.6665C17.1267 8.33333 17.4998 7.96024 17.4998 7.5V3.33333C17.4998 2.8731 17.1267 2.5 16.6665 2.5Z"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6665 11.6641H12.4998C12.0396 11.6641 11.6665 12.0372 11.6665 12.4974V16.6641C11.6665 17.1243 12.0396 17.4974 12.4998 17.4974H16.6665C17.1267 17.4974 17.4998 17.1243 17.4998 16.6641V12.4974C17.4998 12.0372 17.1267 11.6641 16.6665 11.6641Z"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 5.83333L4.99984 2.5M4.99984 2.5L8.33317 5.83333M4.99984 2.5V17.5M4.99984 17.5L1.6665 14.1667M4.99984 17.5L8.33317 14.1667"
        stroke="currentColor"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VerticalAlineIcon;
