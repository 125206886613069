import React from 'react';
import ConfluenceIcon from '../assets/icons/Integrations/ConfluenceIcon';
import DriveIcon from '../assets/icons/Integrations/DriveIcon';
import FigmaIcon from '../assets/icons/Integrations/FigmaIcon';
import GitIcon from '../assets/icons/Integrations/GitIcon';
import Gamilicon from '../assets/icons/Integrations/Gamilicon';
import JiraIcon from '../assets/icons/Integrations/JiraIcon';
import MicrosoftTeamsIcon from '../assets/icons/Integrations/MicrosoftTeamsIcon';
import OktaIcon from '../assets/icons/Integrations/OktaIcon';
import AwsS3 from '../assets/icons/Integrations/AwsS3';
import BitBucket from '../assets/icons/Integrations/BitBucket';
import DropBox from '../assets/icons/Integrations/DropBox';
import GitLab from '../assets/icons/Integrations/GitLab';
import GoogleCalander from '../assets/icons/Integrations/GoogleCalander';
import GoogleCloudPlatform from '../assets/icons/Integrations/GoogleCloudPlatform';
import Insided from '../assets/icons/Integrations/Insided';
import Notion from '../assets/icons/Integrations/Notion';
import OneDrive from '../assets/icons/Integrations/OneDrive';
import Outlook from '../assets/icons/Integrations/Outlook';
import SharePoint from '../assets/icons/Integrations/SharePoint';
import Slack from '../assets/icons/Integrations/Slack';
import WorkDay from '../assets/icons/Integrations/WorkDay';
import ZenDesk from '../assets/icons/Integrations/ZenDesk';
import Epic from '../assets/icons/Integrations/Epic';
import Cerner from '../assets/icons/Integrations/Cerner';
import EClinicalWorks from '../assets/icons/Integrations/EClinicalWorks';

const IntegrationData = [
  { id: 1, name: 'Confluence', path: '/confluence', icon: <ConfluenceIcon /> },
  {
    id: 2,
    name: 'Google Drive',
    icon: <DriveIcon />,
  },
  {
    id: 3,
    name: 'Figma',
    icon: <FigmaIcon />,
  },
  {
    id: 4,
    name: 'Gmail',
    icon: <Gamilicon />,
  },
  {
    id: 5,
    name: 'GitHub',
    icon: <GitIcon />,
  },
  {
    id: 6,
    name: 'Jira',
    icon: <JiraIcon />,
  },
  {
    id: 7,
    name: 'Microsoft Teams',
    icon: <MicrosoftTeamsIcon />,
  },
  {
    id: 8,
    name: 'Okta',
    icon: <OktaIcon />,
  },
  {
    id: 9,
    name: 'AWS S3',
    icon: <AwsS3 />,
  },
  {
    id: 10,
    name: 'BitBucket',
    icon: <BitBucket />,
  },
  {
    id: 11,
    name: 'DropBox',
    icon: <DropBox />,
  },
  {
    id: 12,
    name: 'GitLab',
    icon: <GitLab />,
  },
  {
    id: 13,
    name: 'Google Calander',
    icon: <GoogleCalander />,
  },
  {
    id: 14,
    name: 'Google Cloud Platform',
    icon: <GoogleCloudPlatform />,
  },
  {
    id: 15,
    name: 'Insided',
    icon: <Insided />,
  },
  {
    id: 16,
    name: 'Notion',
    icon: <Notion />,
  },
  {
    id: 17,
    name: 'One Drive',
    icon: <OneDrive />,
  },
  {
    id: 18,
    name: 'Outlook',
    icon: <Outlook />,
  },

  {
    id: 19,
    name: 'Share Point',
    icon: <SharePoint />,
  },
  {
    id: 20,
    name: 'Slack',
    icon: <Slack />,
  },
  {
    id: 21,
    name: 'WorkDay',
    icon: <WorkDay />,
  },
  {
    id: 22,
    name: 'ZenDesk',
    icon: <ZenDesk />,
  },
];

const EMRIntegration = [
  { id: 1, name: 'Epic', path: '/epic', icon: <Epic /> },
  { id: 2, name: 'Cerner', path: '/cerner', icon: <Cerner /> },
  {
    id: 3,
    name: 'EClinical Works',
    path: '/eclinicalworks',
    icon: <EClinicalWorks />,
  },
];
const Integration = () => {
  return (
    <div className="flex flex-col gap-5 h-[calc(100vh-54px)]  p-6 lg:px-12 px-5 w-full">
      <h2 className="text-3xl text-center mx-auto font-semibold from-indigo-500 w-fit  to-fuchsia-700 bg-gradient-to-r bg-clip-text text-transparent">
        Integration Apps <span>(Upcoming)</span>
      </h2>

      <div className="grid xs:grid-cols-2 pb-8 md:grid-cols-3 3xl:grid-cols-5 gap-8">
        {IntegrationData?.map(({ id, icon, name }) => (
          <button
            key={id}
            className="bg-white dark:bg-gray-800 dark:border-gray-600 outline-none p-5 py-8 rounded-md shadow-shadow-box border border-gray-300 flex items-center justify-center flex-col gap-4"
          >
            <span>{icon}</span>
            <span className="font-medium text-xl">{name}</span>
          </button>
        ))}
      </div>

      <h2 className="text-3xl text-center mx-auto font-semibold from-indigo-500 w-fit  to-fuchsia-700 bg-gradient-to-r bg-clip-text text-transparent">
        EMR Integration
      </h2>
      <div className="grid xs:grid-cols-2 pb-8 md:grid-cols-3 3xl:grid-cols-5 gap-8">
        {EMRIntegration?.map(({ id, icon, name }) => (
          <button
            key={id}
            className="bg-white dark:bg-gray-800 dark:border-gray-600 outline-none p-5 py-8 rounded-md shadow-shadow-box border border-gray-300 flex items-center justify-center flex-col gap-4"
          >
            <span>{icon}</span>
            <span className="font-medium text-xl">{name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Integration;
