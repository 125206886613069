import React, { useState } from 'react';
import { Line } from 'recharts';
import { Heart } from 'react-feather';
import { LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Tick from '../../assets/icons/Tick';
import ChartAlertBox from './ChartAlertBox';
import StartBarIcon from '../../assets/icons/StartBarIcon';

const AllReportsLineChart = ({
  title,
  subtitle,
  value,
  change,
  isUnusual,
  chartData,
  color,
  insight,
  insightIcon,
  tickIcon,
  hasHeartIcon,
  isProblem,
}) => {
  const alertBoxColor = () => {
    if (color === 'green') {
      return '#D5F7D8';
    } else if (color === 'red') {
      return '#FFD7D7';
    } else if (color === 'blue') {
      return '#EBF2FF';
    }
  };

  const alertBoxTextColor = () => {
    if (color === 'green') {
      return '#159722';
    } else if (color === 'red') {
      return '#F5095C';
    } else if (color === 'blue') {
      return '#2E7CFE';
    }
  };
  const [isFillHeart, setIsFillHeart] = useState(false);

  return (
    <div className="bg-white p-6 rounded-3xl dark:bg-gray-800 dark:border-gray-600 border-[#F1EBF8] border-[1.5px] shadow">
      <div className="flex justify-between items-center mb-2">
        <div className="flex flex-col gap-1 items-start">
          <h2 className="font-medium text-xl text-start">{title}</h2>
          <p className="text-base font-medium text-start text-[#918B9C] dark:text-gray-300">
            {subtitle}
          </p>
        </div>
        <div className="flex items-center gap-1">
          {hasHeartIcon && (
            <button
              onClick={(e) => {
                e?.stopPropagation();
                setIsFillHeart(!isFillHeart);
              }}
            >
              <Heart
                fill={`${isFillHeart ? '#2e7cfe' : 'transparent'}`}
                className="text-blue-40 mr-2"
              />
            </button>
          )}

          {tickIcon && <Tick className="mr-2" />}

          <ChartAlertBox
            text={isProblem ? 'Problem' : isUnusual ? 'Unusual' : 'Normal'}
            backgroundColor={alertBoxColor()}
            textColor={alertBoxTextColor()}
            borderColor={alertBoxTextColor()}
            borderWidth={color === 'blue' ? 1 : 1.5}
          />
        </div>
      </div>
      <div className="mt-6 text-start">
        <h3
          className={`font-bold mb-2 text-[2rem] ${
            color === 'green'
              ? 'text-[#238B5B]'
              : color === 'red'
              ? 'text-[#F5095C]'
              : 'text-[#5D97FF]'
          }`}
        >
          {value}
        </h3>
        <p
          className={`text-sm font-semibold ${
            color === 'green'
              ? 'text-[#238B5B]'
              : color === 'red'
              ? 'text-[#F5095C]'
              : 'text-[#5D97FF]'
          }`}
        >
          {change}
        </p>
      </div>
      <div className="mt-4 h-48">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={chartData}>
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis dataKey="value" tickLine={false} />
            <Line
              type="linear"
              dataKey="value"
              stroke={
                color === 'green'
                  ? '#10B981'
                  : color === 'red'
                  ? '#EF4444'
                  : '#377FFF'
              }
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 pb-5 flex items-start gap-2">
        {insightIcon && (
          <div>
            <StartBarIcon />
          </div>
        )}

        <div className="text-sm font-light">{insight}</div>
      </div>
    </div>
  );
};

export default AllReportsLineChart;
