import React from 'react';
import { Menu, LogOut, LogIn } from 'react-feather';

const ChatHeader = ({
  toggleHistory,
  isLoggedIn,
  onLogin,
  toggleLogoutPopup,
  userName,
}) => {
  return (
    <header className="bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800 py-2 px-4 shadow-sm">
      <div className="w-full mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleHistory}
            className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            aria-label="Toggle History"
          >
            <Menu className="h-6 w-6" />
          </button>
          <div className="flex items-center space-x-2">
            <h1 className="text-base lg:text-xl text-gray-900 dark:text-white font-poppins font-bold truncate">
              Avahi AI Chat Assistant
            </h1>
          </div>
        </div>
        <div className="flex items-center flex-shrink-0 space-x-4">
          {isLoggedIn ? (
            <div className="flex items-center space-x-3">
              <span className="hidden md:inline text-sm text-gray-700 dark:text-gray-300 font-poppins">
                {userName}
              </span>
              <button
                onClick={toggleLogoutPopup}
                className="text-sm px-3 py-1.5 font-poppins font-semibold rounded-full bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-white transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 focus:ring-gray-500 dark:focus:ring-gray-700"
              >
                <span className="hidden md:inline">Log out</span>
                <LogOut className="h-4 w-4 md:hidden" />
              </button>
            </div>
          ) : (
            <button
              onClick={onLogin}
              className="text-sm px-3 md:px-4 py-1.5 font-poppins rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 focus:ring-blue-500"
            >
              <span className="hidden md:inline">Log in</span>
              <LogIn className="h-4 w-4 md:hidden" />
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default ChatHeader;
