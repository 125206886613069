import React, { useState } from 'react';

const ClaimDenialAssistant = () => {
  const [claimID, setClaimID] = useState('');
  const [denialReason, setDenialReason] = useState('');
  const [status, setStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('Processing...');
    setTimeout(() => {
      setStatus('Claim Denied Successfully');
    }, 2000);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Claim Denial Assistant
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="claimID"
              className="block text-sm font-medium text-gray-700"
            >
              Claim ID
            </label>
            <input
              type="text"
              id="claimID"
              value={claimID}
              onChange={(e) => setClaimID(e.target.value)}
              className="mt-1 block w-full border outline-none border-gray-300 p-2 text-black rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label
              htmlFor="denialReason"
              className="block text-sm font-medium text-gray-700"
            >
              Reason for Denial
            </label>
            <textarea
              id="denialReason"
              value={denialReason}
              onChange={(e) => setDenialReason(e.target.value)}
              rows="4"
              className="mt-1 p-2 block w-full border outline-none resize-none border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Submit
          </button>
          {status && (
            <div
              className={`mt-4 text-center ${
                status.includes('Successfully')
                  ? 'text-green-600'
                  : 'text-yellow-600'
              }`}
            >
              {status}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ClaimDenialAssistant;
