/* eslint-disable react/style-prop-object */
import React from 'react';
import TimeIcon from '../assets/icons/TimeIcon';
import SearchIcon from '../assets/icons/SearchIcon';
import { Upload } from 'react-feather';
import BellIcon from '../assets/icons/BellIcon';

const NL2SQLV1 = () => {
  return (
    <div className="px-4 md:px-6 bg-gray-100 dark:bg-gray-900 min-h-screen font-sans">
      <div className="w-full mx-auto">
        <div className="h-[60px] border-gray-200 dark:border-gray-600 flex items-center justify-between">
          <h1 className="text-3xl font-medium leading-5 font-poppins text-[13px] text-gray-800 dark:text-white capitalize">
            Natural language to sql
          </h1>

          <div className="flex py-2.5 items-center gap-2">
            <button className="text-[#D8E6FF] dark:text-gray-300">
              <TimeIcon />
            </button>
            <button className="text-[#D8E6FF] dark:text-gray-300">
              <BellIcon />
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-2 gap-y-3 pt-6 border-b border-gray-10 pb-6 1140:flex-row">
          <div className="flex w-full flex-col  gap-2.5 sm:flex-row 1140:w-auto justify-between">
            <div className="flex items-center gap-4">
              <div className="flex max-w-[195px] w-full items-center gap-2.5 rounded-md border border-gray-10 bg-[#F6F6F6] px-4 py-2 ">
                <input
                  type="text"
                  placeholder="Search by order id"
                  className="w-full border-none bg-transparent font-poppins text-[15px] font-normal leading-5 text-black outline-none placeholder:text-[#8B909A] "
                />
                <SearchIcon />
              </div>
              <button className="flex items-center text-blue-500 font-medium">
                <span className="text-lg">+</span>
                <span className="ml-1">Add filter</span>
              </button>
            </div>

            <button className="bg-[#377FFF] hover:bg-blue-700 text-white font-medium leading-5 py-2 px-4 rounded-md flex items-center gap-2.5">
              <Upload width={16} height={16} />
              <span>Upload file</span>
            </button>
          </div>
        </div>
        <div className="mt-5">
          <div
            style={{ boxShadow: '0px 0px 11px 0px #0000001A' }}
            className="bg-white h-[360px] rounded-2xl"
          ></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default NL2SQLV1;
