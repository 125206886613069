import React from 'react';

const CrossArrorw = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83203 5.83594H14.1654M14.1654 5.83594V14.1693M14.1654 5.83594L5.83203 14.1693"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossArrorw;
