/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import AllNotesHeader from '../AllNotes/AllNotesHeader';
import NewLiveAudioRecording from './NewLiveAudioRecording';
import RecordRTC from 'recordrtc';
import axios from 'axios';
import { toast } from 'react-toastify';
import EhrSummaryTab from '../MedicalScribingTabs/EhrSummaryTab';
import IcdTab from '../MedicalScribingTabs/IcdTab';

const TABS_DATA = [
  { id: 1, label: 'Transcript', value: 'Transcript' },
  { id: 2, label: 'EHR Summary', value: 'Ehr_Summary' },
  { id: 4, label: 'ICD Code Breakdown', value: 'Icd_Code_Breakdown' },
];

const NewLiveTranscription = () => {
  const intervalRef = useRef(null);

  const [isTranscribing, setIsTranscribing] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [, setPartialTranscript] = useState('');
  const [audioContext, setAudioContext] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [mediaStreamSource, setMediaStreamSource] = useState(null);
  const [processor, setProcessor] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingTotalTime, setRecordingTotalTime] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isStoped, setIsStoped] = useState(false);
  const [isShowTab, setIsShowTab] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Transcript');
  const [recordRTC, setRecordRTC] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [, setUploadFileResponse] = useState(null);

  const [reportResponse, setReportResponse] = useState(null);
  const [icd10Response, setIcd10Response] = useState(null);

  const BUFFER_SIZE = 16384;
  const serverUrl = `${process.env.REACT_APP_API_LIVE_TRANSCRIPTION}`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.0.1/socket.io.js';
    script.onload = () => {
      window.socket = window.io(serverUrl, {
        withCredentials: true,
        transports: ['websocket'],
      });

      window.socket.on('transcription', (data) => {
        console.log('transcription data', data);
        updateTranscription(data.text.trim(), data.is_partial);
      });

      window.socket.on('connect', () => {
        console.log('Connected to server');
      });

      window.socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });

      window.socket.on('disconnect', () => {
        console.log('Disconnected from server');
        setIsTranscribing(false);
        setIsStoped(false);
        setIsStarted(false);

        if (processor) {
          processor.disconnect();
          mediaStreamSource.disconnect();
          closeAudioContext();
        }
      });

      window.socket.on('error', (error) => {
        console.error('Socket.IO Error:', error);
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      if (audioContext) {
        closeAudioContext();
      }
    };
  }, [audioContext]);

  const closeAudioContext = () => {
    if (audioContext?.current && audioContext?.current?.state !== 'closed') {
      audioContext?.current
        .close()
        .then(() => {
          console.log('AudioContext closed');
          audioContext.current = null;
        })
        .catch((error) => {
          console.error('Error closing AudioContext:', error);
        });
    } else {
      console.log('AudioContext is already closed');
    }
  };

  const startTranscription = async () => {
    if (isTranscribing || isPaused) return;
    setIsTranscribing(true);
    setIsStarted(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const mediaStreamSrc = audioCtx.createMediaStreamSource(stream);
      const audioProcessor = audioCtx.createScriptProcessor(BUFFER_SIZE, 1, 1);

      mediaStreamSrc.connect(audioProcessor);
      audioProcessor.connect(audioCtx.destination);

      audioProcessor.onaudioprocess = (e) => {
        if (!isPaused) {
          const inputData = e.inputBuffer.getChannelData(0);
          const pcmData = convertFloatTo16BitPCM(inputData);
          sendAudioChunk(pcmData);
        }
      };

      window.socket.emit('start_transcription', (response) => {
        console.log(response.status);
        if (response.status === 'Transcription started') {
          setIsTranscribing(true);
          setIsStarted(true);
          const recorder = RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/mp3',
          });
          recorder.startRecording();
          setRecordRTC(recorder);
        }
      });

      setAudioContext(audioCtx);
      setMediaStreamSource(mediaStreamSrc);
      setProcessor(audioProcessor);
      setRecordingTime(0);
      intervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (err) {
      console.error('Error accessing microphone:', err);
    }
  };

  const handleFileUpload = async (file) => {
    if (file) {
      try {
        setIsFileLoading(true);
        const formData = new FormData();

        formData.append('file', file);

        const response = await axios.post(
          `${process.env.REACT_APP_API_HEALTHCARE_UPLOAD_FILE}/upload_and_transcribe`,
          formData,
          {
            headers: {
              //"Content-Type": "multipart/form-data",
            },
            maxBodyLength: Infinity,
          }
        );

        if (response.status === 200) {
          setIsFileLoading(false);
          setIsShowTab(true);
          setIsShowTab(true);
          setUploadFileResponse(response?.data);
          toast.success('Audio file uploaded successfully!');
          fetchHealthscribeOutputsForReport(response?.data);
        } else {
          setIsFileLoading(false);
          toast.error('Audio file upload failed.');
          throw new Error('Upload failed');
        }
      } catch (error) {
        setIsFileLoading(false);
        console.error('Error uploading Audio:', error);
        toast.error('Error uploading Audio file.');
      }
    }
  };

  const stopTranscription = () => {
    clearInterval(intervalRef.current);
    setIsTranscribing(false);
    setIsStoped(false);
    setIsStarted(false);
    if (processor) {
      processor.disconnect();
      mediaStreamSource.disconnect();
      closeAudioContext();
    }

    setRecordingTotalTime(recordingTime);
    window.socket.emit('stop_transcription', (response) => {
      console.log(response.status);
      if (response.status === 'Transcription stopped') {
        if (recordRTC) {
          recordRTC.stopRecording(() => {
            const blob = recordRTC.getBlob();
            const file = new File([blob], 'recording.mp3', {
              type: 'audio/mp3',
            });

            handleFileUpload(file);

            const fileURL = URL.createObjectURL(file);

            if (fileURL) {
              setAudioURL(fileURL);
            }
          });
        }
      }
    });
  };

  const pauseTranscription = () => {
    setIsPaused(true);
    setIsTranscribing(false);
    clearInterval(intervalRef.current);
    console.log('Transcription paused');
  };

  const resumeTranscription = () => {
    setIsPaused(false);
    setIsStarted(true);
    setIsTranscribing(true);
    console.log('Transcription resumed');
    intervalRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const convertFloatTo16BitPCM = (float32Array) => {
    const int16Array = new Int16Array(float32Array.length);
    for (let i = 0; i < float32Array.length; i++) {
      const s = Math.max(-1, Math.min(1, float32Array[i]));
      int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
    return int16Array;
  };

  const sendAudioChunk = (audioData) => {
    window.socket.emit('audio_data', { audio: audioData.buffer });
    console.log(
      `Sent audio chunk of size ${audioData.length} samples (${
        audioData.length * 2
      } bytes)`
    );
  };

  let fullTranscript = '';
  let lastPartialLength = 0;

  const updateTranscription = (text, isPartial) => {
    if (isPartial) {
      fullTranscript = fullTranscript.slice(0, -lastPartialLength);
      fullTranscript += text;
      lastPartialLength = text.length;
      setPartialTranscript(text);
    } else {
      fullTranscript = fullTranscript.slice(0, -lastPartialLength);
      if (fullTranscript && !fullTranscript.endsWith(' ')) {
        fullTranscript += ' ';
      }
      fullTranscript += text;
      lastPartialLength = 0;
      setPartialTranscript('');
    }
    setTranscription(`${transcription} ${fullTranscript}`);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    startTranscription();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const fetchHealthscribeOutputsForReport = async (requestPayload) => {
    try {
      setIsFileLoading(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_HEALTHCARE}/fetch-healthscribe-outputs-for-recorded-audio/fetch-healthscribe-outputs-for-recorded-audio`,
        {
          queryStringParameters: {
            job_name: requestPayload?.JobName,
            type: 'report',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'Tq6isgVNGT1v2yYpayLwA7Nuw2dmUmjXaR2UQ7jn',
          },
        }
      );

      if (response?.data?.statusCode === 200) {
        setReportResponse(JSON.parse(response?.data?.body));
      } else {
        toast.error('Fetch data failed.');
        throw new Error('Fetch data failed');
      }
    } catch (error) {
      console.error('Error Fetch data:', error);

      toast.error('Error Fetch data.');
    } finally {
      setIsFileLoading(false);
      fetchHealthscribeOutputsForIcd10(requestPayload);
    }
  };

  const fetchHealthscribeOutputsForIcd10 = async (requestPayload) => {
    try {
      setIsFileLoading(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_HEALTHCARE}/fetch-healthscribe-outputs-for-recorded-audio/fetch-healthscribe-outputs-for-recorded-audio`,
        {
          queryStringParameters: {
            job_name: requestPayload?.JobName,
            type: 'icd10',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'Tq6isgVNGT1v2yYpayLwA7Nuw2dmUmjXaR2UQ7jn',
          },
        }
      );

      if (response?.data?.statusCode === 200) {
        setIcd10Response(JSON.parse(response?.data?.body));
      } else {
        toast.error('Fetch data failed.');
        throw new Error('Fetch data failed');
      }
    } catch (error) {
      console.error('Error Fetch data:', error);
      toast.error('Error Fetch data.');
    }
  };

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] ovey lg:h-screen w-full bg-[#FCFCFC] dark:bg-gray-900 overflow-y-hidden">
      <AllNotesHeader title={'Live Transcription'} isHideBtn={true} />

      <div className="max-h-[calc(100%-60px)] flex flex-col py-6 w-full overflow-y-auto h-full">
        <div className="gap-5 max-w-[500px] w-full mx-auto">
          {(isTranscribing || isStoped || isStarted) && (
            <NewLiveAudioRecording
              recordingTime={isStoped ? recordingTotalTime : recordingTime}
              isStoped={isStoped}
              isPaused={isStoped || isPaused}
              handleStopRecording={stopTranscription}
              handleStartRecording={startTranscription}
              handlePauseRecording={pauseTranscription}
              handleResumeRecording={resumeTranscription}
            />
          )}

          {!isTranscribing && !isStarted && !isStoped && (
            <div className="flex items-center flex-col  justify-center rounded-lg border-2 border-dashed border-blue-500 px-2 py-16 transition-all md:px-10">
              <label
                htmlFor="file-upload"
                className={`flex items-center justify-center cursor-pointer`}
              >
                <div className="flex flex-col items-center justify-center gap-4">
                  <button
                    type="button"
                    onClick={handleButtonClick}
                    className="focus:outline-none"
                  >
                    <img
                      src="/images/micIcon.png"
                      alt="mic-img"
                      className="size-20"
                    />
                  </button>

                  <p className="font-poppins text-base font-semibold leading-6 text-blackDark-100">
                    {isTranscribing ? 'Recording...' : 'Start recording'}
                  </p>
                </div>
              </label>

              {audioURL && (
                <>
                  <p className="font-poppins my-5  text-base font-semibold text-black dark:text-white">
                    {formatTime(recordingTime)}
                  </p>
                  <audio
                    src={audioURL}
                    type="audio/mpeg"
                    className="w-full -mb-8 max-w-[375px] mx-auto"
                    controls
                  >
                    <source />
                    Your browser does not support the audio element.
                  </audio>
                </>
              )}
            </div>
          )}
        </div>

        {isShowTab && (
          <div className="flex items-center gap-9 border-b border-gray-300 w-fit mx-9 mt-6">
            {TABS_DATA?.map(({ id, label, value }) => (
              <button
                key={id}
                onClick={() => setSelectedTab(value)}
                className={`${
                  selectedTab === value
                    ? 'border-[#005CFF] !text-[#005CFF]'
                    : 'border-transparent'
                } font-poppins text-base font-medium text-[#989898] pb-2 border-b-2  m-[-1px]`}
              >
                {label}
              </button>
            ))}
          </div>
        )}

        {selectedTab === 'Transcript' && (
          <div className="flex flex-col h-full py-6 px-9   min-h-[300px]">
            <div className="relative border h-full overflow-y-hidden flex items-center dark:border-gray-600 border-gray-10 py-6 rounded-xl">
              <textarea
                id="transcription"
                placeholder="Your transcription"
                className="text-[#737373] dark:text-white !break-words h-full bg-transparent outline-none resize-none px-6 pr-14  overflow-y-auto helpdesk-custom-scrollbar w-full text-base font-normal font-poppins tracking-[-0.08px]"
                disabled
                value={transcription}
              />
            </div>
          </div>
        )}

        {selectedTab === 'Ehr_Summary' && (
          <div className="flex flex-col h-full py-6 px-9  min-h-[300px]">
            <EhrSummaryTab data={reportResponse} />
          </div>
        )}

        {selectedTab === 'Icd_Code_Breakdown' && (
          <div className="flex flex-col h-full py-6 px-9  min-h-[300px]">
            <IcdTab IcdData={icd10Response} />
          </div>
        )}
      </div>

      {isFileLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}
    </div>
  );
};

export default NewLiveTranscription;
