import React from 'react';

const ChartAlertBox = ({
  text,
  borderColor,
  backgroundColor,
  textColor,
  count,
  borderWidth,
}) => {
  return (
    <div
      className={`px-3 flex border-[${borderWidth}px] flex-row py-[6px] gap-2 items-center rounded-full`}
      style={{ backgroundColor: backgroundColor, borderColor: borderColor }}
    >
      <span className="text-sm font-semibold" style={{ color: textColor }}>
        {text}
      </span>
      {count && (
        <div
          className="rounded-full flex items-center justify-center px-2 py-[2px]"
          style={{ backgroundColor: borderColor }}
        >
          <span className="text-sm" style={{ color: textColor }}>
            {count}
          </span>
        </div>
      )}
    </div>
  );
};

export default ChartAlertBox;
