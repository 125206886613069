import React from 'react';

export const HistortData = [
  {
    date: 'August 20,2024',
    result: [
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '18 August',
        resultTime: '4:35 PM',
      },
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '19 August',
        resultTime: '2:25 PM',
      },
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '14 August',
        resultTime: '3:30 PM',
      },
    ],
  },

  {
    date: 'August 15,2024',
    result: [
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '13 August',
        resultTime: '12:30 PM',
      },
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '12 August',
        resultTime: '11:25 PM',
      },
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '11 August',
        resultTime: '5:30 PM',
      },
    ],
  },

  {
    date: 'August 11,2024',
    result: [
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '10 August',
        resultTime: '3:45 PM',
      },
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '9 August',
        resultTime: '12:25 PM',
      },
      {
        title: 'Show all users under 30 years old and over 15 years old.',
        resultDate: '4 August',
        resultTime: '6:20 PM',
      },
    ],
  },
];

const HistorySideBar = () => {
  return (
    <div className="slideInRight scrollbar-custom absolute right-0  top-[110px] max-h-[calc(100%-110px)] h-full max-w-[600px] overflow-y-auto bg-white px-4 py-6 shadow-imageBtn">
      <div
        key={'history'}
        className="flex flex-col gap-6 overflow-y-auto"
      >
        {HistortData &&
          HistortData.length > 0 &&
          HistortData.map((his, index) => (
            <div
              key={`history-${index}`}
              className="grid grid-cols-2 gap-x-9 gap-y-6"
            >
              <div className="col-span-2 flex items-center gap-2">
                <div className="w-full border border-gray-10/50"></div>
                <div className=" whitespace-nowrap rounded-md bg-gray-10/50 p-2 text-center">
                  {his.date}
                </div>
                <div className="w-full border border-gray-10/50"></div>
              </div>

              {his?.result?.map((historydata) => (
                <>
                  <div className="flex flex-col gap-2 rounded-md border border-blue-30 p-4">
                    <h4 className="font-poppins text-sm font-medium leading-5">
                      {historydata?.title}
                    </h4>
                    <div className="flex items-center gap-3">
                      <p className="font-poppins text-sm font-normal leading-5 text-gray-150">
                        {historydata?.resultDate}
                      </p>
                      <p className=" size-1.5 rounded-full bg-gray-150" />
                      <p className="font-poppins text-sm font-normal leading-5 text-gray-150">
                        {historydata?.resultTime}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default HistorySideBar;
