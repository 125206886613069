import React, { useState } from 'react';

const MedicalImageSegmentation = () => {
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="bg-white p-8 rounded-lg  shadow-shadow-div">
        <h2 className="text-2xl font-semibold mb-4">
          Medical Image Segmentation
        </h2>

        <div className="flex gap-4 items-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="p-4 border w-full border-gray-300 rounded"
          />

          <button className="bg-blue-600 whitespace-nowrap text-white px-4 py-2 rounded hover:bg-blue-700">
            Run Segmentation
          </button>
        </div>

        {image && (
          <div className="my-4">
            <img
              src={image}
              alt="Uploaded"
              className="h-auto mx-auto  max-h-[500px] object-contain w-fit  rounded-md"
            />
          </div>
        )}

        {/* Results would be displayed here */}
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-2">Results</h3>
          {/* Display segmentation results here */}
        </div>
      </div>
    </div>
  );
};

export default MedicalImageSegmentation;
