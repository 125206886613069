import React, { useEffect, useState } from "react";
import { LuPanelRightOpen } from "react-icons/lu";
import { CiFileOn } from "react-icons/ci";
import { motion } from "framer-motion";
import { RefreshCw } from "react-feather";
export const PdfHistoryData = [
  {
    type: "Currently Uploaded",
    result: [
      {
        title: "Medical Notes ‘24",
        fileUrl: "",
      },
      {
        title: "Claude Privacy Statement",
        fileUrl: "",
      },
    ],
  },

  {
    type: "Recently Viewed",
    result: [
      {
        title: "Perplexity Privacy Statement",
        fileUrl: "",
      },
      {
        title: "Medical Notes ‘24",
        fileUrl: "",
      },
    ],
  },

  {
    type: "Favorites",
    result: [
      {
        title: "Perplexity Privacy Statement",
        fileUrl: "",
      },
      {
        title: "Medical Notes ‘24",
        fileUrl: "",
      },
    ],
  },
];

const PdfViewerFilesSidebar = ({
  setIsOpenFileList,
  files,
  setPdfFile,
  setPdfUploadData,
}) => {
  const handleFileChange = (file) => {
    setPdfUploadData({ pdf_name: file?.pdf_name });
    setPdfFile(URL.createObjectURL(file));
    setIsOpenFileList(false);
  };

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    // Function to update window width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set initial window width
    handleResize();

    // Add event listener for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={
        windowWidth >= 768
          ? `slideInLeft z-50 scrollbar-custom   w-full max-h-screen h-full max-w-[250px] overflow-y-auto bg-[#F2F2F2]  py-4 shadow-imageBtn`
          : `absolute top-0 left-0 slideInLeft z-50 scrollbar-custom   w-full max-h-screen h-full max-w-[250px] overflow-y-auto bg-[#F2F2F2]  py-4 shadow-imageBtn`
      }
    >
      <div className="flex justify-between items-center gap-3 border-b border-[#E2E0E5] px-4 pb-1.5">
        <h4 className="font-semibold text-base font-poppins leading-6">
          Smart Summarizer
        </h4>
        <button
          className=" dark:text-gray-300"
          onClick={() => {
            setIsOpenFileList(false);
          }}
        >
          <LuPanelRightOpen size={20} />
        </button>
      </div>

      <div key={"history"} className="flex flex-col gap-3 h-auto px-4">
        {files && files.length > 0 && (
          <div className="col-span-2 flex items-center gap-2 pt-2">
            <div className="font-poppins uppercase font-medium text-xs text-[#787878] leading-4">
              Currently Uploaded
            </div>
          </div>
        )}
        {files &&
          files.length > 0 &&
          files.map(
            (item, index) =>
              item?.isUploaded && (
                <div
                  key={`history-${index}`}
                  className="flex flex-col gap-x-9 gap-y-4"
                  onClick={() => {
                    handleFileChange(item);
                  }}
                >
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2 cursor-pointer">
                      <div>
                        <CiFileOn size={15} />
                      </div>
                      <p
                        title={item?.name}
                        className="font-poppins font-normal text-sm leading-5 text-ellipsis max-w-[180px] whitespace-nowrap overflow-hidden"
                      >
                        {item?.name}
                      </p>
                    </div>
                  </div>
                </div>
              )
          )}

        {files && files.length > 0 && (
          <div className="col-span-2 flex items-center gap-2">
            <div className="font-poppins uppercase font-medium text-xs text-[#787878] leading-4">
              Uploading Pending
            </div>
          </div>
        )}
        {files &&
          files.length > 0 &&
          files.map(
            (item, index) =>
              !item?.isUploaded && (
                <div
                  key={`pending-${index}`}
                  className="flex flex-col gap-x-9 gap-y-4"
                >
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2 cursor-pointer">
                      <div>
                        <CiFileOn size={20} />
                      </div>
                      <p
                        title={item?.name}
                        className="font-poppins font-normal text-sm leading-5 text-ellipsis max-w-[180px] whitespace-nowrap overflow-hidden"
                      >
                        {item?.name}
                      </p>
                      {item?.isLoading && (
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{
                            duration: 1,
                            repeat: Infinity,
                            ease: "linear",
                          }}
                        >
                          <RefreshCw size={10} className="mr-2" />
                        </motion.div>
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
};

export default PdfViewerFilesSidebar;
