export const IIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0.125C4.03582 0.125 3.09329 0.410914 2.2916 0.946586C1.48991 1.48226 0.865067 2.24363 0.496089 3.13442C0.127112 4.02521 0.030571 5.00541 0.218674 5.95107C0.406777 6.89672 0.871076 7.76536 1.55286 8.44715C2.23464 9.12893 3.10328 9.59323 4.04894 9.78133C4.99459 9.96943 5.97479 9.87289 6.86558 9.50391C7.75637 9.13494 8.51775 8.51009 9.05342 7.7084C9.58909 6.90671 9.875 5.96418 9.875 5C9.87364 3.70749 9.35958 2.46831 8.44564 1.55436C7.5317 0.640418 6.29251 0.126365 5 0.125ZM5 9.125C4.18415 9.125 3.38663 8.88307 2.70827 8.42981C2.02992 7.97655 1.50121 7.33231 1.189 6.57857C0.876788 5.82482 0.795099 4.99542 0.954263 4.19525C1.11343 3.39508 1.5063 2.66008 2.08319 2.08318C2.66008 1.50629 3.39508 1.11342 4.19525 0.954261C4.99543 0.795097 5.82483 0.876785 6.57857 1.189C7.33232 1.50121 7.97655 2.02992 8.42981 2.70827C8.88307 3.38663 9.125 4.18415 9.125 5C9.12376 6.09364 8.68877 7.14213 7.91545 7.91545C7.14213 8.68876 6.09364 9.12376 5 9.125ZM5.75 7.25C5.75 7.34946 5.71049 7.44484 5.64017 7.51517C5.56984 7.58549 5.47446 7.625 5.375 7.625C5.17609 7.625 4.98532 7.54598 4.84467 7.40533C4.70402 7.26468 4.625 7.07391 4.625 6.875V5C4.52555 5 4.43016 4.96049 4.35984 4.89016C4.28951 4.81984 4.25 4.72446 4.25 4.625C4.25 4.52554 4.28951 4.43016 4.35984 4.35984C4.43016 4.28951 4.52555 4.25 4.625 4.25C4.82391 4.25 5.01468 4.32902 5.15533 4.46967C5.29598 4.61032 5.375 4.80109 5.375 5V6.875C5.47446 6.875 5.56984 6.91451 5.64017 6.98483C5.71049 7.05516 5.75 7.15054 5.75 7.25ZM4.25 2.9375C4.25 2.82625 4.28299 2.71749 4.3448 2.62499C4.40661 2.53249 4.49446 2.46039 4.59724 2.41782C4.70003 2.37524 4.81313 2.3641 4.92224 2.38581C5.03135 2.40751 5.13158 2.46109 5.21025 2.53975C5.28892 2.61842 5.34249 2.71865 5.36419 2.82776C5.3859 2.93688 5.37476 3.04998 5.33218 3.15276C5.28961 3.25554 5.21751 3.34339 5.12501 3.4052C5.03251 3.46701 4.92375 3.5 4.8125 3.5C4.66332 3.5 4.52024 3.44074 4.41475 3.33525C4.30927 3.22976 4.25 3.08668 4.25 2.9375Z"
        fill="#737373"
      />
    </svg>
  );
};
