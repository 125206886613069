import React from 'react';

const features = [
  {
    title: 'Accuracy',
    description: 'AI-driven validation ensures precision in claims processing.',
  },
  {
    title: 'Speed',
    description:
      'Reduce processing time significantly with automated workflows.',
  },
  {
    title: 'Compliance',
    description: 'Stay compliant with the latest healthcare regulations.',
  },
];

const steps = [
  {
    step: '1',
    title: 'Upload Claim',
    description: 'Submit your claims documents securely.',
  },
  {
    step: '2',
    title: 'AI Analysis',
    description: 'Our AI reviews and validates the claims.',
  },
  {
    step: '3',
    title: 'Receive Results',
    description: 'Get detailed results and recommendations quickly.',
  },
];

const testimonials = [
  {
    name: 'Jane Smith',
    text: 'The AI platform significantly sped up our claims processing and improved accuracy.',
  },
  {
    name: 'Bob Johnson',
    text: 'Fantastic tool for managing healthcare claims. Highly recommended!',
  },
];

const ClaimsAI = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col  p-6">
      <section className="bg-gray-50 text-center p-12 rounded-lg shadow-shadow-div">
        <h1 className="text-4xl font-bold mb-4">ClaimsAI</h1>
        <p className="text-lg mb-6">
          Our AI platform streamlines claims processing, improving accuracy and
          efficiency.
        </p>
        <a
          href="#contact"
          className="bg-blue-700 text-white py-2 px-6 rounded-lg hover:bg-blue-600 duration-300"
        >
          Get Started
        </a>
      </section>

      <section
        id="features"
        className="p-12 bg-gray-50 mt-6 rounded-lg  shadow-shadow-div"
      >
        <h2 className="text-3xl font-bold text-center mb-8">Key Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg text-center"
            >
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section
        id="how-it-works"
        className="p-12 bg-gray-50 mt-6 rounded-lg  shadow-shadow-div"
      >
        <h2 className="text-3xl font-bold text-center mb-8">How It Works</h2>
        <div className="flex flex-col space-y-6">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="bg-blue-900 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                {step.step}
              </div>
              <div>
                <h3 className="text-xl font-semibold">{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section
        id="testimonials"
        className="p-12 bg-gray-50 mt-6 rounded-lg  shadow-shadow-div"
      >
        <h2 className="text-3xl font-bold text-center mb-8">
          What Our Clients Say
        </h2>
        <div className="flex flex-col space-y-6">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <p className="italic">"{testimonial.text}"</p>
              <p className="font-semibold text-right mt-4">
                - {testimonial.name}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ClaimsAI;
