// "type": "report"
import React from 'react';
import { motion } from 'framer-motion';
import Accordion from '../Accordian';

const EhrSummaryTab = ({ data }) => {
  const parseSections = (text) => {
    const sectionRegex = /Section: (.+?)(?=\nSection:|\n*$)/gs;
    const bulletRegex = /^\s*•\s+(.+)/gm;

    const sections = [];
    let currentSection = null;
    let match;

    while ((match = sectionRegex.exec(text)) !== null) {
      const sectionName = match[1].split('\n')[0].trim();
      const sectionContent = match[1].split('\n').slice(1).join('\n').trim();

      const bullets = [];
      let bulletMatch;

      while ((bulletMatch = bulletRegex.exec(sectionContent)) !== null) {
        const cleanedBullet = `• ${bulletMatch[1].replace(/^-\s*/, '').trim()}`;
        bullets.push(cleanedBullet);
      }

      currentSection = {
        title: sectionName,
        content: bullets,
      };

      if (currentSection) sections.push(currentSection);
    }
    return sections;
  };

  const resultData = parseSections(data);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      key={'EhrSummaryTab'}
      className={`p-4 rounded-lg min-h-[200px] max-h-[500px] overflow-y-auto helpdesk-custom-scrollbar dark:bg-gray-700 bg-white`}
    >
      {resultData?.map((item, index) => (
        <>
          {item?.content && item?.content?.length > 0 && (
            <Accordion
              key={index}
              title={item?.title}
              content={
                <div className="mt-2">
                  {item?.content?.map((data, inx) => (
                    <p key={inx}>{data}</p>
                  ))}
                </div>
              }
            />
          )}
        </>
      ))}
    </motion.div>
  );
};

export default EhrSummaryTab;
