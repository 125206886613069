import React from 'react';
import SearchIcon from '../../assets/icons/SearchIcon';
import { SlashIcon } from '../../assets/icons/SlashIcon';
import ChangeScreenIcon from '../../assets/icons/ChangeScreenIcon';
import NormalScreenIcon from '../../assets/icons/NormalScreenIcon';

const TranscriptData = [
  {
    data: [
      {
        id: 1,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:14',
        text: 'Hi, Mr Jones. How are you?',
      },
      {
        id: 2,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:16',
        text: `I'm good, Dr Smith.`,
      },
      {
        id: 3,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:17',
        text: `Nice to see you. Nice to see you again. What brings you back? `,
      },
      {
        id: 4,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:21',
        text: `Well, my back's been hurting again. `,
      },
      {
        id: 5,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:23',
        text: `I see. I've seen you a number of times for this, haven't I?`,
      },
      {
        id: 6,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:28',
        text: `Yeah. Well, ever since I got hurt on the job three years ago, it's something that just keeps coming back. It'll be fine for a while and then I'll bend down or I'll move in a weird way and then boom, it'll just go out again.`,
      },
      {
        id: 7,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:37',
        text: `Unfortunately that can happen and I do have quite a few patients who get reoccurring episodes of back pain. Have you been keeping up with the therapy that we had you on before? `,
      },
      {
        id: 8,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:55',
        text: 'Which? The pills?',
      },
      {
        id: 9,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:58',
        text: `Actually, I was talking about the physical therapy that we had you doing; the pills were only meant for short term because they don't actually prevent the back pain from coming back.`,
      },
      {
        id: 10,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '01:11',
        text: 'Yeah, once my back started feeling better, I was happy not to go to the therapist anymore. ',
      },
      {
        id: 11,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '01:17',
        text: `Why? Was that was starting to become kind of a hassle? `,
      },
      {
        id: 12,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '01:21',
        text: 'You know, with my work schedule, and the cost was an issue, but I was able to get back to work, so. And I could use the money. ',
      },
    ],
  },
  {
    data: [
      {
        id: 1,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:14',
        text: 'Hi, Mr Pat Bellamy. How are you?',
      },
      {
        id: 2,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:16',
        text: `I'm good, Dr Smith.`,
      },
      {
        id: 3,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:17',
        text: `Nice to see you. Nice to see you again. What brings you back? `,
      },
      {
        id: 4,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:21',
        text: `Well, my back's been hurting again. `,
      },
      {
        id: 5,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:23',
        text: `I see. I've seen you a number of times for this, haven't I?`,
      },
      {
        id: 6,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:28',
        text: `Yeah. Well, ever since I got hurt on the job three years ago, it's something that just keeps coming back. It'll be fine for a while and then I'll bend down or I'll move in a weird way and then boom, it'll just go out again.`,
      },
      {
        id: 7,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:37',
        text: `Unfortunately that can happen and I do have quite a few patients who get reoccurring episodes of back pain. Have you been keeping up with the therapy that we had you on before? `,
      },
      {
        id: 8,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:55',
        text: 'Which? The pills?',
      },
      {
        id: 9,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:58',
        text: `Actually, I was talking about the physical therapy that we had you doing; the pills were only meant for short term because they don't actually prevent the back pain from coming back.`,
      },
      {
        id: 10,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '01:11',
        text: 'Yeah, once my back started feeling better, I was happy not to go to the therapist anymore. ',
      },
      {
        id: 11,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '01:17',
        text: `Why? Was that was starting to become kind of a hassle? `,
      },
      {
        id: 12,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '01:21',
        text: 'You know, with my work schedule, and the cost was an issue, but I was able to get back to work, so. And I could use the money. ',
      },
    ],
  },
  {
    data: [
      {
        id: 1,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:14',
        text: 'Hi, Mr Alexis. How are you?',
      },
      {
        id: 2,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:16',
        text: `I'm good, Dr Smith.`,
      },
      {
        id: 3,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:17',
        text: `Nice to see you. Nice to see you again. What brings you back? `,
      },
      {
        id: 4,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:21',
        text: `Well, my back's been hurting again. `,
      },
      {
        id: 5,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:23',
        text: `I see. I've seen you a number of times for this, haven't I?`,
      },
      {
        id: 6,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:28',
        text: `Yeah. Well, ever since I got hurt on the job three years ago, it's something that just keeps coming back. It'll be fine for a while and then I'll bend down or I'll move in a weird way and then boom, it'll just go out again.`,
      },
      {
        id: 7,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:37',
        text: `Unfortunately that can happen and I do have quite a few patients who get reoccurring episodes of back pain. Have you been keeping up with the therapy that we had you on before? `,
      },
      {
        id: 8,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '00:55',
        text: 'Which? The pills?',
      },
      {
        id: 9,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '00:58',
        text: `Actually, I was talking about the physical therapy that we had you doing; the pills were only meant for short term because they don't actually prevent the back pain from coming back.`,
      },
      {
        id: 10,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '01:11',
        text: 'Yeah, once my back started feeling better, I was happy not to go to the therapist anymore. ',
      },
      {
        id: 11,
        user: 'Dr. Joe (Me)',
        firstLatter: { name: 'D', color: '#238B5B' },
        time: '01:17',
        text: `Why? Was that was starting to become kind of a hassle? `,
      },
      {
        id: 12,
        user: 'Patient',
        firstLatter: { name: 'P', color: '#377FFF' },
        time: '01:21',
        text: 'You know, with my work schedule, and the cost was an issue, but I was able to get back to work, so. And I could use the money. ',
      },
    ],
  },
];

const NotesTranscript = ({ activeIndex, setFullscreen, fullscreen }) => {
  return (
    <div className="w-full h-full border dark:border-gray-600 border-gray-10 rounded-lg">
      <div>
        <div className="flex border-b border-gray-10 dark:border-gray-600 px-4 py-3 pr-3 items-center justify-between dark:bg-gray-900 bg-[#FCFCFC] rounded-t-lg">
          <h2 className="text-base text-blackDark-10 dark:text-white font-medium tracking-[-0.32px]">
            Transcript
          </h2>

          <button
            onClick={() => {
              if (fullscreen?.isFullscreen) {
                setFullscreen({
                  isFullscreen: false,
                  screenLabel: null,
                });
              } else {
                setFullscreen({
                  isFullscreen: true,
                  screenLabel: 'Notes',
                });
              }
            }}
          >
            {fullscreen?.isFullscreen ? (
              <NormalScreenIcon />
            ) : (
              <ChangeScreenIcon />
            )}
          </button>
        </div>

        <div className="flex items-center gap-2 p-3 dark:bg-gray-900 dark:border-gray-600 bg-[#F4F4F4] border-b border-gray-10">
          <SearchIcon />
          <input
            type="text"
            placeholder="Search..."
            className="w-full text-xs font-normal bg-transparent outline-none"
          />
          <SlashIcon />
        </div>
      </div>

      <div className="pb-10 h-[calc(100%-105px)] helpdesk-custom-scrollbar overflow-y-auto">
        {TranscriptData[activeIndex]?.data?.map(
          ({ id, firstLatter, time, user, text }) => (
            <div key={id} className="p-4 flex flex-col gap-2">
              <div className="flex items-center gap-2.5">
                <span
                  className="text-white text-[8px] px-1 rounded pt-0.5 font-medium"
                  style={{
                    backgroundColor: firstLatter?.color,
                  }}
                >
                  {firstLatter?.name}
                </span>

                <span className="text-blackDark-10 dark:text-white text-xs font-medium tracking-[-0.24px]">
                  {user}
                </span>

                <div className="size-1 bg-gray-600 rounded-full dark:bg-white" />

                <span className="text-blackDark-10 dark:text-white text-xs font-medium tracking-[-0.24px]">
                  {time}
                </span>
              </div>

              <p className="pl-6 text-sm dark:text-white text-blackDark-10 font-normal leading-6 tracking-[-0.28px]">
                {text}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default NotesTranscript;
