import React from 'react';

const SendIconInput = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1434_24427)">
        <path
          d="M31.5005 15.6807C31.9912 14.3234 30.6759 13.0081 29.3186 13.4999L12.7297 19.4994C11.3679 19.9923 11.2032 21.8505 12.456 22.5763L17.7513 25.642L22.4797 20.9135C22.694 20.7066 22.9809 20.5921 23.2787 20.5947C23.5765 20.5973 23.8614 20.7167 24.072 20.9273C24.2826 21.1379 24.402 21.4228 24.4046 21.7206C24.4072 22.0184 24.2927 22.3053 24.0858 22.5196L19.3573 27.248L22.4241 32.5433C23.1487 33.7961 25.007 33.6303 25.4999 32.2696L31.5005 15.6807Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1434_24427">
          <rect width="45" height="45" rx="11" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendIconInput;
