import React from "react";
import { Bar } from "react-chartjs-2";
import { motion } from "framer-motion";
import { FaMapMarkedAlt } from "react-icons/fa";

const geographicDistributionData = {
  labels: ["West", "Midwest", "Northeast", "Southeast", "Southwest"],
  datasets: [
    {
      label: "User Distribution",
      data: [4500, 3200, 5100, 3800, 2900],
      backgroundColor: [
        "rgba(54, 162, 235, 0.8)",
        "rgba(255, 206, 86, 0.8)",
        "rgba(75, 192, 192, 0.8)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(255, 159, 64, 0.8)",
      ],
      borderColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      titleFont: {
        family: "Poppins",
        size: 14,
      },
      bodyFont: {
        family: "Poppins",
        size: 12,
      },
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label +=
              new Intl.NumberFormat("en-US").format(context.parsed.y) +
              " users";
          }
          return label;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: "Poppins",
          size: 12,
        },
      },
    },
    y: {
      grid: {
        color: "rgba(0, 0, 0, 0.1)",
      },
      ticks: {
        font: {
          family: "Poppins",
          size: 12,
        },
        callback: function (value) {
          return new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "short",
          }).format(value);
        },
      },
    },
  },
  animation: {
    duration: 2000,
    easing: "easeOutQuart",
  },
};

const GeographicDistributionCard = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
    >
      <div className="flex items-center mb-4">
        <div className="mr-3 text-blue-500 dark:text-blue-400 text-2xl">
          <FaMapMarkedAlt />
        </div>
        <h2 className="text-xl font-poppins font-semibold text-gray-800 dark:text-white">
          Geographic Distribution
        </h2>
      </div>
      <div className="h-64 relative">
        <Bar data={geographicDistributionData} options={chartOptions} />
      </div>
      <div className="mt-4 grid grid-cols-5 gap-2">
        {geographicDistributionData.labels.map((region, index) => (
          <motion.div
            key={region}
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <div className="text-sm font-poppins font-medium text-gray-600 dark:text-gray-400">
              {region}
            </div>
            <div className="text-lg  font-poppins font-bold text-gray-800 dark:text-white">
              {new Intl.NumberFormat("en-US", {
                notation: "compact",
                compactDisplay: "short",
              }).format(geographicDistributionData.datasets[0].data[index])}
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default GeographicDistributionCard;
