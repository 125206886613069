import React, { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";

const Takecamera = ({ isOpen, setIsOpen, onSaveFile }) => {
  const webcamRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  // Configure the webcam's video constraints
  // const videoConstraints = {
  //   width: 1280,
  //   height: 720,
  //   facingMode: "user", // Use "environment" for the back camera on mobile devices
  // };

  const videoConstraints = {
    width: isMobileDevice ? 640 : 1280,
    height: isMobileDevice ? 480 : 720,
    facingMode: "user", // Back camera for mobile, front for others
  };

  console.log("isMobileDevice", isMobileDevice);
  console.log("videoConstraints", videoConstraints);
  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "captured_image.jpg", {
            type: "image/jpeg",
          });

          console.log(file);
          setImageFile(file);
        });
    }
  }, [webcamRef]);

  useEffect(() => {
    if (isOpen) {
      // Prevent background scroll
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      // Cleanup overflow on unmount
      document.body.style.overflow = "";
    };
  }, [isOpen]);
  return (
    <>
      {isOpen && (
        <div
          className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/5 backdrop-blur-sm"
          aria-modal="true"
          role="dialog"
        >
          <div className="bg-white p-2 md:p-4 zoomIn relative w-full max-w-[620px] rounded-md shadow-primaryBox">
            <div className="flex flex-col gap-4 h-full max-h-[calc(100dvh-30px)] ">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                width="100%"
                height="80%"
              />

              <div className="flex flex-row gap-2 sm:gap-4 items-center w-full justify-center">
                <div>
                  <button
                    onClick={capturePhoto}
                    type="button"
                    className="w-auto font-medium px-2 sm:px-4 ml-auto text-white bg-blue-20 border border-blue-20 hover:bg-blue-45 transition-colors duration-300 rounded-lg py-2"
                  >
                    Capture
                  </button>
                </div>
                {imageFile && (
                  <div>
                    <button
                      onClick={() => {
                        onSaveFile(imageFile);
                        setIsOpen(false);
                        setImageFile(null);
                      }}
                      type="button"
                      className="w-auto font-medium px-2 sm:px-4 ml-auto text-white border border-blue-20 bg-blue-20 hover:bg-blue-45 transition-colors duration-300 rounded-lg py-2"
                    >
                      Save
                    </button>
                  </div>
                )}
                <div>
                  <button
                    onClick={() => {
                      setIsOpen(false);
                      setImageFile(null);
                    }}
                    type="button"
                    className="w-auto font-medium px-2 sm:px-4 ml-auto  bg-transparent border border-gray-25 text-black transition-colors duration-300 rounded-lg py-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              {/* {imageFile && (
                <div>
                  <p>File Name: {imageFile.name}</p>
                  <img
                    src={URL.createObjectURL(imageFile)}
                    alt="Captured"
                    style={{ width: "100%", height: "auto", marginTop: "10px" }}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Takecamera;
