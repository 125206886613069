import React from "react";
import { Send } from "react-feather";

const MassageSend = ({
  inputText,
  setInputText,
  isConversationStarted,
  handleSend,
  isDisable,
}) => {
  return (
    <div className="bg-lightGray-100 dark:bg-gray-900 border-2 flex flex-col gap-4 dark:border-gray-700 border-lightGray-10 px-6 pt-6 pb-4 rounded-2xl">
      <textarea
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder={
          isConversationStarted
            ? "Ask a follow-up question..."
            : `I'm here to help, ask anything...`
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
          }
        }}
        disabled={isDisable}
        className="resize-none h-full bg-transparent helpdesk-custom-scrollbar text-base placeholder:text-[#787878] text-black  dark:text-white outline-none"
      />

      <div className="flex items-center justify-end">
        <button
          disabled={isDisable}
          onClick={handleSend}
          className=" text-black dark:text-white"
        >
          <Send size={24} className="animate-pulse duration-200" />
        </button>
      </div>
    </div>
  );
};

export default MassageSend;
