import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9987 9.9974C10.457 9.9974 10.8495 9.83434 11.1762 9.50823C11.5029 9.18212 11.6659 8.78962 11.6654 8.33073C11.6648 7.87184 11.5018 7.47962 11.1762 7.15406C10.8506 6.82851 10.4581 6.66517 9.9987 6.66406C9.53925 6.66295 9.14703 6.82628 8.82203 7.15406C8.49703 7.48184 8.3337 7.87406 8.33203 8.33073C8.33036 8.7874 8.4937 9.1799 8.82203 9.50823C9.15036 9.83656 9.54259 9.99962 9.9987 9.9974ZM9.9987 18.3307C7.76259 16.428 6.09259 14.6607 4.9887 13.0291C3.88481 11.3974 3.33259 9.88684 3.33203 8.4974C3.33203 6.41406 4.00231 4.75434 5.34286 3.51823C6.68342 2.28212 8.23536 1.66406 9.9987 1.66406C11.762 1.66406 13.3143 2.28212 14.6554 3.51823C15.9965 4.75434 16.6665 6.41406 16.6654 8.4974C16.6654 9.88628 16.1134 11.3968 15.0095 13.0291C13.9056 14.6613 12.2354 16.4285 9.9987 18.3307Z"
        fill="#252525"
      />
    </svg>
  );
};

export default LocationIcon;
