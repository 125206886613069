import React from 'react';

const UpDownArrow = () => {
  return (
    <svg
      width="8"
      height="17"
      viewBox="0 0 8 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 5.5L4 3.5L2 5.5"
        stroke="currentcolor"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11.5L4 13.5L6 11.5"
        stroke="currentcolor"
        strokeWidth="0.666667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpDownArrow;
