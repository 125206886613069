import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import ChartDetailsFillter from './ChartDetailsFillter';
import DetailsLineChart from './DetailsLineChart';
import DetailsSubCategory from './DetailsSubCategory';
import DetailsBarChart from './DetailsBarChart';

const ChartDetails = ({
  setActiveTab,
  chartType,
  chartData,
  detailChartColor,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <button
          onClick={() => setActiveTab('HOME')}
          className="flex items-center dark:text-white text-blackDark-10 text-sm gap-1 font-medium font-poppins"
        >
          <IoIosArrowBack size={18} />
          Back
        </button>

        <h2 className="font-medium dark:text-white text-2xl font-poppins tracking-[-2%] text-black">
          {chartType}
        </h2>

        <ChartDetailsFillter />
      </div>

      {chartType === 'Conversation volume' && (
        <DetailsBarChart
          value={
            <>
              <span className="text-[#2D2D2D] font-semibold dark:text-white">
                379%
              </span>
            </>
          }
          date={'Mar 13, 2023 - Mar 13, 2024 '}
          change={
            <>
              <span className="text-[#159722] font-semibold">+1.1%</span>{' '}
              <span className="text-[#2D2D2D] dark:text-white font-normal">
                since last month vs. prior period (Mar 13, 2022 - Mar 13, 2023)
              </span>
            </>
          }
          isProblem={true}
          chartData={chartData}
          color={detailChartColor}
          hasHeartIcon={true}
          insight={
            <>
              The quarter to date{' '}
              <strong>
                <span className="text-[#2D2D2D] dark:text-white font-semibold">
                  Branch Revenue
                </span>
              </strong>{' '}
              had an unusual increase of{' '}
              <strong>
                <span className="text-[#238B5B] font-semibold">+1.2%</span>
              </strong>{' '}
              over the equivalent quarter to date value a year ago, mainly
              attributed to{' '}
              <strong>
                <span className="text-[#2D2D2D] dark:text-white font-semibold">
                  Home Appliance
                </span>
              </strong>
              .
            </>
          }
          insightIcon={true}
        />
      )}

      {chartType !== 'Conversation volume' && (
        <DetailsLineChart
          value={
            <>
              <span className="text-[#2D2D2D] font-semibold dark:text-white">
                379%
              </span>
            </>
          }
          date={'Mar 13, 2023 - Mar 13, 2024 '}
          change={
            <>
              <span className="text-[#159722] font-semibold">+1.1%</span>{' '}
              <span className="text-[#2D2D2D] font-normal dark:text-white">
                since last month vs. prior period (Mar 13, 2022 - Mar 13, 2023)
              </span>
            </>
          }
          isProblem={true}
          chartData={chartData}
          color={detailChartColor}
          hasHeartIcon={true}
          insight={
            <>
              The quarter to date{' '}
              <strong>
                <span className="text-[#2D2D2D] dark:text-white font-semibold">
                  {chartType}
                </span>
              </strong>{' '}
              had an unusual increase of{' '}
              <strong>
                <span className="text-[#238B5B] font-semibold">+1.2%</span>
              </strong>{' '}
              over the equivalent quarter to date value a year ago, mainly
              attributed to{' '}
              <strong>
                <span className="text-[#2D2D2D] dark:text-white font-semibold">
                  Home Appliance
                </span>
              </strong>
              .
            </>
          }
          insightIcon={true}
        />
      )}

      <DetailsSubCategory />
    </div>
  );
};

export default ChartDetails;
