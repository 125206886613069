import React, { useState } from 'react';
import { IoChevronDownSharp, IoClose } from 'react-icons/io5';
import { RiFileCopy2Line } from 'react-icons/ri';
import ReloadIcon from '../../assets/icons/ReloadIcon';
import { MdInsights, MdOutlineSaveAlt } from 'react-icons/md';
import MaskingStatistics from './MaskingStatistics';

const ScribingMaskedDataTab = () => {
  const [showInsights, setShowInsights] = useState(false);

  return (
    <>
      <div className="relative border overflow-y-hidden flex items-center border-gray-10 py-6 rounded-xl">
        <div className="text-[#737373] px-6 pr-14 min-h-[300px] max-h-[548px] overflow-y-auto helpdesk-custom-scrollbar w-full text-base font-normal font-poppins tracking-[-0.08px]">
          Your masked data will be generated here.
        </div>

        <div className="h-full absolute py-6 right-6 flex justify-between flex-col">
          <button>
            <IoClose size={25} className="text-[#737373]" />
          </button>
          <button>
            <RiFileCopy2Line size={25} className="text-[#737373]" />
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex items-center w-full justify-between">
          <button
            onClick={() => setShowInsights(!showInsights)}
            className="flex items-center py-2 px-3 border rounded-lg border-blue-20 gap-1.5 text-base leading-6 font-medium text-blue-20"
          >
            <MdInsights size={20} />
            Data Insights
            <IoChevronDownSharp
              size={12}
              className={`${showInsights ? 'rotate-180' : ''} duration-200`}
            />
          </button>

          <div className="flex items-center gap-2">
            <button className="flex gap-2 py-2 px-4  text-white bg-blue-20 rounded-lg font-medium">
              <ReloadIcon />
              Reload
            </button>

            <button className="flex gap-2 py-2 px-4  text-white bg-blue-20 rounded-lg font-medium">
              <MdOutlineSaveAlt size={24} />
            </button>
          </div>
        </div>

        {showInsights && <MaskingStatistics />}
      </div>
    </>
  );
};

export default ScribingMaskedDataTab;
