import React from 'react';

const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const PricingCard = ({ plan, price, features }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg">
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{plan}</h3>
    <p className="text-gray-600 text-2xl font-bold mb-4">{price}</p>
    <ul className="mb-4">
      {features.map((feature, index) => (
        <li key={index} className="text-gray-600">
          {feature}
        </li>
      ))}
    </ul>
    <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
      Choose Plan
    </button>
  </div>
);

const BillingAI = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <section className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800">
          Welcome to BillingAI
        </h1>
        <p className="text-lg text-gray-600">
          Manage your billing and subscriptions with ease.
        </p>
      </section>

      <section className="p-6 bg-gray-50 mb-6 rounded-lg shadow-shadow-div">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">
          Key Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <FeatureCard
            title="Automated Billing"
            description="Efficiently manage and automate your billing processes."
          />
          <FeatureCard
            title="Detailed Analytics"
            description="Gain insights with comprehensive billing analytics."
          />
          <FeatureCard
            title="Flexible Integration"
            description="Seamlessly integrate with your existing systems."
          />
        </div>
      </section>

      <section className="p-6 bg-gray-50 mb-6 rounded-lg shadow-shadow-div">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">
          Pricing Plans
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <PricingCard
            plan="Basic"
            price="$19/month"
            features={['Feature 1', 'Feature 2']}
          />
          <PricingCard
            plan="Pro"
            price="$49/month"
            features={['Feature 1', 'Feature 2', 'Feature 3']}
          />
          <PricingCard
            plan="Enterprise"
            price="$99/month"
            features={['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4']}
          />
        </div>
      </section>

      <section className="p-6 bg-gray-50 mb-6 rounded-lg shadow-shadow-div">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">
          Billing Details
        </h2>
        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Current Plan
          </h3>
          <p className="text-gray-600">
            You are currently subscribed to the Pro Plan.
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Invoice History
          </h3>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-start">Date</th>
                <th className="py-2 px-4 border-b text-start">Amount</th>
                <th className="py-2 px-4 border-b text-start">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4 border-b">01/09/2024</td>
                <td className="py-2 px-4 border-b">$49</td>
                <td className="py-2 px-4 border-b">Paid</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">01/09/2024</td>
                <td className="py-2 px-4 border-b">$49</td>
                <td className="py-2 px-4 border-b">Paid</td>
              </tr>
              <tr>
                <td className="py-2 px-4 border-b">01/09/2024</td>
                <td className="py-2 px-4 border-b">$49</td>
                <td className="py-2 px-4 border-b">Paid</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-shadow-div">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Payment Methods
          </h3>
          <p className="text-gray-600">Manage your payment methods here.</p>
        </div>
      </section>
    </div>
  );
};

export default BillingAI;
