export const DownArrowIcon = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1528_15847)">
        <path
          d="M1 3.5L4.375 6.875L7.75 3.5"
          stroke="#377FFF"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1528_15847">
          <rect
            width="9"
            height="5"
            fill="white"
            transform="translate(0 2.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
