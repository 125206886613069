import React from "react";

const CloseIcon = ({ height = 20, width = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.684 17.1693C19.8852 17.3706 19.9982 17.6434 19.9982 17.928C19.9982 18.2125 19.8852 18.4854 19.684 18.6866C19.4828 18.8879 19.2099 19.0009 18.9253 19.0009C18.6408 19.0009 18.3679 18.8879 18.1667 18.6866L12.5 13.0182L6.83154 18.6849C6.63034 18.8861 6.35744 18.9991 6.07289 18.9991C5.78834 18.9991 5.51545 18.8861 5.31424 18.6849C5.11304 18.4837 5 18.2108 5 17.9262C5 17.6417 5.11304 17.3688 5.31424 17.1676L10.9827 11.5009L5.31603 5.83244C5.11482 5.63123 5.00179 5.35834 5.00179 5.07379C5.00179 4.78924 5.11482 4.51634 5.31603 4.31514C5.51723 4.11393 5.79013 4.00089 6.07468 4.00089C6.35923 4.00089 6.63212 4.11393 6.83333 4.31514L12.5 9.98359L18.1685 4.31424C18.3697 4.11304 18.6426 4 18.9271 4C19.2117 4 19.4845 4.11304 19.6858 4.31424C19.887 4.51545 20 4.78834 20 5.07289C20 5.35744 19.887 5.63034 19.6858 5.83154L14.0173 11.5009L19.684 17.1693Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
