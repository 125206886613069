import React, { useState } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import SinglePdfSummarizer from './SinglePdfSummarizer';
import PdfViewerFilesSidebar from './PdfViewerFilesSidebar';

const PDFViewer = () => {
  const [isOpenFileList, setIsOpenFileList] = useState(false);
  const [files, setFiles] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUploadData, setPdfUploadData] = useState(null);
  return (
    <div className="flex calc-[w-(100vh-320)] relative lg:max-h-screen lg:h-screen bg-gray-50 dark:bg-gray-900">
      {isOpenFileList && (
        <PdfViewerFilesSidebar
          setIsOpenFileList={setIsOpenFileList}
          files={files}
          setPdfFile={setPdfFile}
          setPdfUploadData={setPdfUploadData}
        />
      )}
      <div className="w-full">
        <div className="flex items-center h-[47px] px-6 justify-between gap-5 pt-4 border-b w-full border-gray-300">
          <h3 className="text-sm mb-4 font-medium dark:text-white font-poppins leading-5 text-black uppercase">
            PDF Summarizer
          </h3>

          <div />
        </div>

        <SinglePdfSummarizer
          setPdfFile={setPdfFile}
          setFiles={setFiles}
          setPdfUploadData={setPdfUploadData}
          pdfUploadData={pdfUploadData}
          files={files}
          pdfFile={pdfFile}
          setIsOpenFileList={setIsOpenFileList}
          isOpenFileList={isOpenFileList}
        />
      </div>
    </div>
  );
};

export default PDFViewer;
