import React from 'react';

const IcdAttributesTable = ({ data }) => {
  return (
    <div className="container overflow-x-auto mx-auto bg-white border border-gray-300 rounded-lg overflow-hidden dark:border-gray-500 dark:bg-gray-900 ">
      <table className="w-full ">
        <thead>
          <tr>
            <th className="py-2 text-start px-4 bg-gray-200 border-b dark:bg-gray-800 dark:border-gray-500">
              No
            </th>
            <th className="py-2 px-4 text-start bg-gray-200  border-b dark:bg-gray-800 dark:border-gray-500">
              Type
            </th>
            <th className="py-2 px-4 text-start bg-gray-200  border-b dark:bg-gray-800 dark:border-gray-500">
              Text
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((attribute, index) => (
            <tr key={index} className="border-b dark:border-gray-500">
              <td className="py-2 text-start px-4">{index + 1}</td>
              <td className="py-2 px-4 text-start">{attribute.Type}</td>
              <td className="py-2 px-4 text-start">{attribute.Text}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IcdAttributesTable;
