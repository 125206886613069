import React, { useState } from 'react';
import { FaSearch, FaInfoCircle } from 'react-icons/fa';

const insights = [
  {
    id: 1,
    title: 'Insight One',
    summary: 'Brief summary of insight one.',
    details: 'Detailed information about insight one.',
  },
  {
    id: 2,
    title: 'Insight Two',
    summary: 'Brief summary of insight two.',
    details: 'Detailed information about insight two.',
  },
  {
    id: 3,
    title: 'Insight Three',
    summary: 'Brief summary of insight three.',
    details: 'Detailed information about insight three.',
  },
];

const AutomaticInsightGeneration = () => {
  const [selectedInsight, setSelectedInsight] = useState(null);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <header className=" text-black mb-4">
        <div className=" mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">Automatic Insight Generation</h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search insights..."
              className="pl-10 pr-4 py-2 rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          </div>
        </div>
      </header>

      <main className="flex-1">
        <div className=" mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <section className="col-span-1 md:col-span-2 lg:col-span-3 bg-white p-6 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-semibold mb-4">
              Welcome to Automatic Insights
            </h2>
            <p className="text-gray-600">
              Discover actionable insights generated by our AI platform based on
              your data. Each insight provides detailed information to help you
              make informed decisions.
            </p>
          </section>

          <section className="col-span-1 md:col-span-2 lg:col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {insights.map((insight) => (
              <div
                key={insight.id}
                className="bg-white p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300"
                onClick={() => setSelectedInsight(insight)}
              >
                <h3 className="text-lg font-semibold mb-2">{insight.title}</h3>
                <p className="text-gray-600">{insight.summary}</p>
                <button className="mt-2 text-blue-600 hover:underline flex items-center">
                  <FaInfoCircle className="mr-1" /> More Details
                </button>
              </div>
            ))}
          </section>

          {selectedInsight && (
            <section className="col-span-1 md:col-span-2 lg:col-span-3 bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-4">
                {selectedInsight.title}
              </h2>
              <p className="text-gray-600">{selectedInsight.details}</p>
              <button
                className="mt-4 text-blue-600 hover:underline"
                onClick={() => setSelectedInsight(null)}
              >
                Close Details
              </button>
            </section>
          )}
        </div>
      </main>
    </div>
  );
};

export default AutomaticInsightGeneration;
