import React, { useState } from 'react';
import ChatContainer from './ChatContainer';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import ChatInput from './ChatInput';
import ChatHistory from './ChatHistory';
import Error from './Error';
import useAuthStore from '../../stores/authStore';
import useChatStore from '../../stores/chatStore';

const ChatBot = () => {
  const { messages, error, isLoading, sendMessage } = useChatStore();

  const { isLoggedIn, logout, toggleLogoutPopup } = useAuthStore();

  // const [showChat, setShowChat] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  // const startChat = () => {
  //   setShowChat(true);
  // };

  return (
    <div className="flex relative h-full w-full dark:bg-gray-900 bg-white">
      {/* History sidebar */}
      <div
        className={`fixed top-[56px] lg:top-0 inset-y-0 left-0 transform ${
          showHistory ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-30 w-full sm:w-80 bg-gray-900 border-r border-gray-700`}
      >
        <ChatHistory toggleHistory={toggleHistory} />
      </div>
      {/* Main chat area */}
      <div className="flex-grow overflow-hidden">
        <ChatContainer>
          <ChatHeader
            toggleHistory={toggleHistory}
            isLoggedIn={isLoggedIn}
            onLogout={logout}
            toggleLogoutPopup={toggleLogoutPopup}
          />
          {error && <Error message={error} />}
          <ChatBody messages={messages} loading={isLoading} />
          <ChatInput onSend={sendMessage} />
        </ChatContainer>
      </div>
    </div>
  );
};

export default ChatBot;
