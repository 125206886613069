import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid } from 'recharts';
import { Heart } from 'react-feather';
import { ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Tick from '../../assets/icons/Tick';
import ChartAlertBox from './ChartAlertBox';
import StartBarIcon from '../../assets/icons/StartBarIcon';
import { Legend, Tooltip } from 'chart.js';

const DetailsBarChart = ({
  date,
  value,
  change,
  isUnusual,
  chartData,
  color,
  insight,
  insightIcon,
  tickIcon,
  hasHeartIcon,
  isProblem,
}) => {
  const alertBoxColor = () => {
    if (color === 'green') {
      return '#D5F7D8';
    } else if (color === 'red') {
      return '#FFD7D7';
    } else if (color === 'blue') {
      return '#EBF2FF';
    }
  };

  const alertBoxTextColor = () => {
    if (color === 'green') {
      return '#159722';
    } else if (color === 'red') {
      return '#F5095C';
    } else if (color === 'blue') {
      return '#2E7CFE';
    }
  };
  const [isFillHeart, setIsFillHeart] = useState(false);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-2 border">
          <p className="label">{`${label}`}</p>
          <p className="text-xs">{`Human Support: ${payload[0]?.value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="bg-white dark:bg-gray-800 dark:border-gray-600 flex flex-col gap-9 p-9 rounded-3xl border-[#E2E0E5] border">
      <div className="flex justify-between items-center">
        <div className="text-start sm:flex-row flex-col flex sm:items-center items-start">
          <h3
            className={`font-bold text-[2rem] pr-4 sm:border-r border-gray-10 ${
              color === 'green'
                ? 'text-[#238B5B]'
                : color === 'red'
                ? 'text-[#F5095C]'
                : 'text-[#5D97FF]'
            }`}
          >
            {value}
          </h3>

          <div className="sm:flex sm:pl-4 hidden text-base font-semibold flex-col gap-1">
            <p>{date}</p>
            <p
              className={`text-base font-semibold ${
                color === 'green'
                  ? 'text-[#238B5B]'
                  : color === 'red'
                  ? 'text-[#F5095C]'
                  : 'text-[#5D97FF]'
              }`}
            >
              {change}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-1">
          {hasHeartIcon && (
            <button onClick={() => setIsFillHeart(!isFillHeart)}>
              <Heart
                fill={`${isFillHeart ? '#2e7cfe' : 'transparent'}`}
                className="text-blue-40 mr-2"
              />
            </button>
          )}

          {tickIcon && <Tick className="mr-2" />}

          <ChartAlertBox
            text={isProblem ? 'Problem' : isUnusual ? 'Unusual' : 'Normal'}
            backgroundColor={alertBoxColor()}
            textColor={alertBoxTextColor()}
            borderColor={alertBoxTextColor()}
            borderWidth={color === 'blue' ? 1 : 1.5}
          />
        </div>
      </div>

      <div className="sm:hidden sm:pl-4 flex text-base font-semibold flex-col gap-1">
        <p>{date}</p>
        <p
          className={`text-base font-semibold ${
            color === 'green'
              ? 'text-[#238B5B]'
              : color === 'red'
              ? 'text-[#F5095C]'
              : 'text-[#5D97FF]'
          }`}
        >
          {change}
        </p>
      </div>

      <div className="h-48 w-full max-w-[424px] mx-auto">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={180}
            data={chartData}
            margin={{ top: 20 }}
            className={'cursor-pointer'}
          >
            <CartesianGrid vertical={false} stroke="#E2E0E5" />
            <XAxis
              dataKey="month"
              tick={{ fill: '#ccc' }}
              axisLine={false}
              tickLine={false}
              tickMargin={10}
            />
            <YAxis
              type="number"
              tick={{ fill: '#ccc' }}
              tickFormatter={(value) => `${value / 1000}`}
              tickMargin={10}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={<CustomTooltip />} />

            <Legend />

            <Bar
              dataKey="value"
              name="Human Support"
              fill="#377FFF"
              radius={[3, 3, 0, 0]}
              barSize={36}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="flex items-start gap-4">
        {insightIcon && (
          <div>
            <StartBarIcon />
          </div>
        )}
        <div className="text-lg font-light">{insight}</div>
      </div>
    </div>
  );
};

export default DetailsBarChart;
