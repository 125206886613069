// "type": "entities"
import React from 'react';
import { motion } from 'framer-motion';
import Accordion from '../Accordian';

const DATA = [
  {
    SectionName: 'CHIEF_COMPLAINT',
    Summary: [
      {
        SummarizedSegment: 'Back pain recurrence.',
      },
    ],
  },
  {
    SectionName: 'HISTORY_OF_PRESENT_ILLNESS',
    Summary: [
      {
        SummarizedSegment:
          'The patient presents to the clinic today for follow up of chronic low back pain.',
      },
      {
        SummarizedSegment:
          'They report having recurrent episodes of low back pain that first started three years ago following a work injury.',
      },
      {
        SummarizedSegment:
          'The back pain comes and goes, with periods of being fine followed by episodes where any bending or twisting will cause their back to "go out" with pain.',
      },
      {
        SummarizedSegment:
          'They have been seen numerous times for this issue in the past.',
      },
      {
        SummarizedSegment:
          'The patient was initially prescribed physical therapy and medications (traMADol) to help control the back pain.',
      },
      {
        SummarizedSegment:
          'The physical therapy was slow at first but was helping.',
      },
      {
        SummarizedSegment:
          'However, they stopped going to therapy once their back started feeling better because it became inconvenient with their work schedule and costly.',
      },
      {
        SummarizedSegment: 'They were able to return to work without therapy.',
      },
      {
        SummarizedSegment: 'The back pain has since returned.',
      },
      {
        SummarizedSegment:
          'They have tried using a heating pad for short term relief.',
      },
      {
        SummarizedSegment:
          'Past costs of medications and long travel distances to therapy appointments have been a barrier to consistent treatment.',
      },
    ],
  },
  {
    SectionName: 'PAST_MEDICAL_HISTORY',
    Summary: [
      {
        SummarizedSegment: '- Back injury on the job three years ago',
      },
      {
        SummarizedSegment:
          '- Recurring back pain since the injury, with episodes coming back every few months',
      },
      {
        SummarizedSegment:
          '- Managed back pain previously with physical therapy, tramadol, and heating pad',
      },
    ],
  },
  {
    SectionName: 'ASSESSMENT',
    Summary: [
      {
        SummarizedSegment: 'Reoccurring back pain from old injury',
      },
    ],
  },
  {
    SectionName: 'PLAN',
    Summary: [
      {
        SummarizedSegment:
          'Back pain\n  - Use a heating pad for short-term relief of back pain\n',
      },
      {
        SummarizedSegment:
          '  - Discuss alternative therapies like yoga, tai chi classes or meditation that may help with back pain and be closer/less expensive than physical therapy\n',
      },
      {
        SummarizedSegment:
          '  - Consider prescribing a generic medication in the future to control back pain if alternative therapies are not effective\n',
      },
      {
        SummarizedSegment:
          'Costs\n  - Research the discussed alternative therapy options and prescription medication costs\n',
      },
      {
        SummarizedSegment:
          '  - Schedule a follow up phone call next week to discuss the research and see if any of the options would work for the patient\n',
      },
    ],
  },
  {
    SectionName: 'PAST_SOCIAL_HISTORY',
    Summary: [
      {
        SummarizedSegment: 'Works full time.',
      },
      {
        SummarizedSegment:
          'Injured on the job three years ago which causes recurring back pain.',
      },
      {
        SummarizedSegment: 'Back pain limits ability to work at times.',
      },
      {
        SummarizedSegment:
          'Uses heating pad, physical therapy, yoga, tai chi, meditation, and traMADol medication to manage pain.',
      },
      {
        SummarizedSegment: 'Cost of treatment and lost work time are concerns.',
      },
    ],
  },
];

const PhiTab = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className={`p-4 rounded-lg min-h-[200px] max-h-[500px] overflow-y-auto helpdesk-custom-scrollbar dark:bg-gray-700 bg-white`}
    >
      {DATA?.map((item, index) => (
        <Accordion
          key={index}
          title={item?.SectionName}
          content={
            <div className="mt-1">
              {item?.Summary?.map((data, index) => (
                <p key={index}>{data?.SummarizedSegment}</p>
              ))}
            </div>
          }
        />
      ))}
    </motion.div>
  );
};

export default PhiTab;
