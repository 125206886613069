/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from "../../assets/icons/CloseIcon";
import UploadIcon from "../../assets/icons/UploadIcon";

const UploadPhoto = ({ files, setFiles, title, setIsShowVerify, setErr }) => {
  const [error, setError] = useState(null);
  const maxFileSize = 4 * 1024 * 1024;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file.size > maxFileSize) {
        setError("File exceeds the maximum size limit of 25MB");
        return;
      }

      setError(null);
      const fileWithPreview = Object.assign(file, {
        preview: URL.createObjectURL(file),
        isUploaded: false,
        isLoading: false,
      });
      setFiles([fileWithPreview]);
    },
    [files, setFiles]
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    const hasSizeError = rejectedFiles.some((file) =>
      file.errors.some((err) => err.code === "file-too-large")
    );
    if (hasSizeError) {
      setError("File exceeds the maximum size limit of 25MB");
    } else {
      setError("Invalid File Format");
    }
  }, []);

  const removeFile = () => {
    setFiles([]);
    if (setIsShowVerify) setIsShowVerify(false);
    if (setErr) setErr("");
  };

  const renderFileList = () => {
    return (
      files &&
      files.map((file, index) => (
        <div
          key={index}
          className="flex items-start justify-between rounded-lg border-2 border-blue-30 dark:border-gray-700 px-3 py-2"
        >
          <div className="flex flex-col items-start justify-between">
            <span className="font-poppins text-sm font-normal leading-6 text-blackDark-100">
              {file.name}
            </span>
            <span className="font-poppins text-xs leading-5 text-[#9B9B9B]">
              {(file.size / (1024 * 1024)).toFixed(2)} MB
            </span>
          </div>

          <button
            type="button"
            onClick={removeFile}
            aria-label="Remove file"
            className="pt-2"
          >
            <CloseIcon height={15} width={15} />
          </button>
        </div>
      ))
    );
  };

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png", ".webp"],
    },
    maxSize: maxFileSize,
    multiple: false,
  });

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <div
        className="w-full z-50 flex items-center justify-center"
        aria-modal="true"
        role="dialog"
      >
        <div className="relative w-full overflow-y-auto shadow-primaryBox">
          <div className="flex w-full flex-col gap-1   bg-white dark:bg-gray-800">
            <h2 className="font-poppins dark:text-white text-sm font-medium text-black">
              {title}
            </h2>

            {files.length > 0 && (
              <div>
                <div className="table-scrollbar flex max-h-[130px] flex-col gap-2 overflow-y-auto px-1">
                  {renderFileList()}
                </div>
              </div>
            )}

            {!files.length && (
              <div>
                <div
                  {...getRootProps()}
                  className={`${
                    isDragActive ? "bg-blue-10 dark:bg-gray-900" : ""
                  } flex cursor-pointer items-center justify-center rounded-lg border-2 border-dashed border-blue-500 px-2 py-5 transition-all hover:bg-blue-10 dark:hover:bg-gray-900 md:px-10`}
                >
                  <input {...getInputProps()} aria-label="File upload input" />
                  <div className="flex items-center justify-center gap-4">
                    <UploadIcon />
                    <p className="font-poppins text-base font-semibold leading-6 text-blackDark-100">
                      {files.length > 0
                        ? "Add another file, or "
                        : " Drag and drop files, or"}{" "}
                      <span className="text-blue-500">Browse</span>
                    </p>
                  </div>
                </div>

                {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPhoto;
