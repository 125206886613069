import { useRef, useState } from "react";
import ChangeScreenIcon from "../../assets/icons/ChangeScreenIcon";
import NotesDetailsSummary from "./NotesTabs/NotesDetailsSummary";
import NotesTranscript from "./NotesTranscript";
import NotesMaskedDataTab from "./NotesTabs/NotesMaskedDataTab";
import NotesICDCodeBreakdownTab from "./NotesTabs/NotesICDCodeBreakdownTab";
import { MdSaveAlt } from "react-icons/md";
import NormalScreenIcon from "../../assets/icons/NormalScreenIcon";

const TAB_DATA = [
  { id: 1, Label: "EHR Summary", value: "EHR_SUMMARY" },
  { id: 2, Label: "Masked Data", value: "MASKED_DATA" },
  { id: 2, Label: "ICD Code Breakdown", value: "ICD_CODE_BREAKDOWN" },
];

const NotesDetails = ({ activeIndex }) => {
  const [selectedTab, setSelectedTab] = useState("EHR_SUMMARY");
  const [fullscreen, setFullscreen] = useState({
    isFullscreen: false,
    screenLabel: null,
  });
  const elementRef = useRef(null);

  return (
    <div
      ref={elementRef}
      className={`p-9 flex gap-6 lg:flex-row flex-col lg:overflow-y-visible overflow-y-auto justify-between h-full`}
    >
      {fullscreen?.isFullscreen && (
        <div
          className={`${
            fullscreen?.screenLabel === "Transcript"
              ? "opacity-0"
              : "opacity-100"
          } max-w-[240px] w-full  items-center justify-center lg:flex hidden`}
        >
          <button
            disabled={fullscreen?.screenLabel === "Transcript"}
            onClick={() =>
              setFullscreen({
                isFullscreen: true,
                screenLabel: "Transcript",
              })
            }
            className={`dark:bg-slate-700 flex gap-2 items-center text-sm font-medium py-2 px-3 bg-[#F2F2F2] rounded-lg `}
          >
            <MdSaveAlt size={20} className="rotate-90" /> Notes
          </button>
        </div>
      )}

      {fullscreen?.screenLabel !== "Notes" && (
        <div className="w-full flex flex-col gap-9 ">
          <div className="flex items-center justify-between ">
            <div className="flex gap-4 lg:gap-9 items-center">
              {TAB_DATA?.map(({ Label, value }) => (
                <button
                  key={Label}
                  onClick={() => setSelectedTab(value)}
                  className={`${
                    selectedTab === value
                      ? "border-blue-20 text-blue-20"
                      : "border-transparent text-[#989898]"
                  } text-base font-medium tracking-[-0.32px] pb-2 border-b-2`}
                >
                  {Label}
                </button>
              ))}
            </div>

            <button
              onClick={() => {
                if (fullscreen?.isFullscreen) {
                  setFullscreen({
                    isFullscreen: false,
                    screenLabel: null,
                  });
                } else {
                  setFullscreen({
                    isFullscreen: true,
                    screenLabel: "Transcript",
                  });
                }
              }}
            >
              {fullscreen?.isFullscreen ? (
                <NormalScreenIcon />
              ) : (
                <ChangeScreenIcon />
              )}
            </button>
          </div>

          {selectedTab === "EHR_SUMMARY" && (
            <div className="mb-9 overflow-y-auto helpdesk-custom-scrollbar">
              <NotesDetailsSummary activeIndex={activeIndex} />
            </div>
          )}

          {selectedTab === "MASKED_DATA" && (
            <div className="mb-9 overflow-y-auto helpdesk-custom-scrollbar">
              <NotesMaskedDataTab activeIndex={activeIndex} />
            </div>
          )}

          {selectedTab === "ICD_CODE_BREAKDOWN" && (
            <div className="mb-9 overflow-y-auto helpdesk-custom-scrollbar">
              <NotesICDCodeBreakdownTab activeIndex={activeIndex} />
            </div>
          )}
        </div>
      )}

      {fullscreen?.screenLabel !== "Transcript" && (
        <div
          className={`pb-9 w-full ${
            fullscreen?.screenLabel === "Notes" ? "w-full" : "lg:max-w-[376px]"
          }  h-full`}
        >
          <NotesTranscript
            activeIndex={activeIndex}
            setFullscreen={setFullscreen}
            fullscreen={fullscreen}
          />
        </div>
      )}

      {fullscreen?.isFullscreen && (
        <div
          className={`${
            fullscreen?.screenLabel === "Notes" ? "opacity-0" : "opacity-100"
          } max-w-[240px] w-full hidden lg:flex items-center justify-center`}
        >
          <button
            onClick={() =>
              setFullscreen({
                isFullscreen: true,
                screenLabel: "Notes",
              })
            }
            disabled={fullscreen?.screenLabel === "Notes"}
            className="dark:bg-slate-700 flex gap-2 items-center text-sm font-medium py-2 px-3 bg-[#F2F2F2] rounded-lg"
          >
            Transcript <MdSaveAlt size={20} className="-rotate-90" />
          </button>
        </div>
      )}
    </div>
  );
};

export default NotesDetails;
