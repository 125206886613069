/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { FaPlay } from 'react-icons/fa';
import { RiStopMiniFill } from 'react-icons/ri';
import { GrPowerReset } from 'react-icons/gr';

const LiveTranscript = () => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (!listening && !isInitialLoad && browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [listening, browserSupportsSpeechRecognition]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser does not support speech recognition.</span>;
  }

  return (
    <div className="flex flex-col items-center p-6 h-screen  bg-[#FCFCFC]  dark:bg-gray-900">
      <div className="p-6 rounded-lg gap-5 w-full h-full flex flex-col justify-between">
        <h1 className="text-2xl font-semibold text-center text-gray-800 mb-2 dark:text-white ">
          Live Audio Transcription
        </h1>

        <div className="p-6 bg-transparent border-2 helpdesk-custom-scrollbar border-blue-30 dark:bg-gray-800 bg-blue-5 dark:border-gray-600 rounded-lg overflow-y-auto h-full">
          <p className="text-gray-700 font-medium dark:text-gray-200">
            {transcript || 'Your transcript will appear here...'}
          </p>
        </div>

        <div className=" flex justify-center">
          {!listening ? (
            <button
              onClick={() => {
                SpeechRecognition.startListening({ continuous: true });
                setIsInitialLoad(false);
              }}
              className="px-4 py-2 flex items-center gap-1.5 duration-300 bg-[#007c2e] font-medium text-white rounded hover:bg-[#268f4e] mr-4"
              disabled={listening}
            >
              <FaPlay />
              Start
            </button>
          ) : (
            <button
              onClick={SpeechRecognition.stopListening}
              className="px-4 py-2 flex items-center gap-1.5 duration-300 font-medium bg-red-500 text-white rounded hover:bg-red-600 mr-4"
            >
              <RiStopMiniFill size={25} />
              Stop
            </button>
          )}

          <button
            onClick={resetTranscript}
            className="px-4 py-2 font-medium flex items-center gap-1.5 duration-300 bg-blue-20 text-white rounded hover:bg-blue-45"
          >
            <GrPowerReset size={20} />
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default LiveTranscript;
