import React from "react";
import ChapterIcon from "../../../assets/icons/ChapterIcon";
// import ShowDetailsIcon from '../../../assets/icons/ShowDetailsIcon';

// const chapters = [
//   'AI',
//   'Transcription',
//   ' Meeting Summary',
//   'Integration',
//   'Analytics',
//   'Collaboration',
// ];

const NOTES_DATA = [
  {
    id: 1,
    name: "Back Pain Check-Up",
    authorData: ["John Jones", "Aug 08 2024, 3:22 AM", "English (Global)"],
    overview: `Mr. Jones visits for his recurring back pain, which originated from a workplace injury three years ago. While Mr. Jones previously underwent physical therapy and took tramadol, he discontinued these treatments due to cost concerns, insurance limitations, and scheduling difficulties. Dr. Smith suggests more affordable alternatives, including using a heating pad for immediate relief and exploring yoga or tai chi classes as long-term solutions instead of medication. The doctor acknowledges the patient's financial constraints and schedules a follow-up phone call for the following Tuesday to discuss researched treatment options, while also recommending that Mr. Jones review his insurance options during open season.`,
    notesData: [
      {
        id: 1,
        title:
          "Patient discontinued previously prescribed physical therapy due to:",
        item: [
          "Work schedule conflicts, ",
          "Financial constraints (insurance coverage limitations)",
          "Location/accessibility issues",
          "Parking costs",
        ],
      },
      {
        id: 2,
        title: "Treatment history",
        item: [
          "Previous treatment included physical therapy and tramadol",
          "Patient reported good response to tramadol, but cost was a concern",
        ],
      },
      {
        id: 3,
        title: "Current treatment plan",
        item: ["Recommended heat therapy for immediate symptom management"],
      },
      {
        id: 4,
        title: "📝  Discussed alternative, more cost-effective therapies",
        item: [
          "Yoga",
          "Tai Chi",
          "Meditation",
          "Avoiding prescription medications for now",
          "Discussed possibility of generic medications if needed in future",
        ],
      },
      {
        id: 5,
        title: "📝  Financial considerations",
        item: [
          "Acknowledged patient's insurance cost concerns",
          "Advised reviewing insurance options during open enrollment",
          "Will research cost-effective treatment alternatives",
        ],
      },
      {
        id: 6,
        title: "📝Follow-up plan",
        item: [
          "Office team to research discussed treatment options",
          "Scheduled telephone consultation for next Tuesday to review findings and establish treatment plan",
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Headaches 9/23",
    authorData: ["Pat Bellamy", "Aug 08 2024, 3:22 AM", "English (Global)"],
    overview: `Ms. Bellamy visits with complaints of a severe headache that started three days ago. The headache is described as a constant 10/10 pain affecting her entire head, made worse by movement and light, with associated neck stiffness and nausea. Pat is particularly concerned because of a neighbor who had a brain tumor, and she's also worried about insurance coverage since she recently changed insurance companies. Her medical history includes controlled high blood pressure, and she smokes half a pack of cigarettes daily. She recently attended a family reunion in North Carolina where a sick child was present. The doctor takes a thorough history, including family history noting her mother's history of migraines, and plans to conduct a physical exam and arrange insurance verification while addressing her concerns.`,
    notesData: [
      {
        id: 1,
        title:
          "Patient discontinued previously prescribed physical therapy due to:",
        item: [
          "Work schedule conflicts, ",
          "Financial constraints (insurance coverage limitations)",
          "Location/accessibility issues",
          "Parking costs",
        ],
      },
      {
        id: 2,
        title: "Treatment history",
        item: [
          "Previous treatment included physical therapy and tramadol",
          "Patient reported good response to tramadol, but cost was a concern",
        ],
      },
      {
        id: 3,
        title: "Current treatment plan",
        item: ["Recommended heat therapy for immediate symptom management"],
      },
      {
        id: 4,
        title: "📝  Discussed alternative, more cost-effective therapies",
        item: [
          "Yoga",
          "Tai Chi",
          "Meditation",
          "Avoiding prescription medications for now",
          "Discussed possibility of generic medications if needed in future",
        ],
      },
      {
        id: 5,
        title: "📝  Financial considerations",
        item: [
          "Acknowledged patient's insurance cost concerns",
          "Advised reviewing insurance options during open enrollment",
          "Will research cost-effective treatment alternatives",
        ],
      },
      {
        id: 6,
        title: "📝Follow-up plan",
        item: [
          "Office team to research discussed treatment options",
          "Scheduled telephone consultation for next Tuesday to review findings and establish treatment plan",
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Diarrhea Complaints",
    authorData: ["Alexis Gupta", "Aug 08 2024, 3:22 AM", "English (Global)"],
    overview: `Mrs. Gupta visits with complaints of diarrhea lasting 2-3 weeks, occurring up to 8 times daily including at night, with recent onset of blood in the stool and crampy abdominal pain. The patient is particularly concerned about bowel cancer due to a friend's sister's diagnosis at age 30, and worried about potentially spreading an infection to her students. Her medical history includes an appendectomy in her teens, penicillin allergy, and she currently takes oral contraceptives and drinks alcohol on weekends. The doctor plans to run blood tests and collect stool samples, scheduling a follow-up appointment for the following week, with the possibility of a specialist referral if symptoms persist. Additional health concerns to be addressed include the patient's alcohol consumption (approximately 20 units/week) and recent weight loss noted through looser-fitting clothes.`,
    notesData: [
      {
        id: 1,
        title:
          "Patient discontinued previously prescribed physical therapy due to:",
        item: [
          "Work schedule conflicts, ",
          "Financial constraints (insurance coverage limitations)",
          "Location/accessibility issues",
          "Parking costs",
        ],
      },
      {
        id: 2,
        title: "Treatment history",
        item: [
          "Previous treatment included physical therapy and tramadol",
          "Patient reported good response to tramadol, but cost was a concern",
        ],
      },
      {
        id: 3,
        title: "Current treatment plan",
        item: ["Recommended heat therapy for immediate symptom management"],
      },
      {
        id: 4,
        title: "📝  Discussed alternative, more cost-effective therapies",
        item: [
          "Yoga",
          "Tai Chi",
          "Meditation",
          "Avoiding prescription medications for now",
          "Discussed possibility of generic medications if needed in future",
        ],
      },
      {
        id: 5,
        title: "📝  Financial considerations",
        item: [
          "Acknowledged patient's insurance cost concerns",
          "Advised reviewing insurance options during open enrollment",
          "Will research cost-effective treatment alternatives",
        ],
      },
      {
        id: 6,
        title: "📝Follow-up plan",
        item: [
          "Office team to research discussed treatment options",
          "Scheduled telephone consultation for next Tuesday to review findings and establish treatment plan",
        ],
      },
    ],
  },
];

const NotesDetailsSummary = ({ activeIndex }) => {
  return (
    <div className="p-6 mb-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4 items-start">
        <h4 className="text-2xl font-medium text-blackDark-10 dark:text-white">
          {NOTES_DATA[activeIndex]?.name}
        </h4>

        <div className="flex items-center gap-3 flex-wrap">
          <div className="bg-[#D9D9D9] size-5 rounded-full" />
          {NOTES_DATA[activeIndex]?.authorData?.map((label, index) => (
            <p
              key={index}
              className="text-sm font-normal dark:text-white text-blackDark-10"
            >
              {label}
            </p>
          ))}
        </div>
      </div>

      <div className="flex items-center gap-2 px-2 py-1.5 text-xs text-blackDark-10 dark:text-white tracking-[-0.24px]">
        <ChapterIcon />2 chapters
      </div>

      {/* <div className="flex items-center gap-2 flex-wrap">
        <ShowDetailsIcon />
        {chapters?.map((chapter, index) => (
          <span
            key={index}
            className="py-1 px-1.5 rounded-md bg-[#F2F2F2] dark:bg-gray-700 dark:text-gray-300 text-[#787878] text-xs font-medium"
          >
            {chapter}
          </span>
        ))}
      </div> */}

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold dark:text-white text-blackDark-10 tracking-[-0.36px]">
          Overview
        </h2>
        <p className="text-base text-blackDark-10 dark:text-white tracking-[-0.32px] leading-7">
          {NOTES_DATA[activeIndex]?.overview}
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold dark:text-white text-blackDark-10 tracking-[-0.36px]">
          Key points from the visit:
        </h2>

        {NOTES_DATA[activeIndex]?.notesData?.map(({ id, item, title }) => (
          <div key={id} className="flex flex-col gap-2.5">
            <h3 className="text-base font-medium text-blackDark-10 dark:text-white">
              {title}
            </h3>
            <ul>
              {item?.map((note, index) => (
                <li
                  key={index}
                  className="text-sm list-disc ml-5 text-blackDark-10 dark:text-white leading-7 tracking-[-0.32px]"
                >
                  {note}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-base text-blackDark-10 dark:text-white tracking-[-0.32px] leading-7">
          Overall impression: Patient is motivated to manage his condition but
          requires treatment options that balance clinical efficacy with cost
          and accessibility concerns. Focus is on non-pharmacological
          interventions that can provide sustainable relief while minimizing
          financial burden.
        </p>
      </div>
    </div>
  );
};

export default NotesDetailsSummary;
