import React from 'react';

const SELECT_DATA = [
  {
    id: 1,
    name: 'Section Header',
    color: {
      bg: '#FFD7D7',
      text: '#F5095C',
    },
  },
  {
    id: 2,
    name: 'Age',
    color: {
      bg: '#D5F7D8',
      text: '#238B5B',
    },
  },
  {
    id: 3,
    name: 'Race/Ethnicity',
    color: {
      bg: '#FFDFD3',
      text: '#FF9E7A',
    },
  },
  {
    id: 4,
    name: 'Gender',
    color: {
      bg: '#EBF2FF',
      text: '#377FFF',
    },
  },
  {
    id: 5,
    name: 'Oncological',
    color: {
      bg: '#E6C7AF',
      text: '#71390E',
    },
  },
  {
    id: 6,
    name: 'Psychological Condition',
    color: {
      bg: '#FFFDDB',
      text: '#DBCD00',
    },
  },
  {
    id: 7,
    name: 'Modifier',
    color: {
      bg: '#EDDBFF',
      text: '#7432BB',
    },
  },
  {
    id: 8,
    name: 'Disease Syndrome Disorder',
    color: {
      bg: '#DAFEFF',
      text: '#007D80',
    },
  },
  {
    id: 9,
    name: 'Date',
    color: {
      bg: '#F2F2F2',
      text: '#949494',
    },
  },
  {
    id: 10,
    name: 'Admission Discharge',
    color: {
      bg: '#FFD0DF',
      text: '#8B485D',
    },
  },
  {
    id: 11,
    name: 'Symptom',
    color: {
      bg: '#E1DCFF',
      text: '#240FAB',
    },
  },
  {
    id: 12,
    name: 'Clinical Department',
    color: {
      bg: '#E4F6D2',
      text: '#639F23',
    },
  },
];

const ScribingICDHeader = ({ handleButtonClick }) => {
  return (
    <div className="w-full flex items-center gap-3 flex-wrap m-2.5">
      {SELECT_DATA?.map(({ id, name, color }) => (
        <button
          key={id}
          onClick={() => handleButtonClick(name)}
          className={` border  duration-300 px-3 py-1.5 font-medium rounded-full`}
          style={{
            color: color?.text,
            backgroundColor: color?.bg,
            borderColor: color?.text,
          }}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

export default ScribingICDHeader;
