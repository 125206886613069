import React, { useState } from "react";

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleFileUpload = (e) => {
    const uploadedDocuments = Array.from(e.target.files).map((file) => ({
      title: file.name,
      size: (file.size / 1024).toFixed(2) + " KB",
      uploadDate: new Date().toLocaleDateString(),
      type: file.type,
    }));
    setDocuments([...documents, ...uploadedDocuments]);
  };

  const handleDeleteDocument = (documentTitle) => {
    setDocuments(documents.filter((doc) => doc.title !== documentTitle));
  };

  const filteredDocuments = documents.filter((doc) =>
    doc.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Manage Your Documents
          </h2>
          <p className="text-gray-600 mb-4">
            Upload, search, and manage your documents with ease.
          </p>

          <div className="mb-6">
            <label
              htmlFor="documentUpload"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Upload Documents:
            </label>
            <input
              type="file"
              id="documentUpload"
              multiple
              accept=".pdf,.docx,.txt"
              onChange={handleFileUpload}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Search Section */}
          <div className="mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search documents..."
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Document List Display */}
          <div className="space-y-4">
            {filteredDocuments.map((doc, index) => (
              <div
                key={index}
                className="flex justify-between items-center bg-gray-100 p-4 rounded-lg shadow"
              >
                <div>
                  <p className="text-lg font-semibold text-gray-900">
                    {doc.title}
                  </p>
                  <p className="text-sm text-gray-700">
                    {doc.size} | {doc.uploadDate} | {doc.type}
                  </p>
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleDeleteDocument(doc.title)}
                    className="text-red-500 hover:text-red-600 font-medium"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Empty State */}
          {filteredDocuments.length === 0 && (
            <div className="text-center text-gray-500">
              <p>No documents found. Upload new documents to get started.</p>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Documents;
