import React from "react";
import { Doughnut } from "react-chartjs-2";
import { motion } from "framer-motion";
import { FaChartPie } from "react-icons/fa";

const serviceUsageData = {
  labels: ["Chat", "Email", "Phone", "Social Media"],
  datasets: [
    {
      data: [40, 25, 20, 15],
      backgroundColor: ["#3b82f6", "#8b5cf6", "#ec4899", "#10b981"],
      borderColor: "rgba(255, 255, 255, 0.1)",
      borderWidth: 1,
    },
  ],
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "70%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      titleFont: {
        family: "Poppins",
        size: 14,
        weight: "bold",
      },
      bodyFont: {
        family: "Poppins",
        size: 12,
      },
      padding: 12,
      cornerRadius: 8,
      callbacks: {
        label: function (context) {
          const label = context.label || "";
          const value = context.formattedValue;
          return `${label}: ${value}%`;
        },
      },
    },
  },
  animation: {
    animateRotate: true,
    animateScale: true,
  },
};

const ServiceUsageCard = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
    >
      <div className="flex items-center mb-4">
        <FaChartPie className="text-blue-500 dark:text-blue-400 text-2xl mr-3" />
        <h2 className="text-xl font-poppins font-semibold text-gray-800 dark:text-white">
          Service Usage
        </h2>
      </div>
      <div className="relative h-64 mb-6">
        <Doughnut data={serviceUsageData} options={chartOptions} />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center">
            <p className="text-3xl font-bold text-gray-800 dark:text-white mb-1">
              {serviceUsageData.datasets[0].data.reduce((a, b) => a + b, 0)}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Total Interactions
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {serviceUsageData.labels.map((label, index) => (
          <motion.div
            key={label}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
            className="flex items-center"
          >
            <div
              className="w-3 h-3 rounded-full mr-2"
              style={{
                backgroundColor:
                  serviceUsageData.datasets[0].backgroundColor[index],
              }}
            />
            <div>
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                {label}
              </p>
              <p className="text-base font-bold text-gray-800 dark:text-white">
                {serviceUsageData.datasets[0].data[index]}%
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default ServiceUsageCard;
