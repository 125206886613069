/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const HippaCompliantData = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <header className=" text-black py-6">
        <div className=" mx-auto text-center">
          <h1 className="text-3xl font-bold">
            HIPAA Compliant Data Management
          </h1>
          <p className="mt-2 text-lg">
            Ensuring the highest standards of data security and privacy for our
            AI platform.
          </p>
        </div>
      </header>

      <main className="py-10">
        <div className=" mx-auto px-4">
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Overview</h2>
            <p className="text-base leading-relaxed mb-6">
              HIPAA (Health Insurance Portability and Accountability Act)
              compliance is critical in the handling of sensitive health data.
              Our AI platform adheres to stringent HIPAA regulations to ensure
              that all data is protected, secure, and managed responsibly.
            </p>
            <p className="text-base leading-relaxed mb-6">
              We implement a comprehensive set of security measures including
              encryption, access controls, and regular audits to maintain
              compliance.
            </p>
          </section>

          <section className="bg-white shadow-md rounded-lg p-6 mb-10">
            <h2 className="text-2xl font-semibold mb-4">Key Features</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>
                Data Encryption: All data is encrypted in transit and at rest.
              </li>
              <li>
                Access Controls: Granular permissions and role-based access
                control.
              </li>
              <li>
                Audit Logs: Comprehensive logging and monitoring of all access
                and changes.
              </li>
              <li>
                Compliance Audits: Regular third-party audits to ensure ongoing
                compliance.
              </li>
            </ul>
          </section>

          <section className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4">Compliance Policy</h2>
            <p className="text-base leading-relaxed mb-6">
              Our compliance policy outlines the practices and procedures we
              follow to ensure adherence to HIPAA regulations. We regularly
              update our policies to address new risks and changes in
              regulations.
            </p>
            <p className="text-base leading-relaxed">
              For more information about our compliance practices, please refer
              to our{' '}
              <a href="#" className="text-blue-600 hover:underline">
                full compliance policy document
              </a>
              .
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default HippaCompliantData;
