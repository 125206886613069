export const DownArrow = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59406 6.09625L6.09195 9.59415M6.09195 9.59415L2.59406 6.09204M6.09195 9.59415L6.09616 2.59415"
        stroke="#F5095C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
