import React from 'react';

const GitIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0C13.425 0 0 13.425 0 30C0 43.275 8.5875 54.4875 20.5125 58.4625C22.0125 58.725 22.575 57.825 22.575 57.0375C22.575 56.325 22.5375 53.9625 22.5375 51.45C15 52.8375 13.05 49.6125 12.45 47.925C12.1125 47.0625 10.65 44.4 9.375 43.6875C8.325 43.125 6.825 41.7375 9.3375 41.7C11.7 41.6625 13.3875 43.875 13.95 44.775C16.65 49.3125 20.9625 48.0375 22.6875 47.25C22.95 45.3 23.7375 43.9875 24.6 43.2375C17.925 42.4875 10.95 39.9 10.95 28.425C10.95 25.1625 12.1125 22.4625 14.025 20.3625C13.725 19.6125 12.675 16.5375 14.325 12.4125C14.325 12.4125 16.8375 11.625 22.575 15.4875C24.975 14.8125 27.525 14.475 30.075 14.475C32.625 14.475 35.175 14.8125 37.575 15.4875C43.3125 11.5875 45.825 12.4125 45.825 12.4125C47.475 16.5375 46.425 19.6125 46.125 20.3625C48.0375 22.4625 49.2 25.125 49.2 28.425C49.2 39.9375 42.1875 42.4875 35.5125 43.2375C36.6 44.175 37.5375 45.975 37.5375 48.7875C37.5375 52.8 37.5 56.025 37.5 57.0375C37.5 57.825 38.0625 58.7625 39.5625 58.4625C51.4125 54.4875 60 43.2375 60 30C60 13.425 46.575 0 30 0Z"
        fill="#1B1F23"
      />
    </svg>
  );
};

export default GitIcon;
