import React from 'react';
import SendBigIcon from '../../../assets/icons/SendBigIcon';
import { ArrowDownCircle } from 'react-feather';

const StaredRecording = ({
  isPaused,
  isStoped,
  recordingTime,
  handlesendRecording,
  resumeRecording,
  pauseRecording,
  startRecording,
  stopRecording,
  audioURL,
}) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const bars = new Array(22).fill(null);

  return (
    <div
      className={`flex items-center flex-col gap-6 justify-center rounded-lg border-2 border-dashed border-blue-500 px-2 py-16 transition-all md:px-10`}
    >
      <h3 className="font-poppins text-base font-semibold text-black">
        {isPaused
          ? isStoped
            ? 'Send recording?'
            : 'Recording paused'
          : 'Recording...'}
      </h3>

      <p className="font-poppins text-base font-semibold text-black">
        {formatTime(recordingTime)}
      </p>

      {isPaused ? (
        <button
          onClick={() => {
            if (isStoped) {
              handlesendRecording();
            } else {
              resumeRecording();
            }
          }}
          className="relative group"
        >
          <img
            src={`${
              isStoped
                ? '/images/SendRecordingIcon.png'
                : '/images/PauseRecordingIcon.png'
            }`}
            alt="mic-img"
            className="size-[150px]"
          />

          {isStoped && (
            <div className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:text-blue-20 duration-200">
              <SendBigIcon />
            </div>
          )}
        </button>
      ) : (
        <button
          onClick={() => {
            pauseRecording();
          }}
        >
          <div className="flex items-center bg-blue-10 rounded-full justify-between p-6 size-[150px] space-x-0.5">
            {bars.map((_, index) => (
              <div
                key={index}
                className={`w-3 bg-blue-500 rounded animate-wave animation-delay-${index}`}
                style={{
                  height: `${Math.random() * (80 - 50) + 40}%`,
                  animationDelay: `${index * 0.1}s`,
                }}
              />
            ))}
          </div>
        </button>
      )}

      <div className="max-w-[350px] w-full flex items-center gap-2 justify-between">
        <div className="flex items-center gap-4">
          {isPaused ? (
            <button
              onClick={() => {
                if (isStoped) {
                  startRecording();
                } else {
                  resumeRecording();
                }
              }}
            >
              <img
                src="/images/ResumeIcon.png"
                alt="Resume icon img"
                className="size-9 min-h-9 min-w-9 h-full w-full"
              />
            </button>
          ) : (
            <button onClick={pauseRecording}>
              <img
                src="/images/PauseIcon.png"
                alt="Pause icon img"
                className="size-9 min-h-9 min-w-9 h-full w-full"
              />
            </button>
          )}

          <button onClick={stopRecording}>
            <img
              src="/images/StopIcon.png"
              alt="Stop icon img"
              className="size-9 min-h-9 min-w-9 h-full w-full"
            />
          </button>
        </div>

        <button
          onClick={handlesendRecording}
          className="w-fit disabled:cursor-not-allowed  bg-blue-500 disabled:hover:bg-blue-500  hover:bg-blue-600 text-white font-bold py-2 px-3 rounded-lg transition duration-300 disabled:opacity-50 flex items-center justify-center shadow-md hover:shadow-lg cursor-pointer"
        >
          <ArrowDownCircle className="stroke-white -rotate-90" />
        </button>
      </div>

      {audioURL && (
        <audio
          src={audioURL}
          type="audio/mpeg"
          className="w-full max-w-[375px] mx-auto"
          controls
        >
          <source />
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
};

export default StaredRecording;
