/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Edit } from 'react-feather';
import CrossArrorw from '../../assets/icons/CrossArrorw';

const src =
  'https://s3-alpha-sig.figma.com/img/401e/dca4/bec4a18c369c89344933bfd67ad34204?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Pmp9ZXmNk6s4O-5KwhMbcLunXFXqzVYgqc3RO6zhbWEQY7hA1BPa6E~cEo2HFWWVG6xcL2W2T4mxVreSEnF36337Kk5eGaDb2yVOkbkvdghfpFNYZt7zuzSTDLEjrpMJJ8OS0pf3pFDVuMXxHqwQ3VCD9yiLLLjMolcP5o0Izzu4ymLhvM8HcX4S6l90N27PM-c78nhf855iAVIhKzCDBcdRzmC9E0zbSIyT3q2gSZqZzq~7nopvXTmiNNZlVuLA5pRDcyOxbO6b8SVUgOELPdAFjiG-yituEw4Yq3gbTO5d7ltHbDYGeixM9v3c9Ur9Aug4tyJf90o7FfP3lF0pTQ__';

const PATIENT_DATA = [
  {
    id: '#100001',
    name: 'John Jones',
    role: 'PCP Holder',
    PatientInfoData: [
      { id: 1, label: 'Patient ID', value: '100001' },
      { id: 2, label: 'Name', value: 'John Doe' },
      { id: 3, label: 'Age', value: '43 years old' },
      { id: 4, label: 'Gender', value: 'Male' },
      { id: 5, label: 'DOB', value: '04/30/1968' },
      { id: 6, label: 'Location', value: 'San Jose, CA' },
      { id: 7, label: 'Email', value: 'johndoe@gmail.com' },
      { id: 8, label: 'Patient since', value: '03/04/2001' },
    ],
    patientHistory: `A 45-year-old male with a history of hypertension and high
    cholesterol presented with chest pain radiating to the left arm and
    shortness of breath.`,
    lastVist: `Lab tests showed elevated troponin levels, and an EKG indicated
            possible ischemia. The patient was diagnosed with
            Acute Coronary Syndrome (ACS), likely a non-ST Elevation
            Myocardial Infarction (NSTEMI). He was admitted for further
            observation, started on antiplatelet therapy and high-dose statins,
            and scheduled for an echocardiogram and coronary angiography.
            Lifestyle changes were also recommended.`,
    sessionNotes: [
      {
        id: 1,
        label: 'Yearly Physical',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
      {
        id: 2,
        label: 'Check-Up',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
      {
        id: 3,
        label: 'Lab Results Discussion',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
    ],
  },
  {
    id: '#100002',
    name: 'Pat Bellamy',
    role: 'PCP Holder',
    PatientInfoData: [
      { id: 1, label: 'Patient ID', value: '100002' },
      { id: 2, label: 'Name', value: 'Pat Bellamy' },
      { id: 3, label: 'Age', value: '49 years old' },
      { id: 4, label: 'Gender', value: 'Female' },
      { id: 5, label: 'DOB', value: '04/30/1968' },
      { id: 6, label: 'Location', value: 'San Jose, CA' },
      { id: 7, label: 'Email', value: 'patty323@gmail.com' },
      { id: 8, label: 'Patient since', value: '03/06/1975' },
    ],
    patientHistory: `A 49-year-old male with a history of hypertension and high
    cholesterol presented with chest pain radiating to the left arm and
    shortness of breath.`,
    lastVist: `Lab tests showed elevated troponin levels, and an EKG indicated
            possible ischemia. The patient was diagnosed with
            Acute Coronary Syndrome (ACS), likely a non-ST Elevation
            Myocardial Infarction (NSTEMI). He was admitted for further
            observation, started on antiplatelet therapy and high-dose statins,
            and scheduled for an echocardiogram and coronary angiography.
            Lifestyle changes were also recommended.`,
    sessionNotes: [
      {
        id: 1,
        label: 'Yearly Physical',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
      {
        id: 2,
        label: 'Check-Up',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
      {
        id: 3,
        label: 'Lab Results Discussion',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
    ],
  },
  {
    id: '#100003',
    name: 'Alexis Gupta',
    role: 'PCP Holder',
    PatientInfoData: [
      { id: 1, label: 'Patient ID', value: '100003' },
      { id: 2, label: 'Name', value: 'Alexis Gupta' },
      { id: 3, label: 'Age', value: '30 years old' },
      { id: 4, label: 'Gender', value: 'Female' },
      { id: 5, label: 'DOB', value: '04/30/1999' },
      { id: 6, label: 'Location', value: 'San Jose, CA' },
      { id: 7, label: 'Email', value: 'lexisgupta@gmail.com' },
      { id: 8, label: 'Patient since', value: '03/06/1975' },
    ],
    patientHistory: `A 30-year-old male with a history of hypertension and high
    cholesterol presented with chest pain radiating to the left arm and
    shortness of breath.`,
    lastVist: `Lab tests showed elevated troponin levels, and an EKG indicated
            possible ischemia. The patient was diagnosed with
            Acute Coronary Syndrome (ACS), likely a non-ST Elevation
            Myocardial Infarction (NSTEMI). He was admitted for further
            observation, started on antiplatelet therapy and high-dose statins,
            and scheduled for an echocardiogram and coronary angiography.
            Lifestyle changes were also recommended.`,
    sessionNotes: [
      {
        id: 1,
        label: 'Yearly Physical',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
      {
        id: 2,
        label: 'Check-Up',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
      {
        id: 3,
        label: 'Lab Results Discussion',
        count: '9/23',
        discription:
          'A 45-year-old male with a history of hypertension and high cholesterol presented with chest pain radiating to the left arm and shortness of breath.',
      },
    ],
  },
];

const PatientsProfile = ({ activeIndex }) => {
  return (
    <div className="p-6 flex flex-col gap-6 items-start w-full">
      <div className="w-full grid lg:grid-cols-[345px_auto] items-start gap-6">
        <div className="lg:max-w-[345px] border dark:border-gray-600 border-gray-10 rounded-2xl w-full p-6 py-6 h-full flex items-center justify-center flex-col gap-6">
          <img
            src={src}
            className="min-h-[150px] min-w-[150px] size-[150px] object-cover  object-left-top rounded-full"
          />
          <div className="flex flex-col items-center gap-1">
            <h2 className="text-blackDark-10 dark:text-white font-medium text-2xl tracking-[-0.5%]">
              {PATIENT_DATA[activeIndex]?.name}
            </h2>
            <span className="text-blue-20 font-medium text-base tracking-[-0.5%]">
              {PATIENT_DATA[activeIndex]?.role}
            </span>
          </div>

          <div className="flex flex-col items-center gap-1">
            <h2 className="text-[#787878] dark:text-gray-300 font-medium text-sm tracking-[-0.5%]">
              PATIENT ID
            </h2>
            <span className="text-blackDark-10 dark:text-white font-medium text-base tracking-[-0.5%]">
              {PATIENT_DATA[activeIndex]?.id}
            </span>
          </div>
        </div>

        <div className="border w-full h-full dark:border-gray-600 border-gray-10 rounded-2xl p-6 flex flex-col gap-4">
          <div className="w-full flex items-center justify-between">
            <h2 className="text-xl font-medium tracking-[-2%]">Patient info</h2>
            <button className="outline-none">
              <Edit size={20} />
            </button>
          </div>

          <div className="p-4 grid  sm:grid-cols-2 gap-6">
            {PATIENT_DATA[activeIndex]?.PatientInfoData?.map(
              ({ id, label, value }) => (
                <div key={id} className="flex items-start gap-2 flex-col">
                  <h6 className="text-sm dark:text-gray-300 font-medium text-[#787878] tracking-[-2%]">
                    {label}
                  </h6>
                  <span className="text-base font-medium dark:text-white text-blackDark-10 tracking-[-2%]">
                    {value}
                  </span>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-start w-full">
        <div className="p-6 flex flex-col gap-4">
          <h3 className="text-xl font-medium tracking-[-2%]">
            Summary of Patient History
          </h3>

          <p className="text-blackDark-10 dark:text-white text-base font-normal">
            {PATIENT_DATA[activeIndex]?.patientHistory}
          </p>
        </div>

        <div className="p-6 flex flex-col gap-4">
          <h3 className="text-xl font-medium tracking-[-2%]">
            Summary of Last Vist
          </h3>

          <p className="text-blackDark-10 dark:text-white text-base font-normal">
            {PATIENT_DATA[activeIndex]?.lastVist}
          </p>
        </div>

        <div className="p-6 w-full flex flex-col gap-4">
          <div className="flex items-center justify-between w-full">
            <h3 className="text-xl font-medium tracking-[-2%]">
              Session Notes
            </h3>

            <button className="text-base font-medium text-blackDark-10 dark:text-white flex items-center gap-1">
              See more
              <CrossArrorw />
            </button>
          </div>

          <div className="grid grid-cols-4 gap-2">
            {PATIENT_DATA[activeIndex]?.sessionNotes?.map(
              ({ id, count, discription, label }) => (
                <div
                  key={id}
                  className="p-6 border dark:border-gray-600 border-gray-10 rounded-xl flex flex-col gap-2.5 items-start"
                >
                  <div className="flex justify-start items-start w-full gap-2 flex-col">
                    <h4 className="text-base font-medium dark:text-white tracking-[-2%] text-blackDark-10">
                      {label}
                    </h4>
                    <h4 className="text-sm text-start dark:text-white font-medium tracking-[-2%] text-[#787878]">
                      {count}
                    </h4>
                  </div>
                  <p className="text-sm dark:text-white text-start font-medium tracking-[-2%] text-blackDark-10">
                    {discription}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientsProfile;
