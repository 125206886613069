export const ProptIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5803 9.63281H12.4196C11.5427 9.63281 10.8252 10.3503 10.8252 11.2272V22.3879C10.8252 23.2648 11.5427 23.9823 12.4196 23.9823H15.6084L17.9999 26.3739L20.3915 23.9823H23.5803C24.4572 23.9823 25.1747 23.2648 25.1747 22.3879V11.2272C25.1747 10.3503 24.4572 9.63281 23.5803 9.63281ZM19.4987 18.3063L17.9999 21.5907L16.5012 18.3063L13.2168 16.8076L16.5012 15.3088L17.9999 12.0244L19.4987 15.3088L22.7831 16.8076L19.4987 18.3063Z"
        fill="url(#paint0_linear_1532_14020)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1532_14020"
          x1="10.8252"
          y1="9.63281"
          x2="29.0168"
          y2="17.5883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#377FFF" />
          <stop offset="1" stopColor="#F5095C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
