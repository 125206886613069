import React, { useState } from "react";
import {
  Filter,
  Upload,
  Download,
  Loader,
  AlertCircle,
  Settings,
  Clock,
  ChevronRight,
} from "react-feather";
import { motion, AnimatePresence } from "framer-motion";

const Step = ({ number, title, isActive, isCompleted, onClick }) => (
  <motion.div
    onClick={onClick}
    className={`flex items-center space-x-2 cursor-pointer ${
      isActive
        ? "text-indigo-600 dark:text-indigo-400"
        : "text-gray-500 dark:text-gray-400"
    }`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div
      className={`w-8 h-8 rounded-full flex items-center justify-center ${
        isCompleted
          ? "bg-indigo-600 text-white"
          : isActive
          ? "bg-indigo-100 dark:bg-indigo-900 text-indigo-600 dark:text-indigo-400"
          : "bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400"
      }`}
    >
      {isCompleted ? "✓" : number}
    </div>
    <span className="text-sm font-medium">{title}</span>
  </motion.div>
);

const SmartSearch = () => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setStep(2);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setStep(2);
    }
  };

  const handleExtract = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setError("");
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setExtractedData({
        name: "John Doe",
        email: "john@example.com",
        phone: "123-456-7890",
      });
      setStep(3);
    } catch (err) {
      setError("An error occurred while extracting data. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  const goToStep = (newStep) => {
    if (
      newStep >= 1 &&
      newStep <= 3 &&
      newStep <= Math.max(step, file ? 2 : 1)
    ) {
      setStep(newStep);
    }
  };

  return (
    <div className="max-w-7xl font-poppins mx-auto px-4 py-8 space-y-8">
      <h1 className="text-4xl font-bold mb-8 text-gray-800 dark:text-white">
        Data Extractor
      </h1>
      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-4">
          <Step
            number={1}
            title="Upload File"
            isActive={step === 1}
            isCompleted={step > 1}
            onClick={() => goToStep(1)}
          />
          <ChevronRight className="text-gray-400 dark:text-gray-600" />
          <Step
            number={2}
            title="Review File"
            isActive={step === 2}
            isCompleted={step > 2}
            onClick={() => goToStep(2)}
          />
          <ChevronRight className="text-gray-400 dark:text-gray-600" />
          <Step
            number={3}
            title="Results"
            isActive={step === 3}
            isCompleted={false}
            onClick={() => goToStep(3)}
          />
        </div>
        <div className="flex space-x-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-2 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            <Settings size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="p-2 rounded-full bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            <Clock size={20} />
          </motion.button>
        </div>
      </div>
      <AnimatePresence mode="wait">
        {step === 1 && (
          <motion.div
            key="step1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
          >
            <div
              className="p-6 space-y-6"
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-indigo-300 border-dashed rounded-lg cursor-pointer bg-indigo-50 dark:bg-gray-700 hover:bg-indigo-100 dark:hover:bg-gray-600 dark:border-indigo-600 dark:hover:border-indigo-500 transition-colors"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-10 h-10 mb-3 text-indigo-500 dark:text-indigo-400" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PDF, DOCX, TXT, CSV (MAX. 10MB)
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                    accept=".pdf,.docx,.txt,.csv"
                  />
                </label>
              </div>
            </div>
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            key="step2"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
          >
            <div className="p-6 space-y-6">
              <div className="flex items-center space-x-4 bg-indigo-50 dark:bg-gray-700 p-4 rounded-lg">
                <div className="bg-indigo-100 dark:bg-gray-600 p-2 rounded-full">
                  <Upload className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div>
                  <p className="font-semibold text-gray-800 dark:text-white">
                    {file.name}
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                  </p>
                </div>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleExtract}
                disabled={isProcessing}
                className="w-full bg-gradient-to-r from-indigo-500 to-indigo-600 hover:from-indigo-600 hover:to-indigo-700 text-white font-bold py-3 px-4 rounded-lg transition duration-200 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50"
              >
                {isProcessing ? (
                  <Loader size={20} className="animate-spin mr-2" />
                ) : (
                  <Filter size={20} className="mr-2" />
                )}
                {isProcessing ? "Extracting..." : "Extract Data"}
              </motion.button>
            </div>
          </motion.div>
        )}
        {step === 3 && extractedData && (
          <motion.div
            key="step3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
          >
            <div className="p-6">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">
                Extracted Data:
              </h2>
              <div className="bg-indigo-50 dark:bg-gray-700 p-4 rounded-lg shadow-inner">
                <pre className="text-gray-700 dark:text-gray-300">
                  {JSON.stringify(extractedData, null, 2)}
                </pre>
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="mt-4 flex items-center justify-center px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
              >
                <Download size={20} className="mr-2" />
                Download Extracted Data
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {error && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-200 rounded-lg mt-4 flex items-center"
        >
          <AlertCircle size={20} className="mr-2" />
          {error}
        </motion.div>
      )}
    </div>
  );
};

export default SmartSearch;
