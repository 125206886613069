import React from 'react';
import CheckBox from '../CheckBox';
import TopBottomDropDownIcon from '../../assets/icons/TopBottomDropDownIcon';
import { motion } from 'framer-motion';

const ScribingICDTable = ({ data }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className="overflow-x-auto rounded-lg  bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 shadow-sm"
    >
      <table className="w-full table-fixed divide-y divide-gray-300 dark:divide-gray-600">
        <thead>
          <tr>
            <th className="w-1/5 py-4 pl-6 text-left font-poppins text-sm font-semibold text-gray-150">
              <div className="flex items-center gap-3">
                <CheckBox />
                <div className="flex items-center text-[#A1A1A1]">
                  <span className="mr-2">Chunk</span>
                  <div className="text-black dark:text-white">
                    <TopBottomDropDownIcon />
                  </div>
                </div>
              </div>
            </th>
            <th className="w-[10%]  py-4 text-left font-poppins text-sm font-semibold text-gray-150">
              <div className="flex items-center text-[#A1A1A1]">
                <span className="mr-2">Begin</span>
                <div className="text-black dark:text-white">
                  <TopBottomDropDownIcon />
                </div>
              </div>
            </th>
            <th className="w-[10%]  py-4 text-left font-poppins text-sm font-semibold text-gray-150">
              <div className="flex items-center text-[#A1A1A1]">
                <span className="mr-2">End</span>
                <div className="text-black dark:text-white">
                  <TopBottomDropDownIcon />
                </div>
              </div>
            </th>
            <th className="w-1/5 py-4 text-left font-poppins text-sm font-semibold text-gray-150">
              <div className="flex items-center text-[#A1A1A1]">
                <span className="mr-2">Entity</span>
                <div className="text-black dark:text-white">
                  <TopBottomDropDownIcon />
                </div>
              </div>
            </th>
            <th className="w-[10%] py-4 text-left font-poppins text-sm font-semibold text-gray-150">
              <div className="flex items-center text-[#A1A1A1]">
                <span className="mr-2">Confidence</span>
                <div className="text-black dark:text-white">
                  <TopBottomDropDownIcon />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map(
            ({ id, Chunk, Begin, End, Entity, Confidence }) =>
              Chunk && (
                <tr
                  key={id}
                  className="border-b border-gray-30 dark:border-gray-600 last:border-none"
                >
                  <td className="w-1/5 py-4 pl-6 text-left font-poppins text-sm font-semibold text-blackDark-100">
                    <div className="flex items-center gap-3">
                      <CheckBox />
                      <h3 className="font-poppins text-sm font-medium leading-5 text-blackDark-100">
                        {Chunk}
                      </h3>
                    </div>
                  </td>
                  <td className="w-[10%]  py-4 text-left font-poppins text-sm font-medium text-blackDark-100">
                    <span>{Begin}</span>
                  </td>
                  <td className="w-[10%]  py-4 text-left font-poppins text-sm font-medium text-blackDark-100">
                    <span>{End}</span>
                  </td>
                  <td className="w-1/5 py-4 text-left font-poppins text-sm font-medium text-blackDark-100">
                    <span>{Entity}</span>
                  </td>
                  <td className="w-[10%]  py-4 text-left font-poppins text-sm font-medium text-blackDark-100">
                    <span>{Confidence}</span>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </motion.div>
  );
};

export default ScribingICDTable;
