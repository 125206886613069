const SearchIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87543 1.5C6.85888 1.50009 5.85708 1.74327 4.95363 2.20927C4.05017 2.67527 3.27125 3.35056 2.68186 4.17882C2.09247 5.00707 1.70969 5.96426 1.56547 6.97053C1.42124 7.97681 1.51975 9.00298 1.85277 9.96344C2.18579 10.9239 2.74367 11.7908 3.47986 12.4918C4.21605 13.1928 5.10921 13.7076 6.08482 13.9932C7.06043 14.2788 8.09019 14.327 9.0882 14.1337C10.0862 13.9404 11.0235 13.5112 11.8219 12.882L14.5609 15.621C14.7024 15.7576 14.8918 15.8332 15.0885 15.8315C15.2851 15.8298 15.4732 15.7509 15.6123 15.6119C15.7514 15.4728 15.8302 15.2847 15.8319 15.0881C15.8336 14.8914 15.7581 14.702 15.6214 14.5605L12.8824 11.8215C13.6234 10.8815 14.0848 9.7518 14.2138 8.56179C14.3427 7.37179 14.134 6.16952 13.6116 5.09257C13.0892 4.01562 12.2741 3.10752 11.2597 2.47217C10.2452 1.83683 9.07241 1.49992 7.87543 1.5ZM3.00043 7.875C3.00043 6.58207 3.51405 5.34209 4.42829 4.42785C5.34253 3.51361 6.5825 3 7.87543 3C9.16837 3 10.4083 3.51361 11.3226 4.42785C12.2368 5.34209 12.7504 6.58207 12.7504 7.875C12.7504 9.16793 12.2368 10.4079 11.3226 11.3221C10.4083 12.2364 9.16837 12.75 7.87543 12.75C6.5825 12.75 5.34253 12.2364 4.42829 11.3221C3.51405 10.4079 3.00043 9.16793 3.00043 7.875Z"
        fill="#8B909A"
      />
    </svg>
  );
};

export default SearchIcon;
