import React from 'react';

const HeaderHistoryIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20.5H12.5V20C12.5 17.7457 13.3955 15.5837 14.9896 13.9896C16.5837 12.3955 18.7457 11.5 21 11.5C23.2543 11.5 25.4163 12.3955 27.0104 13.9896C28.6045 15.5837 29.5 17.7457 29.5 20C29.5 22.2543 28.6045 24.4163 27.0104 26.0104C25.4163 27.6045 23.2543 28.5 21 28.5H20.9994C19.8835 28.5014 18.7784 28.2819 17.7478 27.8541C16.8709 27.4901 16.0628 26.9815 15.3565 26.3506L16.0705 25.6366C17.384 26.7942 19.1116 27.5 21 27.5C25.1461 27.5 28.5 24.1461 28.5 20C28.5 15.8539 25.1461 12.5 21 12.5C16.8539 12.5 13.5 15.8539 13.5 20V20.5H14H15.7908L12.9955 23.2884L10.2071 20.5H12ZM21 20.25V20.5345L21.2446 20.6798L24.3145 22.5042L24.1057 22.8551L20.5 20.7153V16.5H21V20.25Z"
        fill="#377FFF"
        stroke="#377FFF"
      />
    </svg>
  );
};

export default HeaderHistoryIcon;
