import React from 'react';

const ConfluenceIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.17554 45.8874C1.555 46.8993 0.858071 48.0736 0.266162 49.0092C0.0114817 49.4396 -0.0641861 49.9527 0.0554221 50.4383C0.17503 50.9238 0.480367 51.3431 0.905805 51.606L13.3168 59.2435C13.5325 59.3766 13.7725 59.4655 14.0228 59.505C14.2732 59.5444 14.5289 59.5336 14.7751 59.4731C15.0213 59.4127 15.2529 59.3039 15.4566 59.153C15.6602 59.0021 15.8318 58.8122 15.9613 58.5943C16.4577 57.7637 17.0974 56.6849 17.7943 55.5297C22.7109 47.4149 27.6562 48.4077 36.5731 52.6657L48.879 58.5179C49.1097 58.6277 49.3601 58.6901 49.6153 58.7014C49.8706 58.7127 50.1255 58.6726 50.365 58.5836C50.6044 58.4946 50.8236 58.3584 51.0095 58.1831C51.1954 58.0078 51.3442 57.7971 51.4471 57.5632L57.3567 44.1975C57.5574 43.7387 57.5698 43.2194 57.3912 42.7515C57.2126 42.2837 56.8574 41.9047 56.402 41.6963C53.8052 40.4743 48.6404 38.0398 43.991 35.7963C27.2648 27.6718 13.0495 28.1969 2.17554 45.8874Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M57.8245 15.5568C58.4451 14.5449 59.142 13.3706 59.7339 12.435C59.9886 12.0046 60.0643 11.4915 59.9447 11.0059C59.8251 10.5204 59.5197 10.1011 59.0943 9.83824L46.6833 2.20072C46.466 2.05437 46.2209 1.9543 45.9633 1.90674C45.7057 1.85917 45.4411 1.86513 45.1859 1.92423C44.9307 1.98333 44.6903 2.09432 44.4799 2.25028C44.2694 2.40625 44.0933 2.60385 43.9624 2.83081C43.466 3.66139 42.8263 4.74019 42.1294 5.89537C37.2128 14.0102 32.2675 13.0174 23.3506 8.75944L11.0829 2.93583C10.8522 2.82603 10.6018 2.76364 10.3466 2.75236C10.0913 2.74108 9.83641 2.78114 9.59694 2.87016C9.35746 2.95919 9.13829 3.09537 8.9524 3.27063C8.76651 3.4459 8.61768 3.65669 8.51474 3.89052L2.6052 17.2562C2.40452 17.715 2.39213 18.2343 2.57071 18.7022C2.74929 19.1701 3.10453 19.5491 3.55989 19.7575C6.15665 20.9795 11.3215 23.414 15.9709 25.6575C32.7353 33.7723 46.9506 33.2282 57.8245 15.5568Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="57.0225"
          y1="63.1864"
          x2="19.4745"
          y2="41.6103"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.18"
            stopColor="#0052CC"
          />
          <stop
            offset="1"
            stopColor="#2684FF"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-15833.9"
          y1="45040.9"
          x2="-14533.9"
          y2="46532.8"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.18"
            stopColor="#0052CC"
          />
          <stop
            offset="1"
            stopColor="#2684FF"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ConfluenceIcon;
