/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { FiEdit } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import Popup from 'reactjs-popup';

const data = [
  {
    image: '/images/JackSmithImg.png',
    name: 'Jack Smith',
    role: 'Product Manager',
    interviewDate: '12 SEP 2024',
  },
  {
    image: '/images/JackSmithImg.png',
    name: 'Jack Smith',
    role: 'Product Manager',
    interviewDate: '12 SEP 2024',
  },
  {
    image: '/images/JackSmithImg.png',
    name: 'Jack Smith',
    role: 'Product Manager',
    interviewDate: '12 SEP 2024',
  },
  {
    image: '/images/JackSmithImg.png',
    name: 'Jack Smith',
    role: 'Product Manager',
    interviewDate: '12 SEP 2024',
  },
  {
    image: '/images/JackSmithImg.png',
    name: 'Jack Smith',
    role: 'Product Manager',
    interviewDate: '12 SEP 2024',
  },
];

const RecentConversationsTable = () => {
  return (
    <div className="w-full min-w-[500px] flex flex-col gap-6">
      <div className="overflow-x-auto  border rounded-2xl overflow-hidden border-[#E2E0E5] dark:border-gray-600">
        <table className="min-w-full">
          <thead>
            <tr className="border-b border-[#E2E0E5] dark:border-gray-600">
              <th className="flex text-start items-center gap-2  px-3 py-6">
                <span className="text-sm font-medium font-poppins text-[#A1A1A1] dark:text-white">
                  Name
                </span>
                <span className="text-black dark:text-white">
                  <UpDownArrow />
                </span>
              </th>
              <th className="text-sm text-start font-medium font-poppins text-[#A1A1A1] px-3 dark:text-white">
                Role
              </th>
              <th className="text-sm text-start font-medium font-poppins text-[#A1A1A1] px-3 dark:text-white">
                Interview Date
              </th>
              <th className="px-3 w-6"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                className="border-b border-[#E2E0E5] dark:border-gray-600 last:border-transparent dark:last:border-transparent"
              >
                <td className="py-[25px] px-3 flex items-center gap-4">
                  <img
                    src={item?.image}
                    alt="image"
                    height={'28px'}
                    width={'28px'}
                    className="rounded-full min-h-7 min-w-7 "
                  />
                  <span className="font-poppins font-medium text-black text-sm dark:text-white">
                    {item.name}
                  </span>
                </td>
                <td className="py-2 px-3 font-poppins font-normal text-[#A1A1A1] text-sm dark:text-white">
                  {item.role}
                </td>
                <td className="py-2 px-3 font-poppins font-normal text-[#A1A1A1] text-sm dark:text-white">
                  {item.interviewDate}
                </td>
                <td className="py-2 relative px-3 font-poppins font-normal text-black text-sm">
                  <div className="relative flex items-center justify-center">
                    <Popup
                      trigger={
                        <button>
                          <HiOutlineDotsHorizontal
                            size={20}
                            className="text-black dark:text-white"
                          />
                        </button>
                      }
                      position="bottom right"
                      closeOnDocumentClick
                    >
                      <div className="bg-white z-30 border border-lightGray-10 flex flex-col shadow-card py-1.5 gap-3">
                        <button className="flex items-center gap-2 hover:bg-lightGray-10 w-full px-2 py-0.5">
                          <FiEdit />
                          <span className="text-blackDark-10 text-sm font-poppins leading-5 font-normal">
                            Edit
                          </span>
                        </button>

                        <button className="flex items-center gap-2 hover:bg-lightGray-10 w-full px-2 py-0.5">
                          <MdDelete />
                          <span className="text-blackDark-10 text-sm font-poppins leading-5 font-normal">
                            Delete
                          </span>
                        </button>
                      </div>
                    </Popup>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentConversationsTable;
