import React from 'react';

const ChapterIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5C8.5 3.02625 10.3884 2.01844 14.5 2C14.5657 1.99976 14.6309 2.01252 14.6916 2.03756C14.7524 2.0626 14.8076 2.09942 14.8541 2.1459C14.9006 2.19238 14.9374 2.24759 14.9624 2.30837C14.9875 2.36914 15.0003 2.43427 15 2.5V11.5C15 11.6326 14.9473 11.7598 14.8536 11.8536C14.7598 11.9473 14.6326 12 14.5 12C10.5 12 8.95469 12.8066 8 14M8 5C7.5 3.02625 5.61157 2.01844 1.5 2C1.43427 1.99976 1.36914 2.01252 1.30837 2.03756C1.24759 2.0626 1.19238 2.09942 1.1459 2.1459C1.09942 2.19238 1.0626 2.24759 1.03756 2.30837C1.01252 2.36914 0.999756 2.43427 1 2.5V11.4397C1 11.7484 1.19125 12 1.5 12C5.5 12 7.05094 12.8125 8 14M8 5V14"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChapterIcon;
