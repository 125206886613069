// src/routes.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import ChatBot from './components/Chat/ChatBot';
import MeetingSummarizer from './components/MeetingSummarizer';
import OpenAIToBedrock from './components/OpenAIToBedrock';
import DataExtractor from './components/DataExtractor';
import EntityResolution from './components/EntityResolution';
import MedicalScribing from './components/MedicalScribing';
import ImageGeneration from './components/ImageGeneration';
import PDFViewer from './components/PdfViewer';

import Dashboard from './components/Dashboard';
import DashboardOld from './components/DashboardOld';
import Inbox from './components/Inbox';
import Reports from './components/Reports';
import AssistantV2 from './components/AssistantV2';
import CSVQuerying from './components/CsvQuerying';
import NL2SQL from './components/NL2SQL';
import Integration from './components/Integration';
import NL2SQLV1 from './components/NL2SQLV1';
import ProductSummarizer from './components/Enterprice/ProductSummarizer';
import GenerateContent from './components/Enterprice/GenerateContent';
import ImageEnhancement from './components/Enterprice/ImageEnhancement';
import ReviewContracts from './components/Enterprice/ReviewContracts';
import EnterpriceSearch from './components/Enterprice/EnterpriceSearch';
import Files from './components/Enterprice/Files';
import Documents from './components/Enterprice/Documents';
import ImagesList from './components/Enterprice/ImagesList';
import SettlementProcessing from './components/Finance/SettlementProcessing';
import LendingCopilot from './components/Finance/LendingCopilot';
import ContractManagement from './components/PublicSector/ContractManagement';
import SmartSearch from './components/PublicSector/SmartSearch';
import TaxProcessing from './components/PublicSector/TaxProcessing';
import ClaimsAI from './components/Healthcare/ClaimsAI';
import PriorAuthorization from './components/Healthcare/PriorAuthorization';
import BillingAI from './components/Healthcare/BillingAI';
import CodingAI from './components/Healthcare/CodingAI';
import MedicalImageSegmentation from './components/Healthcare/MedicalImageSegmentation';
import VisualSearch from './components/Healthcare/VisualSearch';
import ImageClassification from './components/Healthcare/ImageClassification';
import EhrSumarizer from './components/Healthcare/EhrSumarizer';
import MedicalImagingAnalysis from './components/Healthcare/MedicalImagingAnalysis';
import DiffernetialDiagnosis from './components/Healthcare/DiffernetialDiagnosis';
import AutomaticInsightGeneration from './components/Healthcare/AutomaticInsightGeneration';
import AiPowerTreatment from './components/Healthcare/AiPowerTreatment';
import HippaCompliantData from './components/Healthcare/HippaCompliantData';
import AutomationDdiForm from './components/Healthcare/AutomationDdiForm';
import AutomationPayerBehaviour from './components/Healthcare/AutomationPayerBehaviour';
import ClaimDenialAssistant from './components/Healthcare/ClaimDenialAssistant';
import VirtualDrugTrial from './components/Healthcare/VirtualDrugTrial';
import Help from './components/Enterprice/Help';
import AssistantNew from './components/AssistantNew';
import DataMaskingNew from './components/DataMaskingNew/DataMaskingNew';
import StructureExtractionNew from './components/Enterprice/StructureExtractionNew/StructureExtractionNew';
import SummarizerNew from './components/SummarizerNew/SummarizerNew';
import AllReportsNew from './components/AllReportsNew';
import MedicalScribingNew from './components/MedicalScribingNew';
import LiveTranscript from './components/LiveTranscript';
import AllNotes from './components/AllNotes';
import FavoriteNotes from './components/FavoriteNotes/FavoriteNotes';
import AllNotePatients from './components/AllNotePatients';
import LiveTranscription from './components/LiveTranscription';
import NewLiveTranscription from './components/NewLiveTranscription/NewLiveTranscription';
import NewMedicalScribingV1 from './components/NewMedicalScribingV1';
import UploadPhoto from './components/UploadPhoto';

const routes = [
  {
    path: '/login',
    element: <Login />,
    public: true,
  },
  {
    path: '/register',
    element: <Register />,
    public: true,
  },
  {
    path: '/chat',
    element: <ChatBot />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/all-reports',
    element: <AllReportsNew />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin'],
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin'],
  },
  {
    path: '/nl2sql',
    element: <NL2SQL />,
    public: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/nl2sql-v1',
    element: <NL2SQLV1 />,
    public: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/meeting-summarizer',
    element: <MeetingSummarizer />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/openai-to-bedrock',
    element: <OpenAIToBedrock />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/data-extractor',
    element: <DataExtractor />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/medical-scribing',
    element: <NewMedicalScribingV1 />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/medical-scribing-new',
    element: <MedicalScribingNew />,
    public: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/medical-scribing-v1',
    element: <MedicalScribing />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/data-masking',
    element: <DataMaskingNew />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/entity-resolution',
    element: <EntityResolution />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/image-generation',
    element: <ImageGeneration />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/pdf-viewer',
    element: <PDFViewer />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/tax-processing',
    element: <TaxProcessing />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/contract-management',
    element: <ContractManagement />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/smart-search',
    element: <SmartSearch />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/claims-ai',
    element: <ClaimsAI />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/prior-authorization',
    element: <PriorAuthorization />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/csv-querying',
    element: <CSVQuerying />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/live-transcript-v1',
    element: <LiveTranscript />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/live-transcript',
    element: <LiveTranscription />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/dashboard-v2',
    element: <DashboardOld />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin'],
  },
  {
    path: '/billing-ai',
    element: <BillingAI />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/inbox',
    element: <Inbox />,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin'],
    public: true,
  },
  {
    path: '/reports',
    element: <Reports />,
    protected: true,
    roles: ['HR Manager', 'IT Manager', 'Sales Manager', 'Super Admin'],
  },

  {
    path: '/coding-ai',
    element: <CodingAI />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/settlement-processing',
    element: <SettlementProcessing />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/assistant',
    element: <AssistantV2 />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/smartassistant',
    element: <AssistantNew />,
    protected: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '/integration',
    element: <Integration />,
    public: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },

  {
    path: '/lending-copilot',
    element: <LendingCopilot />,
    public: true,
    roles: [
      'Customer',
      'HR Employee',
      'IT Employee',
      'Sales Employee',
      'HR Manager',
      'IT Manager',
      'Sales Manager',
      'Super Admin',
    ],
  },
  {
    path: '*',
    element: <Navigate to="/login" replace />,
    public: true,
  },
  {
    path: '/segmentation',
    element: <MedicalImageSegmentation />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/visualsearch',
    element: <VisualSearch />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/classifcation',
    element: <ImageClassification />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/ehr-summarizer',
    element: <EhrSumarizer />,
    protected: true,
    roles: ['IT Employee', 'IT Manager', 'Super Admin'],
  },
  {
    path: '/healthcare-medical-scribing',
    element: <MedicalScribing />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/medical-imaging-analysis',
    element: <MedicalImagingAnalysis />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/differential-diagnosis',
    element: <DiffernetialDiagnosis />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/automatic-insight-generation',
    element: <AutomaticInsightGeneration />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/ai-power-treatment',
    element: <AiPowerTreatment />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/hippa-compliant-data',
    element: <HippaCompliantData />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/automatic-edi-form-filling',
    element: <AutomationDdiForm />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/automatic-payer-behaviour',
    element: <AutomationPayerBehaviour />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/claim-denial-assistant',
    element: <ClaimDenialAssistant />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/virtual-drug-trials',
    element: <VirtualDrugTrial />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/all-notes',
    element: <AllNotes />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/favorite-notes',
    element: <FavoriteNotes />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/patients',
    element: <AllNotePatients />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/all-notes-livetranscription',
    element: <NewLiveTranscription />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/summarizer',

    element: <SummarizerNew />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/product-recommendation',
    element: <ProductSummarizer />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/generate-content',
    element: <GenerateContent />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/image-enhancement',
    element: <ImageEnhancement />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/review-contracts',
    element: <ReviewContracts />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/enterprise-search',
    element: <EnterpriceSearch />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/structured-extraction',
    element: <StructureExtractionNew />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/face-recognition',
    element: <UploadPhoto />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/files',
    element: <Files />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/documents',
    element: <Documents />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/images',
    element: <ImagesList />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
  {
    path: '/help',
    element: <Help />,
    protected: true,
    roles: ['HR Employee', 'HR Manager', 'Super Admin'],
  },
];

export default routes;
