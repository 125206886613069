import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

const CategoryDropdown = ({
  selectedCategory,
  setSelectedCategory,
  categories,
  openPanel,
  setExpandedManuOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'Enterprise':
        return (
          <img
            src="/images/Enterprise.png"
            alt="images"
            className="min-h-4 min-w-4 size-4"
          />
        );
      case 'Public Sector':
        return (
          <img
            src="/images/PublicSector.png"
            alt="images"
            className="min-h-4 min-w-4 size-4"
          />
        );
      case 'Healthcare':
        return (
          <img
            src="/images/Healthcare.png"
            alt="images"
            className="min-h-4 min-w-4 size-4"
          />
        );
      case 'Finance':
        return (
          <img
            src="/images/Finance.png"
            alt="images"
            className="min-h-4 min-w-4 size-4"
          />
        );
      default:
        return (
          <img
            src="/images/Enterprise.png"
            alt="images"
            className="min-h-4 min-w-4 size-4"
          />
        );
    }
  };

  const categoryList = Object.keys(categories);
  const currentIndex = categoryList.indexOf(selectedCategory);

  const navigateCategory = (direction) => {
    const newIndex =
      (currentIndex + direction + categoryList.length) % categoryList.length;
    setSelectedCategory(categoryList[newIndex]);
    setExpandedManuOpen(false);
  };

  return (
    <div className="relative lg:px-3 py-2 lg:w-auto w-full">
      <div
        className={`${
          openPanel ? ' justify-between' : 'justify-center'
        } flex items-center cursor-pointer border border-blue-20 rounded-lg bg-blue-10 dark:bg-gray-700 dark:border-gray-600`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={`flex items-center ${
            openPanel ? 'justify-start' : 'justify-center'
          }`}
        >
          <div className="p-2">{getCategoryIcon(selectedCategory)}</div>
          {openPanel && (
            <span className="text-sm font-medium">{selectedCategory}</span>
          )}
        </div>

        {openPanel && (
          <div className="flex flex-col items-center justify-center pr-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigateCategory(-1);
              }}
              className="text-black dark:text-white"
            >
              <ChevronUp size={14} />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigateCategory(1);
              }}
              className="text-black dark:text-white"
            >
              <ChevronDown size={14} />
            </button>
          </div>
        )}
      </div>

      {isOpen && (
        <div className="absolute w-full left-0 z-10 mt-1 px-3">
          <div className=" bg-white dark:bg-gray-700 p-1 rounded-lg shadow-shadow-button border border-gray-10 dark:border-gray-500">
            {categoryList.map((category) => (
              <div
                key={category}
                className={`${
                  selectedCategory === category
                    ? 'dark:bg-gray-800 bg-gray-200'
                    : ''
                } flex mt-1 items-center dark:hover:bg-gray-800 hover:bg-gray-200 rounded-md cursor-pointer`}
                onClick={() => {
                  setSelectedCategory(category);
                  setIsOpen(false);
                  setExpandedManuOpen(false);
                }}
              >
                <div className="w-8 h-8 text-black dark:text-white rounded-lg flex items-center justify-center ">
                  {getCategoryIcon(category)}
                </div>
                {openPanel && (
                  <span className="text-black dark:text-white text-xs">
                    {category}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;
