import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuItem = ({
  icon: Icon,
  text,
  path,
  isOpen,
  isSmallScreen,
  expandedSections,
  setExpandedSections,
}) => {
  return (
    <NavLink
      to={path}
      onClick={() => {
        setExpandedSections(() => ({
          [`Tools`]: false,
          [`Objects`]: false,
        }));
      }}
      className={({ isActive }) =>
        `flex items-center mx-3 last:mb-2 p-2 hover:font-medium dark:text-gray-200 mt-2 rounded-lg hover:dark:bg-gray-700 hover:dark:border-gray-500  border border-transparent  hover:bg-blue-10 transition-all duration-200 ${
          isActive && !expandedSections?.Tools
            ? 'bg-blue-10 border !border-blue-20 dark:bg-gray-700 text-[#377FFF] dark:text-white dark:!border-gray-500 font-semibold'
            : ' text-gray-700 '
        } ${isOpen && !isSmallScreen ? 'justify-start' : 'justify-center'}`
      }
    >
      <Icon size={20} className="flex-shrink-0" />
      {isOpen && !isSmallScreen && (
        <span className="ml-3 truncate text-sm">{text}</span>
      )}
    </NavLink>
  );
};

export default MenuItem;
