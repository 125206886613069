import React from "react";

const DropBox = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 107 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.1047 4L0 24.1558L21.6488 41.5744L52.7535 22.1651L31.1047 4ZM0 58.4953L31.1047 78.9L52.7535 60.7349L21.6488 41.5744L0 58.4953ZM52.7535 60.7349L74.6512 78.9L105.507 58.7442L84.107 41.5744L52.7535 60.7349ZM105.507 24.1558L74.6512 4L52.7535 22.1651L84.107 41.5744L105.507 24.1558ZM53.0023 64.7163L31.1047 82.8814L21.8977 76.6605V83.6279L53.0023 102.291L84.107 83.6279V76.6605L74.6512 82.8814L53.0023 64.7163Z"
        fill="#007EE5"
      />
    </svg>
  );
};

export default DropBox;
