import React from 'react';

const AiPowerTreatment = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <header className=" text-black mb-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">AI Power Platform</h1>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a href="#home" className="hover:underline">
                  Home
                </a>
              </li>
              <li>
                <a href="#features" className="hover:underline">
                  Features
                </a>
              </li>
              <li>
                <a href="#benefits" className="hover:underline">
                  Benefits
                </a>
              </li>
              <li>
                <a href="#contact" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section
        id="home"
        className=" text-black text-center py-10 bg-white rounded-lg"
      >
        <h2 className="text-4xl font-extrabold mb-4">
          Transform Your Business with AI Power
        </h2>
        <p className="text-lg mb-6">
          Harness the full potential of artificial intelligence to optimize,
          automate, and innovate.
        </p>
        <a
          href="#contact"
          className="bg-blue-500 text-white py-2 px-6 rounded-lg font-semibold hover:bg-blue-600"
        >
          Get Started
        </a>
      </section>

      <section
        id="features"
        className=" text-black text-center py-10 mt-6 bg-white px-6 rounded-lg"
      >
        <div className=" mx-auto text-center">
          <h3 className="text-3xl font-bold mb-8">Our Features</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-xl font-semibold mb-4">Feature One</h4>
              <p>Innovative solutions tailored to your business needs.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-xl font-semibold mb-4">Feature Two</h4>
              <p>Advanced analytics and data-driven insights.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg">
              <h4 className="text-xl font-semibold mb-4">Feature Three</h4>
              <p>Seamless integration with existing systems.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section
        id="benefits"
        className=" text-black text-center py-10 mt-6 bg-white px-6 rounded-lg"
      >
        <div className="mx-auto text-center">
          <h3 className="text-3xl font-bold mb-8">Why Choose Us?</h3>
          <div className="flex flex-col gap-4 md:flex-row md:justify-around">
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8 md:mb-0 md:w-1/3">
              <h4 className="text-xl font-semibold mb-4">Benefit One</h4>
              <p>Improved efficiency and productivity.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8 md:mb-0 md:w-1/3">
              <h4 className="text-xl font-semibold mb-4">Benefit Two</h4>
              <p>Enhanced decision-making through data insights.</p>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg shadow-lg md:w-1/3">
              <h4 className="text-xl font-semibold mb-4">Benefit Three</h4>
              <p>Scalable solutions for growing businesses.</p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section
        id="contact"
        className=" text-black text-center py-10 mt-6 bg-white px-6 rounded-lg"
      >
        <div className="container mx-auto">
          <h3 className="text-3xl font-bold mb-4">Ready to Get Started?</h3>
          <p className="text-lg mb-6">
            Contact us today to see how AI Power can transform your business.
          </p>
          <a
            href="mailto:info@example.com"
            className="bg-blue-500 text-white py-2 px-6 rounded-lg font-semibold hover:bg-blue-600"
          >
            Contact Us
          </a>
        </div>
      </section>
    </div>
  );
};

export default AiPowerTreatment;
