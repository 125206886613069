import React from 'react';

const FigmaIcon = () => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 300"
      width="60"
      height="60"
    >
      <style type="text/css">
        {`
          .st0 { fill: #0acf83; }
          .st1 { fill: #a259ff; }
          .st2 { fill: #f24e1e; }
          .st3 { fill: #ff7262; }
          .st4 { fill: #1abcfe; }
        `}
      </style>
      <title>Figma.logo</title>
      <desc>Created using Figma</desc>
      <path
        id="path0_fill"
        className="st0"
        d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
      />
      <path
        id="path1_fill"
        className="st1"
        d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
      />
      <path
        id="path1_fill_1_"
        className="st2"
        d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
      />
      <path
        id="path2_fill"
        className="st3"
        d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
      />
      <path
        id="path3_fill"
        className="st4"
        d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
      />
    </svg>
  );
};

export default FigmaIcon;
