import React, { useState } from 'react';
import { toast } from 'react-toastify';

const PriorAuthorization = () => {
  const [formData, setFormData] = useState({
    patientName: '',
    dateOfBirth: '',
    insuranceId: '',
    requestDetails: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      patientName: '',
      dateOfBirth: '',
      insuranceId: '',
      requestDetails: '',
    });
    toast.success('Submitted successfully!');
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-2xl font-bold mb-4">Prior Authorization</h1>

      <main>
        <form
          className="bg-white p-8 rounded-lg  shadow-shadow-div"
          onSubmit={handleSubmit}
        >
          <h2 className="text-xl font-semibold mb-4">Submit a Request</h2>

          <div className="mb-4">
            <label htmlFor="patientName" className="block text-gray-700">
              Patient Name
            </label>
            <input
              type="text"
              id="patientName"
              name="patientName"
              value={formData.patientName}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="dateOfBirth" className="block text-gray-700">
              Date of Birth
            </label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="insuranceId" className="block text-gray-700">
              Insurance ID
            </label>
            <input
              type="text"
              id="insuranceId"
              name="insuranceId"
              value={formData.insuranceId}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label htmlFor="requestDetails" className="block text-gray-700">
              Request Details
            </label>
            <textarea
              id="requestDetails"
              name="requestDetails"
              value={formData.requestDetails}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              rows="4"
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-fit bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Submit Request
          </button>
        </form>
      </main>
    </div>
  );
};

export default PriorAuthorization;
