import React from 'react';

const UploadPhotoHeader = ({ title }) => {
  return (
    <div className="border-b min-h-[60px] py-2.5 px-6 bg-lightGray-100 dark:bg-gray-900 dark:border-gray-700 border-lightGray-10 h-[60px] flex items-center justify-center gap-2">
      <h2 className="font-poppins font-medium text-sm">{title}</h2>
    </div>
  );
};

export default UploadPhotoHeader;
