// src/pages/SearchPage.js
import React, { useState } from 'react';

const initialFilters = [
  { id: '1', label: 'AI Models', checked: false },
  { id: '2', label: 'Documents', checked: false },
];

const mockResults = [
  {
    id: '1',
    title: 'AI Model 1',
    description: 'Description of AI Model 1',
    link: '#',
  },
  {
    id: '2',
    title: 'Document 1',
    description: 'Description of Document 1',
    link: '#',
  },
];

const EhrSumarizer = () => {
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState(initialFilters);
  const [results] = useState(mockResults);

  const handleSearch = (searchQuery) => {
    setQuery(searchQuery);
  };

  const handleFilterChange = (filterId) => {
    setFilters(
      filters.map((filter) =>
        filter.id === filterId
          ? { ...filter, checked: !filter.checked }
          : filter
      )
    );
  };

  const SearchBar = ({ query, onSearch }) => {
    return (
      <div className="w-full max-w-3xl mx-auto mb-6">
        <input
          type="text"
          value={query}
          onChange={(e) => onSearch(e.target.value)}
          placeholder="Search for documents, AI models, etc."
          className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary"
        />
      </div>
    );
  };

  const FilterPanel = ({ filters, onFilterChange }) => {
    return (
      <div className="bg-white w-full shadow-shadow-div rounded-lg p-4 mb-6 ">
        <h3 className="text-lg font-semibold text-primary mb-4">Filters</h3>
        {filters.map((filter) => (
          <div key={filter.id} className="mb-2">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={filter.checked}
                onChange={() => onFilterChange(filter.id)}
                className="form-checkbox text-primary"
              />
              <span className="ml-2 text-secondary">{filter.label}</span>
            </label>
          </div>
        ))}
      </div>
    );
  };

  const ResultCard = ({ result }) => {
    return (
      <div className="bg-white w-full shadow-shadow-div rounded-lg p-4 mb-4">
        <h4 className="text-xl font-semibold text-primary">{result.title}</h4>
        <p className="text-secondary mt-2">{result.description}</p>
        <a href={result.link} className="text-accent mt-4 block">
          View Details
        </a>
      </div>
    );
  };

  const SearchResults = ({ results }) => {
    return (
      <div className="w-full">
        {results.length ? (
          results.map((result) => (
            <ResultCard key={result.id} result={result} />
          ))
        ) : (
          <p className="text-center text-secondary">No results found</p>
        )}
      </div>
    );
  };

  return (
    <div className="bg-background min-h-screen py-8 px-4">
      <div className="p-6 bg-gray-50 mb-6 rounded-lg shadow-shadow-div">
        <h1 className="text-3xl font-bold text-primary mb-6 text-center">
          EhrSumarizer Search
        </h1>

        <SearchBar query={query} onSearch={handleSearch} />

        <FilterPanel filters={filters} onFilterChange={handleFilterChange} />
        <SearchResults results={results} />
      </div>
    </div>
  );
};

export default EhrSumarizer;
