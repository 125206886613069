export const DateIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1528_15844)">
        <path
          d="M12.8571 3.00335V1.28906M5.14279 3.00335V1.28906M1.49993 5.57478H16.4999M1.28564 7.32678C1.28564 5.51392 1.28564 4.60706 1.65936 3.91449C1.9971 3.29661 2.52151 2.8013 3.15764 2.49935C3.89136 2.14621 4.85136 2.14621 6.77136 2.14621H11.2285C13.1485 2.14621 14.1085 2.14621 14.8422 2.49935C15.4876 2.80963 16.0114 3.30506 16.3405 3.91363C16.7142 4.60792 16.7142 5.51478 16.7142 7.32763V11.5379C16.7142 13.3508 16.7142 14.2576 16.3405 14.9502C16.0028 15.5681 15.4784 16.0634 14.8422 16.3653C14.1085 16.7176 13.1485 16.7176 11.2285 16.7176H6.77136C4.85136 16.7176 3.89136 16.7176 3.15764 16.3645C2.52163 16.0628 1.99724 15.5678 1.65936 14.9502C1.28564 14.2559 1.28564 13.3491 1.28564 11.5362V7.32678Z"
          stroke="#377FFF"
          strokeWidth="1.28571"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1528_15844">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
