import React from 'react';
import UpDownArrow from '../../assets/icons/UpDownArrow';
import { HiOutlineDotsVertical } from 'react-icons/hi';

const PatientsTable = ({ data, setIsShowProfile, setActiveIndex }) => {
  return (
    <div className="min-w-[870px]">
      <table className="w-full ">
        <thead>
          <tr className="w-full flex items-center border-b dark:border-gray-600 border-gray-10 p-4">
            <th className="flex w-[12.17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>PID</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[12.17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>First Name</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[12.17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>Last Name</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[12.17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>DOB</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[12.17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>Gender</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[12.17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>Status</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[17%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm">
              <p>Email address</p>
              <button className="text-black">
                <UpDownArrow />
              </button>
            </th>
            <th className="flex w-[10%] items-center gap-1 font-medium dark:text-gray-300 text-[#A1A1A1] text-sm" />
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr
              key={index}
              onClick={() => {
                setIsShowProfile(true);
                setActiveIndex(index);
              }}
              className="w-full flex items-center border-b dark:border-gray-600 border-gray-10 p-4 hover:bg-gray-100 dark:hover:bg-gray-800 duration-300 cursor-pointer"
            >
              <td className="flex w-[12.17%] items-center gap-1 py-2.5 dark:text-white font-medium text-blackDark-10 text-sm">
                {item?.pid}
              </td>
              <td className="flex w-[12.17%] items-center gap-1 py-2.5 font-medium dark:text-white text-blackDark-10 text-sm">
                {item?.firstName}
              </td>
              <td className="flex w-[12.17%] items-center gap-1 py-2.5 font-medium dark:text-white text-blackDark-10 text-sm">
                {item?.lastName}
              </td>
              <td className="flex w-[12.17%] items-center gap-1 py-2.5 font-medium dark:text-white text-blackDark-10 text-sm">
                {item?.dob}
              </td>
              <td className="flex w-[12.17%] items-center gap-1 py-2.5 font-medium dark:text-white text-blackDark-10 text-sm">
                {item?.gender}
              </td>
              <td className="flex w-[12.17%] items-center gap-1 py-2.5 font-medium dark:text-white text-blackDark-10 text-sm">
                <p className="bg-[#d5f7d8e1] py-0.5 px-2 border border-[#238B5B] text-[#238B5B] rounded-full">
                  {item?.status}
                </p>
              </td>
              <td className="flex w-[17%] items-center gap-1 font-medium py-2.5 dark:text-white text-blackDark-10  text-sm">
                {item?.email}
              </td>
              <td className="flex w-[10%] items-center justify-end gap-1 font-medium py-2.5 text-blue-20 text-sm">
                <button>
                  <HiOutlineDotsVertical size={20} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PatientsTable;
