import React, { useState } from 'react';

const ImageUpload = ({ onUpload }) => {
  const [image, setImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      onUpload();
    }
  };

  return (
    <div className="flex flex-col items-center">
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="mb-4 border w-full p-4 rounded-md border-gray-300"
      />
      {image && (
        <img
          src={image}
          alt="Uploaded preview"
          className="max-w-md rounded shadow-lg"
        />
      )}
    </div>
  );
};

const ClassificationResults = ({ results }) => (
  <div className="mx-auto p-8">
    <h2 className="text-xl font-bold mb-4">Classification Results</h2>
    {results.length === 0 ? (
      <p>No results to display.</p>
    ) : (
      <ul className="list-disc pl-5">
        {results.map((result, index) => (
          <li key={index} className="mb-2">
            <strong>{result.label}:</strong> {result.confidence}%
          </li>
        ))}
      </ul>
    )}
  </div>
);

const ImageClassification = () => {
  const [results, setResults] = useState([]);

  const handleImageUpload = () => {
    setResults([
      { label: 'Cat', confidence: 85 },
      { label: 'Dog', confidence: 15 },
    ]);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="p-6 bg-gray-50 mb-6 rounded-lg shadow-shadow-div">
        <h3 className="text-2xl font-semibold text-gray-800 mb-6">
          Image Classification
        </h3>
        <ImageUpload onUpload={handleImageUpload} />

        {results && results?.length > 0 && (
          <ClassificationResults results={results} />
        )}
      </div>
    </div>
  );
};

export default ImageClassification;
