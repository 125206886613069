import React, { useState } from 'react';
import DetailsCategoryBarChart from './DetailsCategoryBarChart';

const SUB_CATEGORY = [
  {
    id: 1,
    title: 'Which Sub-Category had a higher ROI?',
    chartData: [
      { name: 'FB', price: 8200, amt: 11000, percentage: '$8.2K (29.5%)' },
      { name: 'IG', price: 4500, amt: 11000, percentage: '$4.5K (16.2%)' },
      { name: 'X', price: 4200, amt: 11000, percentage: '$4.2K (14.9%)' },
      {
        name: 'All else',
        price: 11000,
        amt: 11000,
        percentage: '$11.0K (39.4%)',
      },
    ],
  },
  {
    id: 2,
    title: 'Which Sub-Category decreased the most?',
    chartData: [
      { name: 'FB', price: 5200, amt: 11000, percentage: '$5.2K (29.5%)' },
      { name: 'IG', price: 4500, amt: 11000, percentage: '$4.5K (16.2%)' },
      { name: 'X', price: 4200, amt: 11000, percentage: '$4.2K (14.9%)' },
      {
        name: 'All else',
        price: 4700,
        amt: 11000,
        percentage: '$4.7K (39.4%)',
      },
    ],
  },
  {
    id: 3,
    title: 'Which Medium showed the most growth?',
    chartData: [
      { name: 'FB', price: 5300, amt: 11000, percentage: '$5.3K (29.5%)' },
      { name: 'IG', price: 1500, amt: 11000, percentage: '$1.5K (16.2%)' },
      { name: 'X', price: 3000, amt: 11000, percentage: '$3K (14.9%)' },
      {
        name: 'All else',
        price: 11000,
        amt: 11000,
        percentage: '$11.0K (39.4%)',
      },
    ],
  },
];

const DetailsSubCategory = () => {
  const [chartData, setChartData] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  return (
    <>
      <div className="flex items-center gap-4 flex-wrap">
        {SUB_CATEGORY?.map(({ title, chartData, id }) => (
          <button
            key={id}
            onClick={() => {
              setSelectedCategory(title);
              setChartData(chartData);
            }}
            className="border-[#F1EBF8] border dark:bg-gray-700 dark:text-white dark:border-gray-500 bg-white py-3 px-4 text-blue-20 text-sm font-medium font-poppins rounded-full"
          >
            {title}
          </button>
        ))}
      </div>

      {chartData && (
        <div className="py-6 border-t dark:border-gray-500 border-[#E2E0E5] flex flex-col gap-4">
          <div className="border-[#F1EBF8] flex items-center gap-3 w-fit border dark:bg-gray-700 dark:border-gray-500 bg-white py-3 px-4 text-blue-20 text-sm font-medium font-poppins rounded-full">
            <p className="pr-3 text-[#918B9C] border-r-[1.5px] dark:text-white border-gray-10">
              You asked
            </p>
            {selectedCategory}
          </div>

          <DetailsCategoryBarChart
            chartData={chartData}
            insight={
              <>
                The quarter to date{' '}
                <strong>
                  <span className="text-[#2D2D2D] dark:text-white font-semibold">
                    Branch Revenue
                  </span>
                </strong>{' '}
                had an unusual increase of{' '}
                <strong>
                  <span className="text-[#238B5B] font-semibold">+1.2%</span>
                </strong>{' '}
                over the equivalent quarter to date value a year ago, mainly
                attributed to{' '}
                <strong>
                  <span className="text-[#2D2D2D] dark:text-white font-semibold">
                    +1.2%
                  </span>
                </strong>
              </>
            }
            insightIcon={true}
          />
        </div>
      )}
    </>
  );
};

export default DetailsSubCategory;
