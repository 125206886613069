import React, { useState } from "react";
import { MessageCircle, RefreshCw, Copy, Check, AlertCircle } from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { ThemeContext } from "../components/ThemeProvider";

const OpenAIToBedrock = () => {
  const [openaiPrompt, setOpenaiPrompt] = useState("");
  const [bedrockPrompt, setBedrockPrompt] = useState("");
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { isDarkMode } = useContext(ThemeContext);

  const handleConvert = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      // Simulating API call
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setBedrockPrompt(
        "Converted Bedrock prompt:\n\n" +
        "Human: " + openaiPrompt + "\n\n" +
        "Assistant: I understand you'd like assistance with that. I'll do my best to help. What specific aspects would you like me to focus on?"
      );
    } catch (err) {
      setError("An error occurred during conversion. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(bedrockPrompt);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="max-w-4xl font-poppins mx-auto px-4 py-8 space-y-8">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white">
        OpenAI to Bedrock Prompt Converter
      </h1>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={`bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden ${
          isDarkMode ? "border border-gray-700" : "border border-gray-200"
        }`}
      >
        <div className="p-6 space-y-6">
          <form onSubmit={handleConvert} className="space-y-4">
            <div>
              <label
                htmlFor="openaiPrompt"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
              >
                Enter your OpenAI prompt:
              </label>
              <div className="relative">
                <textarea
                  id="openaiPrompt"
                  className={`w-full p-4 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200 ${
                    isDarkMode
                      ? "bg-gray-700 text-white border-gray-600"
                      : "bg-white text-gray-900 border-gray-300"
                  }`}
                  rows="4"
                  value={openaiPrompt}
                  onChange={(e) => setOpenaiPrompt(e.target.value)}
                  placeholder="Enter your OpenAI prompt here..."
                ></textarea>
                <MessageCircle
                  className="absolute right-3 top-3 text-gray-400"
                  size={20}
                />
              </div>
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              disabled={isLoading}
              className={`w-full font-bold py-3 px-4 rounded-lg transition duration-200 flex items-center justify-center shadow-md hover:shadow-lg disabled:opacity-50 ${
                isDarkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              }`}
            >
              {isLoading ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                >
                  <RefreshCw size={20} className="mr-2" />
                </motion.div>
              ) : (
                <RefreshCw size={20} className="mr-2" />
              )}
              {isLoading ? "Converting..." : "Convert to Bedrock"}
            </motion.button>
          </form>
        </div>
        <AnimatePresence>
          {bedrockPrompt && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className={`p-6 border-t ${
                isDarkMode ? "bg-gray-900 border-gray-700" : "bg-gray-50 border-gray-200"
              }`}
            >
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
                  Converted Bedrock Prompt:
                </h2>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={copyToClipboard}
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition duration-200 flex items-center"
                >
                  {copied ? <Check size={20} /> : <Copy size={20} />}
                  <span className="ml-2">{copied ? "Copied!" : "Copy"}</span>
                </motion.button>
              </div>
              <pre className={`p-4 rounded-lg text-sm overflow-x-auto shadow-inner ${
                isDarkMode ? "bg-gray-800 text-gray-300" : "bg-gray-100 text-gray-800"
              }`}>
                {bedrockPrompt}
              </pre>
            </motion.div>
          )}
        </AnimatePresence>
        {error && (
          <div className={`p-4 rounded-lg mt-4 flex items-center ${
            isDarkMode ? "bg-red-900 text-red-200" : "bg-red-100 text-red-700"
          }`}>
            <AlertCircle size={20} className="mr-2" />
            {error}
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default OpenAIToBedrock;