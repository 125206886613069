/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
} from 'chart.js';

import Frame from '../Frame.js';

import Performance from './Performance.js';
import AllReportsLineChart from './AllReportsLineChart.js';
import AllReportsBarChart from './AllReportsBarChart.js';
import ChartDetails from './ChartDetails.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const ApplianceSalesData = [
  { name: 'WK 14', value: 1300 },
  { value: 1000 },
  { value: 1400 },
  { value: 900 },
  { name: 'WK 18', value: 1600 },
];

const CampaignROIData = [
  { name: 'OCT', value: 800 },
  { name: 'MAY', value: 1200 },
];

const data = [
  { data: { value: 0, month: 'Jun 6' } },
  { data: { value: 4000, month: 'Jun 7' } },
  { data: { value: 0, month: 'Jun 8' } },
  { data: { value: 0, month: 'Jun 9' } },
  { data: { value: 2000, month: 'Jun 10' } },
];

const flattenedData = data.map((item) => ({
  name: item.name,
  value: item.data.value,
  month: item.data.month,
}));

const AllReportsNew = () => {
  const [activeTab, setActiveTab] = useState('HOME');
  const [chartType, setChartType] = useState(null);

  let detailChartData = [];
  let detailChartColor = '';

  switch (chartType) {
    case 'Appliance Sales':
      detailChartData = ApplianceSalesData;
      detailChartColor = 'green';
      break;
    case 'Branch Revenue':
      detailChartData = ApplianceSalesData;
      detailChartColor = 'red';
      break;
    case 'Campaign ROI':
      detailChartData = CampaignROIData;
      detailChartColor = 'blue';
      break;
    default:
      detailChartData = flattenedData;
      detailChartColor = 'red';
  }

  return (
    <Frame name={'All reports'}>
      {activeTab === 'HOME' && (
        <div className="flex flex-col gap-9">
          <Performance />

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
            <button
              type="button"
              className="relative"
              onClick={() => {
                setChartType('Appliance Sales');
                setActiveTab('DETAILS');
              }}
            >
              <AllReportsLineChart
                title="Appliance Sales"
                subtitle="Week to date, Los Angeles"
                value="1,675 units"
                change={
                  <>
                    +356 units{' '}
                    <span className="text-[#2D2D2D] dark:text-white font-semibold">
                      (+27% compared to previous period)
                    </span>
                  </>
                }
                isUnusual={true}
                chartData={ApplianceSalesData}
                color="green"
                hasHeartIcon={true}
                insight={
                  <>
                    The week to date{' '}
                    <strong>
                      <span className="text-[#2D2D2D] font-semibold dark:text-white">
                        Appliance Sales
                      </span>
                    </strong>{' '}
                    has seen an{' '}
                    <span className="text-[#238B5B] font-semibold">
                      unusual increase{' '}
                    </span>{' '}
                    of{' '}
                    <span className="text-[#238B5B] font-semibold">
                      {' '}
                      +356 units (+27%)
                    </span>{' '}
                    over the last week and is now 17% above the{' '}
                    <span className="text-[#2D2D2D] font-semibold dark:text-white">
                      normal range
                    </span>
                    .
                  </>
                }
                insightIcon={true}
              />
            </button>

            <button
              type="button"
              className="relative"
              onClick={() => {
                setChartType('Branch Revenue');
                setActiveTab('DETAILS');
              }}
            >
              <AllReportsLineChart
                title="Branch Revenue"
                subtitle="Quarter to date, London"
                value="$6.7M"
                change={
                  <>
                    +$0.3M{' '}
                    <span className="text-[#2D2D2D] font-semibold dark:text-white">
                      (+7% QTD)
                    </span>
                  </>
                }
                isUnusual={true}
                chartData={ApplianceSalesData}
                color="red"
                hasHeartIcon={true}
                insight={
                  <>
                    The quarter to date{' '}
                    <strong>
                      <span className="text-[#2D2D2D] dark:text-white font-semibold">
                        Branch Revenue
                      </span>
                    </strong>{' '}
                    had an unusual increase of{' '}
                    <span className="text-[#ED4E4E] font-semibold">+1.2% </span>{' '}
                    of over the equivalent quarter to date value a year ago,
                    mainly attributed to{' '}
                    <span className="text-[#2D2D2D] dark:text-white font-semibold">
                      Home Appliance.
                    </span>
                    .
                  </>
                }
                insightIcon={true}
              />
            </button>

            <button
              type="button"
              className="relative"
              onClick={() => {
                setChartType('Conversation volume');
                setActiveTab('DETAILS');
              }}
            >
              <AllReportsBarChart
                title="Conversation volume"
                subtitle="By day, Jun 6 - Jun 10"
                value={
                  <>
                    <span className="text-[#2D2D2D]  dark:text-white font-semibold">
                      4 total
                    </span>
                  </>
                }
                change={
                  <>
                    -1.1%{' '}
                    <span className="text-[#2D2D2D]  dark:text-white font-semibold">
                      since last month
                    </span>
                  </>
                }
                isUnusual={true}
                chartData={flattenedData}
                color="red"
                hasHeartIcon={true}
                insight={
                  <>
                    Conversation volume was{' '}
                    <strong>
                      <span className="text-[#2D2D2D] dark:text-white font-semibold">
                        highest on June 7
                      </span>
                    </strong>{' '}
                    as opposed to June 10, where the volumed{' '}
                    <span className="text-[#F5095C] font-semibold">
                      dropped by 66%.{' '}
                    </span>
                  </>
                }
              />
            </button>

            <button
              type="button"
              className="relative"
              onClick={() => {
                setChartType('Campaign ROI');
                setActiveTab('DETAILS');
              }}
            >
              <AllReportsLineChart
                title="Campaign ROI"
                subtitle="Monthly, Fremont"
                value={
                  <>
                    <span className="text-[#2D2D2D]  dark:text-white font-semibold">
                      379%
                    </span>
                  </>
                }
                change={
                  <>
                    <span className="text-[#2D2D2D] dark:text-white font-semibold">
                      +1.1%
                    </span>{' '}
                    <span className="text-[#2D2D2D] dark:text-white font-normal">
                      since last month
                    </span>
                  </>
                }
                isProblem={true}
                chartData={CampaignROIData}
                color="blue"
                hasHeartIcon={true}
                insight={
                  <>
                    The quarter to date{' '}
                    <strong>
                      <span className="text-[#2D2D2D] dark:text-white font-semibold">
                        Branch Revenue
                      </span>
                    </strong>{' '}
                    had an unusual increase of
                    <strong>
                      <span className="text-[#238B5B] font-semibold">
                        +1.2%
                      </span>
                    </strong>{' '}
                    over the equivalent quarter to date value a year ago, mainly
                    attributed to{' '}
                    <strong>
                      <span className="text-[#2D2D2D] dark:text-white font-semibold">
                        +1.2%
                      </span>
                    </strong>
                  </>
                }
                insightIcon={true}
              />
            </button>
          </div>
        </div>
      )}

      {activeTab === 'DETAILS' && (
        <ChartDetails
          chartType={chartType}
          setActiveTab={setActiveTab}
          chartData={detailChartData}
          detailChartColor={detailChartColor}
        />
      )}
    </Frame>
  );
};

export default AllReportsNew;
