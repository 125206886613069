import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Loader, Upload, X } from "react-feather";

const UploadCsv = ({
  handleUpload,
  uploadFiles,
  setUploadFiles,
  isLoading,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setUploadFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: true,
  });

  const removeUploadFile = (index) => {
    setUploadFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="mt-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        Upload File
      </label>
      <div {...getRootProps()} className="mb-4">
        <input {...getInputProps()} />
        <div
          className={`p-6 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors ${
            isDragActive
              ? "border-blue-500 bg-blue-50 dark:bg-blue-900"
              : "border-gray-300 dark:border-gray-700"
          }`}
        >
          <Upload size={24} className="mx-auto mb-2 text-gray-400" />
          <p className="text-sm text-gray-600 dark:text-gray-400">
            {isDragActive
              ? "Drop the PDF file here"
              : "Drag & drop a PDF file here, or click to select"}
          </p>
        </div>
      </div>
      {uploadFiles.length > 0 && (
        <div className="mb-4">
          <h4 className="text-sm font-medium  text-gray-700 dark:text-gray-300 mb-2">
            Selected file:
          </h4>
          <ul className="space-y-2">
            {uploadFiles.map((file, index) => (
              <li
                key={index}
                className="flex items-center justify-between bg-blue-5 dark:bg-gray-800 p-2 rounded-md"
              >
                <span className="text-sm text-gray-600 dark:text-gray-400 truncate">
                  {file.name}
                </span>
                <button
                  onClick={() => removeUploadFile(index)}
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                >
                  <X size={18} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <button
        onClick={handleUpload}
        disabled={!uploadFiles.length}
        className={`w-fit px-4 py-2 rounded-md transition-colors ${
          uploadFiles.length
            ? "bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700"
            : "bg-gray-300 text-gray-500 cursor-not-allowed dark:bg-gray-700 dark:text-gray-400"
        }`}
      >
        {isLoading ? <Loader /> : "Upload"}
      </button>
    </div>
  );
};

export default UploadCsv;
