import React, { useState } from 'react';

const CheckBox = ({ label, link, path }) => {
  const [checked, setChecked] = useState(false);

  return (
    <button
      type="button"
      onClick={() => setChecked(!checked)}
      className="flex items-center gap-3"
    >
      <div
        className={`flex size-5 items-center justify-center rounded bg-[#E2E0E550] dark:bg-white shadow-shadow-box`}
      >
        {checked && (
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.5684 0.983042C11.7325 1.14713 11.8246 1.36965 11.8246 1.60167C11.8246 1.83369 11.7325 2.05621 11.5684 2.22029L5.01002 8.77871C4.92335 8.8654 4.82045 8.93417 4.7072 8.98109C4.59395 9.028 4.47256 9.05215 4.34998 9.05215C4.22739 9.05215 4.10601 9.028 3.99275 8.98109C3.8795 8.93417 3.7766 8.8654 3.68993 8.77871L0.431434 5.52079C0.347862 5.44008 0.281203 5.34352 0.235345 5.23677C0.189487 5.13002 0.165349 5.0152 0.16434 4.89902C0.16333 4.78284 0.185469 4.66762 0.229465 4.56008C0.273461 4.45255 0.338432 4.35485 0.420588 4.2727C0.502744 4.19054 0.60044 4.12557 0.707974 4.08157C0.815508 4.03758 0.930728 4.01544 1.04691 4.01645C1.16309 4.01746 1.27791 4.0416 1.38466 4.08745C1.49142 4.13331 1.58797 4.19997 1.66868 4.28354L4.34968 6.96454L10.3306 0.983042C10.4119 0.901732 10.5083 0.837229 10.6145 0.793222C10.7207 0.749214 10.8346 0.726562 10.9495 0.726562C11.0645 0.726562 11.1783 0.749214 11.2845 0.793222C11.3907 0.837229 11.4872 0.901732 11.5684 0.983042Z"
              fill="#377FFF"
            />
          </svg>
        )}
      </div>

      {label && (
        <span className="font-poppins text-sm font-normal leading-4 text-gray-75">
          {label}{' '}
          {link && (
            <a href={path || ''} className="font-medium text-blue-20">
              {link}
            </a>
          )}
        </span>
      )}
    </button>
  );
};

export default CheckBox;
