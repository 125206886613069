import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuthStore from '../../stores/authStore';

const ProtectedRoute = ({ children, roles }) => {
  const { isLoggedIn, user } = useAuthStore();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (roles && !roles.includes(user.role)) {
    // Redirect to an appropriate route based on the user's role
    switch (user.role) {
      case 'Customer':
      case 'HR Employee':
      case 'IT Employee':
      case 'Sales Employee':
        return <Navigate to="/chat" replace />;
      case 'HR Manager':
      case 'IT Manager':
      case 'Sales Manager':
      case 'Super Admin':
        return <Navigate to="/dashboard" replace />;
      default:
        return <Navigate to="/login" replace />;
    }
  }

  return children;
};

export default ProtectedRoute;