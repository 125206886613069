import React from 'react';

const ReloadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9321 13.0409C19.7433 14.4809 19.166 15.8423 18.2622 16.9791C17.3585 18.1159 16.1623 18.9853 14.802 19.4939C13.4418 20.0026 11.9687 20.1314 10.5407 19.8665C9.11282 19.6016 7.78395 18.953 6.69664 17.9902C5.60933 17.0275 4.8046 15.7869 4.36876 14.4016C3.93292 13.0162 3.8824 11.5384 4.22264 10.1265C4.56287 8.71464 5.28102 7.42202 6.30006 6.38728C7.3191 5.35254 8.60059 4.61471 10.0071 4.25292C13.9061 3.25292 17.9421 5.25992 19.4321 8.99992"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 4V9H15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReloadIcon;
