import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LeftPanel from './components/LeftPanel';
import LogoutPopup from './components/LogoutPopup';
import useAuthStore from './stores/authStore';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import routes from './routes';
import './index.css';
import DeleteUserPopup from './components/DeleteUserPopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const App = () => {
  const { showLogoutPopup, showDeleteAccountPopup, isLoggedIn } =
    useAuthStore();
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const [isShowPanel, setIsShowPanel] = useState(false);
  const toggleLeftPanel = () => setIsLeftPanelOpen(!isLeftPanelOpen);

  useEffect(() => {
    if (isLoggedIn) {
      setIsShowPanel(true);
    } else {
      setIsShowPanel(false);
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <div className="flex font-poppins lg:flex-row flex-col h-[calc(100vh-60px)] lg:h-screen dark:bg-gray-900 dark:text-white bg-gray-100 text-black">
        {showLogoutPopup && <LogoutPopup />}
        {showDeleteAccountPopup && <DeleteUserPopup />}
        <ToastContainer />

        {isShowPanel && (
          <LeftPanel
            isOpen={isLeftPanelOpen}
            onToggle={toggleLeftPanel}
            setIsLeftPanelOpen={setIsLeftPanelOpen}
          />
        )}

        <div className="flex-1 flex flex-col h-full bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
          <main className="flex-1 overflow-y-auto">
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedRoute roles={route.roles}>
                        {route.element}
                      </ProtectedRoute>
                    ) : (
                      route.element
                    )
                  }
                />
              ))}
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
};

export default App;
