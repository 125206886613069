/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { Send, ChevronDown, FileText } from "react-feather";
import { motion, AnimatePresence } from "framer-motion";
import useAuthStore from "../stores/authStore";
import axios from "axios";
import { toast } from "react-toastify";
import { useTransition, animated, config } from "@react-spring/web";
import HeaderSettingIcon from "../assets/icons/HeaderSettingIcon";
import HeaderHistoryIcon from "../assets/icons/HeaderHistoryIcon";
import SourcesLogo from "../assets/icons/SourcesLogo";
import MassageSend from "./AssistantNew/MassageSend";
import SelectFolder from "./AssistantNew/SelectFolder";
import UploadCsv from "./AssistantNew/UploadCsv";

const AssistantV2 = () => {
  const { user } = useAuthStore();
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileResponse, setFileResponse] = useState(null);
  const [bucketName] = useState("avahi-rag-test");
  const handleUpload = async () => {
    if (uploadFiles.length > 0) {
      setIsLoading(true);

      const formData = new FormData();

      uploadFiles.forEach((file) => {
        formData.append("files", file);
      });

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_RAG}/api/upload_embeddings`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              bucket: `${bucketName}`,
            },
          }
        );

        if (response?.data?.statusCode === 200) {
          const bodyParse = JSON.parse(response?.data?.body);
          setFileResponse(bodyParse);
          toast.success("Upload successful");
        }
      } catch (error) {
        console.error("Error uploading files:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSend = async () => {
    if (inputText.trim()) {
      setMessages((msgs) => [
        ...msgs,
        {
          type: "user",
          content: inputText,
        },
      ]);
      setInputText("");

      setIsConversationStarted(true);

      try {
        setIsQueryLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_RAG}/api/rag_with_sources`,
          null,
          {
            params: {
              query: `${inputText}`,
              s3_folder_id: `${fileResponse?.s3_folder_id}`,
            },
          }
        );

        if (response?.data?.statusCode === 200) {
          setIsQueryLoading(false);
          const parsedData = JSON.parse(response?.data?.body);
          setMessages((prev) => [
            ...prev,
            {
              content: `${parsedData?.response}`,
              type: "assistant",
              sources: parsedData?.resources_used,
            },
          ]);
        } else {
          setIsQueryLoading(false);
          toast.error("Message sending failed.");
          throw new Error("Message failed");
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const ChatBody = ({ messages, loading, isLast, totalMessages }) => {
    const messagesEndRef = useRef(null);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const transitions = useTransition(messages, {
      from: { opacity: 0, transform: "translateY(20px)" },
      enter: (item) => async (next) => {
        await next({ opacity: 1, transform: "translateY(0)" });
      },
      leave: { opacity: 0 },
      trail: 100,
      config: config.gentle,
      keys: (message, index) => index,
    });

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

    useEffect(() => {
      if (totalMessages.length > 0) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, [totalMessages, loading]);

    useEffect(() => {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } =
          messagesEndRef.current.parentElement;
        setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
      };

      const scrollContainer = messagesEndRef.current.parentElement;
      scrollContainer.addEventListener("scroll", handleScroll);
      return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const renderMessageContent = (msg) => {
      console.log("msg", msg);
      return (
        <div
          className={`max-w-4xl rounded-2xl p-4 ${
            msg.type === "user"
              ? "dark:bg-gray-700 text-black dark:text-white dark:text-white-200"
              : "bg-transparent text-black dark:text-white"
          }`}
        >
          <p className="text-base font-poppins break-words">
            {formatText(msg.content)}
          </p>

{/* Start of relevant sources */}
{/*
          {msg.type === "assistant" && msg.sources && (
            <div className="mt-4 ml-6">
              <p className="text-sm text-white-600 dark:text-white-400 mb-2">
                {msg?.sources?.length} relevant sources found
              </p>
              <div className="w-full grid  gap-2">
                {msg.sources.map((source, idx) => (
                  <div key={idx} className="flex flex-col items-start ">
                    <div className="flex items-center gap-2">
                      <FileText size={18} className="mr-3 text-white-500" />
                      <span className="text-base text-white-700 dark:text-white-300">
                        {source?.source.split("/").pop()}
                      </span>{" "}
                    <button
                        key={idx}
                        className="mt-2 bg-gray-50 rounded-md border w-fit px-2 py-0.5 border-gray-300 dark:text-black"
                      >
                        {source?.page && <span>p. {source?.page}</span>}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
*/}
{/* End of relevant sources shown */}
        </div>
      );
    };

    const formatText = (text) => {
      const lines = text.split("\n");
      return lines.map((line, index) => {
        const trimmedLine = line?.trim();
        if (trimmedLine.startsWith("Answer:") || trimmedLine.endsWith(":")) {
          return (
            <p key={index} className="font-poppins mb-2">
              {trimmedLine}
            </p>
          );
        } else if (/^\d+\./.test(trimmedLine)) {
          return (
            <p key={index} className="font-poppins ml-4 mb-1">
              {trimmedLine}
            </p>
          );
        } else {
          return (
            <p key={index} className="font-poppins mb-1">
              {trimmedLine}
            </p>
          );
        }
      });
    };

    return (
      <div className="relative flex-1 p-4 overflow-y-auto helpdesk-custom-scrollbar bg-gray-50 dark:bg-gray-900 scroll-smooth">
        {transitions((style, msg, _, index) => (
          <animated.div
            style={index === messages.length - 1 ? style : {}}
            className={`flex gap-4 flex-col ${
              msg.type === "assistant" ? "item-start " : "items-end"
            }`}
            key={index}
          >
            {msg.type === "assistant" ? (
              <SourcesLogo />
            ) : (
              <div className="flex items-center gap-2.5">
                <span className="font-poppins text-base font-medium">You</span>
                <div className="w-[25px] h-[25px] rounded-full bg-blue-500 text-white font-semibold flex items-center justify-center shadow-md">
                  <span>U</span>
                </div>
              </div>
            )}
            <div
              className={`rounded-2xl max-w-3xl  ${
                msg.type === "assistant"
                  ? "bg-transparent dark:!bg-transparent text-gray-800 dark:!text-white"
                  : "bg-[#EBF2FFBF] dark:bg-gray-700 text-white shadow-shadow-div"
              }`}
              key={index}
            >
              {renderMessageContent(msg)}
            </div>
          </animated.div>
        ))}
        {loading && isLast && (
          <div className="flex flex-col items-start gap-2.5">
            <SourcesLogo />
            <div className="dotPulse text-[#737373] dark:text-white text-sm font-poppins leading-5 font-normal">
              <span className="pr-0.5">Searching for relevant sources</span>
              <span className="dot dark:darkDot" />
              <span className="dot dark:darkDot" />
              <span className="dot dark:darkDot" />
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
        {showScrollButton && (
          <button
            className="fixed bottom-10 right-4 bg-[#377FFF] text-white rounded-full p-3 shadow-xl hover:bg-blue-600 transition-all duration-300 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800"
            onClick={scrollToBottom}
          >
            <ChevronDown size={24} />
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="flex relative font-poppins flex-col lg:h-screen h-[calc(100vh-56px)] bg-[#FCFCFC] dark:bg-gray-900 overflow-auto">
      <AnimatePresence>
        {!isConversationStarted && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="border-b py-2.5 px-6 bg-lightGray-100 dark:bg-gray-900 dark:border-gray-700 border-lightGray-10 h-[60px] flex items-center justify-end gap-2">
              <button>
                <HeaderHistoryIcon />
              </button>
              <button>
                <HeaderSettingIcon />
              </button>
            </div>

            <div className="flex flex-col lg:px-24 px-6 py-[51px] gap-9">
              <div className="font-poppins font-medium leading-10 w-fit text-transparent  bg-clip-text text-3xl bg-gradient-to-r from-[#377FFF] to-[#F5095C]">
                <h1>Hi there, {user?.username}!</h1>
                <h2>What would you like to know?</h2>
              </div>

              <div className="w-full gap-10 grid lg:grid-cols-2 2xl:grid-cols-[600px_1fr]">
                <div className="flex flex-col gap-6 dark:border-gray-700 border-2 rounded-2xl  p-6">
                  {/* <SelectFolder
                    setSelectedFolder={setSelectedFolder}
                    selectedFolder={selectedFolder}
                  /> */}
                  <UploadCsv
                    handleUpload={handleUpload}
                    setUploadFiles={setUploadFiles}
                    uploadFiles={uploadFiles}
                    isLoading={isLoading}
                  />
                </div>
                <MassageSend
                  inputText={inputText}
                  setInputText={setInputText}
                  isConversationStarted={isConversationStarted}
                  handleSend={handleSend}
                  isDisable={!fileResponse}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isConversationStarted && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex-grow overflow-auto helpdesk-custom-scrollbar  mb-[88px] lg:px-24 px-6"
          >
            {messages.map((message, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <ChatBody
                  messages={message}
                  totalMessages={messages}
                  loading={isQueryLoading}
                  isLast={index === messages.length - 1}
                />
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {isConversationStarted && (
        <div className="absolute bottom-0 w-full bg-[#FCFCFC] dark:bg-gray-900 py-2 pb-3 lg:px-24 px-6">
          <div className="bg-lightGray-100 dark:bg-gray-800 dark:border-gray-700 w-full border-2 flex items-center gap-4 border-lightGray-10 px-6 rounded-2xl">
            <input
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder={
                isConversationStarted
                  ? "Ask a follow-up question..."
                  : "I'm here to help, ask anything..."
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSend();
                }
              }}
              maxLength={1000}
              className="resize-none w-full py-5 bg-transparent helpdesk-custom-scrollbar text-base placeholder:text-[#787878] text-black dark:text-white outline-none"
            />

            <div className="flex items-center justify-end gap-4">
              <span>{inputText?.length}/1000</span>
              <button
                onClick={handleSend}
                className="hover:text-blue-500 text-black dark:text-white"
              >
                <Send size={24} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssistantV2;
