import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleQuantize } from 'd3-scale';
import { Tooltip } from 'react-tooltip';

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

const stateFips = {
  '01': 'AL',
  '02': 'AK',
  '04': 'AZ',
  '05': 'AR',
  '06': 'CA',
  '08': 'CO',
  '09': 'CT',
  10: 'DE',
  12: 'FL',
  13: 'GA',
  15: 'HI',
  16: 'ID',
  17: 'IL',
  18: 'IN',
  19: 'IA',
  20: 'KS',
  21: 'KY',
  22: 'LA',
  23: 'ME',
  24: 'MD',
  25: 'MA',
  26: 'MI',
  27: 'MN',
  28: 'MS',
  29: 'MO',
  30: 'MT',
  31: 'NE',
  32: 'NV',
  33: 'NH',
  34: 'NJ',
  35: 'NM',
  36: 'NY',
  37: 'NC',
  38: 'ND',
  39: 'OH',
  40: 'OK',
  41: 'OR',
  42: 'PA',
  44: 'RI',
  45: 'SC',
  46: 'SD',
  47: 'TN',
  48: 'TX',
  49: 'UT',
  50: 'VT',
  51: 'VA',
  53: 'WA',
  54: 'WV',
  55: 'WI',
  56: 'WY',
};

const dummyData = Object.keys(stateFips).reduce((acc, fips) => {
  acc[fips] = {
    id: fips,
    state: stateFips[fips],
    value: Math.floor(Math.random() * 1000) + 1,
  };
  return acc;
}, {});

const USAMapCard = () => {
  const [tooltipContent, setTooltipContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const handleResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        let height;
        if (windowWidth >= 1024) {
          height = Math.max(windowHeight * 0.6, 400);
        } else if (windowWidth >= 768) {
          height = Math.max(windowHeight * 0.5, 350);
        } else {
          height = Math.max(width * 0.6, 300);
        }

        setDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const colorScale = scaleQuantize()
    .domain([0, Math.max(...Object.values(dummyData).map((d) => d.value))])
    .range([
      '#e3f2fd',
      '#bbdefb',
      '#90caf9',
      '#64b5f6',
      '#42a5f5',
      '#2196f3',
      '#1e88e5',
      '#1976d2',
      '#1565c0',
      '#0d47a1',
    ]);

  return (
    <motion.div
      ref={containerRef}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 w-full h-full flex flex-col"
    >
      <div className="flex items-center mb-4">
        <FaMapMarkerAlt className="text-blue-500 dark:text-blue-400 text-2xl mr-3" />
        <h2 className="text-xl font-poppins font-semibold text-gray-800 dark:text-white">
          User Distribution (USA)
        </h2>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center flex-grow">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="flex-grow" style={{ minHeight: dimensions.height }}>
          <ComposableMap
            projection="geoAlbersUsa"
            projectionConfig={{ scale: 1000 }}
            width={dimensions.width}
            height={dimensions.height}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const cur = dummyData[geo.id];
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={cur ? colorScale(cur.value) : '#EEE'}
                      stroke="#FFF"
                      strokeWidth={0.5}
                      onMouseEnter={() => {
                        const { name } = geo.properties;
                        setTooltipContent(
                          `${name}: ${
                            cur ? cur.value.toLocaleString() : 'No data'
                          }`
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipContent('');
                      }}
                      style={{
                        default: { outline: 'none' },
                        hover: { outline: 'none', fill: '#3b82f6' },
                        pressed: { outline: 'none' },
                      }}
                      data-tooltip-id="usa-map-tooltip"
                      aria-label={`${geo.properties.name}: ${
                        cur ? cur.value.toLocaleString() : 'No data'
                      }`}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
      )}
      <Tooltip
        id="usa-map-tooltip"
        content={tooltipContent}
        place="top"
        className="bg-gray-800 text-white p-2 rounded shadow-lg z-10 font-poppins"
      />
      <div className="mt-4 flex items-center justify-between">
        <span className="text-sm text-gray-600 dark:text-gray-400">Low</span>
        <div className="flex-1 h-2 mx-2 bg-gradient-to-r from-[#e3f2fd] to-[#0d47a1]"></div>
        <span className="text-sm text-gray-600 dark:text-gray-400">High</span>
      </div>
    </motion.div>
  );
};
export default USAMapCard;
