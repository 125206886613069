import React from "react";

const NormalScreenIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V9H23V13H27V15H21ZM15 15V9H13V13H9V15H15ZM15 27L15 21H9V23H13L13 27H15ZM21 21V27H23V23H27V21H21Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NormalScreenIcon;
