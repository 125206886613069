import React from 'react';
import ExpandedIcon from '../../assets/icons/ExpandedIcon';
import { NavLink } from 'react-router-dom';
import { ChevronRight } from 'react-feather';

const LeftPanelExpanded = ({
  selectedCategory,
  categories,
  expandedSections,
  setExpandedSections,
  setOpenMenu,
  manuOpen,
  openNote,
  setIsNotebookPanel,
  isChatbotOpen,
  setIsChatbotOpen,
}) => {
  return (
    <>
      {!expandedSections?.Tools && (
        <div className="h-screen !bg-[#FCFCFC] dark:!bg-gray-900 hidden overflow-hidden lg:flex items-start pt-5 pl-1">
          <button
            onClick={() => {
              setExpandedSections((prev) => ({
                ...prev,
                [`Tools`]: true,
                [`Objects`]: true,
              }));
              setIsNotebookPanel(false);
              setOpenMenu(false);
            }}
            className="rotate-180"
          >
            <ExpandedIcon />
          </button>
        </div>
      )}

      {expandedSections?.Tools && (
        <div className="w-min-[183px] max-w-fit relative h-screen overflow-hidden bg-[#F0F1F1] dark:border-gray-600 dark:bg-gray-800 border-r border-gray-10">
          <div className="absolute top-0 bg-[#F0F1F1] dark:bg-gray-800 w-full">
            <div className="flex items-center justify-between px-4 py-5">
              <h3 className="text-xl font-medium ">Tools</h3>
              <button
                onClick={() => {
                  setExpandedSections((prev) => ({
                    ...prev,
                    [`Tools`]: false,
                    [`Objects`]: true,
                  }));
                }}
              >
                <ExpandedIcon />
              </button>
            </div>

            {/* <ExpandedSearch /> */}
          </div>
          {/* 134  */}
          <div className=" h-[calc(100vh-70px)] mt-[70px] pb-6 overflow-y-auto helpdesk-custom-scrollbar">
            {categories[selectedCategory].map((item, index) => (
              <div key={index}>
                {item.section && (
                  <div key={item.section}>
                    <div>
                      {item.items.map(({ icon: Icon, text, path }, key) => (
                        <NavLink
                          key={key}
                          to={path}
                          onClick={() => {
                            setExpandedSections((prev) => ({
                              ...prev,
                              [`Tools`]: !prev[`Objects`],
                            }));
                          }}
                          className={({ isActive }) =>
                            `flex !items-start mx-1 last:mb-2 p-2 mt-2 rounded-lg transition-all text-black dark:text-white duration-200 font-medium ${
                              isActive
                                ? 'bg-white dark:bg-gray-900'
                                : ' hover:bg-white dark:hover:bg-gray-900'
                            }`
                          }
                        >
                          <Icon size={20} className="flex-shrink-0" />
                          <span className="ml-3 truncate text-sm">{text}</span>
                        </NavLink>
                      ))}
                    </div>

                    {item?.notebook && (
                      <div className="flex flex-col gap-1">
                        <button
                          onClick={() => {
                            setIsNotebookPanel(true);
                          }}
                          className={`py-2 px-3  dark:hover:bg-gray-600 hover:bg-gray-200 text-sm  w-full transition-all text-black dark:text-white duration-200 font-medium flex items-center justify-between`}
                        >
                          {item?.notebook?.text}
                          <ChevronRight
                            size={16}
                            className={`ml-auto  transition-transform duration-200 ${
                              openNote ? 'rotate-90' : ''
                            } `}
                          />
                        </button>
                        {openNote && (
                          <div className={`pl-4 pr-1.5`}>
                            {item?.notebook?.items?.map(
                              ({ path, icon: Icon, text }, key) => (
                                <NavLink
                                  key={key}
                                  to={path}
                                  onClick={() => {
                                    setExpandedSections((prev) => ({
                                      ...prev,
                                      [`Tools`]: !prev[`Objects`],
                                    }));
                                  }}
                                  title={text}
                                  className={({ isActive }) =>
                                    `flex ${
                                      isActive
                                        ? 'bg-white dark:bg-gray-900'
                                        : ' hover:bg-white dark:hover:bg-gray-900'
                                    } !items-center  mt-2 first:mt-0 mx-1 p-2 duration-300 hover:bg-white dark:hover:bg-gray-900 rounded-lg transition-all text-black dark:text-white font-medium`
                                  }
                                >
                                  <Icon size={16} className="flex-shrink-0" />
                                  <span className="ml-3 truncate text-sm">
                                    {text}
                                  </span>
                                </NavLink>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {item?.objects && (
                      <div className="flex flex-col gap-1">
                        <button
                          onClick={() => setOpenMenu(!manuOpen)}
                          className={`py-2 px-3 dark:hover:bg-gray-600 hover:bg-gray-200 text-sm  w-full transition-all text-black dark:text-white duration-200 font-medium flex items-center justify-between`}
                        >
                          {item?.objects?.text}
                          <ChevronRight
                            size={16}
                            className={`ml-auto transition-transform duration-200 ${
                              manuOpen ? 'rotate-90' : ''
                            } `}
                          />
                        </button>
                        {manuOpen && (
                          <div className={`pl-4 pr-1.5`}>
                            {item?.objects?.items?.map(
                              ({ path, icon: Icon, text }, key) => (
                                <NavLink
                                  key={key}
                                  to={path}
                                  title={text}
                                  className={`flex !items-center mt-2 first:mt-0 mx-1 p-2 duration-300 hover:bg-white dark:hover:bg-gray-900 rounded-lg transition-all text-black dark:text-white font-medium`}
                                >
                                  <Icon size={16} className="flex-shrink-0" />
                                  <span className="ml-3 truncate text-sm">
                                    {text}
                                  </span>
                                </NavLink>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LeftPanelExpanded;
