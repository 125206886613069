import React from 'react';

const UploadIcon = () => {
  return (
    <svg
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="35" height="35" rx="17.5" fill="#D8E6FF" />
      <g clipPath="url(#clip0_370_2263)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8708 6.65299C20.8708 6.56839 20.8372 6.48725 20.7773 6.42742C20.7175 6.36759 20.6364 6.33398 20.5518 6.33398H11.6195C10.6888 6.33398 9.79623 6.70369 9.13815 7.36178C8.48006 8.01987 8.11035 8.91242 8.11035 9.8431V25.2264C8.11035 26.157 8.48006 27.0496 9.13815 27.7077C9.79623 28.3658 10.6888 28.7355 11.6195 28.7355H24.3799C25.3106 28.7355 26.2031 28.3658 26.8612 27.7077C27.5193 27.0496 27.889 26.157 27.889 25.2264V15.1348C27.889 15.0502 27.8554 14.9691 27.7956 14.9093C27.7357 14.8494 27.6546 14.8158 27.57 14.8158H21.8278C21.574 14.8158 21.3306 14.715 21.1511 14.5355C20.9716 14.356 20.8708 14.1126 20.8708 13.8588V6.65299ZM20.5913 20.4802C20.7896 20.6389 21.0429 20.7123 21.2953 20.6843C21.5478 20.6563 21.7788 20.5292 21.9375 20.3309C22.0963 20.1326 22.1697 19.8793 22.1417 19.6269C22.1137 19.3744 21.9866 19.1434 21.7882 18.9847L18.6058 16.439C18.4355 16.2996 18.2223 16.2234 18.0022 16.2233H17.992C17.7759 16.2246 17.5667 16.2993 17.3987 16.4351L14.2124 18.9847C14.0141 19.1432 13.8869 19.3741 13.8587 19.6264C13.8306 19.8787 13.9039 20.1319 14.0624 20.3302C14.221 20.5286 14.4518 20.6558 14.7042 20.6839C14.9565 20.712 15.2097 20.6387 15.408 20.4802L17.0426 19.171V23.5605C17.0426 23.6862 17.0674 23.8107 17.1155 23.9268C17.1636 24.0429 17.2341 24.1484 17.323 24.2373C17.4118 24.3261 17.5173 24.3966 17.6334 24.4447C17.7495 24.4928 17.874 24.5176 17.9997 24.5176C18.1254 24.5176 18.2498 24.4928 18.3659 24.4447C18.482 24.3966 18.5875 24.3261 18.6764 24.2373C18.7653 24.1484 18.8358 24.0429 18.8839 23.9268C18.932 23.8107 18.9567 23.6862 18.9567 23.5605V19.171L20.5913 20.4802Z"
          fill="#377FFF"
        />
        <path
          d="M22.7852 7.06711C22.7852 6.83232 23.0314 6.68302 23.2139 6.82976C23.3683 6.95482 23.5074 7.10028 23.6261 7.26617L27.4708 12.6217C27.5576 12.7442 27.4631 12.9024 27.3126 12.9024H23.1042C23.0196 12.9024 22.9384 12.8688 22.8786 12.809C22.8188 12.7492 22.7852 12.668 22.7852 12.5834V7.06711Z"
          fill="#377FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_370_2263">
          <rect
            width="20.4167"
            height="22.9688"
            fill="white"
            transform="translate(7.7915 6.01562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
