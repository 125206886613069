import React, { useState } from "react";
import ChapterIcon from "../../../assets/icons/ChapterIcon";
// import ShowDetailsIcon from '../../../assets/icons/ShowDetailsIcon';
import { MdInsights } from "react-icons/md";
import { IoChevronDownSharp } from "react-icons/io5";

// const chapters = [
//   'AI',
//   'Transcription',
//   ' Meeting Summary',
//   'Integration',
//   'Analytics',
//   'Collaboration',
// ];

const NOTES_DATA = [
  {
    id: 1,
    name: "Back Pain Check-Up",
    authorData: ["John Jones", "Aug 08 2024, 3:22 AM", "English (Global)"],
    overview: `Mr. Jones visits for his recurring back pain, which originated from a workplace injury three years ago. While Mr. Jones previously underwent physical therapy and took tramadol, he discontinued these treatments due to cost concerns, insurance limitations, and scheduling difficulties. Dr. Smith suggests more affordable alternatives, including using a heating pad for immediate relief and exploring yoga or tai chi classes as long-term solutions instead of medication. The doctor acknowledges the patient's financial constraints and schedules a follow-up phone call for the following Tuesday to discuss researched treatment options, while also recommending that Mr. Jones review his insurance options during open season.`,
    notesData: [
      {
        id: 1,
        title: "❗️ Chief Complaint",
        item: [],
      },
    ],
  },
  {
    id: 2,
    name: "Headaches 9/23",
    authorData: ["Pat Bellamy", "Aug 08 2024, 3:22 AM", "English (Global)"],
    overview: `Ms. Bellamy visits with complaints of a severe headache that started three days ago. The headache is described as a constant 10/10 pain affecting her entire head, made worse by movement and light, with associated neck stiffness and nausea. Pat is particularly concerned because of a neighbor who had a brain tumor, and she's also worried about insurance coverage since she recently changed insurance companies. Her medical history includes controlled high blood pressure, and she smokes half a pack of cigarettes daily. She recently attended a family reunion in North Carolina where a sick child was present. The doctor takes a thorough history, including family history noting her mother's history of migraines, and plans to conduct a physical exam and arrange insurance verification while addressing her concerns.`,
    notesData: [
      {
        id: 1,
        title: "❗️ Chief Complaint",
        item: [],
      },
    ],
  },
  {
    id: 3,
    name: "Diarrhea Complaints",
    authorData: ["Alexis Gupta", "Aug 08 2024, 3:22 AM", "English (Global)"],
    overview: `Mrs. Gupta visits with complaints of diarrhea lasting 2-3 weeks, occurring up to 8 times daily including at night, with recent onset of blood in the stool and crampy abdominal pain. The patient is particularly concerned about bowel cancer due to a friend's sister's diagnosis at age 30, and worried about potentially spreading an infection to her students. Her medical history includes an appendectomy in her teens, penicillin allergy, and she currently takes oral contraceptives and drinks alcohol on weekends. The doctor plans to run blood tests and collect stool samples, scheduling a follow-up appointment for the following week, with the possibility of a specialist referral if symptoms persist. Additional health concerns to be addressed include the patient's alcohol consumption (approximately 20 units/week) and recent weight loss noted through looser-fitting clothes.`,
    notesData: [
      {
        id: 1,
        title: "❗️ Chief Complaint",
        item: [],
      },
    ],
  },
];

const MASKING_STATISTICS_DATA = [
  {
    id: 1,
    title: "Processing Time",
    description:
      "Choose to what degree you want to mask your sensitive information.",
    duration: "1.2 s",
  },
  {
    id: 2,
    title: "Original Characters",
    description:
      "Choose to what degree you want to mask your sensitive information.",
    duration: "120",
  },
  {
    id: 3,
    title: "Masked Characters",
    description:
      "Choose to what degree you want to mask your sensitive information.",
    duration: "34",
  },
];

const ActiveRules = [
  "Names",
  "Emails",
  "Phone Numbers",
  "Addresses",
  "Credit Cards",
  "Social Security Numbers",
];

const NotesMaskedDataTab = ({ activeIndex }) => {
  const [showMaking, setShowMaking] = useState(false);

  function formatText(input) {
    const hiddenText = input.replace(
      /(\b[A-Z]{3}-[A-Z]{3}-[0-9]{4}\b)|(\b\d{10}\b)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)|(\b\d{1,3},?\s?\d{1,3},?\s?\d{1,3},?\s?\d{1,3}\b)|(\b[A-Z][a-z]*\s[A-Z][a-z]*\*\b)/g,
      '<span style="background-color: #252525; color: white; min-height: 11px; min-width: 20px;">\t\t\t</span>'
    );

    const finalText = hiddenText.replace(
      /\*\*\*/g,
      '<span style="background-color: #252525; color">\t\t\t</span>'
    );

    const formattedText = finalText.replace(/\\n/g, "\n");

    return formattedText;
  }

  return (
    <div className="p-6 mb-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4 items-start">
        <h4 className="text-2xl font-medium text-blackDark-10 dark:text-white">
          {NOTES_DATA[activeIndex]?.name}
        </h4>

        <div className="flex items-center gap-3 flex-wrap">
          <div className="bg-[#D9D9D9] size-5 rounded-full" />
          {NOTES_DATA[activeIndex]?.authorData?.map((label, index) => (
            <p
              key={index}
              className="text-sm font-normal dark:text-white text-blackDark-10"
            >
              {label}
            </p>
          ))}
        </div>
      </div>

      <div className="flex items-center gap-2 px-2 py-1.5 text-xs text-blackDark-10 dark:text-white tracking-[-0.24px]">
        <ChapterIcon />2 chapters
      </div>

      {/* <div className="flex items-center gap-2 flex-wrap">
        <ShowDetailsIcon />
        {chapters?.map((chapter, index) => (
          <span
            key={index}
            className="py-1 px-1.5 rounded-md bg-[#F2F2F2] dark:bg-gray-700 dark:text-gray-300 text-[#787878] text-xs font-medium"
          >
            {chapter}
          </span>
        ))}
      </div> */}

      <div className="flex flex-col gap-2">
        <h2 className="text-lg font-semibold text-blackDark-10 tracking-[-0.36px] dark:text-white">
          Overview
        </h2>

        <div
          className="text-base text-blackDark-10 tracking-[-0.32px] leading-7 dark:text-white"
          dangerouslySetInnerHTML={{
            __html: formatText(NOTES_DATA[activeIndex]?.overview),
          }}
        />
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-semibold text-blackDark-10 dark:text-white tracking-[-0.36px]">
          Notes
        </h2>

        {NOTES_DATA[activeIndex]?.notesData?.map(({ id, item, title }) => (
          <div key={id} className="flex flex-col gap-2.5">
            <h3 className="text-base font-medium text-blackDark-10 dark:text-white">
              {title}
            </h3>
            <ul>
              {item?.map((note, index) => (
                <li
                  key={index}
                  className="text-sm list-disc ml-5 text-blackDark-10 leading-7 tracking-[-0.32px]"
                >
                  {note}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div>
        <button
          onClick={() => setShowMaking(!showMaking)}
          className="flex w-fit items-center py-1.5 px-3 border rounded-lg border-blue-20 gap-1.5 text-base leading-6 font-medium text-blue-20"
        >
          <MdInsights size={20} />
          Data Insights
          <IoChevronDownSharp
            size={12}
            className={`${showMaking ? "rotate-180" : ""} duration-200`}
          />
        </button>

        {showMaking && (
          <div className="py-9 sm:p-9 flex flex-col gap-6 items-start">
            <h2 className="text-black dark:text-white text-xl font-medium tracking-[-0.4px] font-poppins">
              Masking Statistics
            </h2>

            <div className="grid sm:grid-cols-3  gap-1.5">
              {MASKING_STATISTICS_DATA?.map(
                ({ id, title, description, duration }) => (
                  <div
                    key={id}
                    className="p-6 border dark:border-gray-600 border-gray-10 rounded-2xl flex flex-col gap-5"
                  >
                    <div>
                      <h2 className="font-poppins text-sm font-medium tracking-[-0.28px]">
                        {title}
                      </h2>
                      <p className="text-[#6D7280] text-[10px] font-normal tracking-[-0.2px]">
                        {description}
                      </p>
                    </div>

                    <span className="text-blackDark-10 text-4xl dark:text-white font-medium tracking-[-0.18px]">
                      {duration}
                    </span>
                  </div>
                )
              )}
            </div>

            <div className="flex flex-col gap-4 items-start">
              <h2 className="text-black dark:text-white text-xl font-medium tracking-[-0.4px] font-poppins">
                Active Rules
              </h2>

              <ul>
                {ActiveRules?.map((rule, index) => (
                  <li
                    key={index}
                    className="text-sm list-disc dark:text-white ml-6 text-blackDark-10 leading-7 tracking-[-0.32px]"
                  >
                    {rule}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesMaskedDataTab;
