/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import NewFileUploadModel from "./NewMedicalScribingV1/NewFileUploadModel";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TranscriptTab from "./MedicalScribingTabs/TranscriptTab";
import EhrSummaryTab from "./MedicalScribingTabs/EhrSummaryTab";
import IcdTab from "./MedicalScribingTabs/IcdTab";

const TAB_Data = [
  { id: 1, name: "medicalScribing.tabs.transcript", value: "Transcript" },
  { id: 2, name: "medicalScribing.tabs.ehrSummary", value: "EHR Summary" },
  { id: 3, name: "medicalScribing.tabs.icdCode", value: "ICD Code Breakdown" },
];

const NewMedicalScribingV1 = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Transcript");
  const [isSummarySection, setIsSummarySection] = useState(false);
  const [insightsLoading, setInsightsLoading] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  const handleGenerateSummary = async (data) => {
    setInsightsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_LIVE_TRANSCRIPTION}/fetch_healthscribe_report`,
        {
          // queryStringParameters: {
          job_name: data?.JobName,
          //},
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "Tq6isgVNGT1v2yYpayLwA7Nuw2dmUmjXaR2UQ7jn",
          },
        }
      );

      if (response?.status === 200) {
        setSummaryData(response?.data?.data);
      } else {
        toast.error("Fetch data failed.");
        throw new Error("Fetch data failed");
      }
    } catch (error) {
      console.error("Error Fetch data:", error);
      toast.error("Error Fetch data.");
    } finally {
      setInsightsLoading(false);
      setIsSummarySection(true);
    }
  };

  const handleGeneratingInsights = async (item) => {
    setInsightsLoading(true);
    const data = {
      transformed_filename: item?.transformed_filename,
      s3_path: item?.s3_path,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HEALTHCARE_UPLOAD_FILE}/transcribe`,
        data,
        {
          maxBodyLength: Infinity,
        }
      );

      if (response.status === 200) {
        handleGenerateSummary(response?.data);
      } else {
        toast.error("File upload failed.");
        setInsightsLoading(false);
        throw new Error("Upload failed");
      }
    } catch (error) {
      console.error("Error", error);
      setInsightsLoading(false);
    }
  };

  const handleUploadFile = async (uploadFile) => {
    setIsFileLoading(true);
    if (uploadFile) {
      try {
        const formData = new FormData();
        formData.append("file", uploadFile);
        const response = await axios.post(
          `${process.env.REACT_APP_API_HEALTHCARE_UPLOAD_FILE}/upload_healthscribe`,
          formData,
          {
            maxBodyLength: Infinity,
          }
        );

        if (response.status === 200) {
          setSummaryData(response?.data);
          handleGeneratingInsights(response?.data);
        } else {
          toast.error("File upload failed.");
          throw new Error("Upload failed");
        }
      } catch (error) {
        console.error("Error", error);
        toast.error("Error uploading file.");
      } finally {
        setIsFileLoading(false);
      }
    }
  };

  useEffect(() => {
    if (files?.length > 0) {
      handleUploadFile(files[0]);
    }
  }, [files]);

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] lg:h-screen bg-[#FCFCFC] dark:bg-gray-900 overflow-auto">
      <div className="border-b py-2.5 px-6 bg-lightGray-100 dark:bg-gray-900 dark:border-gray-700 border-lightGray-10 h-[60px] flex items-center justify-center gap-2">
        <h2 className="font-poppins font-medium text-sm">Medical scribing</h2>
      </div>

      <div className="h-[calc(100%-60px)] overflow-y-auto flex flex-col gap-4 p-6">
        <NewFileUploadModel
          files={files}
          setFiles={setFiles}
          isFileLoading={isFileLoading}
          massage={summaryData?.response}
          insightsLoading={insightsLoading}
        />

        {isSummarySection && (
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-9 border-b border-gray-300 w-fit">
              {TAB_Data?.map(({ id, name, value }) => (
                <button
                  key={id}
                  onClick={() => setSelectedTab(value)}
                  className={`${
                    selectedTab === value
                      ? "border-[#005CFF] !text-[#005CFF]"
                      : "border-transparent"
                  } font-poppins text-base font-medium text-[#989898] pb-2 border-b-2  m-[-1px]`}
                >
                  {t(name)}
                </button>
              ))}
            </div>
            <>
              {selectedTab === "Transcript" && summaryData?.transcript && (
                <TranscriptTab TranscriptData={summaryData?.transcript} />
              )}
              {selectedTab === "EHR Summary" && summaryData?.report && (
                <EhrSummaryTab data={summaryData?.report} />
              )}
              {selectedTab === "ICD Code Breakdown" && summaryData?.icd10 && (
                <IcdTab IcdData={summaryData?.icd10} />
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewMedicalScribingV1;
