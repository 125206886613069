import React from 'react';

const InitialRecording = ({
  handleFileChange,
  handleButtonClick,
  isRecording,
}) => {
  return (
    <div className="flex items-center justify-center rounded-lg border-2 border-dashed border-blue-500 px-2 py-16 transition-all md:px-10">
      <input
        type="file"
        accept="audio/mp3"
        onChange={handleFileChange}
        className="hidden"
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className={`flex items-center justify-center cursor-pointer`}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <button
            type="button"
            onClick={handleButtonClick}
            className="focus:outline-none"
          >
            <img src="/images/micIcon.png" alt="mic-img" className="size-20" />
          </button>

          <p className="font-poppins text-base font-semibold leading-6 text-blackDark-100">
            {isRecording ? 'Recording...' : 'Start recording, or '}
            <span className="text-blue-500">Upload an MP3 file</span>
          </p>
        </div>
      </label>
    </div>
  );
};

export default InitialRecording;
