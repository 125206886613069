import React, { useState } from 'react';
import { FiSearch, FiChevronDown, FiChevronUp } from 'react-icons/fi';

const faqs = [
  {
    question: 'How do I integrate the AI with my system?',
    answer:
      'To integrate the AI system, you can use our API documentation to connect it to your existing platform. Start by creating an API key in the dashboard.',
  },
  {
    question: 'What are the pricing options?',
    answer:
      'Our pricing options depend on the level of usage. Please visit the pricing page for more detailed information on the packages available.',
  },
];

const Help = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto py-12 px-6">
        {/* Header */}
        <h1 className="text-2xl sm:text-4xl font-bold text-center mb-8">
          Help & Support
        </h1>

        {/* Search Bar */}
        <div className="relative mx-auto max-w-lg">
          <input
            type="text"
            className="w-full rounded-full border outline-none dark:bg-gray-700 dark:border-gray-500 border-gray-300 py-3 px-5 pl-10 text-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="Search for help topics..."
          />
          <FiSearch className="absolute left-4 top-3 text-gray-400" size={20} />
        </div>

        {/* FAQ Section */}
        <div className="mt-12">
          <h2 className="sm:text-2xl text-xl font-semibold mb-6">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-4"
              >
                <button
                  onClick={() => toggleFaq(index)}
                  className="flex items-center justify-between w-full text-left font-medium sm:text-lg text-base"
                >
                  <span>{faq.question}</span>
                  {openFaq === index ? (
                    <FiChevronUp size={20} />
                  ) : (
                    <FiChevronDown size={20} />
                  )}
                </button>
                {openFaq === index && (
                  <div className="mt-4 text-gray-700 dark:text-gray-300 text-sm">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
