import React from 'react';
import AiStarIcon from '../../assets/icons/AiStarIcon';
import DownIcon from '../../assets/icons/DownIcon';

const Performance = () => {
  return (
    <div className="w-full flex flex-col gap-4">
      <h3 className="text-black font-semibold font-poppins dark:text-white text-lg leading-6">
        Performance
      </h3>

      <div className="flex gap-2">
        <span>
          <AiStarIcon />
        </span>

        <div className="flex items-center justify-between gap-4 w-full">
          <div className="max-w-[838px]">
            <p className="text-[#252525] dark:text-white text-base font-normal leading-6">
              <span className="font-semibold">Performance</span> is seeing an
              unusual spike, with{' '}
              <span className="font-semibold underline">Appliance Sales</span>{' '}
              and <span className="font-semibold underline">Campaign ROl</span>{' '}
              steadily increasing. Of the 12 metrics under Performance, 2 are
              unusual.
            </p>
            <p className="text-[#A1A1A1] text-base font-normal leading-6">
              Last updated 5 minutes ago
            </p>
          </div>

          <button>
            <DownIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Performance;
