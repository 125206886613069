import React, { useContext } from 'react';
import LanguageSelector from '../LanguageSelector';
import { motion } from 'framer-motion';
import { ThemeContext } from '../ThemeProvider';
import { Moon, Sun } from 'react-feather';

const ThemeToggle = ({ selectedLanguage, setSelectedLanguage }) => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <div className={`flex gap-2 items-center`}>
      <LanguageSelector
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />

      <div
        className={`relative flex items-center bg-[#EFEFEF] shadow-shadow-box dark:bg-gray-500 rounded-full cursor-pointer w-11 h-5`}
        onClick={toggleDarkMode}
      >
        <motion.div
          className={`absolute flex items-center justify-center rounded-full bg-[#FFFFFF] dark:bg-gradient-to-b dark:from-gray-500 dark:to-[#000] w-4 h-4`}
          animate={{ x: isDarkMode ? 24 : 2 }}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        >
          {isDarkMode ? (
            <Moon size={12} className="text-white" />
          ) : (
            <Sun size={12} className="text-gray-800" />
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default ThemeToggle;
