import React, { useState } from "react";
import AllNotesHeader from "./AllNotesHeader";
import AllNotesSearch from "./AllNotesSearch";
import UserIcon from "../../assets/icons/UserIcon";
import NotesDetails from "./NotesDetails";
import PatientsProfile from "../AllNotePatients/PatientsProfile";
import StarFillButton from "../StarFillButton";

const RecentSessionNotesData = [
  {
    id: 1,
    title: "Back Pain Check-Up",
    username: "John Jones",
    count: "8/08",
    description: `Mr. Jones visits for his recurring back pain, which originated from a workplace injury three years ago. While Mr. Jones previously underwent physical therapy and took tramadol, he discontinued these treatments due to cost concerns, insurance limitations, and scheduling difficulties. Dr. Smith suggests more affordable alternatives, including using a heating pad for immediate relief and exploring yoga or tai chi classes as long-term solutions instead of medication. The doctor acknowledges the patient's financial constraints and schedules a follow-up phone call for the following Tuesday to discuss researched treatment options, while also recommending that Mr. Jones review his insurance options during open season.`,
  },
  {
    id: 2,
    title: "Headaches 9/23",
    username: "Pat Bellamy",
    count: "9/23",
    description: `Ms. Bellamy visits with complaints of a severe headache that started three days ago. The headache is described as a constant 10/10 pain affecting her entire head, made worse by movement and light, with associated neck stiffness and nausea. Pat is particularly concerned because of a neighbor who had a brain tumor, and she's also worried about insurance coverage since she recently changed insurance companies. Her medical history includes controlled high blood pressure, and she smokes half a pack of cigarettes daily. She recently attended a family reunion in North Carolina where a sick child was present. The doctor takes a thorough history, including family history noting her mother's history of migraines, and plans to conduct a physical exam and arrange insurance verification while addressing her concerns.`,
  },
  {
    id: 3,
    title: "Diarrhea Complaints",
    username: "Alexis Gupta",
    count: "9/23",
    description: `Mrs. Gupta visits with complaints of diarrhea lasting 2-3 weeks, occurring up to 8 times daily including at night, with recent onset of blood in the stool and crampy abdominal pain. The patient is particularly concerned about bowel cancer due to a friend's sister's diagnosis at age 30, and worried about potentially spreading an infection to her students. Her medical history includes an appendectomy in her teens, penicillin allergy, and she currently takes oral contraceptives and drinks alcohol on weekends. The doctor plans to run blood tests and collect stool samples, scheduling a follow-up appointment for the following week, with the possibility of a specialist referral if symptoms persist. Additional health concerns to be addressed include the patient's alcohol consumption (approximately 20 units/week) and recent weight loss noted through looser-fitting clothes.`,
  },
];

const AllNotesData = [
  {
    id: 1,
    title: "Back Pain Check-Up",
    username: "John Jones",
    count: "8/08",
    description: `Mr. Jones visits for his recurring back pain, which originated from a workplace injury three years ago. While Mr. Jones previously underwent physical therapy and took tramadol, he discontinued these treatments due to cost concerns, insurance limitations, and scheduling difficulties. Dr. Smith suggests more affordable alternatives, including using a heating pad for immediate relief and exploring yoga or tai chi classes as long-term solutions instead of medication. The doctor acknowledges the patient's financial constraints and schedules a follow-up phone call for the following Tuesday to discuss researched treatment options, while also recommending that Mr. Jones review his insurance options during open season.`,
  },
  {
    id: 2,
    title: "Headaches 9/23",
    username: "Pat Bellamy",
    count: "9/23",
    description: `Ms. Bellamy visits with complaints of a severe headache that started three days ago. The headache is described as a constant 10/10 pain affecting her entire head, made worse by movement and light, with associated neck stiffness and nausea. Pat is particularly concerned because of a neighbor who had a brain tumor, and she's also worried about insurance coverage since she recently changed insurance companies. Her medical history includes controlled high blood pressure, and she smokes half a pack of cigarettes daily. She recently attended a family reunion in North Carolina where a sick child was present. The doctor takes a thorough history, including family history noting her mother's history of migraines, and plans to conduct a physical exam and arrange insurance verification while addressing her concerns.`,
  },
  {
    id: 3,
    title: "Diarrhea Complaints",
    username: "Alexis Gupta",
    count: "9/23",
    description: `Mrs. Gupta visits with complaints of diarrhea lasting 2-3 weeks, occurring up to 8 times daily including at night, with recent onset of blood in the stool and crampy abdominal pain. The patient is particularly concerned about bowel cancer due to a friend's sister's diagnosis at age 30, and worried about potentially spreading an infection to her students. Her medical history includes an appendectomy in her teens, penicillin allergy, and she currently takes oral contraceptives and drinks alcohol on weekends. The doctor plans to run blood tests and collect stool samples, scheduling a follow-up appointment for the following week, with the possibility of a specialist referral if symptoms persist. Additional health concerns to be addressed include the patient's alcohol consumption (approximately 20 units/week) and recent weight loss noted through looser-fitting clothes.`,
  },
];

const AllNotes = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] ovey lg:h-screen w-full bg-[#FCFCFC] dark:bg-gray-900 overflow-y-hidden">
      <AllNotesHeader
        title={"All Notes"}
        SoundbiteName={isShowProfile ? "Profile Info" : "Soundbite Name"}
        isShowDetails={isShowDetails || isShowProfile}
        setIsShowDetails={isShowProfile ? setIsShowProfile : setIsShowDetails}
      />

      {!isShowDetails && !isShowProfile && (
        <>
          <div className="max-h-[calc(100%-60px)] w-full overflow-y-auto h-full py-6 px-9">
            <AllNotesSearch />

            <div className="py-6 flex flex-col gap-4 items-start relative">
              <h2 className="text-black dark:text-white text-xl font-medium tracking-[-0.4px]">
                Recent Session Notes
              </h2>

              <div className="relative grid md:grid-cols-3 sm:grid-cols-2 gap-2 w-full">
                {RecentSessionNotesData?.map(
                  ({ count, description, id, title, username }, index) => (
                    <button
                      key={id}
                      onClick={() => {
                        setIsShowDetails(true);
                        setActiveIndex(index);
                      }}
                      className="p-6 w-full flex items-start flex-col gap-2.5 border dark:border-gray-600 border-gray-10 rounded-2xl"
                    >
                      <div className="flex items-center justify-between w-full">
                        <h2 className="text-black dark:text-white text-base font-medium tracking-[-0.32px]">
                          {title}
                        </h2>

                        <StarFillButton isFillBtn={false} />
                      </div>

                      <div className="flex items-center justify-between w-full">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsShowProfile(true);
                            setActiveIndex(index);
                          }}
                          className="flex text-blackDark-10 dark:text-white text-xs font-medium gap-1 items-center tracking-[-0.24px]"
                        >
                          <UserIcon />
                          {username}
                        </span>

                        <span className="text-xs font-medium text-gray-500 tracking-[-0.24px]">
                          {count}
                        </span>
                      </div>

                      <p className="text-sm line-clamp-4 text-start font-normal tracking-[-0.28px] text-blackDark-10 dark:text-white">
                        {description}
                      </p>
                    </button>
                  )
                )}
              </div>
            </div>

            <div className="py-6 flex flex-col gap-4 items-start">
              <h2 className="text-black dark:text-white text-xl font-medium tracking-[-0.4px]">
                All Notes
              </h2>

              <div className="relative grid md:grid-cols-3 sm:grid-cols-2 gap-2 w-full">
                {AllNotesData?.map(
                  ({ count, description, id, title, username }, index) => (
                    <button
                      key={id}
                      onClick={() => {
                        setIsShowDetails(true);
                        setActiveIndex(index);
                      }}
                      className="p-6 w-full flex items-start flex-col gap-2.5 border dark:border-gray-600 border-gray-10 rounded-2xl"
                    >
                      <div className="flex items-center justify-between w-full">
                        <h2 className="text-black dark:text-white text-base font-medium tracking-[-0.32px]">
                          {title}
                        </h2>

                        <StarFillButton isFillBtn={false} />
                      </div>

                      <div className="flex items-center justify-between w-full">
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsShowProfile(true);
                            setActiveIndex(index);
                          }}
                          className="flex text-blackDark-10 dark:text-white text-xs font-medium gap-1 items-center tracking-[-0.24px]"
                        >
                          <UserIcon />
                          {username}
                        </span>

                        <span className="text-xs font-medium text-gray-500 tracking-[-0.24px]">
                          {count}
                        </span>
                      </div>

                      <p className="text-sm line-clamp-4 text-start font-normal tracking-[-0.28px] text-blackDark-10 dark:text-white">
                        {description}
                      </p>
                    </button>
                  )
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {isShowProfile && (
        <div className="max-h-[calc(100%-60px)] w-full overflow-y-auto h-full">
          <PatientsProfile activeIndex={activeIndex} />
        </div>
      )}

      {!isShowProfile && isShowDetails && (
        <NotesDetails activeIndex={activeIndex} />
      )}
    </div>
  );
};

export default AllNotes;
