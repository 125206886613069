// useNL2SQLStore.js
import useAuthStore from '../stores/authStore.js';

import { create } from 'zustand';
import { toast } from 'react-toastify';
import axios from 'axios';

const dummyCSVData = {
  'customers.csv': [
    { id: 1, name: 'John Doe', email: 'john@example.com' },
    { id: 2, name: 'Jane Smith', email: 'jane@example.com' },
    { id: 3, name: 'Bob Johnson', email: 'bob@example.com' },
  ],
  'orders.csv': [
    { id: 1, customer_id: 1, product: 'Widget A', quantity: 2 },
    { id: 2, customer_id: 2, product: 'Widget B', quantity: 1 },
    { id: 3, customer_id: 3, product: 'Widget C', quantity: 3 },
  ],
  'products.csv': [
    { id: 1, name: 'Widget A', price: 9.99 },
    { id: 2, name: 'Widget B', price: 14.99 },
    { id: 3, name: 'Widget C', price: 19.99 },
  ],
};

const useNL2SQLStore = create((set, get) => ({
  selectedFolder: '',
  folderName: '',
  selectedFiles: [],
  folderOptions: [],
  fileOptions: [],
  csvData: {},
  chatHistory: [],
  isLoading: false,
  error: null,
  isFolderLoading: false,
  isFileLoading: false,
  isUploadLoading: false,
  isQueryLoading: false,
  isPreviewLoading: false,
  csvPaths: [],

  fetchCSVOptions: async () => {
    set({ isLoading: true, error: null });

    setTimeout(() => {
      set({
        csvOptions: Object.keys(dummyCSVData),
        isLoading: false,
      });
    }, 500);
  },

  handleCSVSelect: (csv) => {
    set({
      selectedCSV: csv,
      csvData: dummyCSVData[csv] || [],
      chatHistory: [],
    });
  },

  previewCSV: async (files) => {
    set({ isPreviewLoading: true, error: null });
    const { user } = useAuthStore.getState();
    const companyFolder = user?.companyFolder || 'gen-test';

    try {
      console.log('selected folder', get().selectedFolder);

      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/csv-query/preview-csv`,
        {
          companyFolder: companyFolder,
          csvFile: files,
          path: get().selectedFolder,
        }
      );

      const csvData = response.data.preview_response;
      const csvPaths = Object.values(response.data.path);
      set({ csvData, csvPaths, isPreviewLoading: false });
    } catch (error) {
      console.error('Error previewing CSV:', error);
      set({ error: 'Failed to preview CSV.', isPreviewLoading: false });
    }
  },

  fetchFolderOptions: async (companyFolder) => {
    set({ isFolderLoading: true, error: null });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/csv-query/show-folders`,
        { companyFolder }
      );
      console.log('response', response);
      const folders = response.data.folders;
      set({ folderOptions: folders, isFolderLoading: false });
      if (folders.length > 0 && !get().selectedFolder) {
        get().handleFolderSelect(folders[0]);
      }
    } catch (error) {
      console.error('Error fetching folder options:', error);
      set({ error: 'Failed to fetch folder options.', isFolderLoading: false });
    }
  },

  fetchFileOptions: async (companyFolder, selectedFolder) => {
    console.log('company folder selec folder', companyFolder, selectedFolder);

    set({ isFileLoading: true, error: null });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/csv-query/show-files`,
        { companyFolder, selectedFolder }
      );
      console.log('response', response);
      set({ fileOptions: response.data.files, isFileLoading: false });
    } catch (error) {
      console.error('Error fetching file options:', error);
      set({ error: 'Failed to fetch file options.', isFileLoading: false });
    }
  },

  handleFolderSelect: (folder) => {
    const { user } = useAuthStore.getState();
    const companyFolder = user?.companyFolder || 'gen-test';

    set({
      selectedFolder: folder,
      selectedFiles: [],
      csvData: {},
      csvPaths: [],
    });
    // TODO: get the company folder name dynamically from the user.
    get().fetchFileOptions(companyFolder, folder);
  },

  handleFileSelect: (selectedOptions) => {
    const TOTAL_CSVS = 4;
    let newSelectedFiles;

    if (selectedOptions.length > TOTAL_CSVS) {
      newSelectedFiles = selectedOptions.slice(0, TOTAL_CSVS);
      toast.warn('You can select a maximum of 3 files.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      newSelectedFiles = selectedOptions;
    }

    set({ selectedFiles: newSelectedFiles.map((option) => option.value) });
    get().previewCSV(newSelectedFiles.map((option) => option.value));
  },

  uploadCSV: async (file, folder) => {
    set({ isUploadLoading: true, error: null });
    const { user } = useAuthStore.getState();
    const companyFolder = user?.companyFolder || 'gen-test';
    try {
      const formData = new FormData();

      formData.append('file', file);

      formData.append('companyFolder', companyFolder);
      formData.append('selectedFolder', folder);

      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/csv-query/upload-csv`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      console.log('response', response);

      if (response.status === 200) {
        set((state) => ({
          isUploadLoading: false,
        }));
        toast.success('CSV file uploaded successfully!');

        get().fetchFileOptions(companyFolder, get().selectedFolder);
      } else {
        toast.error('CSV file upload failed.');
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading CSV:', error);
      set({ error: 'Failed to upload CSV.', isUploadLoading: false });
      toast.error('Error uploading CSV file.');
    }
  },

  createFolder: async () => {
    set({ isFolderLoading: true, error: null });
    const { user } = useAuthStore.getState();
    const companyFolder = user?.companyFolder || 'gen-test';

    console.log('HerE???');
    const selectedFolder = !!get().selectedFolder;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/csv-query/create-folder`,
        {
          companyFolder: companyFolder,
          selectedFolder: selectedFolder ? get().selectedFolder : '',
          folderName: get().folderName,
        }
      );

      console.log('response', response);

      set((state) => ({
        folderName: '',
        isFolderLoading: false,
      }));
      setTimeout(() => {
        get().fetchFolderOptions(companyFolder);
        toast.success('Folder created successfully!');
      }, 500);
    } catch (error) {
      console.error('Error creating folder:', error);
      set({
        error: `Failed to create folder.${error} `,
        isFolderLoading: false,
      });
    }
  },

  setNewFolderName: (folderName) => {
    set({ folderName: folderName });
  },

  handleQuery: async (query) => {
    set((state) => ({
      chatHistory: [...state.chatHistory, { type: 'user', text: query }],
      isQueryLoading: true,
      error: null,
    }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/csv-query/pandas-agent`,
        {
          csvList: get().csvPaths,
          query: query,
        }
      );
      set((state) => ({
        chatHistory: [
          ...state.chatHistory,
          { type: 'ai', text: response.data.answer },
        ],
        isQueryLoading: false,
      }));
    } catch (error) {
      console.error('Error querying CSV:', error);
      set((state) => ({
        chatHistory: [
          ...state.chatHistory,
          {
            type: 'ai',
            text: 'An error occurred while processing your query. Please try again.',
          },
        ],
        error: 'Failed to query CSV data.',
        isQueryLoading: false,
      }));
    }
  },

  resetState: () =>
    set({
      selectedFolder: '',
      selectedFiles: [],
      folderOptions: [],
      fileOptions: [],
      csvData: {},
      chatHistory: [],
      error: null,
      folderName: '',
      isFolderLoading: false,
      isFileLoading: false,
      isUploadLoading: false,
      isQueryLoading: false,
      isPreviewLoading: false,
    }),

  refreshCSVOptions: () => {
    get().fetchCSVOptions();
  },
}));

export default useNL2SQLStore;
