import React from 'react';
import { IoClose } from 'react-icons/io5';
import { RiFileCopy2Line } from 'react-icons/ri';
import ReloadIcon from '../../assets/icons/ReloadIcon';
import { MdOutlineSaveAlt } from 'react-icons/md';

const ScribingTranscriptTab = () => {
  return (
    <>
      <div className="relative border overflow-y-hidden flex items-center border-gray-10 py-6 rounded-xl">
        <div className="text-[#737373] px-6 pr-14 min-h-[300px] max-h-[548px] overflow-y-auto helpdesk-custom-scrollbar w-full text-base font-normal font-poppins tracking-[-0.08px]">
          Your transcript will be generated here.
        </div>

        <div className="h-full absolute py-6 right-6 flex justify-between flex-col">
          <button>
            <IoClose size={25} className="text-[#737373]" />
          </button>
          <button>
            <RiFileCopy2Line size={25} className="text-[#737373]" />
          </button>
        </div>
      </div>

      <div className="flex items-center w-full justify-between">
        <button className="flex gap-2 py-2 px-4  text-white bg-blue-20 rounded-lg font-medium">
          <ReloadIcon />
          Reload transcript
        </button>

        <button className="flex gap-2 py-2 px-4  text-white bg-blue-20 rounded-lg font-medium">
          <MdOutlineSaveAlt size={24} />
        </button>
      </div>
    </>
  );
};

export default ScribingTranscriptTab;
