import React from 'react';

const AiStarIcon = () => {
  return (
    <svg
      width="18"
      height="26"
      viewBox="0 0 18 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6737 6.77819C13.3964 6.07394 12.4024 6.07394 12.1251 6.77819L11.6864 7.89194L10.5754 8.33069C9.87286 8.60856 9.87286 9.60531 10.5754 9.88319L11.6869 10.3219L12.1251 11.4357C12.4019 12.1399 13.3964 12.1399 13.6737 11.4357L14.1119 10.3219L15.2228 9.88319C15.9259 9.60531 15.9259 8.60856 15.2228 8.33069L14.1119 7.89194L13.6737 6.77819ZM7.87373 9.10244C7.50417 8.16306 6.17836 8.16306 5.80936 9.10244L5.00105 11.1556L2.95242 11.9656C2.01586 12.3357 2.01586 13.6643 2.95242 14.035L5.00105 14.8444L5.8088 16.8976C6.17836 17.8369 7.50417 17.8369 7.87373 16.8976L8.68205 14.8444L10.7301 14.0344C11.6672 13.6643 11.6672 12.3357 10.7301 11.9656L8.68148 11.1556L7.87373 9.10244ZM14.1119 15.6775L13.6731 14.5638C13.3964 13.8601 12.4019 13.8601 12.1251 14.5638L11.6864 15.6775L10.5754 16.1168C9.87286 16.3947 9.87286 17.3914 10.5754 17.6693L11.6869 18.1081L12.1251 19.2218C12.4019 19.9261 13.3964 19.9261 13.6737 19.2218L14.1119 18.1081L15.2228 17.6693C15.9259 17.3914 15.9259 16.3947 15.2228 16.1168L14.1119 15.6775Z"
        fill="#377FFF"
      />
    </svg>
  );
};

export default AiStarIcon;
