import React from "react";

const Epic = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="120px"
      height="120px"
      viewBox="0 0 60 60"
      enableBackground="new 0 0 60 60"
      xmlSpace="preserve"
    >
      {" "}
      <image
        id="image0"
        width="60"
        height="60"
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOAAAADgCAMAAAAt85rTAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAADGUExURf////3299Z3hL8sPsU4TbYAG/LM0/Tg4sRPWbAA
ALIAEfzz9f74+vDQ1bssOLgOJboSK7QAFMAhOcE1RcRKV70/R9mAjee6v7IABrIADcxcavDGzawA
AOGWodWMkeSqseGiqsdUYLUAGPPY3NRwfrUIHbMAAPnr7bweM8tga7cvNLkPKOmwurQYIeSzuLcC
IdWCi8RZX81ocvzw8+Cjqt+SnsM/Trg1ObYpLsxSY8I6StJ7hLkgLsx1eshDVtFmdcdna9KHizhw
4DoAAAABYktHRACIBR1IAAAAB3RJTUUH6AkRCRwFSrrzmQAACU5JREFUeNrtmwF3oroSgLWKNIgN
VKykoigK2KUVV9u+Wrt17///U0+03S2ZRBDq3bd9852ew55tSTKZZDIzGSoVBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEFOQ/WsVk+jNP70mD4V9ZxoKQhpnqIf
vQxlOm5dUCOFeXkS+dpWYTpXZXru2pyA7PoUAvYcpTBOv8zMDlhaPoO4pxBwSIzCkDJGoTny0q1R
bXwKASfFBfSDMh2HUz/dnFILTyHgyCwsoFYr0/GY34LmzSmM6PhbVFhA+6xMz31+6bBBKaMs4fau
sHwGmZXp2SJ+Cs/unkC+ypAVli821TI914xaist26xQCzosL6E9LGYUQcAr5wu9xYQHNxUk8q8+l
dVFcQDb806PPQc+jgqHvDCtNHpH83xHLNAp6NWyWtYzLcWPm3vf7/YfBQ384vL1tjI9ZOLMAnhK2
k4+LQ46HOptPrkfWo2WNbq76Bc1HeHs1efzP051p2sQkjBGb+f7TN+v66naZr4XmAh7z2lWrkQt3
xdFuvE/6ZEqc7Wi8xGG1NeI4Zv0hTOlS5V9eTaop5S97EyNwCFMiXgVUIcQJLodhNVvApeUBAe/y
Hkddm6UgZKfSsWsFxORWvq+ReP5Rj/eMw/n+UXWNwXNgmwd8kMgk03X2SEPq82/G/8np8uoDO/2m
8rw19Eu3w4T72vDJy/r3wdkHocTD72m/vaG2l+lh+UwZZJ3E4wCMRTnPqcDmDbe8zUW10jhnsXRk
lHR6by9Xf/ACOu+/2rbhmdTIAyWPGdqYw1CCTHIKyEcDUTDbtqcdHJFH7t9mtsMdT3HwtkWbK+dw
G2l1ZLiL19CPIXmDWDVIj5G+zOZO1szTt6C28cL9pdber/tZjeTT3hs+6R0apAGNqNPLlm2Hy2k/
qr1m75uthDtTM7O5P7X3C2dwd2z0prQPrNIwAH5MFOR1CSc2/2qcJ/Iyz5uCdEW01+yKHe9Y2Wu5
LzFzYP+5M1u1I7bKR8hAcDzRJDKpWrLsQkQpjSTTRwP5afEAG2SbSqZztfsDPfCNQsSdcSU0uJeT
yKQJ1sReAMVkzDZtb+s4CPVrr6Un/gYKGL8O5lmsEz+pJ8/lRJ6mMU1TJL+13UorgOkKfc4EOopZ
0L4ZuN1er3u/3noQAgvkG7JdtXz0BC0SzzwM2TXYh8v77X0niOqvq3pdIY4w2DRvdJCusAeVGYH6
iQhZ9ZbvDnt1qW4COGQayOxi48kogrdLDm1EGowUv332yxEe9y1NMIX+tLnhJKesoTtwycfehHdV
Wh1oZslcIqBbbBex5KSsWoIVGNnWfWpE+r1gPLGt1jm5t/tyATegSQWneAs6gvZGImC/UEp0HweC
i4VkmGQNfMPWBdRh/HDJ+zGL2yfQnlkTmkdoGWUpf+gP5oJeJEf1DGbjYjIUGOCGA0xH1AZtbr5D
r5+Kzb9KtrGhphGPMS95mowYYgGBP5gP/zxRUx+sKKqJrxZWcBphv3XKKzDyJcdbczK6ThjtfhLO
JflZ0SrLgZZ4DtUfYOWxufgAdYMcjVKg5nJZ5R29nEEJhzlM1sk5rwXvWXIaqQXcr+Teo3wWcUgK
Ze13EX+LjwYM51bSTRgXMdZkUVo+ELDmI9pF/F3giiiyfkKjwEKhdvmrtLAGT7Io+9LTtrbv6sDG
EOllbyENelbOtNkBVLgFqfHcfqw9P9ce29Ln9EGk/SiQZgYFaZFsZBbrGLpwC9qjZphJ4rqHbU77
/qXUJhS5Qo5YqYurPWeCfEzeooIxHykfWFKvBdwJ6ufNKxygDgNWqSUEWuHPNnMizadrBbZg/BlX
aQx0HJG8zW54G6P9kAWdt05OoVICdj7BiMKO8xcVXPInjPwqrVANwGcI6JbJxwDD6FkSAYeFDtu9
Qy9Cn4BLjZV4Mq6E+Zh8wAgh1sRJdLXjZYsDHarIlBmZew1caizE9m0EbcyH64HDCAJJ8bv6DfS0
4alYn4L/0iQVF2qH907onXgu1Gfgx0RBXtssKB6KiWgeLRimA8+bTicwdeJPhUtevwFqISPxGHuC
Rg212syDbgniA1bjF2m1NxVoerPidmX8vdeBWhU629U2aDAmEg/DhZs/mt4scjEyRLCa+1HEZe9M
E4S6bTXmpFF+Ls8FXrHjgoOnZQHnK5JepQwE/kWUlTB8Q3IF4fnnw25L1Sv6sjXr/4wZ1EsUuCpv
vc155UYwmFhbpy1pa3AhSKj9lFjv8KckLVuGyGR33x6tkfX4dGcLb0GdnzrIGdn9yoPIGYi8i8mv
akbV3TyZcGPEL7LjpGA+JoeQiqKZsskzOyrMp95t/WoiHA1NruJXm9XqNSKOyGWn8uv2Bi2UriiH
QluVkJ9Z+m18oKYzNpOjThNvCnrg9nPGihcZFpZP6Qqy6cr59sQbvxRYUDS5p5KgD4qXqBXF3OU5
Xd6zITv36f74/FD8fh0uQlQ+UoIcg4uczs4eDPjF6Oyyqfo/znESRuziUBEJuJ8rBTUzBxcH33cu
cbgAGtxbyuU6836fa+9gaFcoTyLtzOpnDc72+qHYev8K0ZprknvSI/Lengy3RKk9wFzoC+eQkTCd
x/fpBvnU33Xfumvni423p8cqKzK/tqOI7n6OfkJdJbXWwxcmVmIUM8/6nQmZERql2iMfMvTh1R1R
Dq+FiJrkYpKdeJgGmsdMk3ns2CcD8cH+Oq2x8IkPL1BM+2U9++1UVs9Iur0gfZT1/rF84vkSIWOF
sYvFME+w3y1Oj//cge7dpWXvhxEQzYv39yiUmhoJjGu39TGw0xugPS7KUhuDEQ0I00y6ZVejun3G
psZI4LXXbuskhc8p+Izhr/xJNeyd1eOAJLWkJAimr2e9sEDRs74Mu/NVPbaDIEia2j4CNq1v5jM1
TwFlaUBtDUtXcejjpJb0MyY6VBu7tlS1fBr/CETRwJdCGA18IWBtzdPX+i4V5HI8608P6XMBuRyS
N5n6lwA/2xz+6SF9KkuQy8ldQPt3IK+1/iKAXM5brfWXAeRy7LxF+n8H1bm41vrLAGutlVJfgf7P
EcYs/eEta58+fPk3aXnp725rxuYv+Ar0CKqgauZfjWQQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQ
BEEQBEEQBEEQBEEQBEEQBEEQBEEQ5P+K/wJ5BZUWOvQhOwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAy
NC0wOS0xN1QwOToyODowNSswMDowMGo3h2MAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDktMTdU
MDk6Mjg6MDUrMDA6MDAbaj/fAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI0LTA5LTE3VDA5OjI4
OjA1KzAwOjAwTH8eAAAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default Epic;
