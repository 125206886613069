import React, { useState } from 'react';

import AllNotesHeader from '../AllNotes/AllNotesHeader';
import AllNotesSearch from '../AllNotes/AllNotesSearch';
import PatientsTable from './PatientsTable';
import PatientsProfile from './PatientsProfile';

const PatientsTableData = [
  {
    pid: '100001',
    firstName: 'John',
    lastName: 'Jones',
    dob: '04/30/1968',
    gender: 'M',
    status: 'Open',
    email: 'johnjones@gmail.com',
  },
  {
    pid: '100002',
    firstName: 'Pat',
    lastName: 'Bellamy',
    dob: '03/06/1975',
    gender: 'F',
    status: 'Open',
    email: 'patty323@gmail.com',
  },
  {
    pid: '100003',
    firstName: 'Alexis',
    lastName: 'Gupta',
    dob: '04/30/1999',
    gender: 'F',
    status: 'Open',
    email: 'lexisgupta@gmail.com',
  },
];

const AllNotePatients = () => {
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] ovey lg:h-screen w-full bg-[#FCFCFC] dark:bg-gray-900 overflow-y-hidden">
      <AllNotesHeader title={'Patients'} />

      <div className="max-h-[calc(100%-60px)] w-full overflow-y-auto h-full ">
        {!isShowProfile && (
          <>
            <div className="px-9 py-6">
              <AllNotesSearch />
            </div>

            <div className="py-6 px-9 overflow-x-auto w-full">
              <PatientsTable
                setIsShowProfile={setIsShowProfile}
                data={PatientsTableData}
                setActiveIndex={setActiveIndex}
              />
            </div>
          </>
        )}

        {isShowProfile && <PatientsProfile activeIndex={activeIndex} />}
      </div>
    </div>
  );
};

export default AllNotePatients;
