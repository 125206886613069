import React from "react";

const aiProducts = [
  {
    id: 1,
    name: "OpenAI GPT-4",
    category: "AI-powered Language Model",
    image:
      "https://hypeinsight.com/wp-content/uploads/2023/04/How-to-get-the-very-best-results-and-usage-out-of-GPT-4-by-OpenAI-.png",
    description:
      "GPT-4 is a state-of-the-art language model that can generate human-like text. It powers applications like ChatGPT, virtual assistants, and content generation tools.",
    useCases:
      "Writing assistants, customer service automation, chatbots, content generation.",
    price: "API usage-based pricing",
    recommendation:
      "Ideal for businesses automating writing tasks or developing conversational AI interfaces.",
  },

  {
    id: 2,
    name: "Google Cloud AI Platform",
    category: "Cloud AI Services",
    image:
      "https://miro.medium.com/v2/resize:fit:1000/1*hrP5wvrcWqTN_kBWeP36MQ.jpeg",
    description:
      "Google Cloud AI provides an array of AI services like pre-trained models for vision, language, and structured data, along with customizable ML training tools.",
    useCases:
      "Image recognition, natural language processing, and custom ML model deployment.",
    price: "Pay-as-you-go pricing",
    recommendation:
      "Ideal for businesses and developers leveraging Google’s powerful AI infrastructure.",
  },
];

const AIProductCard = ({ product }) => {
  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-4">
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-48 object-cover rounded-lg"
      />
      <h3 className="mt-4 text-lg font-semibold text-gray-800">
        {product.name}
      </h3>
      <p className="mt-1 text-gray-600">
        <strong>Category:</strong> {product.category}
      </p>
      <p className="mt-1 text-gray-600">{product.description}</p>
      <p className="mt-1 text-gray-600">
        <strong>Use Cases:</strong> {product.useCases}
      </p>
      <div className="flex items-center justify-between mt-2">
        <span className="text-gray-600">
          <strong>Price:</strong> {product.price}
        </span>
      </div>
      <p className="mt-2 text-blue-600 font-semibold">
        {product.recommendation}
      </p>
    </div>
  );
};
const ProductSummarizer = () => {
  return (
    <main className="mx-auto p-6 w-full">
      <section className="my-12 text-center">
        <h2 className="text-3xl font-bold text-gray-800">Top AI Products</h2>
      </section>

      <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {aiProducts.map((product) => (
          <AIProductCard key={product.id} product={product} />
        ))}
      </section>
    </main>
  );
};

export default ProductSummarizer;
