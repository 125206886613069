import React from 'react';
import ExpandedIcon from '../../assets/icons/ExpandedIcon';
import { NavLink, useLocation } from 'react-router-dom';
import { FiPlusCircle } from 'react-icons/fi';
import { HiMenuAlt1 } from 'react-icons/hi';

const LeftPanelNotebook = ({
  selectedCategory,
  categories,
  expandedSections,
  setExpandedSections,
  setOpenMenu,
  setFileModelOpen,
}) => {
  const location = useLocation();

  return (
    <>
      {!expandedSections?.Tools && (
        <div className="h-screen !bg-[#FCFCFC] dark:!bg-gray-900 hidden overflow-hidden lg:flex items-start pt-5 pl-1">
          <button
            onClick={() => {
              setExpandedSections((prev) => ({
                ...prev,
                [`Tools`]: true,
                [`Objects`]: true,
              }));
              setOpenMenu(false);
            }}
            className="rotate-180"
          >
            <HiMenuAlt1 size={24} className="rotate-180" />
          </button>
        </div>
      )}

      {expandedSections?.Tools && (
        <div className="min-w-[200px] max-w-fit relative h-screen overflow-hidden bg-[#F0F1F1] dark:border-gray-600 dark:bg-gray-800 border-r border-gray-10">
          <div className="absolute top-0 bg-[#F0F1F1] dark:bg-gray-800 w-full">
            <div className="flex items-center justify-between px-4 py-5">
              <h3 className="text-xl font-medium ">Notebook</h3>
              <button
                onClick={() => {
                  setExpandedSections((prev) => ({
                    ...prev,
                    [`Tools`]: false,
                    [`Objects`]: true,
                  }));
                }}
              >
                <ExpandedIcon />
              </button>
            </div>

            <div className="px-3 py-2">
              <button
                onClick={() => setFileModelOpen(true)}
                className="flex whitespace-nowrap rounded-lg px-2 gap-2 items-center text-sm text-white font-medium bg-blue-20 hover:bg-blue-45 duration-300 py-2.5"
              >
                <FiPlusCircle size={20} />
                Create a new note
              </button>
            </div>
          </div>

          <div className=" h-[calc(100vh-140px)] mt-[140px] pb-6 overflow-y-auto helpdesk-custom-scrollbar">
            {categories[selectedCategory].map((item, index) => (
              <div key={index}>
                {item?.notebook && (
                  <div className={`pl-2 pr-1.5`}>
                    {item?.notebook?.items?.map(
                      ({ path, icon: Icon, text }, key) => (
                        <NavLink
                          key={key}
                          to={path}
                          onClick={() => {
                            setExpandedSections((prev) => ({
                              ...prev,
                              [`Tools`]: !prev[`Objects`],
                            }));
                          }}
                          title={text}
                          className={({ isActive }) =>
                            `flex ${
                              isActive
                                ? 'bg-white dark:bg-gray-900 text-blue-20'
                                : ' hover:bg-white dark:hover:bg-gray-900 text-black dark:text-white'
                            } !items-center mt-2 first:mt-0 mx-1 p-2 duration-300 hover:bg-white dark:hover:bg-gray-900 rounded-lg transition-all   font-medium`
                          }
                        >
                          <Icon
                            size={16}
                            className={`${
                              location?.pathname === path
                                ? 'fill-blue-20 dark:stroke-blue-20'
                                : 'fill-black dark:fill-white'
                            } flex-shrink-0 `}
                          />
                          <span className="ml-3 truncate text-sm">{text}</span>
                        </NavLink>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LeftPanelNotebook;
