import React from 'react';

const MASKING_STATISTICS_DATA = [
  {
    id: 1,
    title: 'Protection Score',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '75%',
  },
  {
    id: 2,
    title: 'Processing Time',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '1.2 s',
  },
  {
    id: 3,
    title: 'Original Characters',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '120',
  },
  {
    id: 4,
    title: 'Masked Characters',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '34',
  },
];

const ACTIVE_RULES = [
  'Names',
  'Emails',
  'Phone Numbers',
  'Addresses',
  'Credit Cards',
  'Social Security Numbers',
];

const MaskingStatistics = () => {
  return (
    <div className="p-9 flex flex-col gap-6 border border-gray-10 shadow-shadow-div rounded-2xl">
      <h2 className="text-black text-xl font-medium tracking-[-0.4px] font-poppins">
        Masking Statistics
      </h2>

      <div className="grid grid-cols-4 gap-1.5">
        {MASKING_STATISTICS_DATA?.map(
          ({ id, title, description, duration }) => (
            <div
              key={id}
              className="p-6 border border-gray-10 rounded-2xl flex flex-col gap-5"
            >
              <div>
                <h2 className="font-poppins text-sm font-medium tracking-[-0.28px]">
                  {title}
                </h2>
                <p className="text-[#6D7280] text-[10px] font-normal tracking-[-0.2px]">
                  {description}
                </p>
              </div>

              <span className="text-blackDark-10 text-4xl font-medium tracking-[-0.18px]">
                {duration}
              </span>
            </div>
          )
        )}
      </div>

      <div className="flex flex-col gap-4 items-start">
        <h4 className="text-black font-poppins font-medium text-xl tracking-[-0.4px]">
          Active Rules
        </h4>

        <ul className="list-disc  pl-5 ">
          {ACTIVE_RULES?.map((rule, index) => (
            <li
              key={index}
              className="text-black font-normal text-base tracking-[-0.32px] leading-7"
            >
              {rule}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MaskingStatistics;
