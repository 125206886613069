import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { MdOutlineSaveAlt } from 'react-icons/md';

import { toast } from 'react-toastify';
import axios from 'axios';
import TranslateTextHeader from '../TranslateTextHeader';
import FileUpload from '../SummarizerNew/FileUpload';
import SingleFileUploadModel from '../SingleFileUploadModel';
import CloseIcon from '../../assets/icons/CloseIcon';

import { LuUpload } from 'react-icons/lu';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { RiFileCopy2Line } from 'react-icons/ri';
import jsPDF from 'jspdf';
import SettingsModel from './SettingsModel';

const MASKING_STATISTICS_DATA = [
  {
    id: 2,
    title: 'Processing Time',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '1.2 s',
  },
  {
    id: 3,
    title: 'Original Characters',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '120',
  },
  {
    id: 4,
    title: 'Masked Characters',
    description:
      'Choose to what degree you want to mask your sensitive information.',
    duration: '34',
  },
];

const SETTINGS_DATA = [
  {
    id: 1,
    label: 'Names',
    description: 'Allow Data Masker to mask names (first and last names).',
    isSelect: true,
  },
  {
    id: 2,
    label: 'Emails',
    description: 'Allow Data Masker to mask emails.',
    isSelect: true,
  },
  {
    id: 3,
    label: 'Phone Numbers',
    description: 'Allow Data Masker to mask phone numbers.',
    isSelect: true,
  },
  {
    id: 4,
    label: 'Addresses',
    description: 'Allow Data Masker to mask addresses.',
    isSelect: true,
  },
  {
    id: 5,
    label: 'Credit Cards',
    description:
      'Allow Data Masker to mask credit, debit, and account numbers.',
    isSelect: true,
  },
  {
    id: 6,
    label: 'SSNs',
    description: 'Allow Data Masker to mask social security numbers.',
    isSelect: true,
  },
];

const REQUIREMENTS = [
  {
    id: 1,
    label: 'Enhances and expedites text conversion in an intuitive layout.',
    img: '/images/planIcon.png',
  },
  {
    id: 2,
    label: 'Upload .txt, .dox, and .pdf files.',
    img: '/images/BulbIcon.png',
  },
  {
    id: 3,
    label: 'Generate summaries and view the original text side-by-side.',
    img: '/images/InfoIcon.png',
  },
];

const DataMaskingNew = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [maskingText, setMaskingText] = useState(null);
  const [prevMaskingText, setPrevMaskingText] = useState(null);
  const [uploadFileText, setUploadFileText] = useState(null);
  const [showMaking, setShowMaking] = useState(false);

  const [isAline, setIsAline] = useState('horizontal');
  const [isActiveAline, setIsActiveAline] = useState(true);
  const [isShowInput, setIsShowInput] = useState(false);
  const [fileModelOpen, setFileModelOpen] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isGetStarted, setIsGetStarted] = useState(false);
  const [isSettingModelOpen, setIsSettingModelOpen] = useState(false);
  const [settings, setSettings] = useState(SETTINGS_DATA);
  const [activeRules, setActiveRules] = useState(null);

  const handleMasking = async () => {
    if (uploadFileText) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/api/data_masking`,
          {
            input_text: uploadFileText,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.statusCode === 200) {
          setIsLoading(false);
          setIsActiveAline(false);
          const parsedText = JSON.parse(response.data.body);
          setMaskingText(parsedText?.masked_data);
          setPrevMaskingText(uploadFileText);
          setShowMaking(true);
          setActiveRules(settings);
        } else {
          toast.error('Summarize failed');
          throw new Error('Summarize failed');
        }
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  const handleUploadFile = async () => {
    setIsFileLoading(true);
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/api/extract/upload_doc_and_extract_text`,
          formData,
          {
            maxBodyLength: Infinity,
          }
        );

        if (response.data.statusCode === 200) {
          setFile(null);
          setShowMaking(false);
          setIsFileLoading(false);
          setFileModelOpen(false);
          const parsedText = JSON.parse(response.data.body);
          setUploadFileText(parsedText?.extracted_text);
          setIsShowInput(true);
          toast.success('File uploaded successfully!');
        } else {
          toast.error('File upload failed.');
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error', error);
        toast.error('Error uploading file.');
      } finally {
        setIsFileLoading(false);
      }
    }
  };

  function formatText(input) {
    // Hide sensitive information
    const hiddenText = input.replace(
      /(\b[A-Z]{3}-[A-Z]{3}-[0-9]{4}\b)|(\b\d{10}\b)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)|(\b\d{1,3},?\s?\d{1,3},?\s?\d{1,3},?\s?\d{1,3}\b)|(\b[A-Z][a-z]*\s[A-Z][a-z]*\*\b)/g,
      '<span style="background-color: #252525; color: white; min-height: 11px; min-width: 20px;">\t\t\t</span>'
    );

    const finalText = hiddenText.replace(
      /\*\*\*/g,
      '<span style="background-color: #252525; color">\t\t\t</span>'
    );

    const formattedText = finalText.replace(/\\n/g, '\n');

    return formattedText;
  }

  function copyFormatText(input) {
    const hiddenText = input.replace(
      /(\b[A-Z]{3}-[A-Z]{3}-[0-9]{4}\b)|(\b\d{10}\b)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)|(\b\d{1,3},?\s?\d{1,3},?\s?\d{1,3},?\s?\d{1,3}\b)|(\b[A-Z][a-z]*\s[A-Z][a-z]*\*\b)/g,
      '••••••'
    );

    const finalText = hiddenText.replace(/\*\*\*/g, '••••••');

    const formattedText = finalText.replace(/\\n/g, '\n');

    return formattedText;
  }

  const onButtonClick = () => {
    const doc = new jsPDF();
    const margin = 10;
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth() - margin * 2;
    const title = 'Masking Text';
    doc.setFontSize(16);
    doc.text(title, margin, margin);
    doc.setFontSize(12);
    const textLines = doc.splitTextToSize(
      copyFormatText(maskingText),
      pageWidth
    );
    const startY = margin + 10;
    let currentY = startY;
    const lineHeight = 5;
    textLines.forEach((line, index) => {
      if (currentY + lineHeight > pageHeight - margin) {
        doc.addPage();
        currentY = margin;
      }
      doc.text(line, margin, currentY);
      currentY += lineHeight;
    });

    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    doc.save(`Masking_${formattedDate}.pdf`);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window !== undefined) {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 1024) {
          setIsAline('vertical');
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Coppied');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] lg:h-screen bg-[#FCFCFC] dark:bg-gray-900 overflow-y-hidden">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="h-full"
        >
          <div className="relative">
            <TranslateTextHeader
              setIsAline={setIsAline}
              isActiveAline={isActiveAline}
              isAline={isAline}
              title={'Data Masker'}
              isSettings={true}
              setIsSettingModelOpen={setIsSettingModelOpen}
            />

            {isSettingModelOpen && (
              <div className="absolute z-50 top-11 right-[130px] max-w-[354px] w-full">
                <SettingsModel
                  isOpen={isSettingModelOpen}
                  setIsOpen={setIsSettingModelOpen}
                  data={SETTINGS_DATA}
                  setSettings={setSettings}
                  settings={settings}
                />
              </div>
            )}
          </div>

          {!isGetStarted && (
            <div className="h-[calc(100vh-60px)] py-3">
              <FileUpload
                title={'Data Masker'}
                setIsGetStarted={setIsGetStarted}
                data={REQUIREMENTS}
              />
            </div>
          )}

          {(isShowInput || isGetStarted) && (
            <div
              className={`h-[calc(100%-60px)] overflow-y-auto max-w-  flex p-12 gap-9 ${
                isAline === 'vertical' ? 'flex-col' : 'flex-row'
              }`}
            >
              <div
                className={`w-full mx-auto max-w-[932px] flex flex-col gap-6  ${
                  isAline === 'vertical'
                    ? showMaking
                      ? 'h-fit'
                      : 'h-full '
                    : 'h-full max-w-full'
                }`}
              >
                <h3 className="text-xl font-medium">Title</h3>

                <div
                  className={`${
                    isAline === 'vertical'
                      ? showMaking
                        ? 'h-fit'
                        : 'h-full'
                      : 'h-[calc(100%-120px)]'
                  }  w-full relative`}
                >
                  {!showMaking && (
                    <textarea
                      type="text"
                      value={uploadFileText}
                      onChange={(e) => {
                        setUploadFileText(e.target.value);
                      }}
                      placeholder="Your transcript will be generated here."
                      className={`resize-none ${
                        uploadFileText ? 'pr-12' : ''
                      } w-full h-full helpdesk-custom-scrollbar lg:min-h-[500px] text-base dark:border-gray-700 border-lightGray-10 placeholder:text-[#787878] px-6 pt-6 pb-4 rounded-2xl bg-lightGray-100 dark:bg-gray-900 border-2  text-black dark:text-white outline-none`}
                    />
                  )}

                  {showMaking && (
                    <div
                      className={`whitespace-pre-wrap ${
                        isAline === 'vertical' ? 'max-h-[400px] ' : ''
                      } leading-7 h-full resize-none helpdesk-custom-scrollbar text-base dark:border-gray-700 border-lightGray-10 overflow-y-auto placeholder:text-[#787878] px-6 pt-6 pb-4 rounded-2xl bg-lightGray-100 dark:bg-gray-900 border-2  text-black dark:text-white outline-none`}
                      dangerouslySetInnerHTML={{
                        __html: `${formatText(maskingText)}`,
                      }}
                    />
                  )}

                  {!showMaking && uploadFileText && (
                    <button
                      className="absolute right-6 top-6"
                      onClick={() => setUploadFileText('')}
                    >
                      <CloseIcon />
                    </button>
                  )}

                  {uploadFileText && (
                    <button
                      className="absolute right-6 bottom-6 text-gray-700 dark:text-white"
                      onClick={() =>
                        handleCopy(
                          showMaking
                            ? copyFormatText(maskingText)
                            : uploadFileText
                        )
                      }
                    >
                      <RiFileCopy2Line size={20} />
                    </button>
                  )}
                </div>

                <div className="flex items-center justify-between flex-row">
                  <button
                    type="button"
                    onClick={() => setFileModelOpen(true)}
                    className="sm:py-2 py-2.5 rounded-lg px-3 font-poppins flex items-center gap-2 hover:bg-blue-45 duration-300 text-white text-base font-medium bg-blue-20"
                  >
                    <LuUpload size={20} />
                    <span className="sm:block hidden">Upload file</span>
                  </button>

                  <div className="flex items-center gap-3">
                    {showMaking && (
                      <button
                        type="button"
                        onClick={onButtonClick}
                        className="flex items-center hover:bg-blue-10 dark:hover:bg-gray-700  dark:text-white py-2 px-3 rounded-lg text-blackDark-10 text-base gap-2.5"
                      >
                        <MdOutlineSaveAlt size={24} />
                      </button>
                    )}

                    {showMaking ? (
                      <button
                        type="button"
                        className="py-2 rounded-lg px-3 hover:bg-blue-30 duration-300 disabled:cursor-not-allowed disabled:border-lightGray-10 border border-transparent disabled:bg-[#F2F2F2] disabled:text-[#B1B1B1] font-poppins flex items-center gap-2 text-blue-20 text-base font-medium bg-blue-10"
                        onClick={() => {
                          setShowMaking(false);
                        }}
                        disabled={isLoading || !uploadFileText}
                      >
                        <AiOutlineEye size={24} />
                        Unmask data
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="py-2 rounded-lg px-3 hover:bg-blue-45 duration-300 disabled:cursor-not-allowed disabled:border-lightGray-10 border border-transparent disabled:bg-[#F2F2F2] disabled:text-[#B1B1B1] font-poppins flex items-center gap-2 text-white text-base font-medium bg-blue-20"
                        onClick={() => {
                          if (
                            !!prevMaskingText &&
                            prevMaskingText?.trim() === uploadFileText?.trim()
                          ) {
                            setMaskingText(prevMaskingText);
                            setShowMaking(true);
                          } else {
                            handleMasking();
                          }
                        }}
                        disabled={isLoading || !uploadFileText}
                      >
                        <AiOutlineEyeInvisible size={24} />
                        Mask data
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {showMaking && (
                <div
                  className={`flex ${
                    isAline === 'vertical' ? 'max-w-[932px]' : ''
                  } flex-col gap-6 mx-auto w-full sm:mt-0 mt-9`}
                >
                  <h2 className="text-black text-xl dark:text-white font-medium tracking-[-0.4px] font-poppins">
                    Masking Statistics
                  </h2>

                  <div
                    className={`grid ${
                      isAline === 'vertical' ? 'grid-cols-2' : 'grid-cols-2'
                    }  gap-1.5`}
                  >
                    {MASKING_STATISTICS_DATA?.map(
                      ({ id, title, description, duration }) => (
                        <div
                          key={id}
                          className="p-6 border border-gray-10 dark:border-gray-600 rounded-2xl flex flex-col gap-5"
                        >
                          <div>
                            <h2 className="font-poppins text-sm font-medium tracking-[-0.28px]">
                              {title}
                            </h2>
                            <p className="text-[#6D7280] dark:text-gray-300 text-[10px] font-normal tracking-[-0.2px]">
                              {description}
                            </p>
                          </div>

                          <span className="text-blackDark-10 dark:text-white text-4xl font-medium tracking-[-0.18px]">
                            {duration}
                          </span>
                        </div>
                      )
                    )}

                    <div className="p-6 border border-gray-10 rounded-2xl flex flex-col gap-5">
                      <h2 className="font-poppins text-sm font-medium tracking-[-0.28px]">
                        Active Rules
                      </h2>

                      <div className="flex flex-wrap gap-2 gap-y-1">
                        {activeRules?.map(({ id, isSelect, label }) => (
                          <>
                            {isSelect && (
                              <span
                                key={label}
                                className="text-black font-normal rounded-full font-poppins text-sm bg-blue-30 px-3 py-1  tracking-[-0.32px] leading-7"
                              >
                                {label}
                              </span>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </motion.div>
      </AnimatePresence>

      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}

      <SingleFileUploadModel
        handleUploadFile={handleUploadFile}
        isOpen={fileModelOpen}
        file={file}
        setFile={setFile}
        setIsOpen={setFileModelOpen}
        isLoading={isFileLoading}
      />
    </div>
  );
};

export default DataMaskingNew;
