import React from "react";
import useAuthStore from "../stores/authStore";
import { motion } from "framer-motion";

const LogoutPopup = () => {
  const { logout, toggleLogoutPopup } = useAuthStore();

  const handleLogout = () => {
    logout();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, y: 50 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="bg-white dark:bg-gradient-to-br dark:from-gray-900 dark:to-gray-800 rounded-2xl p-6 sm:p-10 w-full max-w-lg shadow-2xl"
      >
        <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-gray-900 dark:text-white font-poppins">
          Confirm Logout
        </h2>
        <p className="text-lg text-gray-600 dark:text-gray-300 mb-8 text-center font-poppins">
          Are you sure you want to log out?
        </p>
        <div className="space-y-4 sm:space-y-6">
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <button
              onClick={handleLogout}
              className="w-full py-3 px-4 rounded-lg text-base sm:text-lg font-semibold text-white bg-blue-600 hover:bg-blue-700 dark:bg-gradient-to-r dark:from-blue-500 dark:to-indigo-600 dark:hover:from-blue-600 dark:hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 transition duration-300 ease-in-out transform hover:-translate-y-1 font-poppins"
            >
              Logout
            </button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <button
              onClick={toggleLogoutPopup}
              className="w-full py-3 px-4 rounded-lg text-base sm:text-lg font-semibold text-gray-700 dark:text-gray-300 bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 transition duration-300 font-poppins"
            >
              Cancel
            </button>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default LogoutPopup;
