import React from 'react';
import HeaderHistoryIcon from '../assets/icons/HeaderHistoryIcon';
import VerticalAlineIcon from '../assets/icons/VerticalAlineIcon';
import AlineIcon from '../assets/icons/AlineIcon';
import HeaderSettingIcon from '../assets/icons/HeaderSettingIcon';

const TranslateTextHeader = ({
  setIsAline,
  isActiveAline,
  isAline,
  title,
  isSettings,
  setIsSettingModelOpen,
  isExtractionSettings,
  setIsExtractionSettings,
}) => {
  return (
    <div className="border-b py-2.5 px-6 bg-lightGray-100 dark:bg-gray-900 dark:border-gray-700 border-lightGray-10 h-[60px] flex items-center justify-between gap-2">
      <div />

      <h2 className="font-poppins font-medium text-sm">{title}</h2>

      <div className="flex items-center gap-2">
        {isExtractionSettings && !isExtractionSettings && (
          <button className="hover:bg-blue-10 rounded-lg dark:hover:bg-gray-950">
            <HeaderHistoryIcon />
          </button>
        )}

        {isSettings && (
          <button
            onClick={() => setIsSettingModelOpen(true)}
            className="hover:bg-blue-10 rounded-lg dark:hover:bg-gray-950"
          >
            <HeaderSettingIcon />
          </button>
        )}

        {isExtractionSettings && (
          <button
            onClick={() => setIsExtractionSettings(true)}
            className="hover:bg-blue-10 rounded-lg dark:hover:bg-gray-950"
          >
            <HeaderSettingIcon />
          </button>
        )}

        <div className="lg:flex hidden items-center bg-[#F2F2F2] dark:bg-gray-800 dark:border-gray-600 border border-gray-10 rounded-md">
          <button
            onClick={() => {
              setIsAline('vertical');
            }}
            disabled={isActiveAline}
            className={`${
              isAline === 'vertical' && !isActiveAline
                ? 'border-blue-45 dark:bg-gray-950 dark:border-gray-500 rounded-md bg-blue-10 text-blue-45'
                : 'border-transparent hover:text-blue-20'
            } px-3 py-1.5 w-full text-blackDark-10 dark:text-white border-[1.5px] disabled:cursor-not-allowed disabled:text-[#B1B1B1] dark:disabled:text-gray-300`}
          >
            <VerticalAlineIcon />
          </button>

          <button
            onClick={() => {
              setIsAline('horizontal');
            }}
            disabled={isActiveAline}
            className={`${
              isAline === 'horizontal' && !isActiveAline
                ? 'border-blue-45 dark:bg-gray-950 dark:border-gray-500 rounded-md bg-blue-10 text-blue-45'
                : 'border-transparent hover:text-blue-20'
            } px-3 py-1.5 w-full text-blackDark-10 dark:text-white border-[1.5px] disabled:cursor-not-allowed disabled:text-[#B1B1B1] dark:disabled:text-gray-300`}
          >
            <AlineIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranslateTextHeader;
