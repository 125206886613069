import React from 'react';

const StartIcon = ({ isFill }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2042_29391)">
        <path
          d="M11.5229 3.86196C11.6726 3.40131 12.3243 3.40131 12.474 3.86196L14.0415 8.68638C14.1085 8.89239 14.3004 9.03187 14.517 9.03187H19.5897C20.0741 9.03187 20.2755 9.65168 19.8836 9.93638L15.7797 12.918C15.6045 13.0454 15.5312 13.271 15.5981 13.4771L17.1656 18.3015C17.3153 18.7621 16.7881 19.1452 16.3962 18.8605L12.2923 15.8788C12.1171 15.7515 11.8798 15.7515 11.7045 15.8788L7.60065 18.8605C7.20879 19.1452 6.68155 18.7621 6.83123 18.3015L8.39878 13.4771C8.46571 13.271 8.39238 13.0454 8.21714 12.918L4.11324 9.93638C3.72139 9.65168 3.92277 9.03187 4.40714 9.03187H9.47983C9.69645 9.03187 9.88842 8.89239 9.95536 8.68638L11.5229 3.86196Z"
          fill={`${isFill ? '#377FFF' : 'white'}`}
          stroke="#377FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_2042_29391">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StartIcon;
