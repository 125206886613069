import React from 'react';

const AvahiColorIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="8"
        fill="url(#paint0_linear_1874_44647)"
      />
      <g clipPath="url(#clip0_1874_44647)">
        <path
          d="M24.2748 23.7059C24.2508 23.6615 24.2364 23.6333 24.2214 23.6062C22.3633 20.388 20.5052 17.1698 18.6459 13.9522C18.6146 13.8988 18.6158 13.8615 18.6477 13.8081C19.0526 13.1256 19.4551 12.4414 19.8576 11.7577C19.9008 11.6844 19.9441 11.6111 19.9957 11.5234C22.797 16.2729 25.5917 21.0116 28.3917 25.7587H17.9424C17.9586 25.7281 17.9712 25.7034 17.985 25.6788C18.3533 25.0444 18.7228 24.4106 19.0892 23.7756C19.1204 23.7216 19.1553 23.7035 19.2166 23.7035C20.8638 23.7053 22.5104 23.7047 24.1577 23.7047C24.1913 23.7047 24.225 23.7047 24.2748 23.7047V23.7059Z"
          fill="white"
        />
        <path
          d="M18.3821 19.5547C18.3996 19.5829 18.4128 19.6027 18.4242 19.6232C18.8345 20.3321 19.2442 21.0409 19.6569 21.7486C19.6906 21.8063 19.6918 21.8477 19.6575 21.906C18.8477 23.3015 18.0403 24.6983 17.2323 26.095C17.2119 26.1298 17.1945 26.1659 17.168 26.2151C17.2197 26.2151 17.2557 26.2151 17.2912 26.2151C21.0452 26.2151 24.7987 26.2151 28.5527 26.2133C28.632 26.2133 28.6735 26.238 28.7131 26.3053C29.1235 27.0021 29.5374 27.6966 29.9501 28.3916C29.9657 28.4181 29.9801 28.4451 29.9999 28.48H13.2332C14.9501 25.5033 16.664 22.5332 18.3827 19.5547H18.3821Z"
          fill="white"
        />
        <path
          d="M18.3455 14.3281C20.0619 17.3018 21.7758 20.2713 23.4975 23.2528C23.4597 23.2528 23.4363 23.2528 23.4128 23.2528C22.6493 23.2528 21.8857 23.2522 21.1222 23.254C21.0633 23.254 21.0315 23.2354 21.002 23.1843C20.1502 21.7029 19.2965 20.2226 18.4429 18.7424C18.4272 18.7154 18.4104 18.6889 18.3876 18.6511C18.3648 18.6877 18.3461 18.7148 18.3293 18.743C16.4736 21.96 14.6185 25.1764 12.7646 28.394C12.7268 28.46 12.6853 28.4823 12.6102 28.4823C11.771 28.4799 10.9318 28.4805 10.0919 28.4805H10C12.7857 23.7568 15.5641 19.0458 18.3455 14.3281Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1874_44647"
          x1="0"
          y1="0"
          x2="47.9313"
          y2="24.4547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#377FFF" />
          <stop offset="1" stopColor="#F5095C" />
        </linearGradient>
        <clipPath id="clip0_1874_44647">
          <rect
            width="20"
            height="16.9602"
            fill="white"
            transform="translate(10 11.5234)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvahiColorIcon;
