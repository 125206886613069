import React from 'react';

const JiraIcon = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.1599 28.3008L32.3589 2.50004L29.8589 0L10.4366 19.4222L1.5552 28.3008C1.11158 28.7467 0.862549 29.3501 0.862549 29.9791C0.862549 30.6081 1.11158 31.2115 1.5552 31.6574L19.2992 49.4012L29.8589 59.9582L49.2785 40.536L49.581 40.2362L58.1599 31.6708C58.3822 31.4501 58.5587 31.1876 58.6791 30.8984C58.7995 30.6092 58.8615 30.299 58.8615 29.9858C58.8615 29.6725 58.7995 29.3624 58.6791 29.0732C58.5587 28.784 58.3822 28.5215 58.1599 28.3008V28.3008ZM29.8589 38.8443L20.9936 29.9791L29.8589 21.1138L38.7215 29.9791L29.8589 38.8443Z"
        fill="#2684FF"
      />
      <path
        d="M29.8696 21.135C27.0815 18.3476 25.5098 14.5701 25.4978 10.6276C25.4857 6.68517 27.0343 2.89815 29.8053 0.09375L10.4076 19.4835L20.9643 30.0403L29.8696 21.135Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M38.7564 29.9766L29.8699 38.8659C32.6685 41.6653 34.2407 45.4617 34.2407 49.4201C34.2407 53.3786 32.6685 57.175 29.8699 59.9744L49.3213 40.5335L38.7564 29.9766Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="28.2823"
          y1="12.1655"
          x2="15.9027"
          y2="24.5424"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.18"
            stopColor="#0052CC"
          />
          <stop
            offset="1"
            stopColor="#2684FF"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="2259.97"
          y1="2602.89"
          x2="3524.07"
          y2="3422.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.18"
            stopColor="#0052CC"
          />
          <stop
            offset="1"
            stopColor="#2684FF"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JiraIcon;
