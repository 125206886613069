import React, { useState } from "react";

const Files = () => {
  const [files, setFiles] = useState([]);
  const [sortType, setSortType] = useState("name");

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files).map((file) => ({
      name: file.name,
      size: (file.size / 1024).toFixed(2) + " KB",
      uploadDate: new Date().toLocaleDateString(),
      type: file.type,
    }));
    setFiles([...files, ...uploadedFiles]);
  };

  const handleFileDelete = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const handleSort = (type) => {
    setSortType(type);
    const sortedFiles = [...files].sort((a, b) => {
      if (type === "name") return a.name.localeCompare(b.name);
      if (type === "size") return parseFloat(a.size) - parseFloat(b.size);
      return new Date(b.uploadDate) - new Date(a.uploadDate);
    });
    setFiles(sortedFiles);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      {/* Main Content */}
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Manage Your Files
          </h2>
          <p className="text-gray-600 mb-4">
            Upload, view, and manage your files effortlessly.
          </p>

          {/* File Upload Section */}
          <div className="mb-6">
            <label
              htmlFor="fileUpload"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Upload Files:
            </label>
            <input
              type="file"
              id="fileUpload"
              multiple
              onChange={handleFileUpload}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* File Sorting */}
          <div className="mb-4 flex justify-between items-center">
            <p className="text-lg font-semibold text-gray-800">Files List</p>
            <div>
              <label
                htmlFor="sortFiles"
                className="mr-2 text-sm font-medium text-gray-700"
              >
                Sort by:
              </label>
              <select
                id="sortFiles"
                value={sortType}
                onChange={(e) => handleSort(e.target.value)}
                className="p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="name">Name</option>
                <option value="size">Size</option>
                <option value="date">Upload Date</option>
              </select>
            </div>
          </div>

          {/* File List Display */}
          <ul className="mb-6 space-y-4">
            {files.map((file, index) => (
              <li
                key={index}
                className="flex justify-between items-center bg-gray-100 p-4 rounded-lg shadow"
              >
                <div>
                  <p className="text-lg font-semibold text-gray-900">
                    {file.name}
                  </p>
                  <p className="text-sm text-gray-700">
                    {file.size} | {file.uploadDate} | {file.type}
                  </p>
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => handleFileDelete(file.name)}
                    className="text-red-500 hover:text-red-600 font-medium"
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>

          {/* Empty State */}
          {files.length === 0 && (
            <div className="text-center text-gray-500">
              <p>No files uploaded yet.</p>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Files;
