import React from 'react';

const AlineIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 2.5H3.33333C2.8731 2.5 2.5 2.8731 2.5 3.33333V7.5C2.5 7.96024 2.8731 8.33333 3.33333 8.33333H7.5C7.96024 8.33333 8.33333 7.96024 8.33333 7.5V3.33333C8.33333 2.8731 7.96024 2.5 7.5 2.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6665 2.5H12.4998C12.0396 2.5 11.6665 2.8731 11.6665 3.33333V7.5C11.6665 7.96024 12.0396 8.33333 12.4998 8.33333H16.6665C17.1267 8.33333 17.4998 7.96024 17.4998 7.5V3.33333C17.4998 2.8731 17.1267 2.5 16.6665 2.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83333 11.6641L2.5 14.9974M2.5 14.9974L5.83333 18.3307M2.5 14.9974H17.5M17.5 14.9974L14.1667 11.6641M17.5 14.9974L14.1667 18.3307"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlineIcon;
