import { useState } from 'react';
import { MdLanguage } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const LanguageSelector = ({ setSelectedLanguage, selectedLanguage }) => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const countries = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
      icon: '/images/english.png',
    },
    {
      code: 'es',
      name: 'Spanish',
      country_code: 'es',
      icon: '/images/spanish.png',
    },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng.code);
    setDropdownOpen(false);
    setSelectedLanguage(lng);
  };

  return (
    <div className="relative flex items-center text-left">
      <button
        onClick={toggleDropdown}
        className="inline-flex items-center gap-1 justify-center w-full text-sm font-medium text-gray-700 focus:outline-none"
        aria-label="usermenu-button"
      >
        <img src={selectedLanguage?.icon} alt="alt" className=" w-4 h-4" />
        <span>
          <MdLanguage className="h-5 w-5 cursor-pointer text-black dark:text-white" />
        </span>
      </button>

      {dropdownOpen && (
        <div className=" absolute right-1/2 translate-x-1/2 top-5 mt-2 w-24 rounded-md shadow-lg z-50  bg-white dark:bg-gray-600 ring-1 ring-black ring-opacity-5">
          <div
            className="p-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            {countries.map((lng) => (
              <button
                key={lng.code}
                onClick={() => handleLanguageChange(lng)}
                className={`flex items-center p-1.5 gap-1 text-xs w-full text-left ${
                  i18n.language === lng.code
                    ? 'bg-gray-100 dark:bg-gray-700 dark:text-white text-gray-900'
                    : 'text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700'
                } rounded-lg mt-1`}
                role="menuitem"
              >
                <span>
                  <img src={lng?.icon} alt="alt" className=" w-4 h-4" />
                </span>
                <span>{lng.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
