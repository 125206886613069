import React, { useState } from 'react';

const MedicalImagingAnalysis = () => {
  const [image, setImage] = useState(null);
  const [results, setResults] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setResults([
        {
          title: 'Tumor Detection',
          content: 'A suspicious area has been detected in the left lung.',
        },
        {
          title: 'Bone Fracture',
          content: 'Minor fractures observed in the right arm.',
        },
      ]);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-8 px-4">
      <div className={'w-full  mx-auto'}>
        <h1 className="text-3xl font-bold text-primary mb-6 text-center">
          Medical Imaging Analysis
        </h1>

        <div
          className={'bg-gray-50 w-full p-6 rounded-lg shadow-shadow-div mb-6'}
        >
          <h3 className="text-xl font-semibold text-primary mb-4">
            Upload Medical Image
          </h3>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className={
              'w-full border border-gray-300 bg-white rounded-lg py-3 px-4 text-sm text-gray-700'
            }
          />
        </div>

        {(image || results.length > 0) && (
          <div className="bg-gray-50 p-4 rounded-lg shadow-shadow-div mb-4">
            {image && (
              <div className={' mb-6'}>
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  className={
                    'w-full h-auto rounded-lg shadow-shadow-div border max-w-[400px] mx-auto'
                  }
                />
              </div>
            )}

            {results.length > 0 && (
              <div className={'w-full '}>
                {results.map((result, index) => (
                  <div
                    key={index}
                    className={'bg-white p-4 rounded-lg shadow-shadow-div mb-4'}
                  >
                    <h4 className={'text-lg font-semibold text-primary mb-2'}>
                      {result.title}
                    </h4>
                    <p className={'text-secondary'}>{result.content}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicalImagingAnalysis;
