import React, { useState } from 'react';
import SelectBox from '../SelectBox';
import { IoChevronDownSharp, IoClose } from 'react-icons/io5';
import { RiFileCopy2Line } from 'react-icons/ri';
import ScribingICDHeader from './ScribingICDHeader';
import jsonData from './jsonData.json';
import ScribingICDTable from './ScribingICDTable';
import ReloadIcon from '../../assets/icons/ReloadIcon';
import { MdOutlineSaveAlt } from 'react-icons/md';

const TRAINED_MODEL_DATA = [
  { id: 1, name: 'ner_jsl' },
  { id: 2, name: 'ner_jsl_slim' },
  { id: 3, name: 'ner_events_clinical' },
  { id: 4, name: 'ner_healthcare' },
];

const ScribingICDBreakdownTab = () => {
  const [selectedTrainedModel, setSelectedTrainedModel] = useState(null);
  const [data, setData] = useState(null);
  const [showResults, setShowResults] = useState(false);

  const handleButtonClick = () => {
    setData(jsonData);
  };

  return (
    <>
      {!selectedTrainedModel?.name ? (
        <div className="max-w-[580px]">
          <SelectBox
            options={TRAINED_MODEL_DATA}
            placeholder={'Choose a patient'}
            onSelect={(select) => setSelectedTrainedModel(select)}
            value={selectedTrainedModel?.name}
          />
        </div>
      ) : (
        <div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="w-full"
        >
          <ScribingICDHeader handleButtonClick={handleButtonClick} />
        </div>
      )}

      <div className="relative border overflow-y-hidden flex items-center border-gray-10 py-6 rounded-xl">
        <div className="text-[#737373]  flex flex-wrap gap-2.5  px-6 pr-14 min-h-[300px] max-h-[548px] overflow-y-auto helpdesk-custom-scrollbar w-full text-base font-normal font-poppins tracking-[-0.08px]">
          {data
            ? data?.map(({ Chunk, name, text, color }, key) => (
                <div key={key} className="h-fit">
                  {name ? (
                    <div
                      key={name}
                      className="border gap-2 rounded-3xl flex items-center flex-col justify-center border-blue-600 w-fit py-2 px-4 bg-blue-100"
                      style={{
                        borderColor: color?.text,
                        backgroundColor: color?.bg,
                      }}
                    >
                      <p className="bg-white w-fit text-[#737373]  text-center rounded-full text-base px-3 font-poppins font-normal">
                        {Chunk}
                      </p>
                      <span
                        className="font-poppins text-base font-medium"
                        style={{
                          color: color?.text,
                        }}
                      >
                        {name}
                      </span>
                    </div>
                  ) : (
                    <div
                      key={name}
                      className="font-poppins py-2 text-black text-base leading-6"
                    >
                      {text}
                    </div>
                  )}
                </div>
              ))
            : 'Your summary will be generated here.'}
        </div>

        <div className="h-full absolute py-6 right-6 flex justify-between flex-col">
          <button
            onClick={() => {
              setData(null);
              setSelectedTrainedModel(null);
            }}
          >
            <IoClose size={25} className="text-[#737373]" />
          </button>
          <button>
            <RiFileCopy2Line size={25} className="text-[#737373]" />
          </button>
        </div>
      </div>

      {data && (
        <div className="flex flex-col gap-9">
          <div className="flex items-center w-full justify-between">
            <button
              onClick={() => setShowResults(!showResults)}
              className="flex items-center py-2 px-3 border rounded-lg border-blue-20 gap-1.5 text-base leading-6 font-medium text-blue-20"
            >
              Raw results
              <IoChevronDownSharp
                size={12}
                className={`${showResults ? 'rotate-180' : ''} duration-200`}
              />
            </button>

            <div className="flex items-center gap-2">
              <button className="flex gap-2 py-2 px-4  text-white bg-blue-20 rounded-lg font-medium">
                <ReloadIcon />
                Reload
              </button>

              <button className="flex gap-2 py-2 px-4  text-white bg-blue-20 rounded-lg font-medium">
                <MdOutlineSaveAlt size={24} />
              </button>
            </div>
          </div>

          {showResults && <ScribingICDTable data={data} />}
        </div>
      )}
    </>
  );
};

export default ScribingICDBreakdownTab;
