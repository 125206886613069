import React, { useState, useEffect } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { FaUser, FaClock, FaStar } from "react-icons/fa";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import GeographicDistributionCard from "./GeographicDistributionCard";
import ServiceUsageCard from "./ServiceUsageCard";
import USAMapCard from "./USAMapCard";

ChartJS.register(...registerables);

// Sample data arrays
const performanceMetrics = [
  { title: "Answer rate", value: 89.3, change: 0.5, color: "blue" },
  { title: "Deflection rate", value: 70, change: 0, color: "blue" },
  { title: "Resolution rate", value: 77.8, change: 0.3, color: "blue" },
];

const keyMetrics = [
  { title: "First response time", value: "5m", icon: <FaClock /> },
  { title: "Participated", value: 295, icon: <FaUser /> },
  { title: "Overall ratings", value: 90, unit: "%", icon: <FaStar /> },
];

const serviceUsageData = {
  labels: ["Chat", "Email", "Phone", "Social Media"],
  datasets: [
    {
      data: [40, 25, 20, 15],
      backgroundColor: ["#3b82f6", "#8b5cf6", "#ec4899", "#10b981"],
    },
  ],
};

const Dashboard = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Simulate loading data
    setTimeout(() => {
      setChartData({
        serviceUsage: serviceUsageData,
      });
    }, 1000);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="p-6 sm:p-8 bg-gray-50 dark:bg-gray-900 min-h-screen"
    >
      <Header />
      <PerformanceMetrics data={performanceMetrics} />
      <KeyMetrics data={keyMetrics} />
      <ChartsSection chartData={chartData} />
      <AdditionalMetrics />
      <ActiveUsersTable data={activeUsers} />
    </motion.div>
  );
};

const Header = () => (
  <header className="mb-8">
    <motion.h1
      initial={{ y: -20 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300 }}
      className="text-3xl sm:text-4xl font-poppins font-bold text-gray-800 dark:text-white mb-2"
    >
      Dashboard Overview
    </motion.h1>
    <p className="text-gray-600 dark:text-gray-300 font-poppins">
      Welcome back! Here's what's happening with your support team.
    </p>
  </header>
);

const PerformanceMetrics = ({ data }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
    {data.map((metric, index) => (
      <AnimatedPerformanceCard key={index} {...metric} />
    ))}
  </div>
);

const KeyMetrics = ({ data }) => (
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-8">
    {data.map((metric, index) => (
      <AnimatedMetricCard key={index} {...metric} />
    ))}
  </div>
);

const ChartsSection = ({ chartData }) => (
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
    <ServiceUsageCard />
    <GeographicDistributionCard />
    <div className="lg:col-span-3 h-full">
      <USAMapCard />
    </div>
  </div>
);

const AdditionalMetrics = () => (
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
    <AnimatedAvailabilityCard data={availabilityData} />
    <AnimatedConversationRatingsCard data={conversationRatings} />
  </div>
);

const AnimatedPerformanceCard = ({ title, value, change, color }) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
    className={`bg-${color}-500 dark:bg-${color}-700 rounded-xl shadow-lg p-6`}
  >
    <h3 className="text-lg font-poppins font-medium text-white mb-2">
      {title}
    </h3>
    <p className="text-4xl font-poppins font-bold text-white mb-2">
      <CountUp end={value} duration={2} decimals={1} suffix="%" />
    </p>
    <p
      className={`text-sm font-poppins ${
        change >= 0 ? "text-green-300" : "text-red-300"
      }`}
    >
      {change >= 0 ? "▲" : "▼"} {Math.abs(change)}%
    </p>
  </motion.div>
);

const AnimatedMetricCard = ({ title, value, unit = "", icon }) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
    className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
  >
    <div className="flex items-center mb-3">
      <div className="mr-3 text-blue-500 dark:text-blue-400 text-2xl">
        {icon}
      </div>
      <h3 className="text-lg font-medium font-poppins text-gray-800 dark:text-white">
        {title}
      </h3>
    </div>
    <p className="text-3xl font-bold font-poppins text-gray-900 dark:text-white">
      <CountUp
        end={typeof value === "number" ? value : 0}
        duration={2}
        separator=","
        suffix={unit}
      />
      {typeof value === "string" && value}
    </p>
  </motion.div>
);

const AnimatedAvailabilityCard = ({ data }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white dark:bg-gray-800  rounded-xl shadow-lg p-6"
  >
    <h2 className="text-xl font-poppins font-semibold  text-gray-800 dark:text-white mb-4">
      Agent Availability
    </h2>
    <div className="flex justify-between">
      {data.map((item, index) => (
        <div key={index} className="text-center items-center justify-center">
          <p
            className={`text-2xl font-bold font-poppins text-${item.color}-500`}
          >
            {item.value}
          </p>
          <p className="text-sm text-gray-600 font-poppins dark:text-gray-400">
            {item.status}
          </p>
        </div>
      ))}
    </div>
  </motion.div>
);

const AnimatedConversationRatingsCard = ({ data }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
  >
    <h2 className="text-xl font-poppins font-semibold text-gray-800 dark:text-white mb-4">
      Conversation Ratings
    </h2>
    {data.map((rating, index) => (
      <div key={index} className="flex items-center mb-2">
        <span className="text-2xl font-poppins mr-2">{rating.emoji}</span>
        <div className="flex-grow bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${rating.percentage}%` }}
          ></div>
        </div>
        <span className="ml-2 font-poppins text-sm text-gray-600 dark:text-gray-400">
          {rating.percentage}%
        </span>
      </div>
    ))}
  </motion.div>
);

const ActiveUsersTable = ({ data }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 overflow-x-auto"
  >
    <h2 className="text-2xl font-poppins font-semibold mb-4 text-gray-800 dark:text-white">
      Active Users
    </h2>
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
      <thead className="bg-gray-50 dark:bg-gray-700">
        <tr>
          {[
            "Name",
            "Status",
            "Time on status",
            "Time active",
            "Time away",
            "First online",
            "Last seen",
            "Open",
            "Idle",
          ].map((header) => (
            <th
              key={header}
              className="px-3 py-3 font-poppins text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
        {data.map((user, index) => (
          <motion.tr
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <td className="px-3 font-poppins py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
              {user.name}
            </td>
            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              <span
                className={`px-2 font-poppins py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  user.status === "Active"
                    ? "bg-green-100 text-green-800"
                    : "bg-yellow-100 text-yellow-800"
                }`}
              >
                {user.status}
              </span>
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.timeOnStatus}
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.timeActive}
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.timeAway}
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.firstOnline}
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.lastSeen}
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.open}
            </td>
            <td className="px-3 py-4 font-poppins whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
              {user.idle}
            </td>
          </motion.tr>
        ))}
      </tbody>
    </table>
  </motion.div>
);

// Additional data arrays
const availabilityData = [
  { status: "Active", value: 15, color: "blue" },
  { status: "Away", value: 8, color: "red" },
  { status: "Offline", value: 7, color: "gray" },
];

const conversationRatings = [
  { emoji: "😊", percentage: 92.9, change: 1.2 },
  { emoji: "😐", percentage: 5.1, change: -0.8 },
  { emoji: "😟", percentage: 2.0, change: -0.4 },
];

const activeUsers = [
  {
    name: "Alex Johnson",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
    status: "Active",
    timeOnStatus: "2h 15m",
    timeActive: "5h 30m",
    timeAway: "45m",
    firstOnline: "Today, 8:30 AM",
    lastSeen: "Just now",
    open: 12,
    idle: 3,
  },
  {
    name: "Samantha Lee",
    avatar: "https://randomuser.me/api/portraits/women/2.jpg",
    status: "Away",
    timeOnStatus: "1h 20m",
    timeActive: "4h 45m",
    timeAway: "1h 35m",
    firstOnline: "Today, 9:15 AM",
    lastSeen: "10m ago",
    open: 8,
    idle: 5,
  },
  {
    name: "Michael Chen",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
    status: "Active",
    timeOnStatus: "3h 40m",
    timeActive: "6h 20m",
    timeAway: "20m",
    firstOnline: "Today, 7:45 AM",
    lastSeen: "Just now",
    open: 15,
    idle: 2,
  },
  {
    name: "Emily Rodriguez",
    avatar: "https://randomuser.me/api/portraits/women/4.jpg",
    status: "Active",
    timeOnStatus: "1h 55m",
    timeActive: "3h 40m",
    timeAway: "30m",
    firstOnline: "Today, 10:00 AM",
    lastSeen: "5m ago",
    open: 10,
    idle: 4,
  },
];

export default Dashboard;
