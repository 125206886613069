/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '../assets/icons/CloseIcon';
import UploadIcon from '../assets/icons/UploadIcon';
import QuationIcon from './QuationIcon';
import { LuLoader2 } from 'react-icons/lu';

const SingleFileUploadModel = ({
  isOpen,
  setIsOpen,
  file,
  setFile,
  handleUploadFile,
  isLoading,
}) => {
  const [error, setError] = useState(null);
  const maxFileSize = 25 * 1024 * 1024;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      if (selectedFile.size > maxFileSize) {
        setError('File exceeds the maximum size limit of 25MB');
      } else {
        const fileWithPreview = Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile),
          isUploaded: false,
          isLoading: false,
        });
        setError(null);
        setFile(fileWithPreview);
      }
    },
    [setFile]
  );

  const onDropRejected = useCallback((rejectedFiles) => {
    const hasSizeError = rejectedFiles.some((file) =>
      file.errors.some((err) => err.code === 'file-too-large')
    );
    if (hasSizeError) {
      setError('File exceeds the maximum size limit of 25MB');
    } else {
      setError('Invalid File Format');
    }
  }, []);

  const removeFile = () => {
    setFile(null);
  };

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: maxFileSize,
    multiple: false,
  });

  useEffect(() => {
    return () => {
      if (file) URL.revokeObjectURL(file.preview);
    };
  }, [file]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/5 backdrop-blur-sm"
          aria-modal="true"
          role="dialog"
        >
          <div className="bg-white dark:bg-gray-700 zoomIn relative max-h-[calc(100vh-30px)] w-full max-w-[580px] overflow-y-auto rounded-2xl dark:shadow-none shadow-primaryBox">
            <div className="flex w-full flex-col gap-6 rounded-t-2xl bg-white dark:bg-gray-700 p-6 shadow-card">
              <div className="flex items-center justify-between">
                <h2 className="font-poppins text-2xl font-medium leading-9 dark:text-white text-black">
                  Upload a file
                </h2>
                <button
                  onClick={() => {
                    setIsOpen(false);
                    setFile(null);
                  }}
                  aria-label="Close upload modal "
                >
                  <CloseIcon />
                </button>
              </div>

              {file && (
                <>
                  <div className="table-scrollbar flex max-h-[130px] flex-col gap-2 overflow-y-auto px-1">
                    <div className="flex items-start justify-between rounded-lg border-2 border-blue-30 dark:border-gray-400 px-2 py-1">
                      <div className="flex flex-col items-start justify-between">
                        <span className="font-poppins text-sm font-normal leading-6 text-blackDark-100">
                          {file.name}
                        </span>
                        <span className="font-poppins text-xs leading-5 text-[#9B9B9B]">
                          {(file.size / (1024 * 1024)).toFixed(2)} MB
                        </span>
                      </div>
                      <button
                        type="button"
                        onClick={removeFile}
                        aria-label="Remove file"
                        className="pt-2 text-black dark:text-white"
                      >
                        <CloseIcon height={15} width={15} />
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="w-full border-b border-[#D8E6FF]" />
                    <span className="font-poppins text-base font-normal leading-6 text-[#9B9B9B]">
                      OR
                    </span>
                    <div className="w-full border-b border-[#D8E6FF]" />
                  </div>
                </>
              )}

              <div>
                <div
                  {...getRootProps()}
                  className={`${
                    isDragActive ? 'bg-blue-10 dark:bg-gray-800' : ''
                  } flex cursor-pointer items-center justify-center rounded-lg border-2 border-dashed dark:border-gray-500 border-blue-500 px-2 py-16 transition-all hover:bg-blue-10 dark:hover:bg-gray-800 md:px-10`}
                >
                  <input {...getInputProps()} aria-label="File upload input" />
                  <div className="flex flex-col items-center justify-center gap-4">
                    <UploadIcon />
                    <p className="font-poppins text-base font-semibold leading-6 text-blackDark-100">
                      {file ? 'Replace file, or ' : ' Drag and drop a file, or'}{' '}
                      <span className="text-blue-500">Browse</span>
                    </p>
                  </div>
                </div>
                <p className="mt-1.5 flex items-center justify-between font-poppins text-[10px] font-normal leading-3 dark:text-white text-[#9B9B9B]">
                  Supported format: PDF
                  <span>Maximum size: 25MB</span>
                </p>
                {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
              </div>
            </div>

            <div className="flex w-full flex-col items-center justify-between gap-y-3 rounded-b-2xl dark:bg-gray-600 bg-blue-10 px-6 py-3 sm:flex-row">
              <div className="flex items-center gap-3">
                <QuationIcon />
                <span className="font-poppins text-base font-medium leading-6 dark:text-white text-[#B1B1B1]">
                  Help Center
                </span>
              </div>

              {file && (
                <button
                  type="button"
                  onClick={() => handleUploadFile()}
                  className="flex max-h-[50px] px-4 py-2 items-center justify-center gap-2 rounded-lg border border-blue-20 font-poppins text-base font-normal leading-6 transition-all bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white w-fit"
                >
                  <span>Finish</span>

                  {isLoading && <LuLoader2 className="animate-spin" />}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleFileUploadModel;
