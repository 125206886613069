import React, { useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';

const SelectBox = ({ options, onSelect, placeholder, error, value }) => {
  const [selected, setSelected] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  // Effect to update selected option based on value prop
  useEffect(() => {
    const selectedOption =
      options.find((option) => option.name === value) || null;
    setSelected(selectedOption);
  }, [value, options]);

  const handleSelect = (option) => {
    setSelected(option);
    if (typeof onSelect === 'function') {
      onSelect(option);
    } else {
      console.error('onSelect is not a function');
    }
    setShowOptions(false);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowOptions(false);
    }
  };

  return (
    <div
      className="relative w-full flex flex-col"
      onBlur={handleBlur}
      tabIndex={-1}
    >
      <button
        type="button"
        onClick={() => setShowOptions(!showOptions)}
        className={`flex w-full items-center ${
          showOptions ? 'rounded-t-md' : 'rounded-md '
        } border ${
          error ? 'border-red-700' : 'border-gray-10'
        } p-2 text-sm font-normal leading-5 text-black outline-none`}
      >
        <span
          className={`${
            !selected ? 'text-gray-75' : ''
          } w-full text-start font-poppins text-sm text-[#787878] font-normal leading-5 outline-none`}
        >
          {selected ? selected.name : placeholder}
        </span>
        <div className={`${showOptions ? 'rotate-180' : ''} duration-300`}>
          <FaAngleDown size={10} className="!text-[#23272E]" />
        </div>
      </button>

      {showOptions && (
        <div className="border-lightGray-5 z-10 flex max-h-60 w-full flex-col items-start overflow-y-auto rounded-b-md border border-t-transparent bg-transparent shadow-card">
          {options.length > 0 ? (
            options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleSelect(option)}
                className={`w-full cursor-pointer px-4 py-3 text-start text-sm text-gray-75 duration-300 hover:bg-[#F2F2F2] ${
                  selected?.id === option.id ? 'bg-[#F2F2F2]' : ''
                }`}
                disabled={option === selected}
              >
                <div className="flex items-center gap-3">
                  {option?.sortName && (
                    <p
                      className={`p-1 rounded-full px-1 font-semibold font-poppins text-xs leading-4`}
                      style={{
                        backgroundColor: option?.color,
                      }}
                    >
                      {option?.sortName}
                    </p>
                  )}
                  <p className="font-poppins text-blackDark-10 font-medium text-sm leading-5">
                    {option.name}
                  </p>
                </div>
              </button>
            ))
          ) : (
            <div className="text-blackDark-10 px-4 py-2 font-poppins text-sm">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectBox;
