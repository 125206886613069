import React from "react";

const Cerner = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="120px"
      height="120px"
      viewBox="0 0 60 60"
      enableBackground="new 0 0 60 60"
      xmlSpace="preserve"
    >
      {" "}
      <image
        id="image0"
        width="60"
        height="60"
        x="0"
        y="0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATYAAACiCAMAAAD84hF6AAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAADwUExURf/////+//7/+/Hf3+jLxtqfl892ashfU8ZTR8VP
PcVPO8ZbSstrX9iXjOfFvfjy8NaQichHNcxpW8JALcQ0HL4wEt+1rPTn478/NO7b18dAK8c/LchF
MsU4Isc/KNWHe8hHMsx6c8lGNchHNMQuIN+zqezT0sc6Kd6locUuD9GIfsluZ/f39+nRxuS+st+t
p8EpANWPgct7asdfS79PRePj4sHBwKKhn5iXl7Kwrs3NzD07ORMNBRkSDR8aFiUgHFxaWI6OjSAd
GSwpJTEtKjk2NG9tbNvb2klHRQsAAK2trGdmY3t5eFxbWYWDgQAAANLjR3kAAAABYktHRACIBR1I
AAAAB3RJTUUH6AkRCR0DusJn7QAADXpJREFUeNrtmw1Dosoax4EsM0skUVfeIuxmGbue9lzNSqB8
ydzevv+3ufPMgIKi0MlO697nt2fbwGGY+fPM8zJ4OA5BEARBEARBEARBEARBEARBEARBEARBEARB
EARBEARBEARBEATh+a8ewXsHvLaehA/cTmCfCEJq/VLdbU3TEiKQw5h5CXOtgsZJA+U5YWsreTKx
fdOBwL/vUSNujJywNjMgHQmZ7Z3sbi6X22PkAvL7BwUxokhRyoXY2wtdsXtYzCyflnBQksu5yoqB
iJXi/m4ujlKV4zIlcpO9nTTT4cRCcT8fHt50Rt9q65KNEw8kWVFVLQ5F0Y2jrZBwRcMkaPEourRE
F4E7lq163Tz5z7JRnO7ouhI7CFPeJp9nZLjBYYr51I4bpCc1bpD6wXokI3KcyYZGZiRJdfhBqQdY
8Ld03hSnuhWVeoho67pFpnghxHgonivIklava+ZJdXEQpHn1oqyQT+sLkGdk5aFRRicHyn6yEeyU
G8HQ57CkvXWtUTGra6blT12yItQDMQ1pattFdaFhdGxGNm6h2oY/kdLixInTKRjq7DHN9ynXfNkk
KVm2TEmJEYzNri5/X5NqVbPEJCPLkaBGIMf+BEw9E5FNizajlFRNgtaNoxjHfaT4lmTJPxZH0Trx
jQO8QrhPQGYLK51sBVlj99HCXTBUvbmmkCCSgcJz0PRS9qi5wNGhqYNykqWdn85ks8zDZgz7JZ0+
gnJm4T5kzoEfsEri/KeVc2pnliLn9mNGIaSXLfMX3IZoJtf3Y0YoriefErLMU+n5H2J8CzuzQ125
Kfmzpdamt+IfwoFMH3R+4T45ZqPUEpTjyGc8VytLYIqafHS6Ir9II1vVAKM1VXnn9DPz423douNt
xd+EpUkFslRhEe8kyAZ9tHTLrEvl+Sh/0CD2KsmFokKWsQUeJnzDHFWzkYvNDaYN08iWVYibtlTz
NHLlYl8fowqOwNKUU25p9s2TP2JOgaSDrT2QzVxibYRDbUFVgZgTLB3jghNKmlQnM7PDd2vRpa0c
2qunlUK2Hycm6V3Ji9ynUjTI6jDlwurnwHPVEsirHqaQraKDN25GO8jDGjTLJPUo6MQYTeMyFDRs
iXa+mzTXFLLlTIm46b1PVs2mvsYoJrf8oRMrqcO8k2Q7JbLV1Wguv03jgX4GD+eCLCPypEKJAEno
IL4mZu9JsvHcd93SSE+ZdRbScQMBB66VxBR1HtiLZJwly5ah1hZx+lt6KE5UGyY4ylxwT547VqSF
MPFPZCMe1IBHlpwQfxB6m0YzzbOpwOKiI0qQ7UAFfbdnJ3juEHSp6745nYGIkj5rAQ6gLp9+WDae
y1O/mlnVCb+GjZGdEtymkGZXQTyRSGlikqarZRNBFIv0OZtMRSbB1Wxc+ocsbkpyUGNVqckbyUNI
tDaRPgAtJ4VL+CjK2cdV43bpc66marunkRhliAmyibukupUk2Q4LSWoHSyPFoJ+gfyfh2zKnuV2N
ypaiQk+UjT4A0pekWUt2GtTsOnbhcjQ1TBd3shqoVQ0tUrE6h7h1ZqrE+Zt6cXoZz/1UIMeRZ/bH
NWlO2mgxK6cOVjlag2w1ub4aKTnupJINpGikky1PZRPDsmX/0n0M9lPWIZUgESHUJY2TpnE88wS8
WCKr1jIbzMxZ6P35b8gGu0ZriLF/Uxe6laapAH5DiixSnmvKlga7FSalTqt+M7rNwPyNGS1DK3R+
oAAfLNK/1yBblUZsUzUWi3hayDdyH5cMgPUj6ZU0T2DrBOr9PS4aSbeD7QZ/Zwx+aJEdjiadikz3
Jnl4kULvdUgrYdZQlOkGi504hETZBImGhJ3m0RKSo3UqzhosNU30kzy3DdtlpCrloyGhIhProjtC
kI0Rc9P0XHhwpzL4OjU711+V7ovSrRCBpPbk97Dv+4ey8VxWZZnBiibrgDmDNH6SrlFNhw3vkGyw
xEoK25HTNOrf8pXw6IQc7NdaerVwFqGVBakkhXq8S5hsKZs4hiTZBPJsLX9JfCYCS6FS7DNzBzop
XjVZ5ObSXVKu7kK1RGTbL1QqmWr0iZJ8mgQEkvxWz3VjRsOwYKdIgjqIJxYJlqdXksaQXCVUZcuS
rEYrqaePytaCEddPtpNaFnTNNCWDZgnzeZuY1aluxlmg5JTaiQlbhlBInelxe/uaBP6BJveakRSa
UpTyh8S4TYnUI5/8kvQbrCH2YmilaiZ5jJpejZONE37Cxo9lyT/nB0sLWZPVTVlNW5RNohlege1t
KgnOIoVsNWIGJLAb63pjsFSQMol/Vl3/CYrEPCI4JRZl2P6w/DIypkqAPV1SexnZaAp4ptON7iN/
RsFbMf/dmATJm6WfknvsGBIJHJq+YnXxbJMACvVVlnQEVTZ5UtvC5+6CNHV4aSUp+lEmNu0Vagd1
g21l51kdHFdctcrUvalS2GCqOk3ZVD+1OPirHMWACKzmSZ+ixSzR2DtbanECk01baW2CnVPomxFj
b7u2TN6Py0l0uGiY1JZUuWyUFmjIukJXmqXmRHbHGNl4MiWNvQ0ozEZF93mn+YDA2VGElg42RmxY
4GrwzhFqMF2W1cVRlFS56VsbibnL+a8A6TU4FFKDxPdU0o/X4vjAM7FnHfNqV9MkU6JJmZEPKv74
Ur72TWFvJbaDxV6h4Ua5WG4+LBTQrYRayfBTZivGB5LC3w5kk+rxaKQnMNXqnmrCS1xIvmO60kpr
ennFbZd92eJGRGWzFHnHDh5SrGw8J+6q9A26fMzaibReX7G/wnPf6Y1hq51cnqUFh2XGSWKVwWR9
a1uCRStOMkhxX9aI47Fi3zGbcqqaKBW1Q1nR6n5JGQFOSnVF3g1l3pcKOW8sem97X6cvVBu74nTj
w9JX5lBHrMZibVqWrsQEWxiGcUzKMqF6Tmw/Hlqf5IIH++ObrE6vjUymrqb5Cklqvh+ViBMzYtHl
i0LYGzRlw1DkBTkETmjKtAu9QRZL4Zz+ml95W7FkwBVlFsbtyiHJieNGoYi0QbFsLKdR9os6+P5U
5dCQDWWhK8UgOdQ6v6jF2aeVg2IMB635CFu5hPOxO8+tS3ZRs8qzzpoJqViBta/Qr51BSC204kYR
2HrhsriUy9CDJBMSM624VomlyGeRLgwJ72qdDL+uztadzK2hv1kXs2TzX/vK7cLr8Zg7r++blAiC
IAiCIAiCIAiCbCLtzlW3e3V9k/wFFiRA7N72HNfzPNfp3V3j5ko67p3+YBgwcIadrx7QJtAeucMI
g97DV4/p92ccsrQA9/GrR/W70/ZVG3jOZNJz2cHk11cP63fnkQnljq7Gtt2+fuqRY+c+zZX/z5Hj
ivm1Xjc4MX503efg9+7d7fD27n4cfGh3KSRJ6TyNXqbHAsddv4yGj8/RWNJ5fRwNb5+u2sGJ9j20
JpZsX915V1899Q9gM1vrXYfOvfqOrf3U8waD4WDg9e78mdv9vuf1HZsjRundkWNIWrwe1751oCVp
OEv7bm4dj/Q+GLhO8Bhu3khr5wWC0MDdZNmuqbF50UXJVl9nEkpKJp2Qyp7w1Cfn7qaq3/SCpt40
ltyHLvdGTPYbB/p64sCd9jdZthc6t0FMadCZRGJr72Ym2+gXnf5MtuFMIcdX49WJpDQjeybbw6s3
3HDZnBhjo7Rdlvo6Q4f9NgjJNhrOyTb0+p4fkEdM9B5LAD3yH/38YSrb8JY23GTZxnR2zs3iJw8e
1QA+6VBFqC+aygT5yuPs2H246j72mVmOaTv4wHtqQ9SgWk3GU9nY5W/d9w31d4LNw1n88mmbLlGP
fTB26ToMy+bedjs302OPhlq69oYueMFf9IoX1hcN1t5zWLbe3VWnnXKMvyEdugDdRdfWBdPpB0kv
FcSZyTTs+SuMHQfrjR64EJRpMjgJhGGp4Uy2Qf+G22g6zhLZ7mCmTnA0dnxxfNmmyQM77vn6PHu+
bPZbyNiIufX9xevL1htzm42/SBfXC11vo1ef54G/zJhMg2mW4Sckvuz3gWzUZQ7ugsufBv7ivVka
gTYL5sLc6/nzNjMLL2DoWw+TaZapLpGNyTMIrmYX/QrO9zbYq/mMBn4qEStbdDtpKpszK7biZevE
XA5rnOVto6+e9Mdh0a+34KJZper0QkzuptY2jbyrrS18de8tsLbFh7R53LC0dDT/7U4a/Fwx+r9f
zMu0VDZxwuSZvzworjafO9/JzwVTpsDCrltK2djaX/Rhf45s44lfVF7HnO7PZwppZeu6cc/iD5KN
u/dTUGf40O1eUWA/jVmh+9wZtxnvko2FlMGwexO5/A+SjXsIXsBAwtAnTF65oFodeq7v0O/fJRv3
q+cXrv7lN3+cbNxDNF3wE4Tr8MZRkOCmlo17DnfqvXJ/nmzc1ZsXmuPEz0au32a7aG/j98rGPc+2
KQdD/k+UjWs/O+7An2X/dXr2pedvorlBOTTdFA+O6aZ4cHzvkINJEFs6bFM8nBbSTXH3JcWINgT7
+uG2T1LayVt4p3d8fzdwXdcZBefse8pMtshx55X8/jpLnTvPjx65fPI87Q8a3G/w/mSsdLYtiuJc
0iD4mepq+BV9Jl+NIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIAiCIMi7+R9q71wN
8tTeyQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wOS0xN1QwOToyOTowMyswMDowMOYl2WcAAAAl
dEVYdGRhdGU6bW9kaWZ5ADIwMjQtMDktMTdUMDk6Mjk6MDMrMDA6MDCXeGHbAAAAKHRFWHRkYXRl
OnRpbWVzdGFtcAAyMDI0LTA5LTE3VDA5OjI5OjAzKzAwOjAwwG1ABAAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default Cerner;
