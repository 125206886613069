import React from 'react';
import { FaSearch } from 'react-icons/fa';

const DiffernetialDiagnosis = () => {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <header className=" text-white  mb-4">
        <div className=" mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold text-black">
            Differential Diagnosis
          </h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="pl-10 pr-4 py-2 rounded-md border text-black border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
          </div>
        </div>
      </header>

      <main className="flex-1 ">
        <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
          <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Diagnosis Summary</h2>
            <p className="text-gray-600">
              Overview of possible diagnoses based on the provided data.
            </p>
          </section>

          <section className="col-span-1 md:col-span-2 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Possible Diagnoses</h2>
            <ul className="space-y-4">
              {[1, 2, 3].map((item) => (
                <li key={item} className="border-b pb-4">
                  <h3 className="text-lg font-semibold">Diagnosis {item}</h3>
                  <p className="text-gray-600">
                    Description and details about Diagnosis {item}.
                  </p>
                  <button className="mt-2 text-blue-600 hover:underline">
                    More Info
                  </button>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </main>
    </div>
  );
};

export default DiffernetialDiagnosis;
