import React from 'react';
import AvahiIcon from '../../assets/icons/AvahiIcon';
import ThemeToggle from './ThemeToggle';

const LeftPanelHeader = ({ isOpen, setSelectedLanguage, selectedLanguage }) => {
  return (
    <div
      className={`flex items-center ${
        isOpen ? 'justify-between' : 'justify-center'
      }  py-4 px-3`}
    >
      <AvahiIcon />

      {isOpen && (
        <ThemeToggle
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
        />
      )}
    </div>
  );
};

export default LeftPanelHeader;
