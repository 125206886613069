import React from 'react';

const AvahiIcon = () => {
  return (
    <svg
      width="42"
      height="40"
      viewBox="0 0 42 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1357_9658)">
        <path
          d="M26.9851 25.1825C26.9514 25.1202 26.9312 25.0807 26.9102 25.0428C24.3089 20.5374 21.7075 16.0319 19.1045 11.5273C19.0608 11.4524 19.0624 11.4003 19.107 11.3254C19.6739 10.37 20.2374 9.41207 20.8009 8.45496C20.8614 8.35235 20.922 8.24975 20.9943 8.12695C24.9161 14.7762 28.8286 21.4104 32.7487 28.0563H18.1196C18.1423 28.0134 18.16 27.9789 18.1793 27.9444C18.6949 27.0563 19.2121 26.169 19.7252 25.28C19.7689 25.2043 19.8177 25.1791 19.9035 25.1791C22.2096 25.1816 24.5149 25.1808 26.8211 25.1808C26.8682 25.1808 26.9153 25.1808 26.9851 25.1808V25.1825Z"
          fill="#377FFF"
        />
        <path
          d="M18.7349 19.3682C18.7593 19.4077 18.7778 19.4354 18.7938 19.464C19.3683 20.4565 19.9418 21.4489 20.5196 22.4397C20.5667 22.5204 20.5684 22.5784 20.5205 22.66C19.3868 24.6137 18.2564 26.5692 17.1252 28.5246C17.0966 28.5734 17.0722 28.6238 17.0352 28.6928C17.1075 28.6928 17.158 28.6928 17.2076 28.6928C22.4633 28.6928 27.7181 28.6928 32.9738 28.6903C33.0848 28.6903 33.1428 28.7248 33.1984 28.819C33.7728 29.7946 34.3523 30.7668 34.9301 31.7399C34.9519 31.7769 34.9721 31.8148 34.9999 31.8635H11.5264C13.9301 27.6962 16.3296 23.5381 18.7358 19.3682H18.7349Z"
          fill="#377FFF"
        />
        <path
          d="M18.6838 12.0562C21.0866 16.2193 23.4861 20.3766 25.8966 24.5507C25.8436 24.5507 25.8108 24.5507 25.778 24.5507C24.709 24.5507 23.64 24.5498 22.5711 24.5524C22.4886 24.5524 22.4441 24.5263 22.4029 24.4548C21.2103 22.3808 20.0151 20.3085 18.82 18.2361C18.7981 18.1983 18.7746 18.1613 18.7426 18.1083C18.7107 18.1596 18.6846 18.1974 18.6611 18.237C16.0631 22.7408 13.4659 27.2437 10.8705 31.7483C10.8175 31.8408 10.7595 31.872 10.6543 31.872C9.47939 31.8686 8.30446 31.8694 7.12868 31.8694H7C10.8999 25.2563 14.7897 18.6609 18.6838 12.0562Z"
          fill="#377FFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1357_9658">
          <rect
            width="28"
            height="23.7443"
            fill="white"
            transform="translate(7 8.12793)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvahiIcon;
