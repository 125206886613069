/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import MassageIcon from '../../assets/icons/MassageIcon';

const ProjectsData = [
  {
    id: 1,
    Icon: <MassageIcon />,
    name: 'B2B Discovery Interview Q2',
    images: ['/images/JackSmithImg.png'],
    massage: 'Updated 1h ago',
  },
  {
    id: 2,
    Icon: <MassageIcon />,
    name: 'Moderated Usability Test',
    images: [
      '/images/JackSmithImg.png',
      '/images/JackSmithImg2.png',
      '/images/JackSmithImg2.png',
    ],
    massage: 'Updated yesterday',
  },
  {
    id: 3,
    Icon: <MassageIcon />,
    name: 'B2B Discovery Interview Q1',
    images: ['/images/JackSmithImg.png'],
    massage: 'Updated 02/09/22',
  },
];

const ProjectsSection = () => {
  return (
    <div className="w-full grid grid-cols-3 gap-6">
      <div className="col-span-3 flex items-center justify-between">
        <h3 className="text-black dark:text-white font-medium text-xl tracking-[-2%] font-poppins">
          Projects you’re working on
        </h3>
        <button
          type="button"
          className="text-[#9747FF] font-semibold text-base font-poppins tracking-[-2%]"
        >
          See all
        </button>
      </div>

      {ProjectsData?.map(({ id, Icon, images, massage, name }) => (
        <div
          key={id}
          className="py-6 w-full xl:col-span-1 col-span-3 px-9 border rounded-2xl dark:border-gray-600 border-[#E2E0E5] flex flex-col gap-[30px]"
        >
          <div className="flex flex-col items-start gap-4">
            <span className="text-black dark:text-white">{Icon}</span>
            <p className="font-poppins font-medium text-base text-black dark:text-white">
              {name}
            </p>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex">
              {images?.length > 2 ? (
                <div className="flex">
                  <img
                    src={`${images[0]}`}
                    alt="image"
                    height={'28px'}
                    width={'28px'}
                    className="rounded-full min-h-7 min-w-7 "
                  />
                  <img
                    src={`${images[1]}`}
                    alt="image"
                    height={'28px'}
                    width={'28px'}
                    className="rounded-full min-h-7 min-w-7 -translate-x-3"
                  />

                  <div className="rounded-full flex items-center -translate-x-6 justify-center text-xs font-normal font-poppins min-h-7 min-w-7 bg-[#F1EBF8] border border-[#FCFCFC]">
                    +3
                  </div>
                </div>
              ) : (
                <div className="flex">
                  {images?.map((src, index) => (
                    <img
                      key={index}
                      src={`${src}`}
                      alt="image"
                      height={'28px'}
                      width={'28px'}
                      className={`rounded-full min-h-7 min-w-7 ${
                        index === 1 ? '-translate-x-3' : ''
                      }`}
                    />
                  ))}
                </div>
              )}
            </div>

            <span className="text-[#A1A1A1] text-sm font-poppins font-normal">
              {massage}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectsSection;
