import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import axios from 'axios';

import FileUpload from './FileUpload';
import SummarizerUploadedFileText from './SummarizerUploadedFileText';
import SingleFileUploadModel from '../SingleFileUploadModel';
import TranslateTextHeader from '../TranslateTextHeader';

const REQUIREMENTS = [
  {
    id: 1,
    label: 'Enhances and expedites text conversion in an intuitive layout.',
    img: '/images/planIcon.png',
  },
  {
    id: 2,
    label: 'Upload .txt, .dox, and .pdf files.',
    img: '/images/BulbIcon.png',
  },
  {
    id: 3,
    label: 'Generate summaries and view the original text side-by-side.',
    img: '/images/InfoIcon.png',
  },
];

const SummarizerNew = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [summarizerText, setSummarizerText] = useState(null);
  const [uploadFileText, setUploadFileText] = useState(null);
  const [isAline, setIsAline] = useState('horizontal');
  const [fileModelOpen, setFileModelOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isShowInput, setIsShowInput] = useState(false);
  const [isDisableSummriz, setIsDisableSummriz] = useState(true);
  const [isActiveAline, setIsActiveAline] = useState(true);
  const [isGetStarted, setIsGetStarted] = useState(false);

  const handleSummarize = async () => {
    if (uploadFileText?.trim()) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/api/summarize`,
          {
            input_text: uploadFileText,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.statusCode === 200) {
          setIsDisableSummriz(true);
          setIsLoading(false);
          setIsActiveAline(false);
          const parsedText = JSON.parse(response.data.body);
          setSummarizerText(parsedText?.summary);
        } else {
          toast.error('Summarize failed');
          throw new Error('Summarize failed');
        }
      } catch (error) {
        console.error('Error', error);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window !== undefined) {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 1024) {
          setIsAline('vertical');
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleUploadFile = async () => {
    setIsFileLoading(true);
    if (file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/api/extract/upload_doc_and_extract_text`,
          formData,
          {
            maxBodyLength: Infinity,
          }
        );

        if (response.data.statusCode === 200) {
          setIsDisableSummriz(false);
          setFile(null);
          setIsFileLoading(false);
          setFileModelOpen(false);
          const parsedText = JSON.parse(response.data.body);
          setUploadFileText(parsedText?.extracted_text);
          setIsShowInput(true);
          toast.success('File uploaded successfully!');
        } else {
          toast.error('File upload failed.');
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error', error);
        toast.error('Error uploading file.');
      } finally {
        setIsFileLoading(false);
      }
    }
  };

  return (
    <div className="flex relative font-poppins flex-col h-[calc(100vh-72px)] lg:h-screen bg-[#FCFCFC] dark:bg-gray-900 overflow-y-auto">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="h-full"
        >
          <TranslateTextHeader
            setIsAline={setIsAline}
            isActiveAline={isActiveAline}
            isAline={isAline}
            title={'Summarizer'}
          />

          {!isGetStarted && (
            <div className="h-[calc(100vh-60px)] py-3">
              <FileUpload
                title={'Smart Summarizer'}
                setIsGetStarted={setIsGetStarted}
                data={REQUIREMENTS}
              />
            </div>
          )}

          {(isShowInput || isGetStarted) && (
            <SummarizerUploadedFileText
              handleSummarize={handleSummarize}
              isDisableSummriz={isDisableSummriz}
              isLoading={isLoading}
              setFileModelOpen={setFileModelOpen}
              setUploadFileText={setUploadFileText}
              setIsDisableSummriz={setIsDisableSummriz}
              summarizerText={summarizerText}
              uploadFileText={uploadFileText}
              isAline={isAline}
              setIsAline={setIsAline}
            />
          )}
        </motion.div>
      </AnimatePresence>

      {isLoading && (
        <div className="fixed top-0 left-0 h-screen w-screen flex items-center justify-center backdrop-blur-sm">
          <span className="loader" />
        </div>
      )}

      <SingleFileUploadModel
        handleUploadFile={handleUploadFile}
        isOpen={fileModelOpen}
        file={file}
        setFile={setFile}
        setIsOpen={setFileModelOpen}
        isLoading={isFileLoading}
      />
    </div>
  );
};

export default SummarizerNew;
