import React from 'react';

const QuationIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.625"
        y="0.625"
        width="23.75"
        height="23.75"
        rx="11.875"
        stroke="#B1B1B1"
        strokeWidth="1.25"
      />
      <path
        d="M14.1779 12.4842C13.8781 12.7742 13.5811 13.0615 13.3698 13.4495C13.2584 13.6502 13.1892 13.8249 13.1464 14.1462L13.1464 14.1462L13.146 14.1493C13.1076 14.4602 12.8559 14.6875 12.5624 14.6875C12.1943 14.6875 11.9081 14.3707 11.9478 14.0236C11.9827 13.7269 12.0503 13.4407 12.1975 13.1777L12.1982 13.1764C12.4123 12.79 12.7298 12.4743 13.0883 12.1416C13.127 12.1056 13.1664 12.0693 13.2062 12.0326C13.5222 11.7414 13.8645 11.4262 14.1244 11.0546C14.4723 10.561 14.5452 9.86694 14.2914 9.29049C14.0269 8.68971 13.4249 8.25 12.5061 8.25C11.6445 8.25 11.0802 8.70763 10.7434 9.23083L10.7433 9.23079L10.7398 9.23648C10.6132 9.44278 10.3309 9.52994 10.0703 9.42411C9.73547 9.28052 9.61628 8.87708 9.79703 8.60515C10.3505 7.78392 11.2695 7.1875 12.4936 7.1875C13.8593 7.1875 14.7616 7.80438 15.2144 8.54405C15.4056 8.85835 15.5645 9.32441 15.5958 9.8265C15.627 10.3265 15.5308 10.8392 15.2411 11.2686C14.8851 11.7937 14.5373 12.1362 14.2184 12.445C14.2049 12.4581 14.1914 12.4712 14.1779 12.4842ZM13.4374 17.5C13.4374 18.0149 13.0148 18.4375 12.4999 18.4375C11.985 18.4375 11.5624 18.0149 11.5624 17.5C11.5624 16.9851 11.985 16.5625 12.4999 16.5625C13.0148 16.5625 13.4374 16.9851 13.4374 17.5Z"
        fill="#B1B1B1"
        stroke="#B1B1B1"
        strokeWidth="0.625"
      />
    </svg>
  );
};

export default QuationIcon;
