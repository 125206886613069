/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuthStore from '../stores/authStore';
import { motion, AnimatePresence } from 'framer-motion';
import {
  User,
  Mail,
  Lock,
  Eye,
  EyeOff,
  Briefcase,
  ChevronDown,
} from 'react-feather';
import { redirectBasedOnRole } from '../functions/redirectBasedOnRole';

const InputField = ({
  icon: Icon,
  value,
  onChange,
  type,
  placeholder,
  name,
  error,
  showPassword,
  togglePasswordVisibility,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative mb-4">
      <div className="relative">
        <div
          className={`absolute top-1/2 left-3 transform -translate-y-1/2 transition-all duration-300 ${
            isFocused || value
              ? 'text-blue-500 dark:text-blue-400'
              : 'text-gray-400 dark:text-gray-500'
          }`}
        >
          <Icon size={20} />
        </div>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          className={`w-full font-poppins pl-10 pr-3 py-2 text-sm md:text-base rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-white border ${
            error
              ? 'border-red-300 dark:border-red-500'
              : isFocused
              ? 'border-blue-500 dark:border-blue-400'
              : 'border-gray-200 dark:border-gray-700'
          } focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent focus:outline-none transition-all duration-300`}
          aria-label={placeholder}
        />
        {name === 'password' && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 dark:text-gray-500 hover:text-blue-500 dark:hover:text-blue-400 focus:outline-none transition-colors duration-300"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
          </button>
        )}
      </div>
      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-red-500 dark:text-red-400 text-xs mt-1 absolute"
          >
            {error}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

const CustomDropdown = ({ value, onChange, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const roles = [
    'Customer',
    'HR Employee',
    'IT Employee',
    'Sales Employee',
    'HR Manager',
    'IT Manager',
    'Sales Manager',
    'Super Admin',
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectRole = (role) => {
    onChange({ target: { name: 'role', value: role } });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  return (
    <div className="relative font-poppins" ref={dropdownRef}>
      <div
        className={`flex items-center justify-between w-full font-poppins pl-10 pr-3 py-2 text-sm md:text-base rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-white border ${
          error
            ? 'border-red-300 dark:border-red-500'
            : 'border-gray-200 dark:border-gray-700'
        } focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent focus:outline-none transition-all duration-300 cursor-pointer`}
        onClick={toggleDropdown}
      >
        <span
          className={
            value
              ? 'text-gray-900 font-poppins dark:text-white'
              : 'text-gray-400 font-poppins dark:text-gray-500'
          }
        >
          {value || 'Select Role'}
        </span>
        <ChevronDown
          size={20}
          className={`text-gray-400 dark:text-gray-500 transition-transform duration-300 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg max-h-60 overflow-auto">
          {roles.map((role) => (
            <div
              key={role}
              className="px-4 py-2 hover:bg-blue-50 font-poppins dark:hover:bg-blue-900 cursor-pointer text-gray-800 dark:text-gray-200 transition-colors duration-200"
              onClick={() => handleSelectRole(role)}
            >
              {role}
            </div>
          ))}
        </div>
      )}
      {error && (
        <p className="text-red-500 dark:text-red-400 font-poppins text-xs mt-1">
          {error}
        </p>
      )}
    </div>
  );
};

const Register = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    password: '',
    role: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { register } = useAuthStore();
  const navigate = useNavigate();

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  }, []);

  const validateForm = () => {
    let newErrors = {};
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.company) newErrors.last_name = 'Company is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters long';
    }
    if (!formData.role) newErrors.role = 'Role is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (validateForm()) {
        try {
          const result = await register(formData);
          if (result.success) {
            redirectBasedOnRole(result.user.role, navigate);
          } else {
            setErrors({
              form: result.error || 'Registration failed. Please try again.',
            });
          }
        } catch (error) {
          setErrors({ form: 'Registration failed. Please try again.' });
        }
      }
    },
    [formData, register, navigate]
  );

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-xl space-y-6 bg-white dark:bg-gray-800 p-6 sm:p-8 rounded-xl shadow-lg transition-all duration-500">
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl font-poppins font-semibold text-gray-900 dark:text-white mb-2">
            Create an Account
          </h2>
          <p className="text-gray-600 font-poppins dark:text-gray-400 text-sm sm:text-base">
            Join us and start your journey
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <InputField
              icon={User}
              value={formData.first_name}
              onChange={handleChange}
              type="text"
              placeholder="First Name"
              name="first_name"
              error={errors.first_name}
            />
            <InputField
              icon={User}
              value={formData.last_name}
              onChange={handleChange}
              type="text"
              placeholder="Last Name"
              name="last_name"
              error={errors.last_name}
            />
          </div>
          <InputField
            icon={Mail}
            value={formData.email}
            onChange={handleChange}
            type="email"
            placeholder="Email"
            name="email"
            error={errors.email}
          />
          <InputField
            icon={Mail}
            value={formData.company}
            onChange={handleChange}
            type="text"
            placeholder="Company"
            name="company"
            error={errors.company}
          />
          <InputField
            icon={Lock}
            value={formData.password}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            name="password"
            error={errors.password}
            showPassword={showPassword}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <div className="relative">
            <div className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400 dark:text-gray-500 z-10">
              <Briefcase size={20} />
            </div>
            <CustomDropdown
              value={formData.role}
              onChange={handleChange}
              error={errors.role}
            />
          </div>
          <AnimatePresence>
            {errors.form && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="text-red-500 font-poppins dark:text-red-400 text-sm text-center"
              >
                {errors.form}
              </motion.p>
            )}
          </AnimatePresence>
          <motion.button
            type="submit"
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            className="w-full px-4 py-2 text-sm font-poppins sm:text-base bg-blue-600 dark:bg-blue-500 text-white rounded-md hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:ring-offset-2 transition-all duration-300 transform"
          >
            Register
          </motion.button>
        </form>
        <p className="text-center text-sm font-poppins text-gray-600 dark:text-gray-400">
          Already have an account?{' '}
          <Link
            to="en/login"
            className="font-medium font-poppins text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-200"
          >
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
