import React, { useCallback, useState } from "react";
import useAuthStore from "../stores/authStore";
import { AnimatePresence, motion } from "framer-motion";
import { Mail, AlertTriangle } from "react-feather";
import axios from "axios";
import { toast } from "react-toastify";

const InputField = ({ icon: Icon, value, onChange, type, placeholder, name, error }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative mb-6">
      <div className="relative">
        <div
          className={`absolute top-1/2 left-3 transform -translate-y-1/2 transition-all duration-300 ${
            isFocused || value
              ? "text-blue-500 dark:text-blue-400"
              : "text-gray-400 dark:text-gray-500"
          }`}
        >
          <Icon size={20} />
        </div>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          name={name}
          className={`w-full font-poppins pl-10 pr-3 py-3 text-base md:text-lg rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border ${
            error
              ? "border-red-300 dark:border-red-500"
              : isFocused
              ? "border-blue-500 dark:border-blue-400"
              : "border-gray-200 dark:border-gray-700"
          } focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent focus:outline-none transition-all duration-300`}
          aria-label={placeholder}
        />
      </div>
      <AnimatePresence>
        {error && (
          <motion.p
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="text-red-500 dark:text-red-400 text-sm mt-2 flex items-center"
          >
            <AlertTriangle size={16} className="mr-2" />
            {error}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

const DeleteUserPopup = () => {
  const { toggleDeleteAccountPopup } = useAuthStore();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback((e) => {
    setEmail(e.target.value);
    setError("");
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email) {
        setError("Please enter your email address");
        return;
      }
      setIsSubmitting(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_PATH}/api/delete`, { email });
        if (response.status === 200) {
          toast.success("Email has been deleted successfully!");
          toggleDeleteAccountPopup();
        } else {
          setError("Failed to delete email. Please try again.");
        }
      } catch (error) {
        setError(error.response?.data?.message || "An error occurred. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    },
    [email, toggleDeleteAccountPopup]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 dark:bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, y: 50 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="bg-white dark:bg-gradient-to-br dark:from-gray-900 dark:to-gray-800 rounded-2xl p-6 sm:p-10 w-full max-w-lg shadow-2xl"
      >
        <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center text-gray-900 dark:text-white font-poppins">
          Delete Your Account
        </h2>
        <p className="text-lg text-gray-600 dark:text-gray-300 mb-8 text-center font-poppins">
          Please enter your email address to confirm account deletion. This action cannot be undone.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <InputField
            icon={Mail}
            value={email}
            onChange={handleChange}
            type="email"
            placeholder="Enter your email"
            name="email"
            error={error}
          />
          <motion.div className="space-y-4">
            <motion.button
              type="submit"
              disabled={isSubmitting}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full py-3 px-4 rounded-lg text-lg font-semibold text-white bg-red-600 hover:bg-red-700 dark:bg-gradient-to-r dark:from-red-500 dark:to-red-600 dark:hover:from-red-600 dark:hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 transition duration-300 ease-in-out transform hover:-translate-y-1 font-poppins disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? "Deleting..." : "Delete Account"}
            </motion.button>
            <motion.button
              type="button"
              onClick={toggleDeleteAccountPopup}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full py-3 px-4 rounded-lg text-lg font-semibold text-gray-700 dark:text-gray-300 bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-900 transition duration-300 font-poppins"
            >
              Cancel
            </motion.button>
          </motion.div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default DeleteUserPopup;