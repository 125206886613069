import React, { useRef } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md';

const RecordingComplete = ({
  startRecording,
  recordingTotalTime,
  handleFileChange,
  audioURL,
}) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const audioRef = useRef(null);

  console.log('audioRef?.current', audioRef?.current?.duration);

  return (
    <div
      className={`flex items-center flex-col gap-6 justify-center rounded-lg border-2 border-dashed border-blue-500 px-2 py-16 transition-all md:px-10`}
    >
      <button onClick={startRecording}>
        <img
          src={`/images/PauseRecordingIcon.png`}
          alt="mic-img"
          className="size-[150px]"
        />
      </button>

      <p className="font-poppins text-base font-semibold text-black">
        Total time {formatTime(recordingTotalTime)}
      </p>

      <div className="max-w-[375px] mt-6 w-full flex items-center justify-between">
        <div className="flex items-center gap-6">
          <button
            onClick={startRecording}
            className="bg-blue-500 px-4 py-2 rounded-md font-medium text-base text-white"
          >
            Record another
          </button>

          <span className="font-poppins font-medium text-base">or</span>

          <input
            type="file"
            accept="audio/mp3"
            onChange={handleFileChange}
            className="hidden"
            id="file-upload"
          />
          <label
            htmlFor="file-upload"
            className="bg-blue-500 flex items-center gap-2.5 px-4 py-2 rounded-md font-medium text-base text-white"
          >
            <MdOutlineFileUpload size={24} />
            Upload file
          </label>
        </div>
      </div>

      {audioURL && (
        <audio
          src={audioURL}
          ref={audioRef}
          type="audio/mpeg"
          className="w-full max-w-[375px] mx-auto"
          controls
        >
          Your browser does not support the audio element.
        </audio>
      )}
    </div>
  );
};

export default RecordingComplete;
