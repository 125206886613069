import { create } from 'zustand';
import Cookies from 'js-cookie';
import axios from 'axios';

const getUserFromCookie = () => {
  const userString = Cookies.get('user');
  if (userString) {
    try {
      return JSON.parse(userString);
    } catch (error) {
      console.error('Error parsing user cookie:', error);
    }
  }
  return null;
};

const initialUser = getUserFromCookie();
const initialToken = Cookies.get('token');

const useAuthStore = create((set) => ({
  isLoggedIn: !!initialToken,
  user: initialUser,
  showLoginPopup: false,
  showLogoutPopup: false,
  showDeleteAccountPopup: false,

  login: async (credentials) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/login`,
        credentials
      );

      if (response.data.success) {
        const userData = {
          email: response.data.email,
          username: response.data.userName,
          userID: response.data.userID,
          role: response.data.role,
          companyFolder: response.data.companyFolder,
        };
        Cookies.set('token', 'dummy-token', { expires: 7 });
        Cookies.set('user', JSON.stringify(userData), { expires: 7 });
        set((state) => ({
          isLoggedIn: true,
          user: userData,
          showLoginPopup: false,
        }));
        return { success: true, user: userData };
      } else {
        return { success: false, error: response.data.error };
      }
    } catch (error) {
      return { success: false, error: 'Login failed. Please try again.' };
    }
  },

  logout: () => {
    Cookies.remove('token');
    Cookies.remove('user');
    set({ isLoggedIn: false, user: null, showLogoutPopup: false });
  },

  register: async (userData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_PATH}/api/sign-in`,
        {
          firstName: userData.first_name,
          lastName: userData.last_name,
          email: userData.email,
          role: userData.role,
          password: userData.password,
          companyFolder: userData.company,
        }
      );

      if (response.data.success) {
        const newUser = {
          email: userData.email,
          userID: response.data.userID,
          role: userData.role,
          companyFolder: userData.company,
          username: userData.first_name,
        };
        Cookies.set('token', 'dummy-token', { expires: 7 });
        Cookies.set('user', JSON.stringify(newUser), { expires: 7 });
        set((state) => ({
          isLoggedIn: true,
          user: newUser,
          showLoginPopup: false,
        }));
        return { success: true, user: newUser };
      } else {
        return { success: false, error: response.data.error };
      }
    } catch (error) {
      return {
        success: false,
        error: 'Registration failed. Please try again.',
      };
    }
  },

  toggleLoginPopup: () =>
    set((state) => ({ showLoginPopup: !state.showLoginPopup })),

  toggleDeleteAccountPopup: () =>
    set((state) => ({ showDeleteAccountPopup: !state.showDeleteAccountPopup })),

  toggleLogoutPopup: () =>
    set((state) => ({ showLogoutPopup: !state.showLogoutPopup })),
}));

export default useAuthStore;
