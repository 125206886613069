import React from 'react';

const ChangeScreenIcon = () => {
  return (
    <svg
      width="40"
      height="36"
      viewBox="0 0 40 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 9L16 9V11H13V14H11V9ZM11 22H13L13 25H16V27H11V22ZM24 9H29V14H27V11H24V9ZM24 25H27V22H29V27H24V25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChangeScreenIcon;
