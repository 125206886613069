import React from 'react';

const MassageIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6445 4.58594V10.5793C23.6445 11.3909 23.9679 12.1709 24.5445 12.7459C25.1227 13.3216 25.9054 13.6446 26.7212 13.6443H33.5962"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.745 12.7414H18.1867M12.745 19.9964H27.255M12.745 27.2514H27.255M33.75 14.2764V28.5564C33.7168 29.4904 33.4992 30.4087 33.1096 31.2582C32.72 32.1077 32.1662 32.8718 31.48 33.5064C30.0926 34.7933 28.251 35.4767 26.36 35.4064H13.71C12.7678 35.45 11.8264 35.3069 10.9397 34.9854C10.053 34.664 9.23861 34.1704 8.54333 33.5331C7.85081 32.8965 7.29151 32.1289 6.89785 31.2746C6.50418 30.4203 6.28398 29.4964 6.25 28.5564V11.4331C6.28309 10.499 6.5007 9.58076 6.8903 8.7312C7.27989 7.88164 7.83377 7.11759 8.52 6.48306C9.90736 5.19612 11.749 4.51271 13.64 4.58307H23.1633C24.617 4.57841 26.0202 5.11626 27.0983 6.0914L32.0317 10.6281C32.558 11.0816 32.983 11.6408 33.2791 12.2694C33.5751 12.8979 33.7356 13.5818 33.75 14.2764Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MassageIcon;
