import React, { useState } from "react";

const GenerateContent = () => {
  const [input, setInput] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");

  const handleGenerate = () => {
    setGeneratedContent("AI Generated Content based on: " + input);
  };

  const handleReset = () => {
    setInput("");
    setGeneratedContent("");
  };
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Generate Content
          </h2>
          <p className="text-gray-600 mb-4">
            Enter your prompt below and customize the output options to generate
            AI-powered content.
          </p>

          <div className="mb-6">
            <label
              htmlFor="input"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Enter Your Prompt:
            </label>
            <textarea
              id="input"
              rows="4"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Type your content idea or prompt here..."
            />
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold text-gray-800">
              Customize Output
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 max-w-xl">
              <div>
                <label
                  htmlFor="tone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tone:
                </label>
                <select
                  id="tone"
                  className="w-full mt-2 p-2 border border-gray-300 rounded-lg"
                >
                  <option>Formal</option>
                  <option>Casual</option>
                  <option>Professional</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="length"
                  className="block text-sm font-medium text-gray-700"
                >
                  Length:
                </label>
                <select
                  id="length"
                  className="w-full mt-2 p-2 border border-gray-300 rounded-lg"
                >
                  <option>Short</option>
                  <option>Medium</option>
                  <option>Long</option>
                </select>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex space-x-4">
            <button
              onClick={handleGenerate}
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition-transform transform hover:scale-105"
            >
              Generate
            </button>
            <button
              onClick={handleReset}
              className="bg-gray-300 text-gray-700 py-2 px-6 rounded-lg shadow-lg hover:bg-gray-400 transition-transform transform hover:scale-105"
            >
              Reset
            </button>
          </div>
        </section>

        {generatedContent && (
          <section className="bg-white shadow-lg rounded-lg p-8 mt-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              Generated Content
            </h3>
            <div className="bg-gray-100 p-4 rounded-lg text-gray-800">
              {generatedContent}
            </div>
            <button className="mt-4 bg-green-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-green-600 transition-transform transform hover:scale-105">
              Download Content
            </button>
          </section>
        )}
      </main>
    </div>
  );
};

export default GenerateContent;
