import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4047_38018)">
        <path
          d="M8.25 4.5C8.25 5.09674 8.01295 5.66903 7.59099 6.09099C7.16903 6.51295 6.59674 6.75 6 6.75C5.40326 6.75 4.83097 6.51295 4.40901 6.09099C3.98705 5.66903 3.75 5.09674 3.75 4.5C3.75 3.90326 3.98705 3.33097 4.40901 2.90901C4.83097 2.48705 5.40326 2.25 6 2.25C6.59674 2.25 7.16903 2.48705 7.59099 2.90901C8.01295 3.33097 8.25 3.90326 8.25 4.5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C7.5913 0 9.11742 0.632141 10.2426 1.75736C11.3679 2.88258 12 4.4087 12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6ZM6 0.75C5.01133 0.750053 4.04277 1.02927 3.2058 1.55552C2.36883 2.08177 1.69746 2.83366 1.26897 3.72465C0.840484 4.61563 0.67229 5.6095 0.783749 6.59187C0.895208 7.57423 1.28179 8.50516 1.899 9.2775C2.4315 8.4195 3.60375 7.5 6 7.5C8.39625 7.5 9.56775 8.41875 10.101 9.2775C10.7182 8.50516 11.1048 7.57423 11.2163 6.59187C11.3277 5.6095 11.1595 4.61563 10.731 3.72465C10.3025 2.83366 9.63117 2.08177 8.7942 1.55552C7.95723 1.02927 6.98867 0.750053 6 0.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4047_38018">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
