import React, { useState } from 'react';

const ReviewContracts = () => {
  const [file, setFile] = useState(null);
  const [contractText, setContractText] = useState('');
  const [aiAnalysis, setAiAnalysis] = useState(null);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setContractText('Sample contract content goes here...');
    }
  };

  const handleAnalyzeContract = () => {
    setAiAnalysis({
      risks: 'No significant risks detected.',
      keyTerms: 'Non-disclosure, Termination, Payment Schedule',
    });
  };

  const handleReset = () => {
    setFile(null);
    setContractText('');
    setAiAnalysis(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex flex-col">
      <main className="flex-grow w-full mx-auto px-6 py-12">
        <section className="bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Review Your Contract with AI
          </h2>
          <p className="text-gray-600 mb-4">
            Upload your contract, and our AI will analyze key terms and
            potential risks.
          </p>

          <div className="mb-6 max-w-xl">
            <label
              htmlFor="upload"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Upload Contract (PDF/DOCX):
            </label>
            <input
              type="file"
              id="upload"
              accept=".pdf,.docx"
              onChange={handleFileUpload}
              className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {contractText && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800">
                Contract Text
              </h3>
              <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
                <pre className="whitespace-pre-wrap text-gray-800">
                  {contractText}
                </pre>
              </div>
            </div>
          )}

          {aiAnalysis && (
            <section className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                AI Analysis
              </h3>
              <div className="bg-gray-100 p-4 rounded-lg">
                <p className="text-gray-800 mb-2">
                  <strong>Risks Detected:</strong> {aiAnalysis.risks}
                </p>
                <p className="text-gray-800">
                  <strong>Key Terms:</strong> {aiAnalysis.keyTerms}
                </p>
              </div>
            </section>
          )}

          {file && (
            <div className="flex space-x-4">
              <button
                onClick={handleAnalyzeContract}
                className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-2 px-6 rounded-lg shadow-lg hover:from-blue-600 hover:to-purple-700 transition-transform transform hover:scale-105"
              >
                Analyze Contract
              </button>
              <button
                onClick={handleReset}
                className="bg-gray-300 text-gray-700 py-2 px-6 rounded-lg shadow-lg hover:bg-gray-400 transition-transform transform hover:scale-105"
              >
                Reset
              </button>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default ReviewContracts;
