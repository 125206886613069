/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

const VirtualDrugTrial = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-10 px-6">
      <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        <header className="bg-blue-500 text-white py-4 px-6">
          <h1 className="text-3xl font-bold">Virtual Drug Trial Platform</h1>
          <p className="mt-1 text-lg">Optimizing drug trials with AI</p>
        </header>

        <main className="p-6">
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">About the Trial</h2>
            <p className="text-gray-700 leading-relaxed">
              This platform leverages advanced AI technologies to simulate drug
              trials in a virtual environment, providing insights and optimizing
              the trial process. Our goal is to accelerate drug development
              while maintaining rigorous standards.
            </p>
          </section>

          <section className="bg-gray-50 p-6 rounded-lg mb-8">
            <h2 className="text-2xl font-semibold mb-4">Key Features</h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">
                  AI-Powered Simulations
                </h3>
                <p className="text-gray-600">
                  Our AI algorithms simulate real-world scenarios to predict
                  trial outcomes and identify potential issues.
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">
                  Real-Time Data Analysis
                </h3>
                <p className="text-gray-600">
                  Analyze trial data in real-time to make informed decisions
                  quickly and efficiently.
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">
                  Compliance and Reporting
                </h3>
                <p className="text-gray-600">
                  Ensure compliance with regulatory standards and generate
                  comprehensive reports effortlessly.
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">
                  User-Friendly Interface
                </h3>
                <p className="text-gray-600">
                  An intuitive interface that makes navigating the platform and
                  managing trials straightforward.
                </p>
              </div>
            </div>
          </section>

          <section className="text-center">
            <h2 className="text-2xl font-semibold mb-4">Get Started</h2>
            <p className="text-gray-700 mb-6">
              Ready to optimize your drug trials with our AI-powered platform?
              Contact us to schedule a demo or start your free trial today.
            </p>
            <a
              href="#"
              className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-600 transition duration-300"
            >
              Schedule a Demo
            </a>
          </section>
        </main>
      </div>
    </div>
  );
};

export default VirtualDrugTrial;
