import React from 'react';
import { MdOutlineSaveAlt } from 'react-icons/md';
import { StarIcon } from '../../assets/icons/StarIcon';
import { LuUpload } from 'react-icons/lu';
import CloseIcon from '../../assets/icons/CloseIcon';
import jsPDF from 'jspdf';
import { RiFileCopy2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

const SummarizerUploadedFileText = ({
  isLoading,
  uploadFileText,
  summarizerText,
  setFileModelOpen,
  handleSummarize,
  isDisableSummriz,
  isAline,
  setUploadFileText,
  setIsDisableSummriz,
  setIsAline,
}) => {
  const onButtonClick = () => {
    const doc = new jsPDF();
    const margin = 10;
    const pageWidth = doc.internal.pageSize.getWidth() - margin * 2;
    const title = 'Summary';
    doc.setFontSize(16);
    doc.text(title, margin, margin);
    doc.setFontSize(12);
    const textLines = doc.splitTextToSize(summarizerText, pageWidth);
    const startY = margin + 10;
    const lineHeight = 8;
    textLines.forEach((line, index) => {
      doc.text(line, margin, startY + index * lineHeight);
    });
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    doc.save(`summary_${formattedDate}.pdf`);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Coppied');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };

  return (
    <div
      className={`h-[calc(100%-60px)] overflow-y-auto  flex p-12 gap-9 ${
        isAline === 'vertical' ? 'flex-col' : 'flex-row'
      }`}
    >
      <div
        className={` w-full mx-auto flex flex-col gap-6 min-h-[300px] ${
          isAline === 'vertical'
            ? 'h-full lg:h-[300px] min-h-[500px] !max-w-[932px]'
            : `${!summarizerText ? 'max-w-[932px]' : 'max-w-full'} h-auto`
        }`}
      >
        <h3 className="text-xl font-medium">Original Text</h3>

        <div className="h-full w-full relative">
          <textarea
            type="text"
            value={uploadFileText}
            onChange={(e) => {
              setUploadFileText(e.target.value);
              if (e.target?.value?.trim()) {
                setIsDisableSummriz(false);
              }
            }}
            placeholder="Your transcript will be generated here."
            className={`resize-none ${
              uploadFileText ? 'pr-12' : ''
            } w-full h-full helpdesk-custom-scrollbar text-base dark:border-gray-700 border-lightGray-10 placeholder:text-[#787878] px-6 pt-6 pb-4 rounded-2xl bg-lightGray-100 dark:bg-gray-900 border-2  text-black dark:text-white outline-none`}
          />

          {uploadFileText && (
            <button
              className="absolute right-6 top-6"
              onClick={() => setUploadFileText('')}
            >
              <CloseIcon />
            </button>
          )}

          {uploadFileText && (
            <button
              className="absolute right-6 bottom-6 text-gray-700 dark:text-white"
              onClick={() => handleCopy(uploadFileText)}
            >
              <RiFileCopy2Line size={20} />
            </button>
          )}
        </div>

        <div className="flex justify-between gap-y-3">
          <button
            type="button"
            onClick={() => setFileModelOpen(true)}
            className="py-2 rounded-lg w-fit px-3 hover:bg-blue-45 duration-300 font-poppins flex items-center gap-2 text-white text-base font-medium bg-blue-20"
          >
            <LuUpload size={20} />
            <span className="sm:block hidden">Upload file</span>
          </button>

          <div className="flex items-center">
            <button
              type="button"
              className="py-2 rounded-lg px-3 disabled:cursor-not-allowed hover:bg-blue-45 duration-300 disabled:border-lightGray-10 border border-transparent disabled:bg-[#F2F2F2] disabled:text-[#B1B1B1] font-poppins flex items-center gap-2 text-white text-base font-medium bg-blue-20"
              onClick={() => handleSummarize()}
              disabled={isLoading || isDisableSummriz || !uploadFileText}
            >
              <StarIcon />
              Summarize
            </button>
          </div>
        </div>
      </div>

      {!!summarizerText && (
        <div
          className={` w-full mx-auto flex flex-col gap-6 min-h-[300px] ${
            isAline === 'vertical'
              ? 'h-full lg:h-[300px] min-h-[500px] !max-w-[932px]'
              : `${!summarizerText ? 'max-w-[932px]' : 'max-w-full'} h-auto`
          }`}
        >
          <h3 className="text-xl font-medium">Summary</h3>

          <div className="h-full w-full relative">
            <textarea
              type="text"
              value={summarizerText}
              disabled
              placeholder="Your transcript will be generated here."
              className="resize-none pr-12 w-full leading-7 h-full helpdesk-custom-scrollbar text-base dark:border-gray-700 border-lightGray-10 placeholder:text-[#787878] px-6 pt-6 pb-4 rounded-2xl bg-lightGray-100 dark:bg-gray-900 border-2  text-black dark:text-white outline-none"
            />

            {summarizerText && (
              <button
                className="absolute right-6 bottom-6 text-gray-700 dark:text-white"
                onClick={() => handleCopy(summarizerText)}
              >
                <RiFileCopy2Line size={20} />
              </button>
            )}
          </div>

          <div className="flex items-center gap-7 justify-end pt-1 ">
            <button
              type="button"
              onClick={onButtonClick}
              className="flex items-center dark:text-white dark:hover:bg-gray-700 hover:bg-blue-10 py-2 px-3 rounded-lg text-blackDark-10 text-base gap-2.5"
            >
              <MdOutlineSaveAlt size={24} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummarizerUploadedFileText;
