import React, { useState } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { SlashIcon } from '../assets/icons/SlashIcon';
import BellIcon from '../assets/icons/BellIcon';
import { ProptIcon } from '../assets/icons/ProptIcon';
import { motion } from 'framer-motion';
import axios from 'axios';
import { toast } from 'react-toastify';
import SearchIcon from '../assets/icons/SearchIcon';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Frame = ({ children, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('avahiAI');

  const [input, setInput] = useState('');
  const [pdfUploadData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isMessageLoading, setMessageLoading] = useState(false);

  const handleSendMessage = async () => {
    if (input.trim()) {
      setMessages((prev) => [...prev, { text: input, sender: 'user' }]);
      setInput('');

      setTimeout(() => {
        const messagesList = document.querySelector('#messagesList');

        messagesList.scrollTo({
          top: messagesList.scrollHeight,
          behavior: 'smooth',
        });
      }, 5000);
      try {
        setMessageLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_PDF_VIEW_PATH}/get_answer`,
          {
            pdf_name: `dummy.pdf`,
            question: input,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          setMessageLoading(false);
          setMessages((prev) => [
            ...prev,
            {
              text: response?.data?.answer,
              sender: 'ai',
              sources: response?.data?.sources,
            },
          ]);
        } else {
          setMessageLoading(false);
          toast.error('Message sending failed.');
          throw new Error('Message failed');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className="flex bg-[#FCFCFC] dark:bg-gray-900 w-full h-full relative overflow-hidden">
      <div className="w-full">
        <div>
          <div className="flex items-start flex-col justify-between px-6 gap-3 py-[15px] border-b border-[#E2E0E5] dark:border-gray-600">
            <div className="flex items-center justify-between w-full">
              <h3 className="font-semibold text-xl -tracking-[0.5%] whitespace-nowrap">
                {name}
              </h3>

              <div className="w-full max-w-[410px] justify-end flex items-center gap-4">
                <div className="relative w-full max-w-[310px] sm:block hidden">
                  <input
                    type="text"
                    placeholder="Search documents"
                    className="w-full px-4 py-2 pl-9 rounded-full border border-[#E2E0E5] dark:border-gray-600 dark:bg-gray-700 bg-gray-100 text-[#787878] dark:text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#E2E0E5] dark:focus:ring-gray-600 "
                  />
                  <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <SearchIcon />
                  </div>
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                    <SlashIcon />
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <button>
                    <BellIcon />
                  </button>
                  <button onClick={() => setIsOpen(true)}>
                    <ProptIcon />
                  </button>
                </div>
              </div>
            </div>

            <div className="relative w-full sm:hidden">
              <input
                type="text"
                placeholder="Search documents"
                className="w-full px-4 py-2 pl-9 rounded-full border border-[#E2E0E5] dark:border-gray-600 dark:bg-gray-700 bg-gray-100 text-[#787878] dark:text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#E2E0E5] dark:focus:ring-gray-600 "
              />
              <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <SearchIcon />
              </div>
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                <SlashIcon />
              </div>
            </div>
          </div>
        </div>

        <div className="p-6 lg:max-h-[calc(100vh-73px)] max-h-[calc(100vh-165px)] overflow-y-auto">
          {children}
        </div>
      </div>
      <div
        className={`${
          isOpen ? 'max-w-[400px]' : 'max-w-0'
        } border-l block w-full sticky1 top-0 duration-300 h-full`}
      >
        <div className="flex items-center justify-between border-b border-gray-300 px-6 py-[2px]">
          <div className="flex space-x-4">
            <button
              className={`flex items-center space-x-2 border-b-2 py-[21px] ${
                activeTab === 'avahiAI'
                  ? 'text-blue-600 border-[#005CFF]'
                  : 'text-gray-500 border-transparent'
              } font-medium`}
              onClick={() => setActiveTab('avahiAI')}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" rx="3.55556" fill="#005CFF" />
                <path
                  d="M10.3711 4.44531H5.63037C5.25789 4.44531 4.95312 4.75007 4.95312 5.12256V9.8633C4.95312 10.2358 5.25789 10.5406 5.63037 10.5406H6.98487L8.00074 11.5564L9.01662 10.5406H10.3711C10.7436 10.5406 11.0484 10.2358 11.0484 9.8633V5.12256C11.0484 4.75007 10.7436 4.44531 10.3711 4.44531ZM8.63736 8.12955L8.00074 9.52468L7.36413 8.12955L5.969 7.49293L7.36413 6.85632L8.00074 5.46119L8.63736 6.85632L10.0325 7.49293L8.63736 8.12955Z"
                  fill="white"
                />
              </svg>
              <span>Avahi AI</span>
            </button>

            <button
              className={`${
                activeTab === 'details'
                  ? 'text-blue-600 border-[#005CFF]'
                  : 'text-gray-500 border-transparent'
              } font-medium border-b-2 py-[21px]`}
              onClick={() => setActiveTab('details')}
            >
              Details
            </button>
          </div>

          <button
            className="text-gray-500 hover:text-black"
            onClick={() => setIsOpen(false)}
          >
            ✕
          </button>
        </div>

        <div
          className="flex flex-col items-center w-full px-6 py-5 h-[92%] overflow-hidden"
          style={{
            background:
              'linear-gradient(90deg, #FCFCFC 24.9%, #D8E6FF 124.92%)',
          }}
        >
          <div className="w-full h-full">
            {!messages.length ? (
              <div className="flex flex-col items-center justify-center h-full">
                <img src="/images/proptLogo.png" className="w-9 h-9" alt="" />

                <div className="mt-4">
                  <h2 className="text-xl text-center font-semibold text-[#252525]">
                    Hi there, John!
                  </h2>
                  <h2 className="text-xl text-center font-semibold text-[#252525]">
                    What would you like to know?
                  </h2>
                  <p className="font-normal text-xs text-[#252525] mt-6">
                    Use one of the suggested prompts below or write your own.{' '}
                  </p>
                </div>

                <div className="mt-2 space-y-4">
                  <div className="bg-white rounded-lg px-4 py-3 flex flex-col items-start gap-3 border-[0.5px] border-[#F1EBF8]">
                    <img src="/images/calender.png" alt="" />

                    <div className="flex-1">
                      <p className="text-[#252525] font-normal text-sm">
                        Send me an update email when Debt tracking reaches 100%,
                        or when Total debt reaches $10k.
                      </p>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg px-4 py-3 flex flex-col items-start gap-3 border-[0.5px] border-[#F1EBF8]">
                    <img src="/images/chart.png" alt="" />
                    <div className="flex-1">
                      <p className="text-[#252525] font-normal text-sm">
                        Could you provide a summary of website trends over the
                        last month?
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-2 w-full">
                  <button className="text-[#252525] gap-1 flex items-center justify-start text-xs">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 5.50034C9.87772 4.62045 9.46953 3.80517 8.83832 3.18009C8.2071 2.55501 7.38788 2.1548 6.50684 2.04112C5.62579 1.92743 4.73181 2.10658 3.9626 2.55096C3.19339 2.99535 2.59162 3.68031 2.25 4.50034M2 2.50034V4.50034H4M2 6.50034C2.12228 7.38023 2.53047 8.19551 3.16168 8.82059C3.7929 9.44568 4.61212 9.84588 5.49316 9.95957C6.37421 10.0732 7.26819 9.8941 8.0374 9.44972C8.80661 9.00534 9.40838 8.32037 9.75 7.50034M10 9.50034V7.50034H8"
                        stroke="#252525"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Refresh prompts
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center rounded-lg w-full">
                <div className="w-full h-full gap-4 flex flex-col justify-between">
                  <div
                    className="overflow-auto max-h-[calc(100vh-200px)] p-4 h-full"
                    id="messagesList"
                  >
                    {messages.map((message, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                        className={`mb-4 ${
                          message.sender === 'user' ? 'text-right' : 'text-left'
                        }`}
                      >
                        <div
                          className={`inline-block p-3 rounded-lg ${
                            message.sender === 'user'
                              ? 'bg-blue-500 dark:bg-blue-600 text-white'
                              : 'bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white'
                          }`}
                        >
                          <p className="text-sm font-semibold mb-1">
                            {message.sender === 'user' ? 'You' : 'AI Assistant'}
                          </p>
                          <p>{message.text}</p>
                        </div>
                        <div className="flex items-center gap-2">
                          {message?.sender &&
                            message.sender === 'ai' &&
                            message?.sources?.map((item, index) => (
                              <div
                                key={index}
                                className="mt-3 bg-gray-50 rounded-md border w-fit px-2 py-0.5 border-gray-300"
                              >
                                <span>p. {item}</span>
                              </div>
                            ))}
                        </div>
                      </motion.div>
                    ))}

                    {isMessageLoading && (
                      <div className="flex flex-col items-start gap-2.5">
                        <div className="dotPulse text-[#737373] dark:text-white text-sm font-poppins leading-5 font-normal">
                          <span className="pr-0.5">
                            Searching for relevant sources
                          </span>
                          <span className="dot dark:darkDot" />
                          <span className="dot dark:darkDot" />
                          <span className="dot dark:darkDot" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border flex flex-col gap-2 px-4 py-3 border-gray-200 dark:border-gray-400 w-full bg-white rounded-xl dark:bg-gray-500">
            <div className="flex items-center rounded-lg">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-grow pr-3 bg-transparent focus:outline-none text-sm dark:text-white dark:placeholder:text-white placeholder:text-gray-500 text-black"
                placeholder="Ask a question..."
                onKeyDown={handleKeyDown}
              />
              <div className="flex gap-2">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 11C3.675 11 3 11.675 3 12.5C3 13.325 3.675 14 4.5 14C5.325 14 6 13.325 6 12.5C6 11.675 5.325 11 4.5 11ZM19.5 11C18.675 11 18 11.675 18 12.5C18 13.325 18.675 14 19.5 14C20.325 14 21 13.325 21 12.5C21 11.675 20.325 11 19.5 11ZM12 11C11.175 11 10.5 11.675 10.5 12.5C10.5 13.325 11.175 14 12 14C12.825 14 13.5 13.325 13.5 12.5C13.5 11.675 12.825 11 12 11Z"
                    fill="black"
                  />
                </svg>

                <button onClick={handleSendMessage} disabled={!pdfUploadData}>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1493_22483)">
                      <rect
                        width="25"
                        height="25"
                        rx="6.11111"
                        fill="#377FFF"
                      />
                      <path
                        d="M17.5011 8.71149C17.7737 7.95742 17.0429 7.2267 16.2889 7.49993L7.07283 10.833C6.31624 11.1068 6.22474 12.1392 6.92075 12.5424L9.86257 14.2455L12.4895 11.6186C12.6085 11.5037 12.7679 11.4401 12.9334 11.4415C13.0988 11.4429 13.2571 11.5093 13.3741 11.6263C13.4911 11.7433 13.5574 11.9016 13.5589 12.067C13.5603 12.2325 13.4967 12.3919 13.3818 12.5109L10.7548 15.1378L12.4586 18.0796C12.8612 18.7756 13.8935 18.6835 14.1674 17.9275L17.5011 8.71149Z"
                        fill="#252525"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1493_22483">
                        <rect
                          width="25"
                          height="25"
                          rx="6.11111"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frame;
