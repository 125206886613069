import { useState, useRef, useEffect } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa6';

const DataExtractionAccordian = ({
  title,
  content,
  className,
  defaultOpen = false,
}) => {
  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [maxHeight, setMaxHeight] = useState('0px');

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setMaxHeight(isOpen ? `${contentRef.current?.scrollHeight}px` : '0px');

    const handleResize = () => {
      const newMaxHeight = isOpen
        ? `${contentRef.current?.scrollHeight}px`
        : '0px';
      setMaxHeight(newMaxHeight);
    };

    if (typeof window !== 'undefined') {
      window?.addEventListener('resize', handleResize);
    }

    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, [isOpen, contentRef]);

  return (
    <div
      className={`border-b dark:border-gray-600 border-gray-10 py-4 last:mb-0 last:border-b-transparent dark:last:border-transparent  ${className?.mainWrapperClassName}`}
    >
      <div
        className={`cursor-pointer select-none flex items-center justify-between transition duration-300 ease-in-out ${className?.titleClassName}`}
        onClick={handleClick}
      >
        <div className="text-blackDark-10 dark:text-white font-medium text-base font-poppins">
          {title}
        </div>
        {isOpen ? (
          <FaMinus className="w-3.5 text-blackDark-10 dark:text-white" />
        ) : (
          <FaPlus className="w-3.5 text-blackDark-10 dark:text-white" />
        )}
      </div>

      <div
        ref={contentRef}
        style={{
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-in-out',
        }}
        className={`transition-all ${className?.contentClassName}`}
      >
        {content}
      </div>
    </div>
  );
};

export default DataExtractionAccordian;
