import React, { useContext, useState } from 'react';
import {
  ChevronRight,
  HelpCircle,
  LogOut,
  Moon,
  Sun,
  UserX,
} from 'react-feather';
import { IoClose } from 'react-icons/io5';
import LanguageSelector from './LanguageSelector';
import { ThemeContext } from '../components/ThemeProvider';

import { motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import useAuthStore from '../stores/authStore';
import { MdIntegrationInstructions } from 'react-icons/md';
import CategoryDropdown from './LeftPanelFiles/CategoryDropdown';

const MobileLeftPanel = ({
  setIsMenuOpen,
  selectedCategory,
  setSelectedCategory,
  categories,
  selectedLanguage,
  setSelectedLanguage,
  setExpandedManuOpen,
  setOpenMenu,
  manuOpen,
}) => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const { isLoggedIn, toggleLogoutPopup, toggleDeleteAccountPopup } =
    useAuthStore();
  const [expandedSections, setExpandedSections] = useState({
    Tools: true,
    Objects: true,
  });

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const MenuItem = ({ icon: Icon, path, text }) => {
    return (
      <NavLink
        to={path}
        onClick={() => setIsMenuOpen(false)}
        className={({ isActive }) =>
          `flex items-center px-4 py-2 mt-1 dark:hover:bg-gray-700 transition-all duration-200 ${
            isActive
              ? 'bg-blue-10 dark:bg-gray-700 border border-blue-20  dark:text-white dark:!border-gray-500 text-blue-20 font-semibold rounded-lg'
              : 'text-gray-700 dark:text-gray-200'
          } justify-start`
        }
      >
        <Icon size={20} className="flex-shrink-0" />
        <span className="ml-3 truncate">{text}</span>
      </NavLink>
    );
  };

  const renderMenuItem = (item) => (
    <MenuItem
      key={item.text}
      icon={item.icon}
      text={item.text}
      path={item.path}
    />
  );

  const renderSection = (section) => (
    <div key={section?.section}>
      <button
        onClick={() => toggleSection(section?.section)}
        className={`flex items-center w-full px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 justify-start`}
      >
        <section.icon size={20} />

        <>
          <span className="ml-3">{section.section}</span>
          <ChevronRight
            size={16}
            className={`ml-auto transition-transform duration-200 ${
              expandedSections[section.section] ? 'rotate-90' : ''
            }`}
          />
        </>
      </button>

      {expandedSections[section?.section] && (
        <>
          {console.log(section)}

          <div className="pl-4">{section?.items.map(renderMenuItem)}</div>

          {section?.objects && (
            <div className="flex flex-col gap-1">
              <button
                type="button"
                onClick={() => {
                  setOpenMenu(!manuOpen);
                }}
                className={`py-2 px-3 dark:hover:bg-gray-600 hover:bg-gray-200 text-base  w-full transition-all text-gray-700 dark:text-white duration-200 font-normal flex items-center justify-between`}
              >
                {section?.objects?.text}
                <ChevronRight
                  size={16}
                  className={`ml-auto transition-transform duration-200 ${
                    manuOpen ? 'rotate-90' : ''
                  } `}
                />
              </button>
              {manuOpen && (
                <div className={`pl-4 pr-1.5`}>
                  {section?.objects?.items?.map(
                    ({ path, icon: Icon, text }, key) => (
                      <NavLink
                        key={key}
                        to={path}
                        title={text}
                        onClick={() => {
                          setOpenMenu(false);
                          setIsMenuOpen(false);
                        }}
                        className={`flex !items-center mt-2 first:mt-0 mx-2 p-2 duration-300 hover:bg-white dark:hover:bg-gray-900 rounded-lg transition-all text-gray-700 dark:text-white font-normal`}
                      >
                        <Icon size={20} className="flex-shrink-0" />
                        <span className="ml-3 truncate text-base">{text}</span>
                      </NavLink>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  const bottomMenuItemClass = `flex items-center border-transparent dark:hover:border-gray-500 border w-full px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-blue-10 hover:border-blue-20 hover:text-blue-20 dark:hover:bg-gray-700 transition-all duration-200 rounded-md`;
  return (
    <div
      className={`h-screen fixed top-0 w-screen slideInRight bg-white z-[99999] dark:bg-gray-800 lg:hidden`}
    >
      <div className="px-5 py-3 w-full h-full overflow-y-auto flex flex-col gap-3">
        <div className="flex items-center w-full justify-between">
          <button onClick={() => setIsMenuOpen(false)}>
            <IoClose size={25} className="text-gray-900 dark:text-white" />
          </button>
        </div>

        <div className="flex items-center w-full gap-2 border-b border-gray-300 border-dashed dark:border-gray-500 pb-3">
          <CategoryDropdown
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            categories={categories}
            key={selectedCategory}
            openPanel={true}
            setExpandedManuOpen={setExpandedManuOpen}
          />

          <div className={`flex gap-3  items-center flex-col}`}>
            <div
              className={`relative flex items-center bg-[#EFEFEF] shadow-inner dark:bg-[#221638] rounded-full cursor-pointer w-12 h-6
          }`}
              onClick={toggleDarkMode}
            >
              <motion.div
                className={`absolute flex items-center justify-center rounded-full bg-[#FFFFFF] dark:bg-gradient-to-b dark:from-[#6C36B4] dark:to-[#221638]  w-5 h-5`}
                animate={{
                  x: isDarkMode ? 24 : 2,
                }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
              >
                {isDarkMode ? (
                  <Moon size={12} className="text-white" />
                ) : (
                  <Sun size={12} className="text-gray-800" />
                )}
              </motion.div>
            </div>
            <div>
              <LanguageSelector
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
            </div>
          </div>
        </div>

        <nav className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-600 scrollbar-track-transparent">
          {categories[selectedCategory].map((item) =>
            item.section ? renderSection(item) : renderMenuItem(item)
          )}
        </nav>

        <div className="py-4 border-t border-gray-200 dark:border-gray-700 space-y-2">
          <NavLink
            to="/help"
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `${bottomMenuItemClass} ${
                isActive
                  ? 'bg-blue-10 dark:bg-gray-700 !text-blue-20 dark:text-white font-semibold'
                  : ''
              }`
            }
          >
            <HelpCircle size={20} className="flex-shrink-0" />
            <span className="ml-3 truncate">Help</span>
          </NavLink>
          <NavLink
            to="/integration"
            onClick={() => setIsMenuOpen(false)}
            className={({ isActive }) =>
              `${bottomMenuItemClass} ${
                isActive
                  ? 'bg-blue-10 dark:bg-gray-700  !text-blue-20 dark:text-white font-semibold'
                  : ''
              }`
            }
          >
            <MdIntegrationInstructions size={20} className="flex-shrink-0" />
            <span className="ml-3 truncate">Integration</span>
          </NavLink>
          <button
            onClick={() => {
              setIsMenuOpen(false);
              toggleDeleteAccountPopup();
            }}
            className={bottomMenuItemClass}
          >
            <UserX size={20} className="flex-shrink-0" />
            <span className="ml-3 truncate">Delete Account</span>
          </button>
          {isLoggedIn && (
            <button
              onClick={() => {
                setIsMenuOpen(false);
                toggleLogoutPopup();
              }}
              className={`${bottomMenuItemClass} bg-transparent dark:bg-transparent}`}
            >
              <LogOut size={20} className="flex-shrink-0" />
              <span className="ml-3 truncate">Logout</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileLeftPanel;
