import React from "react";

const ChatContainer = ({ children }) => {
  return (
    <div className="flex flex-col w-full h-full max-h-full mx-auto my-0 rounded-lg shadow-2xl overflow-hidden bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-gray-800 dark:to-gray-900">
      {children}
    </div>
  );
};

export default ChatContainer;
