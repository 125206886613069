import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import ReloadIcon from '../../../assets/icons/ReloadIcon';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';

const StructureExtractionSettingsModel = ({
  isOpen,
  setIsOpen,
  settings,
  setSettings,
  defaultSettings,
}) => {
  const [newLabel, setNewLabel] = useState('');
  const [isInputVisible, setIsInputVisible] = useState(false);

  const handleToggle = (id) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id
          ? { ...setting, isSelect: !setting.isSelect }
          : setting
      )
    );
  };

  const handleAddNewLabel = () => {
    if (newLabel?.length > 2 && newLabel?.trim()) {
      const newSetting = {
        id: settings?.length + 1,
        label: newLabel,
        isSelect: true,
      };

      const isSame = settings?.some(
        (setting) =>
          setting.label?.toLowerCase()?.trim() ===
          newLabel?.toLowerCase()?.trim()
      );

      if (!isSame) {
        setSettings((prevSettings) => [...prevSettings, newSetting]);
        setNewLabel('');
        setIsInputVisible(false);
      } else {
        toast.error('Label already exists');
      }
    } else {
      toast.error('Label atleast 3 characters long');
    }
  };

  const handleReload = () => {
    setNewLabel('');
    setSettings(defaultSettings);
    setIsInputVisible(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="bg-[#FCFCFC] dark:bg-gray-700 flex flex-col gap-6 w-full p-6 shadow-shadow-box rounded-xl">
          <div className="flex w-full items-center justify-between">
            <h2 className="text-xl tracking-[-0.4px] font-medium">Settings</h2>

            <button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <MdClose size={23} />
            </button>
          </div>

          <div className="flex flex-col items-start gap-5 w-full">
            {settings?.map(({ id, label, description, isSelect }) => (
              <div
                key={id}
                className="flex items-center justify-between w-full gap-1.5"
              >
                <div className="flex flex-col max-w-[175px] gap-0.5 w-full">
                  <h3 className="text-sm font-medium tracking-[-0.28px] dark:text-white text-blackDark-10">
                    {label}
                  </h3>
                  <p className="text-[10px] font-normal tracking-[-0.2px]">
                    {description}
                  </p>
                </div>

                <button
                  onClick={() => handleToggle(id)}
                  className={`w-9 p-[2px] h-5 rounded-full outline-none transition-colors duration-200 ${
                    isSelect ? 'bg-blue-45' : 'bg-gray-300'
                  }`}
                >
                  <div
                    className={`bg-white w-4 h-4 rounded-full transition-transform duration-200 ${
                      isSelect ? 'translate-x-4' : 'translate-x-0'
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>

          {isInputVisible && (
            <div className="pt-4 border-t border-gray-10 dark:border-gray-500">
              <div className="flex items-center gap-3 mb-2">
                <input
                  type="text"
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                  className="bg-transparent w-full border border-gray-10 font-medium text-sm dark:bg-gray-800 dark:border-gray-700 rounded-lg p-2.5 outline-none text-black dark:text-white"
                  placeholder="Type setting label"
                />
                <button
                  onClick={handleAddNewLabel}
                  className="bg-blue-20 w-fit duration-300 hover:bg-blue-45 gap-1.5 rounded-lg text-white flex items-center justify-center text-base font-medium py-2 px-3"
                >
                  Save
                </button>
              </div>
            </div>
          )}

          <div className="w-full flex items-center gap-3">
            <button
              onClick={handleReload}
              className="bg-blue-20 duration-300 hover:bg-blue-45 w-full gap-1.5 rounded-lg text-white flex items-center justify-center text-base font-medium py-2"
            >
              <ReloadIcon />
              Reload
            </button>
            <button
              onClick={() => setIsInputVisible(!isInputVisible)}
              className="bg-blue-20 duration-300 hover:bg-blue-45 w-full gap-1.5 rounded-lg text-white flex items-center justify-center text-base font-medium py-2"
            >
              {!isInputVisible && <FaPlus size={20} />}
              {isInputVisible ? 'Cancel' : 'Add new label'}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default StructureExtractionSettingsModel;
