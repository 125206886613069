import React, { useState, useEffect, useRef } from 'react';
import RecordRTC from 'recordrtc';
import StaredRecording from './StaredRecording';
import InitialRecording from './InitialRecording';
import RecordingComplete from './RecordingComplete';
import axios from 'axios';
import { toast } from 'react-toastify';

const AudioRecording = ({
  initialFile,
  setFile,
  setIsFileLoading,
  setUploadedFileResponse,
}) => {
  const [error, setError] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStoped, setIsStoped] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [recordRTC, setRecordRTC] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingTotalTime, setRecordingTotalTime] = useState(0);
  const [audioURL, setAudioURL] = useState(null);

  const mediaStreamRef = useRef(null);
  const intervalRef = useRef(null);

  const maxFileSize = 25 * 1024 * 1024;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        setError('File exceeds the maximum size limit of 25MB');
        return;
      }

      if (selectedFile.type !== 'audio/mpeg') {
        setError('Invalid File Format. Please upload an MP3 file.');
        return;
      }

      setError(null);
      setFile(selectedFile);

      const fileURL = URL.createObjectURL(event.target.files[0]);

      if (fileURL) {
        setIsRecording(true);
        setIsPaused(true);
        setIsStoped(true);
        setIsSaved(false);
        setAudioURL(fileURL);
      }
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      const recorder = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/mp3',
      });
      recorder.startRecording();
      setRecordRTC(recorder);
      setIsRecording(true);
      setIsPaused(false);
      setIsSaved(false);
      setRecordingTime(0);
      setRecordingTotalTime(0);
      setFile(null);
      setAudioURL(null);
      intervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (err) {
      setError(
        'Unable to access the microphone. Please check your browser settings.'
      );
    }
  };

  const pauseRecording = () => {
    if (recordRTC && isRecording && !isPaused) {
      recordRTC.pauseRecording();
      setIsPaused(true);
      setIsStoped(false);
      clearInterval(intervalRef.current);
    }
  };

  const resumeRecording = () => {
    if (recordRTC && isPaused) {
      recordRTC.resumeRecording();
      setIsPaused(false);
      intervalRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const stopRecording = () => {
    if (recordRTC) {
      recordRTC.stopRecording(() => {
        const blob = recordRTC.getBlob();
        const file = new File([blob], 'recording.mp3', { type: 'audio/mp3' });
        setFile(file);
        setIsPaused(true);
        setIsStoped(true);
        clearInterval(intervalRef.current);
        setRecordingTotalTime(recordingTime);
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());

        const fileURL = URL.createObjectURL(file);

        if (fileURL) {
          setAudioURL(fileURL);
        }
      });
    }
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    if (isRecording) {
      if (isPaused) {
        resumeRecording();
      } else {
        pauseRecording();
      }
    } else {
      startRecording();
    }
  };

  const handlesendRecording = async () => {
    if (initialFile) {
      try {
        setIsFileLoading(true);
        const formData = new FormData();

        formData.append('file', initialFile);

        const response = await axios.post(
          `${process.env.REACT_APP_API_HEALTHCARE_UPLOAD_FILE}/upload_and_transcribe`,
          formData,
          {
            maxBodyLength: Infinity,
          }
        );

        if (response.status === 200) {
          setUploadedFileResponse(response?.data);
          toast.success('Audio file uploaded successfully!');
          setIsRecording(false);
          setIsSaved(true);
        } else {
          toast.error('Audio file upload failed.');
          throw new Error('Upload failed');
        }
      } catch (error) {
        console.error('Error uploading Audio:', error);
        toast.error('Error uploading Audio file.');
      } finally {
        setIsFileLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div>
      {isRecording && (
        <StaredRecording
          isPaused={isPaused}
          isStoped={isStoped}
          recordingTime={recordingTime}
          handlesendRecording={handlesendRecording}
          resumeRecording={resumeRecording}
          pauseRecording={pauseRecording}
          startRecording={startRecording}
          stopRecording={stopRecording}
          audioURL={audioURL}
        />
      )}

      {!isRecording && !isSaved && (
        <InitialRecording
          handleFileChange={handleFileChange}
          handleButtonClick={handleButtonClick}
          isRecording={isRecording}
        />
      )}

      {isSaved && (
        <RecordingComplete
          startRecording={startRecording}
          recordingTotalTime={recordingTotalTime}
          handleFileChange={handleFileChange}
          audioURL={audioURL}
        />
      )}

      <p className="mt-1.5 flex items-center justify-between font-poppins text-[10px] font-normal leading-3 text-[#9B9B9B]">
        Supported format: MP3
        <span>Maximum size: 25MB</span>
      </p>
      {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default AudioRecording;
