export const SlashIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.333333"
        y="0.333333"
        width="15.3333"
        height="15.3333"
        rx="3.66667"
        stroke="#E2E0E5"
        strokeWidth="0.666667"
      />
      <path
        d="M9.36238 3.48L7.15438 12.328H6.60238L8.79438 3.48H9.36238Z"
        fill="#A1A1A1"
      />
    </svg>
  );
};
