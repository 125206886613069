import React from 'react';

const NewLiveAudioRecording = ({
  isPaused,
  isStoped,
  recordingTime,
  handleStartRecording,
  handleResumeRecording,
  handlePauseRecording,
  handleStopRecording,
  audioURL,
}) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`;
  };

  const bars = new Array(16).fill(null);
  return (
    <div>
      <div
        className={`flex items-center flex-col gap-4 justify-center rounded-lg border-2 border-dashed border-blue-500 px-2 py-6 transition-all md:px-10`}
      >
        <h3 className="font-poppins text-base font-semibold text-black">
          {isPaused
            ? isStoped
              ? 'Recording Completed'
              : 'Recording paused'
            : 'Recording...'}
        </h3>

        <p className="font-poppins text-base font-semibold text-black">
          {formatTime(recordingTime)}
        </p>

        {isPaused ? (
          <button
            onClick={() => {
              if (isStoped) {
                handleStartRecording();
              } else {
                handleResumeRecording();
              }
            }}
            className="relative group"
          >
            <img
              src={`${
                isStoped
                  ? '/images/SendRecordingIcon.png'
                  : '/images/PauseRecordingIcon.png'
              }`}
              alt="mic-img"
              className="size-[80px]"
            />

            {isStoped && (
              <div className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:text-blue-20 duration-200">
                <svg
                  width="45"
                  height="45"
                  viewBox="0 0 76 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1868_2441)">
                    <path
                      d="M75.1183 9.06526C76.9583 3.9753 72.0259 -0.957067 66.936 0.887247L4.72767 23.3853C-0.379328 25.2339 -0.99694 32.2022 3.70116 34.924L23.5584 46.4201L41.2902 28.6883C42.0936 27.9124 43.1695 27.4831 44.2863 27.4928C45.4031 27.5025 46.4714 27.9504 47.2611 28.7401C48.0508 29.5299 48.4988 30.5982 48.5085 31.715C48.5182 32.8318 48.0889 33.9077 47.313 34.711L29.5812 52.4428L41.0815 72.3001C43.799 76.9982 50.7674 76.3763 52.6159 71.2736L75.1183 9.06526Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1868_2441">
                      <rect width="76" height="76" fill="currentColor" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </button>
        ) : (
          <button
            onClick={() => {
              // handlePauseRecording();
              handleStopRecording();
            }}
          >
            <div className="flex items-center bg-blue-10 rounded-full justify-between p-6 size-[80px] space-x-0.5">
              {bars.map((_, index) => (
                <div
                  key={index}
                  className={`w-4 bg-blue-500 rounded animate-wave animation-delay-${index}`}
                  style={{
                    height: `${Math.random() * (100 - 50) + 80}%`,
                    animationDelay: `${index * 0.1}s`,
                  }}
                />
              ))}
            </div>
          </button>
        )}

        <div className="max-w-[350px] w-full flex items-center gap-2 justify-between">
          <div className="flex items-center justify-center w-full gap-4">
            {/* {isPaused ? (
              <button
                onClick={() => {
                  if (isStoped) {
                    handleStartRecording();
                  } else {
                    handleResumeRecording();
                  }
                }}
              >
                <img
                  src="/images/ResumeIcon.png"
                  alt="Resume icon img"
                  className="size-9 min-h-9 min-w-9 h-full w-full"
                />
              </button>
            ) : (
              <button onClick={handlePauseRecording}>
                <img
                  src="/images/PauseIcon.png"
                  alt="Pause icon img"
                  className="size-9 min-h-9 min-w-9 h-full w-full"
                />
              </button>
            )} */}

            <button onClick={handleStopRecording}>
              <img
                src="/images/StopIcon.png"
                alt="Stop icon img"
                className="size-9 min-h-9 min-w-9 h-full w-full"
              />
            </button>
          </div>
        </div>

        {audioURL && (
          <audio
            src={audioURL}
            type="audio/mpeg"
            className="w-full max-w-[375px] mx-auto"
            controls
          >
            <source />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
    </div>
  );
};

export default NewLiveAudioRecording;
